import { useState, useEffect, useRef } from "react";
import axios from "axios";
// Use Socket io - import
//import io from "socket.io-client";
// import { useContext } from 'react';
import { SharedDataContext } from "../components/app/UseContext";

// import constants
import {
  API,
  LOCAL_API,
  SOCKET,
  SIO_TICKET_SETTINGS,
  SIO_AGENT_FORM_XP,
  SIO_AGENT_PLATFORM,
  SIO_GET_TICKET,
  SIO_GET_TICKET_DETAILS,
  SIO_DELETE_AGENT_PLATFORM,
  SIO_TICKET_STATS,
  SIO_TICKET_UPDATE,
  SIO_GET_LATEST_TICKET,
  SIO_TICKET_CHART,
  SIO_TICKET_DELETE,
  SIO_GET_TICKET_SUBJECT,
  SIO_DELETE_TICKET_SUBJECT,
  SIO_GET_TICKET_SUBJECT_SETTINGS,
  SIO_CREATE_TICKET_NATURE,
  SIO_UPDATE_TICKET_NATURE,
  SIO_DELETE_TICKET_NATURE,
  SIO_GET_TICKET_NATURE,
  SIO_GET_TICKET_NATURE_SETTINGS
} from "../constants/Constants";

axios.defaults.baseURL = API.BASE_URL;

//const socket = io(SOCKET.BASE_URL);
//Agent Route
const GET_AGENT_APPID = "rightcare";
const GET_AGENT_ALIAS = "MB1O5HSOK97W"; //MB1O5HSOK97W
const GET_AGENT_FROM_XP = "/agent?sio_channel=";
const SAVE_AGENT_SINCE_CARE = "/agent/add-platform";
const DELETE_AGENT_SINCE_CARE = "/agent/platform/";
const GET_AGENT_PLATFORM = "/agent/platform?sio_channel=";
const GET_CHECK_ONBOARDING = "/agent/check_onboarding";
const PUT_CHECK_ONBOARDING = "/agent/update_onboarding";
//ticket Route
const GET_TICKET_SETTING = "/ticketsettings?sio_channel=";
const UPDATE_SWITCH_TICKET_SETTING = "/ticketsettings";
const UPDATE_SWITCH_ENABLE_CUSTOM_TICKET = "/auto-priorization/enable";
const CREATE_TICKET_SUBJECT = "/auto-priorization";
const UPDATE_TICKET_SUBJECT = "/auto-priorization/";
const DELETE_TICKET_SUBJECT = "/auto-priorization/";
const GET_TICKET_SUBJECT = "/auto-priorization?sio_channel=";
const GET_TICKET_SUBJECT_SETTINGS = "/auto-priorization/settings?sio_channel=";
const CREATE_CUTOMERFILED_TICKET_SETTING = "/ticketsettings/add-customer-info";
const GET_TICKET_STATS = "/ticket/stats?sio_channel=";
const CREATE_TICKET = "/ticket";
const GET_ALL_TICKET = "/ticket?sio_channel=";
const GET_TICKET_INFO = "/ticket/info";
const GET_LATEST_TICKET = "/ticket/latest?sio_channel=";
const GET_TICKET_DETAILS = "/ticket/";
const GET_TICKET_STATS_CHART = "/ticket/chart?sio_channel=";
const GET_TICKET_REPORT = "/ticket/report";

const CREATE_TICKET_NATURE = "/nature";
const GET_ALL_TICKET_NATURE_SETTING = "/natures?sio_channel=";
const GET_TICKET_NATURE = "/nature?sio_channel=";
const UPDATE_TICKET_NATURE = "/nature/";
const DELETE_TICKET_NATURE = "/nature/";

const GET_CUSTOM_TICKET_FIELD = "/custom-ticket-settings";
const CREATE_CUSTOM_TICKET_FIELD = "/custom-ticket-settings";
const UPDATE_CUSTOM_TICKET_FIELD = "/custom-ticket-settings";
const DELETE_CUSTOM_TICKET_FIELD = "/custom-ticket-settings?field_id=";
const UPDATE_STATUS_CUSTOM_TICKET_FIELD =
  "/custom-ticket-settings/status?status=";

const CREATE_CONVERSATIONAL_FORM = "/conversational-form/create";
const GET_MANY_CONVERSATIONAL_FORM = "/conversational-form/list";
const GET_ONE_CONVERSATIONAL_FORM = "/conversational-form/get-one";
const CHANGE_STATUS_CONVERSATIONAL_FORM = "/conversational-form/change-status";
const UPDATE_CONVERSATIONAL_FORM = "/conversational-form/update";
const DELETE_CONVERSATIONAL_FORM = "/conversational-form/delete";

function GetCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

const QUERY_PARAMS = {
  headers: {
    "Content-Type": "application/json"
  }
};
const QUERY_PARAMS_INTERCEPTOR = {
  // headers: {
  //   "Content-Type": "application/json",
  //   publickey: GetCookie("PUBLICKEY"),
  //   apisid: GetCookie("APISID"), // p72f28ja8lcMu6UMwhmcEqUrgS2osgxJZnkS78Lt
  //   sessionid: sessionStorage.getItem("session_id")
  // }
};

/** ** */
export const check_onboardingHttpService = {
  check_onboarding(receptor) {
    // console.log(receptor);
    return axios.get(`${API.BASE_URL + GET_CHECK_ONBOARDING}`, receptor);
  },
  update_onboarding(params, receptor) {
    //console.log(API.BASE_URL + PUT_CHECK_ONBOARDING);
    return axios.put(
      `${API.BASE_URL + PUT_CHECK_ONBOARDING}`,
      params,
      receptor
    );
  }
};

export const AgentFromXPHttpService = {
  getDatasAgentFromXP(receptor) {
    //console.log(receptor);
    return axios.get(
      `${API.BASE_URL +
        GET_AGENT_FROM_XP +
        SIO_AGENT_FORM_XP}&appid=${GET_AGENT_APPID}&alias=${GET_AGENT_ALIAS}`,
      receptor
    );
  },

  SaveAgentSinceCare(params, headers) {
    return axios.post(API.BASE_URL + SAVE_AGENT_SINCE_CARE, params, headers);
  },

  deleteAgentSinceCare(user_connected_id, id_agent, headers) {
    return axios.delete(
      API.BASE_URL +
        DELETE_AGENT_SINCE_CARE +
        id_agent +
        "?sio_channel=" +
        SIO_DELETE_AGENT_PLATFORM +
        "&user_connected_id=" +
        user_connected_id,
      headers
    );
  }
};

export const CustomTicketFieldHttpService = {
  getAll(headers) {
    return axios.get(API.BASE_URL + GET_CUSTOM_TICKET_FIELD, headers);
  },

  createOne(params, headers) {
    return axios.post(
      API.BASE_URL + CREATE_CUSTOM_TICKET_FIELD,
      params,
      headers
    );
  },

  updateOne(params, headers) {
    return axios.put(
      API.BASE_URL + UPDATE_CUSTOM_TICKET_FIELD,
      params,
      headers
    );
  },

  deleteOne(id, headers) {
    return axios.delete(
      API.BASE_URL + DELETE_CUSTOM_TICKET_FIELD + id,
      headers
    );
  },

  updateStatus(status, headers) {
    return axios.put(
      API.BASE_URL + UPDATE_STATUS_CUSTOM_TICKET_FIELD + status,
      {},
      headers
    );
  }
};

export const TicketSettingsHttpService = {
  getDatasTicketSettings(headers) {
    return axios.get(
      API.BASE_URL + GET_TICKET_SETTING + SIO_TICKET_SETTINGS,
      headers
    );
  },

  createCustomerFiledTicketSettings(params) {
    return axios.post(
      API.BASE_URL + CREATE_CUTOMERFILED_TICKET_SETTING,
      params,
      QUERY_PARAMS_INTERCEPTOR
    );
  },
  // Start Ticket Nature Requests
  createTicketNature(params, headers) {
    return axios.post(API.BASE_URL + CREATE_TICKET_NATURE, params, headers);
  },
  updateTicketNature(id, params, headers) {
    return axios.put(
      API.BASE_URL + UPDATE_TICKET_NATURE + id + "/update",
      params,
      headers
    );
  },
  deleteTicketNature(id, headers) {
    return axios.delete(
      API.BASE_URL +
        DELETE_TICKET_NATURE +
        id +
        "/delete?sio_channel=" +
        SIO_DELETE_TICKET_NATURE,
      headers
    );
  },
  getTicketNature(headers) {
    return axios.get(
      API.BASE_URL + GET_TICKET_NATURE + SIO_GET_TICKET_NATURE,
      headers
    );
  },
  getTicketNatureSettings(headers) {
    return axios.get(
      API.BASE_URL +
        GET_ALL_TICKET_NATURE_SETTING +
        SIO_GET_TICKET_NATURE_SETTINGS,
      headers
    );
  },

  // End Ticket Nature Requests

  updateSwitchTicketSettings(params) {
    return axios.post(
      API.BASE_URL + UPDATE_SWITCH_TICKET_SETTING,
      params,
      QUERY_PARAMS_INTERCEPTOR
    );
  },
  updateSwitchEnableCustomTicket(params, headers) {
    return axios.put(
      API.BASE_URL + UPDATE_SWITCH_ENABLE_CUSTOM_TICKET,
      params,
      headers
    );
  },
  createTicketSubject(params, headers) {
    return axios.post(API.BASE_URL + CREATE_TICKET_SUBJECT, params, headers);
  },
  updateTicketSubject(id, params, headers) {
    return axios.put(
      API.BASE_URL + UPDATE_TICKET_SUBJECT + id + "/update",
      params,
      headers
    );
  },
  deleteTicketSubject(id, headers) {
    return axios.delete(
      API.BASE_URL +
        DELETE_TICKET_SUBJECT +
        id +
        "/delete?sio_channel=" +
        SIO_DELETE_TICKET_SUBJECT,
      headers
    );
  },
  getTicketSubject(headers) {
    return axios.get(
      API.BASE_URL + GET_TICKET_SUBJECT + SIO_GET_TICKET_SUBJECT,
      headers
    );
  },
  getTicketSubjectSettings(headers) {
    return axios.get(
      API.BASE_URL +
        GET_TICKET_SUBJECT_SETTINGS +
        SIO_GET_TICKET_SUBJECT_SETTINGS,
      headers
    );
  }

  // getTicketDetails() {
  //   return axios.get(
  //     API.BASE_URL + GET_ALL_TICKET + SIO_GET_TICKET,
  //     QUERY_PARAMS_INTERCEPTOR
  //   );
  // }
};

export const GetAllTicketsHttpService = {
  getAllTicket(headers) {
    return axios.get(API.BASE_URL + GET_ALL_TICKET + SIO_GET_TICKET, headers);
  },
  getLatestTicket(limit, headers) {
    return axios.get(
      `${API.BASE_URL +
        GET_LATEST_TICKET +
        SIO_GET_LATEST_TICKET}&limit=${limit}`,
      headers
    );
  },
  getTicketDetails(id, headers) {
    return axios.get(
      API.BASE_URL +
        GET_TICKET_DETAILS +
        id +
        "/details" +
        "?sio_channel=" +
        SIO_GET_TICKET_DETAILS,
      headers
    );
  },

  ticketInfo(params, headers) {
    return axios.post(API.BASE_URL + GET_TICKET_INFO, params, headers);
  },
  ticketReport(params, config) {
    return axios.post(API.BASE_URL + GET_TICKET_REPORT, params, config);
  },

  ticketStatsPerDate(datefilter, headers) {
    return axios.get(
      `${API.BASE_URL + GET_TICKET_STATS + SIO_TICKET_STATS}&complete_start=${
        datefilter.date_complete_start
      }&complete_end=${datefilter.date_complete_end}&pending_start=${
        datefilter.date_pending_start
      }&pending_end=${datefilter.date_pending_end}&new_start=${
        datefilter.date_new_start
      }&new_end=${datefilter.date_new_end}&total_start=${
        datefilter.date_total_start
      }&total_end=${datefilter.date_total_end}`,
      headers
    );
  },
  //GET_TICKET_STATS_CHART
  ticketStatsChartPerDate(datefilter, headers) {
    return axios.get(
      `${API.BASE_URL + GET_TICKET_STATS_CHART + SIO_TICKET_CHART}&date_start=${
        datefilter.date_start
      }&date_end=${datefilter.date_end}`,
      headers
    );
  }
};

export const UpdateTicketHttpService = {
  updateTicket(idTicket, data, headers) {
    return axios.put(
      API.BASE_URL +
        GET_TICKET_DETAILS +
        idTicket +
        "/update" +
        "?sio_channel=" +
        SIO_TICKET_UPDATE,
      data,
      headers
    );
  },
  deleteTicket(idTicket, headers) {
    return axios.delete(
      API.BASE_URL +
        GET_TICKET_DETAILS +
        idTicket +
        "/delete" +
        "?sio_channel=" +
        SIO_TICKET_DELETE,
      headers
    );
  }
};

export const CreateTicketHttpService = {
  getAgentPlatFrom(user_id, headers) {
    return axios.get(
      `${API.BASE_URL +
        GET_AGENT_PLATFORM +
        SIO_AGENT_PLATFORM}&user_connected_id=${user_id}`,
      headers
    );
  },

  createTicket(params, headers) {
    return axios.post(API.BASE_URL + CREATE_TICKET, params, headers);
  }
};

// Conversational form service.
export const ConversationalFormHttpService = {
  getMany(query, headers) {
    return axios.get(
      `${API.BASE_URL + GET_MANY_CONVERSATIONAL_FORM}?page=${
        query.page
      }&limit=${query.limit}`,
      headers
    );
  },

  getOne(id, headers) {
    return axios.get(
      `${API.BASE_URL + GET_ONE_CONVERSATIONAL_FORM}?id=${id}`,
      headers
    );
  },

  create(params, headers) {
    return axios.post(
      API.BASE_URL + CREATE_CONVERSATIONAL_FORM,
      params,
      headers
    );
  },

  changeStatus(params, headers) {
    return axios.put(
      `${API.BASE_URL + CHANGE_STATUS_CONVERSATIONAL_FORM}`,
      params,
      headers
    );
  },

  update(params, headers) {
    return axios.put(
      API.BASE_URL + UPDATE_CONVERSATIONAL_FORM,
      params,
      headers
    );
  },

  delete(id, headers) {
    return axios.delete(
      `${API.BASE_URL + DELETE_CONVERSATIONAL_FORM}?id=${id}`,
      headers
    );
  }
};

//check session
const AxiosRequest = {
  CHECK_SESSION: (payload, cancelSource) => {
    return axios({
      url: LOCAL_API.CHECK_SESSION,
      method: "get",
      baseURL: "",
      headers: { "Content-Type": "application/json" },
      cancelToken: cancelSource.token
    });
  },
  LOGOUT: () => {
    return axios({
      url: LOCAL_API.LOGOUT,
      method: "get",
      baseURL: "",
      headers: { "Content-Type": "application/json" }
      // cancelToken: cancelSource.token
    });
  }
};

const useHttpService = type => {
  const unmounted = useRef(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  let source = axios.CancelToken.source();

  function send(payload) {
    if (AxiosRequest[type]) {
      if (!unmounted.current) {
        setData(null);
        setLoading(true);
      }
      AxiosRequest[type](payload, source)
        .then(response => {
          if (!unmounted.current) {
            setData(response.data);
            setLoading(false);
          }
        })
        .catch(e => {
          //  console.log("catch === ", e);
          if (!unmounted.current) {
            setError(true);
            setLoading(false);
            if (axios.isCancel(e)) {
              //   console.log(`request cancelled:${e.message}`);
            } else {
              // console.log("another error happened:" + e.message);
            }
          }
        });
    } else {
      //console.warn("No service found");
    }
  }

  function abort() {
    source.cancel();
  }

  useEffect(() => {
    return () => {
      unmounted.current = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { send, abort, data, loading, error };
};

export default useHttpService;
