import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Header from "../layouts/Header";

import SideMenu from "../layouts/SideMenu";
import { options } from "../../configs/options";
//import io from "socket.io-client";
import {
  AgentFromXPHttpService,
  CreateTicketHttpService,
  GetAllTicketsHttpService
} from "../../services/HttpService";
import {
  SOCKET,
  SIO_AGENT_FORM_XP,
  SIO_AGENT_PLATFORM,
  SIO_TICKET_STATS,
  SIO_TICKET_CHART,
  SIO_GET_LATEST_TICKET
} from "../../constants/Constants";
import { SharedDataContext, DataSocketContext } from "../app/UseContext";

//const socket = io(SOCKET.BASE_URL);

class Dashboard extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      statees: true,
      containerWidth: 0,
      containerHeight: 0,
      agentFromXP: [],
      agentFromPlatform: [],
      latestticket: [],
      agentCount: 0,
      ticketStats: { complete: 0, pending: 0, total: 0, new: 0 },
      ticketStatsChart: { new: 0, pending: 0, resolved: 0 },
      date: {},
      dateGraph: {
        date_start: "",
        date_end: ""
      }
    };
  }

  static contextType = SharedDataContext;

  load() {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    this.initSocketGetAgentPlatform(
      context.sharedDataContext.currentUser.userid,
      receptor
    );
  }

  loadAll() {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    let day = new Date();
    let momenday = moment(day);
    let dateDefault = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 10);
    const date = {
      date_complete_start: dateDefault,
      date_complete_end: dateDefault,
      date_pending_start: dateDefault,
      date_pending_end: dateDefault,
      date_new_start: dateDefault,
      date_new_end: dateDefault,
      date_total_start: dateDefault,
      date_total_end: dateDefault
    };
    const dateGraph = {
      date_start: dateDefault,
      date_end: dateDefault
    };
    this.setState(
      {
        date,
        dateGraph
      },
      () => {
        this.initSocketTicketStats(this.state.date, receptor);
        this.initSocketTicketChartStats(this.state.dateGraph, receptor);
      }
    );
    this.initSocketGetAgentPlatform(
      context.sharedDataContext.currentUser.userid,
      receptor
    );
    this.initSocketAgentFromXP(receptor);
    this.initSocketLatestTicket(receptor);
    // this.initSocketTicketStats(this.state.date, receptor);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document.body.style.overflow = "hidden";
  }

  componentDidMount() {
    this._isMounted = true;
    //  console.log("compoent did mount")
    this.loadAll();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.body.style.overflow = "auto";
  }

  updateWindowDimensions = () => {
    this.setState({
      containerWidth: window.innerWidth,
      containerHeight: window.innerHeight
    });
  };

  //TICKET CHART STAT
  initSocketTicketChartStats = (date, receptor) => {
    // console.log("initSocketTicketChartStats ** : ");
    const { socket } = this.props;
    socket.on(SIO_TICKET_CHART, event => {
      const response = JSON.parse(event);
      //  console.log("SIO_TICKET_CHART : ", response);
      // this.onSocketTicketChartStats(response);
      if (this._isMounted) {
        this.setState({ ticketStatsChart: response.data });
      }
    });

    GetAllTicketsHttpService.ticketStatsChartPerDate(date, receptor).then(
      response => {
        // console.log("ticketStatsChartPerDate : ", response);

        if (response.status === 200 || response.status === 202) {
          //  console.log("test success : ", response);
        }
      }
    );
  };
  // onSocketTicketChartStats = response => {
  //   // console.log("onSocketTicketChartStats : ", response.data);

  //   if (response && (response.status === 200 || response.status === 202)) {
  //     this.setState({ ticketStatsChart: response.data });
  //   }
  // };

  //4 TICKET LATEST

  initSocketLatestTicket = receptor => {
    //  console.log("initSocketLatestTicket ** : ");
    const { socket } = this.props;
    socket.on(SIO_GET_LATEST_TICKET, event => {
      const response = JSON.parse(event);
      //   console.log("SIO_GET_LATEST_TICKET : ", response);
      // this.onSocketLatestTicket(response);
      if (this._isMounted) {
        this.setState({ latestticket: response.data });
      }
    });

    GetAllTicketsHttpService.getLatestTicket(4, receptor).then(response => {
      //  console.log("getLatestTicket : ", response);

      if (response.status === 200 || response.status === 202) {
        // console.log("test success : ", response);
      }
    });
  };
  // onSocketLatestTicket = response => {
  //   //console.log("onSocketLatestTicket : ", response.data);

  //   if (response && (response.status === 200 || response.status === 202)) {

  //   }
  // };

  // TICKET STATS
  initSocketTicketStats = (date, receptor) => {
    // console.log("initSocketTicketStats ** : ");
    const { socket } = this.props;
    socket.on(SIO_TICKET_STATS, event => {
      const response = JSON.parse(event);
      // console.log("SIO_TICKET_STATS : ", response);
      let ticketStats = {};
      let resp = [];
      resp = Object.entries(response.data);
      resp.length === 0
        ? (ticketStats = {
            agents: 0,
            complete: 0,
            pending: 0,
            new: 0,
            total: 0
          })
        : (ticketStats = response.data);
      //    this.onSocketTicketStats(response);
      if (this._isMounted) {
        this.setState({ ticketStats: ticketStats });
      }
    });

    GetAllTicketsHttpService.ticketStatsPerDate(date, receptor)
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          // console.log("test success : ", response);
        }
      })
      .catch(e => console.log("[GetAllTicketsHttpService]", e));
  };

  initSocketGetAgentPlatform = (userid, receptor) => {
    // console.log("initSocketGetAgentPlatform ** : ");
    const { socket } = this.props;
    socket.on(SIO_AGENT_PLATFORM, event => {
      const response = JSON.parse(event);
      if (this._isMounted) {
        this.setState({ agentFromPlatform: response.data });
        this.setState({ agentCount: response.data.length }, () => {
          //@TODO get list of agents from xp
          localStorage.setItem(
            "agentCount",
            JSON.stringify(this.state.agentCount)
          );
        });
      }
    });

    CreateTicketHttpService.getAgentPlatFrom(userid, receptor)
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          //   console.log("test success : ", response);
        }
      })
      .catch(e => console.log("[CreateTicketHttpService]", e));
  };

  //GET AGENT FROM XP
  initSocketAgentFromXP = receptor => {
    //   console.log("initSocketAgentFromXP : **** ");
    const { socket } = this.props;
    socket.on(SIO_AGENT_FORM_XP, event => {
      const response = JSON.parse(event);
      if (this._isMounted) {
        this.setState({ agentFromXP: response.data });
      }
    });

    AgentFromXPHttpService.getDatasAgentFromXP(receptor).then(response => {
      //   console.log("getDatasAgentFromXP : ", response);

      if (response.status === 200 || response.status === 202) {
        //  console.log("test success : ", response);
      } else {
        // console.log('test error : ', response);
      }
    });
  };

  ticketCompleteDateFilter = (timeStart, timeEnd) => {
    // console.log('timeStart',timeStart)

    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    let day = new Date();
    let momenday = moment(day);
    //let dateDefault = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 10);

    const timeStartmoment = moment(timeStart)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);
    const timeEndmoment = moment(timeEnd === null ? momenday : timeEnd)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);
    //console.log(timeEndmoment)
    this.setState(
      {
        date: {
          ...this.state.date,
          date_complete_start: timeStartmoment,
          date_complete_end: timeEndmoment
        }
      },
      () => {
        this.initSocketTicketStats(this.state.date, receptor);
      }
    );
  };

  ticketPendingDateFilter = (timeStart, timeEnd) => {
    // console.log('timeStart',timeStart)
    // console.log('timeEnd',timeEnd)
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    let day = new Date();
    let momenday = moment(day);
    // let dateDefault = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 10);

    const timeStartmoment = moment(timeStart)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);
    const timeEndmoment = moment(timeEnd === null ? momenday : timeEnd)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);
    this.setState(
      {
        date: {
          ...this.state.date,
          date_pending_start: timeStartmoment,
          date_pending_end: timeEndmoment
        }
      },
      () => {
        this.initSocketTicketStats(this.state.date, receptor);
      }
    );
  };

  ticketTotalDateFilter = (timeStart, timeEnd) => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    let day = new Date();
    let momenday = moment(day);
    // let dateDefault = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 10);
    const timeStartmoment = moment(timeStart)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);
    const timeEndmoment = moment(timeEnd === null ? momenday : timeEnd)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);
    this.setState(
      {
        date: {
          ...this.state.date,
          date_total_start: timeStartmoment,
          date_total_end: timeEndmoment
        }
      },
      () => {
        this.initSocketTicketStats(this.state.date, receptor);
      }
    );
  };

  graphTicketDateFilter = (timeStart, timeEnd) => {
    //  console.log("timeEnd", timeEnd);
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    // console.log("context.sharedDataContext",context.sharedDataContext)
    let day = new Date();
    let momenday = moment(day);
    let dateDefault = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 10);
    const timeStartmoment = moment(timeStart)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);
    const timeEndmoment = moment(timeEnd === null ? momenday : timeEnd)
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 10);

    this.setState(
      {
        dateGraph: {
          date_start: timeStartmoment,
          date_end: timeEndmoment
        }
      },
      () => {
        //   console.log(this.state.dateGraph);
        this.initSocketTicketChartStats(this.state.dateGraph, receptor);
      }
    );
  };

  render() {
    const { t, kind, defaultLang, changeLang, isLogged, i18n } = this.props;
    const {
      containerHeight,
      containerWidth,
      agentFromXP,
      agentCount,
      agentFromPlatform,
      ticketStats,
      latestticket,
      date,
      ticketStatsChart
    } = this.state;
    // console.log(this.context?.sharedDataContext?.currentUser?.full_rights?.length)
    let haveRights = this.context?.sharedDataContext?.currentUser?.is_owner || this.context?.sharedDataContext?.currentUser?.full_rights
      ?.length;
    return (
      <div className={`${kind === "dashboard" ? "columns" : ""}`}>
        {kind === "dashboard" && (
          <SideMenu
            haveRights={haveRights}
            t={t}
            containerWidth={containerWidth}
            i18n={i18n}
          />
        )}
        <Header
          haveRights={haveRights}
          options={options}
          agentFromXP={agentFromXP}
          agentCount={agentCount}
          agentFromPlatform={agentFromPlatform}
          defaultLang={defaultLang}
          changeLang={changeLang}
          kind={kind}
          isLogged={isLogged}
          t={t}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          i18n={i18n}
          ticketStats={ticketStats}
          ticketCompleteDateFilter={(start, end) =>
            this.ticketCompleteDateFilter(start, end)
          }
          ticketPendingDateFilter={(start, end) =>
            this.ticketPendingDateFilter(start, end)
          }
          ticketTotalDateFilter={(start, end) =>
            this.ticketTotalDateFilter(start, end)
          }
          graphTicketDateFilter={(start, end) =>
            this.graphTicketDateFilter(start, end)
          }
          latestticket={latestticket}
          ticketStatsChart={
            ticketStatsChart
              ? ticketStatsChart
              : { new: 0, pending: 0, resolved: 0 }
          }
          load={() => this.load()}
        />
      </div>
    );
  }
}

Dashboard.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  defaultLang: PropTypes.shape({}).isRequired,
  changeLang: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired
};

const DashboardWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <Dashboard {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);
export default DashboardWithSocket;
