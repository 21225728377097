import React, { Component, useState } from "react";
import PropTypes from "prop-types";
// import { GoSettings } from "react-icons/go";
import { AiFillSetting } from "react-icons/ai";

import trashIcon from "../../../assets/images/tickets/table-list/trash.svg";
import ProfileIcon from "../../../assets/images/profile/user.svg";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Modal from "../../utils/tickets/Modal";
import AuthCustomTicketField from "../components/CustomiseTicketDetails/utils/AuthCustomTicketField";

import { SharedDataContext } from "../../app/UseContext";
import { contextType } from "react-modal";
import context from "react-bootstrap/esm/AccordionContext";
import i18next from "i18next";

//format date
const dateFormat = date => {
  const regex = /-/gi;

  return date.substring(0, 10).replace(regex, "/");
};

class TicketsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "",
      openModaldelet: false,
      ticketId: "",
      id: "",
      allTicketCheck: [],
      AllticketSelected: false,
      ckecked: false,
      actualPage: 0,
      openModalField: false,
      vf: {
        11: true,
        12: true,
        13: true,
        14: true,
        15: true
      },
      visibleFields: [
        {
          id: 10,
          field: "idTicket",
          label_en: "Ticket ID",
          label_fr: "Ticket ID",
          visible: true
        },
        {
          id: 11,
          field: "title",
          label_en: "Ticket Category",
          label_fr: "Catégorie",
          visible: true
        },
        {
          id: 12,
          field: "priority",
          label_en: "Ticket Priority",
          label_fr: "Priorité",
          visible: true
        },
        {
          id: 13,
          field: "nature",
          label_en: "Ticket Nature",
          label_fr: "Nature",
          visible: true
        },
        {
          id: 14,
          field: "agent",
          label_en: "Agent",
          label_fr: "Agent",
          visible: true
        },
        {
          id: 15,
          field: "status",
          label_en: "Status",
          label_fr: "Status",
          visible: true
        }
      ]
    };

    //
    this.hideCustomTicketFieldList = this.hideCustomTicketFieldList.bind(this);
    this.showCustomTicketFieldList = this.showCustomTicketFieldList.bind(this);
    this.toggleCustomTicketFieldsVisibility = this.toggleCustomTicketFieldsVisibility.bind(
      this
    );
    this.props.setVisibleFields(this.state.visibleFields);
  }

  static contextType = SharedDataContext;

  componentDidUpdate(prevProps, prevState, snapshot) {
    const context = this.context;
    if (
      this.state.asset_link === null &&
      typeof context.sharedDataContext.asset_link !== "undefined"
    ) {
      this.setState({
        asset_link: context.sharedDataContext.asset_link
      });
    } else return null;
  }

  openModal = (ticketId, id) => {
    this.setState({ openModaldelet: true, ticketId, id });
  };

  handleClose = () => {
    this.setState({ openModaldelet: false });
  };

  handleCloseAccepte = () => {
    this.setState({ openModaldelet: false });
    this.props.deleteTicket(this.state.ticketId, this.state.id);
    this.setState({
      actualPage: this.state.actualPage + 1
    });
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps === undefined) {
      return false;
    } else {
      if (
        this.state.actualPage === nextProps.page &&
        this.state.allTicketCheck.length > 0 &&
        nextProps.allTickets.length &&
        this.state.allTicketCheck[this.state.allTicketCheck.length - 1]
          .ticket === nextProps.allTickets[nextProps.allTickets.length - 1].id
      ) {
        // )
        this.setState({
          allTicketCheck: this.state.allTicketCheck
        });
      } else {
        this.setState({
          actualPage: nextProps.page,
          allTicketCheck: nextProps.allTickets?.map(elm => {
            return {
              ticket: elm.id,
              isChecked: false
            };
          })
        });
      }
    }
  };

  componentDidMount() {
    const context = this.context;
    this.setState({
      lang: context.sharedDataContext.defaultLang.value
    });
  }

  handleChange = event => {
    //  console.log("event", event.target.value + ":" + event.target.checked);
    let prio = event.target.value;
    let bool = event.target.checked;
    //console.log(prio, "/ ", bool);
    //  this.setState({ [event.target.value]: event.target.checked }, () => {
    if (prio === "AllticketSelected") {
      let allTicketCheck = this.state.allTicketCheck;
      allTicketCheck.forEach(ticket => (ticket.isChecked = bool));
      this.setState({
        allTicketCheck
      });
    } else {
      this.setState({
        allTicketCheck: this.state.allTicketCheck.map(ticket =>
          ticket.ticket === prio ? { ticket: prio, isChecked: bool } : ticket
        )
      });
    }
    // });
  };

  showCustomTicketFieldList() {
    this.setState({ openModalField: true });
  }

  hideCustomTicketFieldList() {
    this.setState({ openModalField: false });
  }

  toggleCustomTicketFieldsVisibility(key) {
    /*
    const { visibleFields } = this.state;
    const current = visibleFields[key]?.visible;
    visibleFields[key]['visible'] = !current;
    this.setState({ visibleFields });
    this.props.setVisibleFields(visibleFields);
     */
    let vfs = this.state.vf;
    vfs[key] = !vfs[key];
    this.setState({ vf: vfs });
  }

  checkVisible(field) {
    return !this.state.vf[field];
    // return !this.state.visibleFields.filter(f => f.field === field)[0]?.visible;
  }

  render() {
    const { t, handleMessageTicket, allTickets, loading } = this.props;
    const { allTicketCheck, visibleFields } = this.state;

    const TicktsList = allTickets
      ? allTickets.map(elm => {
          return {
            id: elm.number,
            title: elm.subject,
            nature: elm.nature,
            nature_info: elm.nature_info,
            priority:
              elm.priority.name === "Medium" || elm.priority.name === "Moyenne"
                ? t("settings.tickets_priority_content.medium")
                : elm.priority.name === "Low" || elm.priority.name === "Faible"
                ? t("settings.tickets_priority_content.low")
                : t("settings.tickets_priority_content.hight"),
            // nature:
            //   elm.nature.name === "Incident" || elm.priority.name === "Incident"
            //     ? t("settings.tickets_nature_content.incident")
            //     : elm.nature.name === "Request" || elm.priority.name === "Demande",
            created: elm.created_by.firstname + " " + elm.created_by.firstname,
            status:
              elm.status.name === "Resolved" || elm.status.name === "Résolu"
                ? t("settings.tickets_status_content.resolved")
                : elm.status.name === "Pending" ||
                  elm.status.name === "En attente"
                ? t("settings.tickets_status_content.pending")
                : t("settings.tickets_status_content.new"),
            idTicket: elm.id,
            created_at: elm.created_at,
            agent: elm.assigned_agent,
            custom_ticket_fields: elm.custom_ticket_fields
          };
        })
      : [];

    const productView =
      TicktsList.length > 0 && allTicketCheck && allTicketCheck.length > 0
        ? TicktsList.map((elmt, index) => {
            return (
              <div
                key={elmt.idTicket}
                className="liste-item-contain"
                style={{
                  background: `${index % 2 === 0 ? "#FAFBFD" : "#FFFFFF"}`,
                  borderBottom: `${
                    index === TicktsList.length - 1
                      ? "none"
                      : "1px solid #C8D3D6"
                  }`
                }}
              >
                {" "}
                {/*{this.state.allTicketCheck.map(elm => {*/}
                {/*  return elm.ticket === elmt.idTicket ? (*/}
                {/*    <FormGroup row key={elm.ticket} style={{ minWidth: "4%" }}>*/}
                {/*      <FormControlLabel*/}
                {/*        control={*/}
                {/*          <Checkbox*/}
                {/*            checked={elm.isChecked}*/}
                {/*            onChange={this.handleChange}*/}
                {/*            value={elm.ticket}*/}
                {/*            color="primary"*/}
                {/*          />*/}
                {/*        }*/}
                {/*        label=""*/}
                {/*      />*/}
                {/*    </FormGroup>*/}
                {/*  ) : (*/}
                {/*    false*/}
                {/*  );*/}
                {/*})}*/}
                {/*{console.log(this.state.allTicketCheck.filter(elm => elm.ticket === elmt.idTicket)[0])}*/}
                <FormGroup
                  row
                  key={
                    this.state.allTicketCheck.filter(
                      elm => elm.ticket === elmt.idTicket
                    )[0]?.ticket
                  }
                  style={{ minWidth: "4%" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          this.state.allTicketCheck.filter(
                            elm => elm.ticket === elmt.idTicket
                          )[0]?.isChecked
                        }
                        onChange={this.handleChange}
                        value={
                          this.state.allTicketCheck.filter(
                            elm => elm.ticket === elmt.idTicket
                          )[0]?.ticket
                        }
                        color="primary"
                      />
                    }
                    label=""
                  />
                </FormGroup>
                <span style={{ minWidth: "11.5%" }}>#{elmt.id}</span>
                <div style={{ flex: 2 }} />
                <div
                  className={"ticketSubject"}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    textAlign: "center",
                    minWidth: 129,
                    width: "17%",
                    whiteSpace: "nowrap",
                    display: this.checkVisible(11) ? "none" : "inherit"
                  }}
                  onClick={() =>
                    handleMessageTicket(
                      "show-ticket-detail",
                      "----",
                      elmt.idTicket
                    )
                  }
                >
                  {elmt.title && elmt.title.length > 16
                    ? elmt.title.substring(0, 16) + "..."
                    : elmt.title}
                </div>
                <div style={{ flex: 2 }} />
                <div
                  style={{ textAlign: "center", minWidth: "13.5%" }}
                  hidden={this.checkVisible(12)}
                >
                  <p
                    className="list-ticket-textpriority"
                    style={{
                      textAlign: "center",
                      background: `${
                        elmt.priority === "High" || elmt.priority === "Haute"
                          ? "#eb592321"
                          : elmt.priority === "Low" ||
                            elmt.priority === "Faible"
                          ? "#6572884a"
                          : "#ff9b214d"
                      }`,
                      color: `${
                        elmt.priority === "High" || elmt.priority === "Haute"
                          ? "#EB5923"
                          : elmt.priority === "Low" ||
                            elmt.priority === "Faible"
                          ? "#657288"
                          : "#FF9B21"
                      }`
                    }}
                  >
                    {elmt.priority}
                  </p>
                </div>
                <div style={{ flex: 2 }} />
                {/* Start Ticket Nature */}
                <div
                  style={{ minWidth: "13.5%" }}
                  hidden={this.checkVisible(13)}
                >
                  <p
                    className="list-ticket-textpriority"
                    style={{
                      background: `${"#0089E1"}`,
                      color: `${"#000000"}`
                    }}
                  >
                    {elmt.nature_info
                      ? elmt.nature_info.labels[this.state.lang].length > 7
                        ? elmt.nature_info.labels[this.state.lang].substring(
                            0,
                            8
                          ) + "..."
                        : elmt.nature_info.labels[this.state.lang]
                      : ""}
                  </p>
                </div>
                <div style={{ flex: 2 }} />
                {/* End Ticket Nature */}
                <div
                  className={"cobok"}
                  style={{ textAlign: "center", minWidth: "13.5%" }}
                  hidden={this.checkVisible(14)}
                >
                  <div
                    data-tooltip={
                      elmt.agent.firstname + " " + elmt.agent.lastname
                    }
                    className="tooltip-title"
                  >
                    <img
                      src={ProfileIcon}
                      alt={elmt.agent.firstname + " " + elmt.agent.lastname}
                      className="profilepicture-assignee"
                      data-tooltip={
                        elmt.agent.firstname + " " + elmt.agent.lastname
                      }
                    />
                  </div>
                </div>
                <div style={{ flex: 2 }} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "13.5%"
                  }}
                  hidden={this.checkVisible(15)}
                >
                  <button
                    className="list-ticket-btn"
                    style={{
                      background: `${
                        elmt.status === "Pending" ||
                        elmt.status === "En attente"
                          ? "#FF9B21"
                          : elmt.status === "New" || elmt.status === "Nouveau"
                          ? "#0089E1"
                          : "#00BD39"
                      }`
                    }}
                  />
                  {elmt.status}
                </div>
                <div style={{ flex: 2 }} />
                {this.props.customTicketFieldsAll.map(f => (
                  <>
                    <div
                      className="head-list-Title"
                      style={{
                        minWidth: "13.5%",
                        wordBreak: "break-all",
                        paddingLeft: 5,
                        paddingRight: 5
                      }}
                      hidden={this.checkVisible(f.id)}
                    >
                      {
                        elmt.custom_ticket_fields?.filter(
                          x =>
                            x["label_" + i18next.language] ===
                            f["label_" + i18next.language]
                        )[0]?.value
                      }
                    </div>
                    <div style={{ flex: 2 }} />
                  </>
                ))}
                <div
                  style={{
                    display: "flex",
                    minWidth: "13.5%",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <button
                    className="button"
                    onClick={() =>
                      handleMessageTicket(
                        "show-ticket-detail",
                        "----",
                        elmt.idTicket
                      )
                    }
                  >
                    {t("tickets.table_list.btn_view")}
                  </button>

                  <button
                    className="button button-trash-table no-padding"
                    onClick={() => this.openModal(elmt.idTicket, elmt.id)}
                  >
                    <img
                      src={trashIcon}
                      alt="img trash"
                      className="img-trash"
                      width="15"
                    />
                  </button>
                </div>
              </div>
            );
          })
        : false;

    return (
      <div style={{ padding: "0" }}>
        <div>
          <div>
            <div className="head-list-ticket">
              <FormGroup row style={{ minWidth: "4%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.ticket}
                      onChange={this.handleChange}
                      value="AllticketSelected"
                      color="primary"
                    />
                  }
                  label=""
                />
              </FormGroup>
              <div className="head-list-Title" style={{ minWidth: "11.5%" }}>
                {t("tickets.table_list.ticket_id")}
              </div>
              <div style={{ flex: 2 }} />
              <div
                className="head-list-Title"
                style={{ textAlign: "xcenter", minWidth: "17%" }}
                hidden={this.checkVisible(11)}
              >
                {t("tickets.table_list.ticket_title")}
              </div>
              <div style={{ flex: 2 }} />
              <div
                className="head-list-Title"
                style={{ textAlign: "center", minWidth: "13.5%" }}
                hidden={this.checkVisible(12)}
              >
                {t("tickets.table_list.ticket_priority")}
              </div>
              <div style={{ flex: 2 }} />
              {/* Ticket nature start */}
              <div
                className="head-list-Title"
                style={{ textAlign: "center", minWidth: "13.5%" }}
                hidden={this.checkVisible(13)}
              >
                {t("tickets.table_list.ticket_nature")}
              </div>
              <div style={{ flex: 2 }} />
              {/* Ticket nature end */}
              <div
                className="head-list-Title"
                style={{ textAlign: "center", minWidth: "13.5%" }}
                hidden={this.checkVisible(14)}
              >
                AGENT
              </div>
              <div style={{ flex: 2 }} />
              <div
                className="head-list-Title"
                style={{ minWidth: "13.5%" }}
                hidden={this.checkVisible(15)}
              >
                {t("tickets.table_list.status")}
              </div>
              <div style={{ flex: 2 }} />

              {this.props.customTicketFieldsAll.map(f => (
                <>
                  <div
                    className="head-list-Title"
                    style={{
                      minWidth: "13.5%",
                      textTransform: "uppercase",
                      paddingLeft: 5,
                      paddingRight: 5
                    }}
                    hidden={this.checkVisible(f.id)}
                  >
                    {f["label_" + i18next.language]}
                  </div>
                  <div style={{ flex: 2 }} />
                </>
              ))}

              {/* <span className="head-list-Title" style={{ textAlign: "center" }}>
                        DATE
                    </span> */}
              <div
                className="head-list-Title"
                style={{ textAlign: "center", minWidth: "13.5%" }}
              >
                {t("tickets.table_list.action")}
                <span>
                  <AiFillSetting
                    onClick={this.showCustomTicketFieldList}
                    style={{
                      cursor: `pointer`,
                      height: `25px`,
                      width: `25px`,
                      marginLeft: `10px`
                    }}
                  />
                </span>
              </div>
            </div>
            {/* to remove the overflow*/}
            <div className="list-ticket-container" style={{ overflow: "auto" }}>
              {loading ? (
                <div className="no-data-contain">
                  {t("tickets.table_list.loading")}
                </div>
              ) : !loading && allTickets.length === 0 ? (
                <div className="no-data-contain">
                  {t("tickets.table_list.no-data")}
                </div>
              ) : (
                productView
              )}
            </div>
          </div>
        </div>
        <Modal
          t={this.props.t}
          dialogAlertMessage={t("tickets.delete_ticket.delete_ticket_massage")}
          handleClickOpen={this.state.openModaldelet}
          handleCloseAccepte={() => this.handleCloseAccepte()}
          handleClose={() => this.handleClose()}
        />
        <AuthCustomTicketField
          t={this.props.t}
          currentLang={this.state.lang}
          visibleFields={visibleFields}
          closeModal={this.hideCustomTicketFieldList}
          showModal={this.state.openModalField}
          toggleVisibility={this.toggleCustomTicketFieldsVisibility}
          context={this.context}
          vf={this.state.vf}
        />
      </div>
    );
  }
}

TicketsList.propTypes = {
  t: PropTypes.func.isRequired,
  handleMessageTicket: PropTypes.func.isRequired
};

export default TicketsList;
