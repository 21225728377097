import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import CreateCategory from "./CreateCategory";
import Category from "./Category";
import Loading from "./Loading";
import { BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const CategoryLevel = props => {
  const {
    t,
    i18n,
    categories,
    level,
    parentList,
    priorities,
    handleCreateTicketSubject,
    handleUpdateTicketSubject,
    handleDeleteTicketSubject,
    priority,
    nature,
    parentSla,
    parentPriority,
    parentRef
  } = props;

  const [currentLevel, setCurrentLevel] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [categoriesParentList, setCategoriesParentList] = useState([]);
  const [isOpen, setIsOpen] = useState({});

  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);

  useEffect(() => {
    setCurrentLevel(level + 1);
  }, [level]);

  useEffect(() => {
    if (
      parentList != "undefined" &&
      parentList != null &&
      parentId != "undefined" &&
      parentId != null &&
      parentId != ""
    ) {
      setCategoriesParentList([...parentList, ...[parentId]]);
    }
  }, [parentList, parentId]);

  // console.log("parentRef", parentRef);

  // useEffect(() => {
  //   console.log("categoriesParentList", categoriesParentList);
  // }, [categoriesParentList]);

  useEffect(() => {
    const condition_1 =
      typeof currentLevel != "undefined" && currentLevel != null;
    const condition_2 =
      typeof categories != "undefined" &&
      categories?.length != 0 &&
      categories != null;

    if (condition_1 && condition_2) {
      const oldOpened = states.opened;
      const newOpened = getCategoriesId(categories);
      const tempOpened = { ...oldOpened, ...newOpened };
      dispatch({ type: "SET", key: "opened", payload: tempOpened });
    }
  }, [currentLevel]);

  const getCategoriesId = array => {
    var tempSetup = {};
    array.map((item, i) => {
      const itemName = `category_${item.id}`;
      tempSetup[itemName] = false;
    });
    return tempSetup;
  };

  const handlerOpenLevel = (stateId, parent_ids) => {
    var tempOpened = states.opened;
    var newOpened = {};
    dispatch({ type: "SET", key: "opened", payload: {} });
    newOpened[stateId] = !tempOpened[stateId];

    for (const [key, value] of Object.entries(tempOpened)) {
      if (parent_ids.includes(key.replace("category_", "")) || key == stateId) {
        newOpened[key] = true;
        //newOpened[key] = !tempOpened[key];
      } else {
        newOpened[key] = false;
      }
    }

    dispatch({ type: "SET", key: "opened", payload: newOpened });
  };

  useEffect(() => {
    if (
      typeof categories != "undefined" &&
      categories?.length != 0 &&
      categories != null
    ) {
      setParentId(categories[0]["parent_id"]);
    }
  }, [categories]);
  let categoryListRef = useRef(null);
  return (
    <div>
      {/* <Col className="category-level-list-col"> */}
      {categories?.length >= 0 && states?.opened && currentLevel != null ? (
        <div
          ref={categoryListRef}
          style={{
            minHeight: `100px`,
            width: `317px`,
            marginBottom: "60px",
            marginRight: "60px",
            marginLeft: "1px"
          }}
        >
          <div
            // hidden={
            //   (currentLevel > 1 && ( typeof states?.opened[`category_${parentId}`] == 'undefined' || states.opened[`category_${parentId}`] == false))

            // }
            hidden={
              currentLevel > 1 &&
              (states.opened[`category_${parentId}`] == false ||
                states.opened.hasOwnProperty(`category_${parentId}`) == false)
            }
            style={{
              // display: (currentLevel > 1 && typeof states?.opened[`category_${parentId}`] == 'undefined') ? 'none':null,
              zIndex:
                currentLevel > 1 &&
                (states.opened[`category_${parentId}`] == false ||
                  states.opened.hasOwnProperty(`category_${parentId}`) == false)
                  ? -1
                  : 99
            }}
          >
            <Row
              style={{ margin: `0` }}
              className="category-level-box-header d-flex justify-content-between"
            >
              <span className="parent-category-header-span">
                {currentLevel == 1
                  ? t("settings.tickets_category_content.parent_category")
                  : `${t(
                      "settings.tickets_category_content.sub_cat_level"
                    )} ${currentLevel}`}
              </span>
              <BiChevronRight className="right-arrow-category-header" />
            </Row>
            <Row style={{ paddingLeft: `15px` }}>
              <CreateCategory
                t={t}
                priorities={priorities}
                i18n={i18n}
                categories={categories}
                level={currentLevel}
                //parentList={parentList}
                //priorities={priorities}
                parentId={parentId}
                categoriesParentList={categoriesParentList}
                handleCreateTicketSubject={handleCreateTicketSubject}
                nature={nature}
                parentSla={parentSla}
                parentPriority={parentPriority}
              />
              <br />
            </Row>

            <Row style={{ margin: `0`, padding: `0` }}>
              <ul>
                {categories?.map((item, i) => {
                  return (
                    <li key={i}>
                      <Category
                        key={i}
                        t={t}
                        i18n={i18n}
                        title={item.title}
                        sla={item.sla}
                        priority={item.priority}
                        priorityName={item.priority.name}
                        priorityLabel={item.priority.label}
                        enable={item.enable}
                        createdAt={item.created_at}
                        updatedAt={item.updated_at}
                        id={item.id}
                        parentId={
                          item.hasOwnProperty("parent_id") ? item.parent_id : ""
                        }
                        items={item.items}
                        level={currentLevel}
                        // parentList={parentList}
                        handleDeleteTicketSubject={handleDeleteTicketSubject}
                        handleUpdateTicketSubject={handleUpdateTicketSubject}
                        handleCreateTicketSubject={handleCreateTicketSubject}
                        priorities={priorities}
                        handlerOpenLevel={handlerOpenLevel}
                        nature={nature}
                        categories={categories}
                        categoriesParentList={categoriesParentList}
                        parentList={categoriesParentList}
                        categoryListRef={categoryListRef}
                      />
                    </li>
                  );
                })}
              </ul>
            </Row>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", textAlign: "center", marginTop: "150px" }}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default CategoryLevel;
