import React, { useState } from "react";
import PropTypes from "prop-types";
import Agents from "./Analytics/Agents";
import ChartDatas from "./canvas/ChartDatas";
import NewRequest from "./Analytics/NewRequest";
import PendingRequest from "./Analytics/PendingRequest";
import CompleteRequest from "./Analytics/CompleteRequest";
import Footer from "../layouts/Footer";
import MoreIcon from "../../assets/images/dashboard/more.svg";
import UserImg from "../../assets/images/dashboard/user.svg";
import CalendarIcon from "../../assets/images/dashboard/calendar.svg";
import AntennaIcon from "../../assets/images/dashboard/antenna.svg";
import LiveActivity from "./Analytics/LiveActivity";
import { CONSTANT } from "../../constants/browser";
const Analytics = props => {
  const {
    i18n,
    t,
    kind,
    agentFromXP,
    agentFromPlatform,
    agentCount,
    ticketCompleteDateFilter,
    ticketPendingDateFilter,
    ticketTotalDateFilter,
    graphTicketDateFilter,
    ticketStats,
    latestticket,
    ticketStatsChart,
    load
  } = props;

  const [liveActivity, setLiveActivity] = useState([
    {
      img: UserImg,
      title: "New Ticket #4424211 Open",
      time: "1 min ago",
      status: "Ticket"
    },
    {
      img: UserImg,
      title: "Response to ticket #3432131",
      time: "6 min ago",
      status: "Ticket"
    },
    {
      img: UserImg,
      title: "New Ticket #44211 Open",
      time: "23 min ago",
      status: "Ticket"
    },
    {
      img: UserImg,
      title: "Response to chat ticket #3432131",
      time: "1 day ago",
      status: "Ticket"
    }
  ]);
  // console.log("latestticket", latestticket);

  const lang = localStorage.getItem(CONSTANT.LOCAL_STORAGE_LANG_KEY);

  return (
    <>
      <h2 className="dashboard-title">
        {kind === "dashboard"
          ? t("dashboard.dashboard_overview")
          : t("settings.settings_overview")}
      </h2>
      <div className="columns analytics-columns">
        <Agents
          t={t}
          agentFromXP={agentFromXP}
          agentFromPlatform={agentFromPlatform}
          CalendarIcon={CalendarIcon}
          agentCount={agentCount}
          MoreIcon={MoreIcon}
          load={load}
          // kind='agent'
        />
        <CompleteRequest
          t={t}
          CalendarIcon={CalendarIcon}
          MoreIcon={MoreIcon}
          ticketCompleteDateFilter={ticketCompleteDateFilter}
          ticketStats={ticketStats.complete}
        />
        <PendingRequest
          t={t}
          CalendarIcon={CalendarIcon}
          MoreIcon={MoreIcon}
          ticketPendingDateFilter={ticketPendingDateFilter}
          ticketStats={ticketStats.pending}
        />
        <NewRequest
          t={t}
          CalendarIcon={CalendarIcon}
          MoreIcon={MoreIcon}
          ticketTotalDateFilter={ticketTotalDateFilter}
          ticketStats={ticketStats.total}
        />
      </div>
      <div className="columns chart-columns">
        <div className="column">
          <div className="column-content column-content-a">
            <h2>{t("dashboard.live_updates")}</h2>
          </div>

          {/*  */}
          {latestticket.length === 0 ? (
            <div className="column-content column-content-no">
              <img src={AntennaIcon} alt="Antenna" className="antenna" />
              <p>{t("dashboard.no_live_update")}</p>
            </div>
          ) : (
            <div className="LiveActivities-Container">
              {latestticket.map((item, i) =>
                i < 4 ? (
                  <LiveActivity
                    t={t}
                    key={i}
                    index={i}
                    img={item.img}
                    title={
                      lang === "en" && item.logs[0].message_en
                        ? item.logs[0].message_en
                        : item.logs[0].message_fr
                        ? item.logs[0].message_fr
                        : item.logs[0].message
                    }
                    time={item.logs[0].created_at}
                    number={item.number}
                    status="Ticket"
                  />
                ) : (
                  false
                )
              )}
            </div>
          )}
          {/*  */}
        </div>

        <div
          className="column column-chart"
          style={{ maxHeight: "100% !important" }}
        >
          <h3>{t("dashboard.channel_chart")}</h3>
          <ChartDatas
            t={t}
            i18n={i18n}
            CalendarIcon={CalendarIcon}
            MoreIcon={MoreIcon}
            ticketStatsChart={ticketStatsChart}
            graphTicketDateFilter={graphTicketDateFilter}
          />
        </div>
      </div>
      <Footer t={t} />
    </>
  );
};

Analytics.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired
};

export default Analytics;
