import React, {
  Fragment,
  useEffect,
  useState,
  useMemo
} from "react";

import { Alert, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";

import { CustomTicketFieldHttpService } from "../../../../../services/HttpService";

import LangIconEn from "../../../../../assets/images/locale/uk.png";
import LangIconFr from "../../../../../assets/images/locale/fr.png";
import i18nConfig from "../../../../../configs/i18nConfig";

const CreateNewFieldModal = props => {
  const {
    t,
    reload,
    current,
    globalNotification,
    sharedDataContext,
    setSharedDataContext,
    customTicketFieldItems,
    currentCustomTickets,
    ...modal
  } = props;

  const fieldNameOptions = [
    { value: "Open Text", label: "Open Text" },
    { value: "Options", label: "Options" }
  ];

  const openTextOptions = [
    { value: "text", label: "Text Field" },
    { value: "email", label: "Email Field" },
    { value: "number", label: "Number Field" }
  ];

  const selectConditionalAction = [
    { value: 0, label: "Visible" },
    { value: 1, label: "Hidden" }
  ];

  const customStyles = {
    option: (provided, { isSelected }) => ({
      ...provided,
      fontSize: "13px",
      backgroundColor: isSelected ? "#ddd" : "#fff",
      color: "#000"
    }),

    menu: (provided, _) => ({
      ...provided,
      padding: 0
    }),

    control: (provided, _) => ({
      ...provided,
      border: "1px solid #AEBACE"
    })
  };

  // Initial form data.
  const initialFormData = {
    fieldNameen: "",
    fieldNamefr: "",
    fieldKind: "",
    dataType: "",
    options: [{ en: "", fr: "" }],
    mandatory: false,
    conditional: false,
    conditionalFieldData: {
      id: "",
      fieldNameEn: "",
      fieldNameFr: "",
      selectedOption: {
        optionName: { en: "", fr: "" },
        action: { value: null, label: "" }
      }
    }
  };

  useEffect(() => {
    if (!current) {
      setFormData(initialFormData);

      setFormDataConditional(false);
      setFormDataAction(null);
      setFormDataField(null);
      setFormDataFieldValue(null);
    } else {
      const loadData = {
        fieldNameen: current.label_en,
        fieldNamefr: current.label_fr,
        fieldKind: current.field_type.name,
        dataType: current.field_type.type,
        options: current.field_type.options,
        mandatory: current.mandatory,
        conditional: current.conditional,
        conditionalFieldData: current.conditionalFieldData,
        conditionalAction: current.conditionalAction || {},
        conditionalField: current.conditionalField || {},
        conditionalFieldValue: current.conditionalFieldValue || {}
      };
      //
      setFormData(loadData);

      setFormDataConditional(current.conditional);
      setFormDataAction(current.conditionalAction);
      setFormDataField(current.conditionalField);

      if (current.conditionalField?.value) {
        let vl = listOfTicketsOfTypeOptions.filter(
          l => l.id === current.conditionalField.value
        )[0];

        const prototype = { en: [], fr: [] };

        vl.field_type.options.forEach(el => {
          const valueInEn = el.en;
          const valueInFr = el.fr;
          const dataInEnglish = {
            value: valueInEn,
            label: valueInEn,
            label_fr: valueInFr
          };
          const dataInFrench = {
            value: valueInFr,
            label: valueInFr,
            label_en: valueInEn
          };
          const { en, fr } = prototype;
          en.push(dataInEnglish);
          fr.push(dataInFrench);
        });

        setSelectedConditionFieldOptions(prototype);

        let v = vl.field_type.options.filter(i => {
          return i.en === current.conditionalFieldValue.label;
        });
        let val =
          choosedLang === "en"
            ? { ...v[0], value: v[0].value, label: v[0].en }
            : { ...v[0], value: v[0].value, label: v[0].fr };
        setFormDataFieldValue(val);
      }
    }
  }, [current]);

  // Modal different state.
  const [choosedLang, setChoosedLang] = useState("en");

  // Error notification.
  const [alert, setAlert] = useState({
    variant: "danger",
    message: "",
    close: true
  });

  // Form data.
  const [formData, setFormData] = useState(initialFormData);
  const [formDataConditional, setFormDataConditional] = useState(false);
  const [formDataAction, setFormDataAction] = useState(null);
  const [formDataField, setFormDataField] = useState(null);
  const [formDataFieldValue, setFormDataFieldValue] = useState(null);

  // Notification show close.
  const closeAlert = () => {
    setAlert({
      variant: "danger",
      message: "",
      close: true
    });
  };

  // Update current choosed language.
  const changeFieldName = e => {
    // setErrorMsg({...errorMsg, [choosedLang]:''})
    checkUniq(e.target.value, choosedLang);
    setFormData({ ...formData, [`fieldName${choosedLang}`]: e.target.value });
  };

  // Change field mandatory.
  const changeFieldMandatory = e => {
    setFormData({ ...formData, mandatory: e.target.checked });
  };

  const changeFieldConditional = e => {
    setFormDataConditional(!formDataConditional);
    setFormData({ ...formData, conditional: e.target.checked });
  };

  // Change select field name type.
  const changeFieldKind = e => {
    setFormData({ ...formData, fieldKind: e.value });
  };

  // Change field data type.
  const changeDataType = e => {
    setFormData({ ...formData, dataType: e.value });
  };

  // handle input change
  const changeOptionListData = (e, index) => {
    const list = [...formData.options];
    list[index][choosedLang] = e.target.value;
    setFormData({ ...formData, options: list });
  };

  // handle the conditional input field for the list of customize ticket with options type
  const conditionalFieldHandler = e => {
    const conditionalData = {
      id: e.value,
      fieldNameEn: e.label_en,
      fieldNameFr: e.label_fr
    };

    // calling function that will get the selected condition fields options
    fxn(conditionalData);
    setFormDataField(e);
  };

  // handle the option selected by the user from selected conditional customize ticket
  const selectedOptionFromConditionalCTicket = e => {
    let _optionName =
      choosedLang === "en"
        ? { en: e.value, fr: e.label_fr }
        : { en: e.label_en, fr: e.value };

    // if its an update of an existing ticket
    // if (current) {
    //   return null;
    // }

    setFormDataFieldValue(e);
  };

  // handle action for the selected condition
  const conditionAction = e => {
    // if its an update of an existing ticket
    // if (current) {
    //   return null;
    // }
    setFormDataAction(e);
  };

  // handle click event of the Remove button.
  const handleRemoveOption = index => {
    const list = [...formData.options];
    list.splice(index, 1);
    setFormData({ ...formData, options: list });
  };

  // handle click event of the Add button.
  const handleAddOption = () => {
    const list = [...formData.options];
    const size = list.length - 1;
    if (list[size][choosedLang] != "") {
      list.push({ en: "", fr: "" });
      setFormData({ ...formData, options: list });
    }
  };
  const [savingField, setsavingField] = React.useState(false);
  const handleSubmitForm = () => {
    setsavingField(true);
    const {
      fieldNameen,
      fieldNamefr,
      fieldKind,
      dataType,
      options,
      mandatory,
      conditional,
      conditionalFieldData
    } = formData;

    if (fieldNameen === "") {
      document.getElementsByClassName("modal-header")[0].scrollIntoView(true);
      setAlert({
        variant: "warning",
        message:
          i18nConfig.language === "fr"
            ? "Veuillez traduire les details du champ en Anglais"
            : "Translate the field details  in English",
        close: false
      });
      setsavingField(false);
      return;
    } else if (fieldNamefr === "") {
      document.getElementsByClassName("modal-header")[0].scrollIntoView(true);
      setAlert({
        variant: "warning",
        message:
          i18nConfig.language === "fr"
            ? "Veuillez traduire les details du champ en Français"
            : "Translate the field details  in French",
        close: false
      });
      setsavingField(false);
      return;
    } else if (fieldKind === "") {
      document.getElementsByClassName("modal-header")[0].scrollIntoView(true);
      setAlert({
        variant: "warning",
        message:
          i18nConfig.language === "fr"
            ? "Veuillez choisir un type de champ valide"
            : "Please select a valid field type",
        close: false
      });
      setsavingField(false);
      return;
    } else if (fieldKind == "Open Text" && dataType === "") {
      document.getElementsByClassName("modal-header")[0].scrollIntoView(true);
      setAlert({
        variant: "warning",
        message:
          i18nConfig.language === "fr"
            ? "Veuillez choisir un type de donnée valid"
            : "Please select a valid data type",
        close: false
      });
      setsavingField(false);
      return;
    } else if (fieldKind == "Options") {
      const isOk = options.findIndex(o => o.en.length == 0 || o.fr.length == 0);
      if (isOk != -1) {
        document.getElementsByClassName("modal-header")[0].scrollIntoView(true);
        setAlert({
          variant: "warning",
          message:
            i18nConfig.language === "fr"
              ? `Veuillez fournir un nom d'option valide pour la ligne ${isOk +
                  1}`
              : `Please provide a valid option name on line ${isOk + 1}`,
          close: false
        });
        setsavingField(false);
        return;
      }
    }

    // Close Message error on form data.
    closeAlert();

    // Construct backend service params.
    const params = {
      payload: [
        {
          label_en: fieldNameen,
          label_fr: fieldNamefr,
          field_type: {
            name: fieldKind,
            type: dataType,
            options: fieldKind == "Options" ? options : initialFormData.options
          },
          mandatory: mandatory,
          conditional: formDataConditional,
          conditionalField: formDataField,
          conditionalFieldValue: formDataFieldValue,
          conditionalAction: formDataAction
        }
      ]
    };

    if (!current) {
      // Backend service to save new field.
      createCallback(params);
      // setsavingField(false)
    } else {
      updateCallback({
        ...params.payload[0],
        id: current.id
      });
      // setsavingField(false)
    }
  };

  const createCallback = params => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    // Call backend service to save data.
    CustomTicketFieldHttpService.createOne(params, receptor)
      .then(r => {
        if (r.status == 200 || r.status == 201 || r.status == 202) {
          //
          globalNotification(
            "success",
            i18nConfig.language === "fr"
              ? "Le champ personnalisé créé avec succès"
              : "Custom field created successfully"
          );
          //
          setFormData(initialFormData);
          //
          modal.onHide();

          // Reload custom ticket fields list.
          reload(true);
          setsavingField(false);
        }
      })
      .catch(e => {
        // console.log("[CustomTicketFieldHttpService.createOne]", e);
        setsavingField(false);
        globalNotification(
          "danger",
          i18nConfig.language === "fr"
            ? "Echec de la création d'un nouveau champ personnalisé !!!"
            : "Failed to create new custom ticket field !!!"
        );
      });
  };

  const updateCallback = params => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    // Call backend service to save data.
    CustomTicketFieldHttpService.updateOne(params, receptor)
      .then(r => {
        if (r.status == 200 || r.status == 201 || r.status == 202) {
          // Close Modal.
          modal.onHide();

          // Initialize modal form.
          setFormData(initialFormData);

          // Show creation message.
          globalNotification(
            "success",
            i18nConfig.language === "fr"
              ? "Le champ personnalisé mis à jour avec succès"
              : "Custom field updated successfully"
          );

          // Reload custom ticket fields list.
          reload(true);
          setsavingField(false);
        }
      })
      .catch(e => {
        // console.log("[CustomTicketFieldHttpService.createOne]", e);
        setsavingField(false);
        // Show failure message.
        globalNotification(
          "danger",
          i18nConfig.language === "fr"
            ? "Impossible de mettre a jour ce champ personnalisé"
            : "Failed to update this custom ticket field !!!"
        );
      });
  };
  const [haveErrors, setHaveErrors] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState({});

  const checkUniq = (value, lang) => {
    let f = props.customTicketFieldItems?.items.filter(
      f => f["label_" + lang].toLowerCase() === value.toLowerCase()
    );
    setHaveErrors({ ...haveErrors, [lang]: !!f.length });
    if (f.length) {
      setErrorMsg({ ...errorMsg, [lang]: "translations.field_name_not_uniq" });
    } else {
      setErrorMsg({ ...errorMsg, [lang]: "" });
    }
  };

  React.useEffect(() => {
    setsavingField(false);
  }, []);

  const [conditionalField, setConditionalField] = useState([
    { value: null, label: "NO OPTIONS", label_en: "", label_fr: "" }
  ]);

  useEffect(() => {
    if (currentCustomTickets.length === 0) {
      return;
    }

    if (currentCustomTickets.length > 0) {
      const FILTERED_TICKETS = []; // these is for tickets that have a field type of options

      currentCustomTickets.forEach(el => {
        if (el.field_type.name === "Options") {
          console.log("data", el);
          let filterData = {
            value: el.id,
            label: choosedLang === "fr" ? el.label_fr : el.label_en,
            label_en: el.label_en,
            label_fr: el.label_fr
          };

          FILTERED_TICKETS.push(filterData);
        }
      });

      if (FILTERED_TICKETS.length === 0) {
        setConditionalField([
          { value: null, label: "NO TICKETS", label_en: null, label_fr: null }
        ]);
      } else {
        setConditionalField(FILTERED_TICKETS);
      }
    }
  }, [currentCustomTickets, /*formData.conditional,*/ choosedLang]);

  const listOfTicketsOfTypeOptions = useMemo(() => currentCustomTickets, [
    currentCustomTickets
  ]);

  //console.log("useMemo", listOfTicketsOfTypeOptions);

  const [
    selectedConditionFieldOptions,
    setSelectedConditionFieldOptions
  ] = useState({
    en: [{ value: "", label: "", label_fr: "" }],
    fr: [{ value: "", label: "", label_en: "" }]
  });

  const fxn = e => {
    const selectedConditionalTicketData = listOfTicketsOfTypeOptions.find(
      el => el.id === e.id || el.id === e.id
    );

    const prototype = { en: [], fr: [] };

    selectedConditionalTicketData.field_type.options.forEach(el => {
      const valueInEn = el.en;
      const valueInFr = el.fr;
      const dataInEnglish = {
        value: valueInEn,
        label: valueInEn,
        label_fr: valueInFr
      };
      const dataInFrench = {
        value: valueInFr,
        label: valueInFr,
        label_en: valueInEn
      };
      const { en, fr } = prototype;
      en.push(dataInEnglish);
      fr.push(dataInFrench);
    });

    setSelectedConditionFieldOptions(prototype);
  };

  useEffect(
    function() {
      console.dir({
        formDataConditional,
        formDataField,
        formDataFieldValue,
        formDataAction
      });
    },
    [formDataConditional, formDataField, formDataFieldValue, formDataAction]
  );
  return (
    <Modal {...modal} size="md" centered>
      {/*{console.log(props.customTicketFieldItems?.items)}*/}
      <Modal.Header closeButton>
        <Modal.Title className="custom-field-title">
          {current
            ? t("translations.update_ticket_field")
            : t("translations.create_ticket_field")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div>
              {JSON.stringify(formData)}
          </div> */}
        <div>
          {!alert.close && (
            <Alert variant={alert.variant} onClose={closeAlert} dismissible>
              <p>{alert.message}</p>
            </Alert>
          )}
        </div>
        <div className="modal-body-row">
          <label htmlFor="">{t("lang")}</label>
          <div className="language-container">
            <div
              className="language-display"
              onClick={() => {
                setChoosedLang("en");
              }}
              style={{
                border: `1px solid ${
                  choosedLang == "en" ? "#0089E1" : "#AEBACE"
                }`
              }}
            >
              <div className="language-text">
                {t("translations.display_input_text")}
              </div>
              <div className="language-image">
                <img src={LangIconEn} alt="EN" srcSet="" />
                <span>{t("english")}</span>
              </div>
            </div>
            <div
              className="language-display"
              onClick={() => {
                setChoosedLang("fr");
              }}
              style={{
                border: `1px solid ${
                  choosedLang == "fr" ? "#0089E1" : "#AEBACE"
                }`
              }}
            >
              <div className="language-text">
                {t("translations.display_input_text")}
              </div>
              <div className="language-image">
                <img src={LangIconFr} alt="FR" srcSet="" />
                <span>{t("french")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body-row">
          <label htmlFor="">{t("translations.field_name")}</label>
          <div className="field-name">
            <input
              type="text"
              placeholder={t("translations.enter_field_name")}
              value={formData[`fieldName${choosedLang}`]}
              onChange={changeFieldName}
              maxLength={128}
            />
          </div>
          <div>
            {haveErrors[choosedLang] && (
              <span className={"text-danger"}>{t(errorMsg[choosedLang])}</span>
            )}
          </div>
        </div>
        <div className="modal-body-row">
          <label htmlFor="">{t("translations.select_field_type")}</label>
          {current ? (
            <Select
              placeholder={t("translations.select")}
              styles={customStyles}
              className="select-field-name"
              classNamePrefix="select"
              name="fieldKind"
              options={fieldNameOptions}
              getOptionLabel={o => t(o.label)}
              onChange={changeFieldKind}
              value={fieldNameOptions.find(o => o.value == formData.fieldKind)}
            />
          ) : (
            <Select
              placeholder={t("translations.select")}
              styles={customStyles}
              className="select-field-name"
              classNamePrefix="select"
              name="fieldKind"
              getOptionLabel={o => t(o.label)}
              options={fieldNameOptions}
              onChange={changeFieldKind}
            />
          )}
        </div>
        {formData.fieldKind == "Open Text" && (
          <div className="modal-body-row">
            <label htmlFor="">{t("translations.data_type")}</label>
            {current ? (
              <Select
                placeholder={t("translations.select")}
                styles={customStyles}
                className="select-field-name"
                classNamePrefix="select"
                name="dataType"
                getOptionLabel={o => t(o.label)}
                options={openTextOptions}
                onChange={changeDataType}
                value={openTextOptions.find(o => o.value == formData.dataType)}
              />
            ) : (
              <Select
                placeholder={t("translations.select")}
                styles={customStyles}
                className="select-field-name"
                classNamePrefix="select"
                name="dataType"
                getOptionLabel={o => t(o.label)}
                options={openTextOptions}
                onChange={changeDataType}
              />
            )}
          </div>
        )}
        {formData.fieldKind == "Options" && (
          <Fragment>
            {formData.options.map((x, i) => {
              return (
                <div key={i} className="modal-body-row">
                  {i == 0 && (
                    <label htmlFor="">{t("translations.option_name")}</label>
                  )}
                  <div className="option-field-list">
                    <div
                      className={i == 0 ? "field-name" : "field-name-reduce"}
                    >
                      <input
                        type="text"
                        placeholder={t("translations.enter_option_name")}
                        value={x[choosedLang]}
                        maxLength={96}
                        onChange={e => changeOptionListData(e, i)}
                      />
                    </div>
                    {i != 0 ? (
                      <div
                        className="option-close-btn"
                        onClick={() => handleRemoveOption(i)}
                      >
                        &times;
                      </div>
                    ) : (
                      <div
                        className="option-close-btn"
                        style={{ color: "silver" }}
                      >
                        &times;
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="add-option" onClick={handleAddOption}>
              {t("translations.add_option")}
            </div>
          </Fragment>
        )}
        <div className={"d-flex align-items-center"}>
          <p className={"p-2"}>
          <span className="mr-1">{t("translations.mark_mandatory")}</span>
          <input
            name="name"
            type="checkbox"
            defaultValue={formData.mandatory}
            checked={formData.mandatory}
            onChange={changeFieldMandatory}
          />
        </p>
          {/** field that has been marked has conditional */}
          <p className={"p-1 m-1"}>
            <span className="mr-1">{t("translations.mark_conditional")}</span>
            <input
              name="name"
              type="checkbox"
              checked={formDataConditional}
              value={formDataConditional}
              onChange={changeFieldConditional}
              key={
                current?.conditional ||
                formData?.conditional ||
                formDataConditional
              }
            />
          </p>
        </div>
        {formDataConditional && (
          <Fragment>
            <div>
              {/** select the action required */}
              <div className="my-3">
                <label htmlFor="">{t("This field will be")}</label>
                <Select
                  // isDisabled={!formDataConditional}
                  placeholder="Select Action"
                  styles={customStyles}
                  className="select-field-name"
                  classNamePrefix="select"
                  name="dataType"
                  options={selectConditionalAction}
                  onChange={conditionAction}
                  defaultValue={
                    formData.conditional ? formDataAction : undefined
                  }
                />
              </div>
              {
                /*current ? (
            <Select
              placeholder={t('translations.selectDependsOn')}
              styles={customStyles}
              className='select-field-name'
              classNamePrefix='select'
              name='dataType'
              options={conditionalField}
              onChange={conditionalFieldHandler}
              value={
                choosedLang === 'en'
                  ? formData.conditionalFieldData.fieldNameEn
                  : formData.conditionalFieldData.fieldNameFr
              }
            />
          ) : */ <>
                  <label htmlFor="">{t("When filed")}</label>
                  <Select
                    // isDisabled={!formDataAction||!formData.conditionalAction}
                    placeholder={t("translations.selectDependsOn")}
                    styles={customStyles}
                    className="select-field-name"
                    classNamePrefix="select"
                    name="dataType"
                    options={conditionalField}
                    onChange={conditionalFieldHandler}
                    defaultValue={
                      formData.conditional ? formDataField : undefined
                    }
                  />
                </>
              }
            </div>
            {/** select options from the conditional field that was selected */}
            <div className="mt-3 modal-body-row">
              <label htmlFor="">{t("Have value")}</label>
              <Select
                // isDisabled={!formDataField||!formData.conditionalField}
                placeholder="Select Field Options"
                styles={customStyles}
                className="select-field-name"
                classNamePrefix="select"
                name="dataType"
                options={
                  choosedLang === "en"
                    ? selectedConditionFieldOptions.en
                    : selectedConditionFieldOptions.fr
                }
                onChange={selectedOptionFromConditionalCTicket}
                defaultValue={
                  formData.conditional ? formDataFieldValue : undefined
                }
              />
            </div>
          </Fragment>
        )}
        <div className="modal-body-row">
          <button
            disabled={haveErrors["fr"] || haveErrors["en"]}
            className="button-add-field w-100"
            onClick={handleSubmitForm}
          >
            {savingField ? (
              <Spinner animation={"border"} size={"sm"} />
            ) : (
              <span>{current ? t("UPDATE FIELD") : t("CREATE FIELD")}</span>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNewFieldModal;
