import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
// Use Socket io - import
//import io from "socket.io-client";
import TicketsPriority from "./Priorities/TicketsPriority";
import TicketsNature from "./Natures/TicketsNature";
import TicketsStatus from "./Statuses/TicketsStatus";
import TicketsCategory from "./Categories/TicketsCategory";
import CustomerInformations from "./Customers/CustomerInformations";
import Modal from "./Modal";
import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
/* START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
import {
  TicketSettingsHttpService,
  CustomTicketFieldHttpService
} from "../../../services/HttpService";
import {
  SOCKET,
  SIO_CREATE_CUSTOMER_TICKET_SETTINGS,
  SIO_UPDATE_SWITCH_TICKET_SETTINGS,
  SIO_UPDATE_SWITCH_ENABLE_TICKET_CUSTOM,
  SIO_CREATE_TICKET_SUBJECT,
  SIO_UPDATE_TICKET_SUBJECT,
  SIO_DELETE_TICKET_SUBJECT,
  SIO_DELETE_TICKET_NATURE,
  SIO_CREATE_TICKET_NATURE,
  SIO_GET_TICKET_NATURE_SETTINGS
} from "../../../constants/Constants";
import AutoPriorization from "./AutoPriorization/AutoPriorization";
import Naturalisation from "./Naturalisation/Naturalisation";
import CustomiseTicketDetails from "./CustomiseTicketDetails/CustomiseTicketDetails";

//const socket = io(SOCKET.BASE_URL);
/* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

const Content = props => {
  const {
    t,
    kind,
    i18n,
    ticketSubject,
    ticketSubjectSetting,
    ticketNatureSetting,
    priority,
    status,
    category,
    customerInformation,
    socket
  } = props;

  //console.log('priority ; ', priority);
  // console.log('status : ', status);
  // console.log('category : ', category);
  // console.log('customerInformation : ', customerInformation);

  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [customerFields, setCustomerFields] = useState({});
  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );
  const [ticketSubjectItems, setTicketSubjectItems] = useState([]);
  const [subjectSetting, setSubjectSetting] = useState({});

  const [ticketNatureItems, setTicketNatureItems] = useState([]);

  const [customTicketFieldItems, setCustomTicketFieldItems] = useState({});
  const [reloadCustomField, setReloadCustomField] = useState(true);

  useEffect(() => {
    setTicketSubjectItems(ticketSubject);
  }, [ticketSubject]);

  useEffect(() => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
  }, []);

  useEffect(() => {
    setSubjectSetting(ticketSubjectSetting);
  }, [ticketSubjectSetting]);

  const agentModal = React.createRef();

  const handleAddRessourceModal = (textTitle, detectContent, buttonText) => {
    document.body.classList.add("modal-opened");
    agentModal.current.classList.add("is-active");
    setModalTitle(textTitle);
    setModalContent(detectContent);
    setModalButton(buttonText);
  };

  const handleCloseRessourceModal = () => {
    document.body.classList.remove("modal-opened");
    agentModal.current.classList.remove("is-active");
    setModalTitle("");
    setModalContent("");
  };

  /** Start - send customerFiled */
  const buildCreateCustomerFiled = newObjectFiled => {
    const createFiled = {
      sio_channel: SIO_CREATE_CUSTOMER_TICKET_SETTINGS,
      customer_information: newObjectFiled
    };

    if (localStorage && createFiled) {
      localStorage.setItem("sv_tmp_create_field", JSON.stringify(createFiled));
    }
  };

  const handleCreateCustomerFiled = () => {
    const createCustomerFiled = JSON.parse(
      localStorage.getItem("sv_tmp_create_field")
    );

    TicketSettingsHttpService.createCustomerFiledTicketSettings(
      createCustomerFiled
    )
      .then(response => {
        if (response && response.data && response.data.status === 202) {
          const construct = customerFields;
          construct.items.push(createCustomerFiled.customer_information);
          setCustomerFields(construct);
          localStorage.removeItem("sv_tmp_create_field");

          // toast(
          //   <Notification
          //     content={this.props.t("create_survey.new.created_successfully")}
          //     icon="success"
          //     reply={false}
          //   />, {
          //     type: toast.TYPE.SUCCESS,
          // });
        } else {
          // toast(
          //   <Notification
          //     content={this.props.t("create_survey.new.created_failed")}
          //     icon="danger"
          //     reply={false}
          //   />, {
          //     type: toast.TYPE.ERROR,
          // });
        }
      })
      .catch(error => {
        // console.log("**** print error ****", error);
      });
  };

  const onSocketCreateCustomerFiled = response => {
    if (response && response.status === 200) {
      // console.log('onSocketCreateCustomerFiled : ', response);
    }
  };

  const initSocketCreateCustomerFiled = () => {
    socket.on(SIO_CREATE_CUSTOMER_TICKET_SETTINGS, event => {
      const response = JSON.parse(event);
      onSocketCreateCustomerFiled(response);
    });
    handleCreateCustomerFiled();
  };

  const initSocketGetTicketNature = () => {
    socket.on(SIO_GET_TICKET_NATURE_SETTINGS, event => {
      const response = JSON.parse(event);
      if (response.status == 200 || response.status == 202) {
        setTicketNatureItems(response.data.filter(item => item.status !== 0));
      }
      // onSocketCreateCustomerFiled(response);
    });
    handleGetTicketNature();
  };

  const handleAddFields = (elt, params) => {
    const { fieldType, fieldLabel } = params;
    const newObjectFiled = {
      name: fieldLabel.toLowerCase(),
      type: fieldType.toLowerCase()
    };

    const detect = customerFields.items.findIndex(
      item =>
        item.name.toLowerCase() === newObjectFiled.name &&
        item.type.toLowerCase() === newObjectFiled.type
    );

    if (elt === "customer") {
      if (detect >= 0) {
        customerFields.items.splice(
          customerFields.items.findIndex(
            item =>
              item.name.toLowerCase() === newObjectFiled.name &&
              item.type.toLowerCase() === newObjectFiled.type
          ),
          1
        );

        return;
      }

      // build & save in dataBase
      buildCreateCustomerFiled(newObjectFiled);
      initSocketCreateCustomerFiled();
    }
  };
  /** End - send customerFiled */

  /** Start - Update Switch */
  const handleUpdateSwitch = updateSwitch => {
    TicketSettingsHttpService.updateSwitchTicketSettings(updateSwitch)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const handleUpdateEnableCustomTicket = updateSwitch => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.updateSwitchEnableCustomTicket(
      updateSwitch,
      receptor
    )
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const handleCreateTicketSubject = params => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.createTicketSubject(params, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };
  const handleCreateTicketNature = params => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    params["nature_en"] = params["nature_en"].trim();
    params["nature_fr"] = params["nature_fr"].trim();
    if (params["nature_en"] === "" && params["nature_fr"] === "") {
      const msg_error = t("notification.msg_create_ticket_nature_empty");
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: { title: "", msg: msg_error }
        }
      });
      return;
    } else if (params["nature_en"] === "" && params["nature_fr"] !== "") {
      params["nature_en"] = params["nature_fr"];
    } else if (params["nature_en"] !== "" && params["nature_fr"] === "") {
      params["nature_fr"] = params["nature_en"];
    }
    // console.log("Current User ", sharedDataContext.currentUser);
    TicketSettingsHttpService.createTicketNature(params, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const updateTicketSubject = (id, params) => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.updateTicketSubject(id, params, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const deleteTicketSubject = id => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.deleteTicketSubject(id, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const deleteTicketNature = id => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.deleteTicketNature(id, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const onSocketUpdateSwitch = response => {
    if (response && response.status === 200) {
      setSubjectSetting(response.data[0]);
    }
  };

  const onSocketTicketSubject = (response, params, type) => {
    // console.log("response", response);

    if (response && response.status === 200) {
      //console.log("params", params);
      // console.log("response");
      const msg =
        type === "create"
          ? t("notification.msg_create_ticket_subject_success")
          : type === "update"
          ? t("notification.msg_update_ticket_subject_success")
          : t("notification.msg_delete_ticket_subject_success");
      const msg_success =
        t("settings.auto_priorization.ticket_subject") +
        " " +
        params.title +
        " " +
        msg;
      setTicketSubjectItems(response.data);
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "success",
          content: { title: "", msg: msg_success }
        }
      });
    } else {
      const msg_error =
        type === "create"
          ? t("notification.msg_create_ticket_subject_error")
          : type === "update"
          ? t("notification.msg_update_ticket_subject_error")
          : t("notification.msg_delete_ticket_subject_error");
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: { title: "", msg: msg_error }
        }
      });
    }
  };

  const onSocketTicketNature = (response, params, type) => {
    if (response && response.status === 200) {
      //console.log("params", params);
      const msg =
        type === "create"
          ? t("notification.msg_create_ticket_nature_success")
          : type === "update"
          ? t("notification.msg_update_ticket_nature_success")
          : t("notification.msg_delete_ticket_nature_success");
      const msg_success =
        t("settings.naturalisation.ticket_nature") +
        " " +
        response.data.labels[sharedDataContext.defaultLang.value] +
        " " +
        msg;

      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "success",
          content: { title: "", msg: msg_success }
        }
      });
    } else {
      const msg_error =
        type === "create"
          ? t("notification.msg_create_ticket_nature_error")
          : type === "update"
          ? t("notification.msg_update_ticket_nature_error")
          : t("notification.msg_delete_ticket_nature_error");
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: { title: "", msg: msg_error }
        }
      });
    }
    initSocketGetTicketNature();
  };

  const initSocketUpdateSwitch = updateSwitch => {
    socket.on(SIO_UPDATE_SWITCH_TICKET_SETTINGS, event => {
      const response = JSON.parse(event);
      onSocketUpdateSwitch(response);
    });
    handleUpdateSwitch(updateSwitch);
  };

  const initSocketUpdateEnableTicketCustom = params => {
    socket.on(SIO_UPDATE_SWITCH_ENABLE_TICKET_CUSTOM, event => {
      const response = JSON.parse(event);
      onSocketUpdateSwitch(response);
    });
    handleUpdateEnableCustomTicket(params);
  };

  const initSocketCreateTicketSubject = params => {
    socket.on(SIO_CREATE_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);
      onSocketTicketSubject(response, params, "create");
    });
    handleCreateTicketSubject(params);
  };
  const initSocketCreateTicketNature = params => {
    socket.on(SIO_CREATE_TICKET_NATURE, event => {
      const response = JSON.parse(event);
      // console.log("response of SIO : ", response);
      onSocketTicketNature(response, params, "create");
    });
    handleCreateTicketNature(params);
  };

  const initSocketUpdateTicketSubject = (id, params) => {
    socket.on(SIO_UPDATE_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);
      onSocketTicketSubject(response, params, "update");
    });
    updateTicketSubject(id, params);
  };

  const initSocketDeleteTicketSubject = params => {
    socket.on(SIO_DELETE_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);
      onSocketTicketSubject(response, params, "delete");
    });
    deleteTicketSubject(params.id);
  };

  const initSocketDeleteTicketNature = params => {
    socket.on(SIO_DELETE_TICKET_NATURE, event => {
      const response = JSON.parse(event);
      // console.log("response of SIO : ", response);
      onSocketTicketNature(response, params, "delete");
    });
    deleteTicketNature(params.id);
  };

  const handleSwitchTicketSetting = (active, label) => {
    const updateSwitch = {
      sio_channel: SIO_UPDATE_SWITCH_TICKET_SETTINGS,
      settings: {
        active,
        label
      }
    };
    initSocketUpdateSwitch(updateSwitch);
  };

  const handleSwitchEnableCustomTicket = value => {
    const updateSwitch = {
      sio_channel: SIO_UPDATE_SWITCH_ENABLE_TICKET_CUSTOM,
      value: value
    };
    initSocketUpdateEnableTicketCustom(updateSwitch);
  };
  /** End - Update Switch */

  const handleCreationTicketSubject = (
    title,
    priority,
    enable,
    sla,
    all_parent_id,
    parent_id
  ) => {
    const createTicketSubject = {
      sio_channel: SIO_CREATE_TICKET_SUBJECT,
      title: title,
      enable: enable,
      priority: priority,
      sla: sla,
      all_parent_id: all_parent_id,
      parent_id: parent_id
    };
    initSocketCreateTicketSubject(createTicketSubject);
  };

  const handleCreationTicketNature = (nature_en, nature_fr) => {
    const createTicketNature = {
      sio_channel: SIO_CREATE_TICKET_NATURE,
      nature_en: nature_en,
      nature_fr: nature_fr
    };
    initSocketCreateTicketNature(createTicketNature);
  };

  const handleUpdateTicketSubject = (
    title,
    priority,
    nature,
    id,
    sla,
    all_parent_id,
    parent_id
  ) => {
    const updateTicketSubject = {
      sio_channel: SIO_UPDATE_TICKET_SUBJECT,
      title: title,
      priority: priority,
      sla: sla,
      all_parent_id: all_parent_id,
      parent_id: parent_id
    };
    initSocketUpdateTicketSubject(id, updateTicketSubject);
  };

  const handleDeleteTicketSubject = params => {
    initSocketDeleteTicketSubject(params);
  };
  const handleDeleteTicketNature = params => {
    initSocketDeleteTicketNature(params);
  };

  const handleGetTicketNature = () => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    // console.log(receptor);
    // console.log("Current User ", sharedDataContext.currentUser);
    TicketSettingsHttpService.getTicketNatureSettings(receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const GetCustomTicketFields = () => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };

    CustomTicketFieldHttpService.getAll(receptor)
      .then(r => {
        if (r && r.data && r.data.status == 200) {
          // Set
          setCustomTicketFieldItems(r.data.data);
        }
      })
      .catch(e => {
        console.log("[CustomTicketFieldHttpService.getAll]", e);
      })
      .finally(f => {
        // Close reload.
        setReloadCustomField(false);
      });
  };

  useEffect(() => {
    initSocketGetTicketNature();
  }, []);

  useEffect(() => {
    if (reloadCustomField) {
      // Fetch custom field list.
      GetCustomTicketFields();
    }
  }, [reloadCustomField]);

  useEffect(() => {
    // console.log("Shared Data Context : ", sharedDataContext)
    setCustomerFields(customerInformation);
    return () => {};
  }, [priority, status, category, customerInformation]);

  return (
    <>
      <div className="card-content">
        <div className="content">
          <div className="content-container">
            <TicketsPriority
              t={t}
              handleCloseRessourceModal={handleCloseRessourceModal}
              handleAddRessourceModal={handleAddRessourceModal}
              priority={priority}
              handleSwitchTicketSetting={handleSwitchTicketSetting}
            />
            <TicketsStatus
              t={t}
              handleCloseRessourceModal={handleCloseRessourceModal}
              handleAddRessourceModal={handleAddRessourceModal}
              status={status}
              handleSwitchTicketSetting={handleSwitchTicketSetting}
            />
            {/* <TicketsCategory
              t={t}
              handleCloseRessourceModal={handleCloseRessourceModal}
              handleAddRessourceModal={handleAddRessourceModal}
              category={category}
              handleSwitchTicketSetting={handleSwitchTicketSetting}
            /> */}
            <CustomerInformations
              t={t}
              handleCloseRessourceModal={handleCloseRessourceModal}
              handleAddRessourceModal={handleAddRessourceModal}
              customerFields={customerFields}
              handleSwitchTicketSetting={handleSwitchTicketSetting}
            />
          </div>
        </div>
      </div>
      {/* <AutoPriorization
        t={t}
        //enable={subjectSetting.enable_ticket_subject}
        enable={true}
        handleSwitch={handleSwitchEnableCustomTicket}
        priorities={priority}
        handleCreateTicketSubject={handleCreationTicketSubject}
        handleUpdateTicketSubject={handleUpdateTicketSubject}
        handleDeleteTicketSubject={handleDeleteTicketSubject}
        ticketSubjectItems={ticketSubjectItems}
      /> */}
      <Naturalisation
        t={t}
        handleCreationTicketNature={handleCreationTicketNature}
        ticketNatureItems={ticketNatureItems}
        handleDeleteTicketNature={handleDeleteTicketNature}
      />
      <CustomiseTicketDetails
        t={t}
        reload={setReloadCustomField}
        sharedDataContext={sharedDataContext}
        setSharedDataContext={setSharedDataContext}
        customTicketFieldItems={customTicketFieldItems}
      />
      <Modal
        i18n={i18n}
        t={t}
        agentModal={agentModal}
        handleCloseRessourceModal={handleCloseRessourceModal}
        title={modalTitle}
        content={modalContent}
        kind={kind}
        buttonText={modalButton}
        handleAddFields={handleAddFields}
      />
    </>
  );
};

Content.propTypes = {
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  priority: PropTypes.objectOf.isRequired,
  nature: PropTypes.objectOf.isRequired,
  status: PropTypes.objectOf.isRequired,
  category: PropTypes.objectOf.isRequired,
  customerInformation: PropTypes.objectOf.isRequired
};

const ContentWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <Content {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);
export default ContentWithSocket;
