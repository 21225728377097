const initialState = {
  sharedState: null,
  categories: null,
  priorities: null,
  categoryPriority: null,
  categorySLA: null,
  categoryParentId: null,
  categoryAllParentId: null,
  items: null,
  opened: {},
  closeAllPopover: 1,
  resetSlaInBosx: 1,
  duplicationData:{}
};

function rootReducer(state = initialState, action) {
  if (!state) {
    return null;
  } else {
    return { ...state, [action.key]: action.payload };
  }
}

export default rootReducer;
