import React from "react";
import { Spinner } from "react-bootstrap";

/* <div style={{position: 'fixed', top: 'calc(50% - 45px)', left: 'calc(50% - 45px)'}}> */
function Loading(props) {
  const { classes } = props;
  return (
    <div style={{ border: "1x solid red !important" }}>
      <Spinner
        animation="border"
        variant="primary"
        size="md"
        style={{ height: "40px", width: "40px" }}
      ></Spinner>
    </div>
  );
}

export default Loading;
