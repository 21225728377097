import React, { useContext, useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
//import io from "socket.io-client";
import FakeAgents from "../../../faker/agents";
import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
import ProfileIcon from "../../../assets/images/profile/user.svg";

import Modal from "./Modal";

import {
  SOCKET,
  SIO_AGENT_FORM_XP,
  SIO_AGENT_SINCE_CARE,
  SIO_AGENT_PLATFORM,
  SIO_DELETE_AGENT_PLATFORM
} from "../../../constants/Constants";
import { AgentFromXPHttpService } from "../../../services/HttpService";

//const socket = io(SOCKET.BASE_URL);

const Agents = props => {
  const {
    t,
    kind,
    agentFromXP,
    agentFromPlatform,
    agentCount,
    load,
    socket
  } = props;

  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );
  const [countAgentSelected, setCountAgentSelected] = React.useState(0);
  const [assignedAgents, setassignedAgents] = useState(0);
  const [platformeAgents, setPlatformeAgents] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // chargement de la liste des agents & init du array default de addAgentsChannel
  const [state, setState] = useState({
    initAgents: [],
    storeInitAgents: [],
    agentFromXP
  });
  React.useEffect(() => {
    // console.log('initAgents', state.initAgents)
    // console.log('storeInitAgents', state.storeInitAgents)
    // console.log('agentFromXP', state.agentFromXP)
    // console.log('agentFromXP', agentFromXP)
    // console.log('assignedAgents', assignedAgents)
    // console.log('platformeAgents', platformeAgents)
    // let paf = platformeAgents.filter(a=>a.user_id === sharedDataContext.currentUser.userid).length
    // console.log(paf)
    // if (paf===0) {
    //   let cu = sharedDataContext.currentUser
    //   cu.user_id = cu.userid
    //   console.log(cu)
    //   handleAddAgent(cu)
    // }
  }, [agentFromPlatform, agentCount]);
  const [modal, setModal] = React.useState("");
  useEffect(() => {
    setState({ ...state, initAgents: agentFromXP, agentFromXP });
    setassignedAgents(agentCount);
    setPlatformeAgents(agentFromPlatform);

    if (agentCount === 0) {
      let cu = sharedDataContext.currentUser;
      cu.user_id = cu.userid;
      // console.log(cu);
      handleAddAgent(cu);
      handleContinue();
    }
    return () => {
      // cleanup
    };
  }, [agentCount, modal]);

  //get Agent from Platform

  // liste Agent
  // console.log("agentFromPlatform", agentFromPlatform);
  // console.log("platformeAgents", platformeAgents);

  // console.log(sharedDataContext.currentUser.userid)
  const listAgents = (
    <ul className="menu-list">
      {state.initAgents &&
        state.initAgents.map(
          (item, i) =>
            item.user_id === sharedDataContext.currentUser.userid && (
              <li key={i}>
                <img src={ProfileIcon} alt="portrait" />
                <span className="user-name">
                  {item.firstname} {item.lastname}
                </span>
              </li>
            )
        )}
      {state.initAgents &&
        state.initAgents.map(
          (item, i) =>
            item.user_id !== sharedDataContext.currentUser.userid && (
              <li key={i}>
                <img src={ProfileIcon} alt="portrait" />
                <span className="user-name">
                  {item.firstname} {item.lastname}
                </span>
                {platformeAgents.filter(elm => elm.user_id === item.user_id)
                  .length !== 0 ? (
                  <span
                    className="remove-user"
                    onClick={e => handleRemoveAgent(item)}
                  >
                    {" "}
                    -{" "}
                  </span>
                ) : (
                  <span
                    className="add-user"
                    onClick={e => handleAddAgent(item)}
                  >
                    {" "}
                    +{" "}
                  </span>
                )}
              </li>
            )
        )}
    </ul>
  );
  // Add Agent
  const handleAddAgent = user => {
    setSelectAll(false);
    const localService = JSON.parse(localStorage.getItem("cr_services"));
    if (localService[0].agentstodelete) {
      if (
        localService[0].agentstodelete.filter(
          elm => elm.user_id === user.user_id
        ).length !== 0
      ) {
        localService[0].agentstodelete = localService[0].agentstodelete.filter(
          elm => elm.user_id !== user.user_id
        );
      } else {
        const localAgent = localService[0].agents;
        localAgent.push(user);

        setassignedAgents(prevState => prevState + 1);
        setPlatformeAgents(prevState => prevState.concat(user));
      }
    } else {
      const localAgent = localService[0].agents;
      localAgent.push(user);

      // localStorage.setItem("cr_services", JSON.stringify(localService));
      setassignedAgents(prevState => prevState + 1);
      setPlatformeAgents(prevState => prevState.concat(user));
    }
    localStorage.setItem("cr_services", JSON.stringify(localService));
  };

  //remove Agent
  const handleRemoveAgent = user => {
    setSelectAll(false);
    //console.log(user);
    setPlatformeAgents(prevState =>
      prevState.filter(elm => elm.user_id !== user.user_id)
    );

    setassignedAgents(prevState => prevState - 1);
    let localService = JSON.parse(localStorage.getItem("cr_services"));

    let agentAdded = localService[0].agents;
    if (agentAdded.filter(elm => elm.user_id === user.user_id).length !== 0) {
      localService[0].agents = agentAdded.filter(
        elm => elm.user_id !== user.user_id
      );
      setPlatformeAgents(prevState =>
        prevState.filter(elm => elm.user_id !== user.user_id)
      );
    } else {
      if (localService[0].agentstodelete) {
        localService[0].agentstodelete.push(user);
        setPlatformeAgents(
          platformeAgents.filter(elm => elm.user_id !== user.user_id)
        );
      } else {
        localService[0].agentstodelete = [];
        localService[0].agentstodelete.push(user);
        setPlatformeAgents(
          platformeAgents.filter(elm => elm.user_id !== user.user_id)
        );
      }
    }
    localStorage.setItem("cr_services", JSON.stringify(localService));
  };
  //remove agent

  const deleteAgentPlatform = () => {
    const localService = JSON.parse(localStorage.getItem("cr_services"));
    if (localService[0].agentstodelete) {
      if (localService[0].agentstodelete.length > 0) {
        let agentDelete = localService[0].agentstodelete;
        for (let agent of agentDelete) {
          const agentTodelete = agentFromPlatform.filter(
            elm => elm.user_id === agent.user_id
          );
          //console.log('agentTodelete[0].id',agentTodelete[0].id)
          initSocketDeleteAgent(agentTodelete[0].id);
        }
      }
    }
  };

  const handleContinue = () => {
    const localService = JSON.parse(localStorage.getItem("cr_services"));
    // console.log("initSocketDeleteAgent : **** ", localService);
    if (localService[0].agents.length === 0) {
      deleteAgentPlatform();
    } else if (localService[0].agentstodelete?.length === 0) {
      buildDataSaveAgent();
    } else {
      buildDataSaveAgent();
      deleteAgentPlatform();
    }
  };

  const initSocketDeleteAgent = id_agent => {
    const storageAgent = JSON.parse(localStorage.getItem("cr_services"));
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.publickey
            : false,
        apisid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.apisid
            : false,
        sessionid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.session_id
            : false
      }
    };
    const user_connectes_id = sharedDataContext.currentUser.userid;
    socket.on(SIO_DELETE_AGENT_PLATFORM, event => {
      const response = JSON.parse(event);
      onSocketDeleteAgent(response);
    });

    AgentFromXPHttpService.deleteAgentSinceCare(
      user_connectes_id,
      id_agent,
      receptor
    ).then(response => {
      //  console.log("deleteAgentFromXP : ", response);

      if (response.status === 200 || response.status === 202) {
        storageAgent[0].agentstodelete.length === 1
          ? setSharedDataContext({
              ...sharedDataContext,
              notification: {
                active: true,
                status: "success",
                content: {
                  title: "",
                  msg:
                    t("notification.msg_agent") +
                    " " +
                    storageAgent[0].agentstodelete[0].firstname +
                    " " +
                    t("notification.msg_delete_one_agent_success")
                }
              }
            })
          : setSharedDataContext({
              ...sharedDataContext,
              notification: {
                active: true,
                status: "success",
                content: {
                  title: "",
                  msg: t("notification.msg_delete_agent_success")
                }
              }
            });
      } else {
        // message de notification error
        setSharedDataContext({
          ...sharedDataContext,
          notification: {
            active: true,
            status: "danger",
            content: {
              title: "",
              msg: t("notification.msg_delete_agent_error")
            }
          }
        });
      }
      storageAgent[0].agentstodelete = [];
      localStorage.setItem("cr_services", JSON.stringify(storageAgent));
      load();
    });
  };

  const onSocketDeleteAgent = response => {
    // console.log("onSocketDeleteAgent : ", response.data);

    if (response && (response.status === 200 || response.status === 202)) {
      // setAgentFromXP(response.data);
    }
  };

  //Aadd Agent to platforme
  const buildDataSaveAgent = () => {
    const storageAgent = JSON.parse(localStorage.getItem("cr_services"));

    const refactData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of storageAgent[0].agents) {
      const agents = {
        user_id: item.user_id,
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        phone: item.phone,
        language: item.language,
        image: item.image,
        sexe: item.sexe,
        status: 1
      };
      refactData.push(agents);
    }

    const saveAgent = {
      sio_channel: SIO_AGENT_SINCE_CARE,
      user_connected_id: sharedDataContext.currentUser.userid,
      agents: refactData
    };

    if (localStorage && saveAgent) {
      localStorage.setItem("sv_tmp_save_agent", JSON.stringify(saveAgent));
      initSocketSaveAgent();
    }
  };

  const initSocketSaveAgent = () => {
    socket.on(SIO_AGENT_SINCE_CARE, response => {
      //const response = JSON.parse(event);
    });
    handleSaveAgentSubmit();
  };

  // const onSocketSaveAgent = response => {
  //   if (response && response.status === 200) {
  //     //   console.log("onSocketSaveAgent : ", response);
  //   }
  // };

  /** Start - Save Agent */
  const handleSaveAgentSubmit = () => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.publickey
            : false,
        apisid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.apisid
            : false,
        sessionid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.session_id
            : false
      }
    };
    const dataSaveAgent = JSON.parse(localStorage.getItem("sv_tmp_save_agent"));
    const storageAgent = JSON.parse(localStorage.getItem("cr_services"));
    AgentFromXPHttpService.SaveAgentSinceCare(dataSaveAgent, receptor)
      .then(response => {
        //  console.log("AgentFromXPHttpService : ", response);

        if (response && response.data && response.data.status === 202) {
          load();
          // localStorage.removeItem("sv_tmp_save_agent");
          if (
            dataSaveAgent.agents.length === 1 &&
            dataSaveAgent.agents[0].user_id ===
              sharedDataContext.currentUser.user_id
          ) {
          } else {
            dataSaveAgent.agents.length === 1
              ? setSharedDataContext({
                  ...sharedDataContext,
                  notification: {
                    active: true,
                    status: "success",
                    content: {
                      title: "",
                      msg:
                        t("notification.msg_agent") +
                        " " +
                        dataSaveAgent.agents[0].firstname +
                        " " +
                        t("notification.msg_save_one_agent_success")
                    }
                  }
                })
              : // message de notification success
                setSharedDataContext({
                  ...sharedDataContext,
                  notification: {
                    active: true,
                    status: "success",
                    content: {
                      title: "",
                      msg: t("notification.msg_save_agent_success")
                    }
                  }
                });
          }
        } else {
          // message de notification error
          setSharedDataContext({
            ...sharedDataContext,
            notification: {
              active: true,
              status: "danger",
              content: {
                title: "",
                msg: t("notification.msg_save_agent_error")
              }
            }
          });
        }
        localStorage.removeItem("sv_tmp_save_agent");
        storageAgent[0].agents = [];
        localStorage.setItem("cr_services", JSON.stringify(storageAgent));
      })
      .catch(error => {
        // console.log("**** print error ****", error);

        // message de notification error
        setSharedDataContext({
          ...sharedDataContext,
          notification: {
            active: true,
            status: "danger",
            content: { title: "", msg: t("notification.msg_save_agent_error") }
          }
        });
      });
  };

  //open and close  modal
  const agentModal = React.createRef();
  const channelModal = React.createRef();
  const referedModal = kind === "agent" ? agentModal : channelModal;

  const handleAddRessourceModal = () => {
    //  console.log(platformeAgents);
    let localService = JSON.parse(localStorage.getItem("cr_services"));
    localService[0].agents = [];
    localService[0].agentstodelete = [];
    localStorage.setItem("cr_services", JSON.stringify(localService));
    document.body.classList.add("modal-opened");
    kind === "agent"
      ? agentModal.current.classList.add("is-active")
      : channelModal.current.classList.add("is-active");
    setModal(Math.random());
  };

  const handleCloseRessourceModal = state => {
    //console.log(state);
    document.body.classList.remove("modal-opened");

    kind === "agent"
      ? agentModal.current.classList.remove("is-active")
      : channelModal.current.classList.remove("is-active");
    return state
      ? setPlatformeAgents(agentFromPlatform)
      : setPlatformeAgents(platformeAgents);
  };

  // SEARCH AGENT

  const handleSearchAgent = contentSearch => {
    const newFilter = contentSearch;
    if (newFilter !== "") {
      setState(prevState => ({
        ...prevState,
        initAgents: state.agentFromXP.filter(
          option =>
            option.firstname.toLowerCase().includes(newFilter.toLowerCase()) ||
            option.lastname.toLowerCase().includes(newFilter.toLowerCase())
        )
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        initAgents: state.agentFromXP
      }));
    }
  };

  //CHECKBOX
  const xhandleSelectAll = status => {
    if (status) {
      /*
              action de cocher tous les agents disponible
            */
      // eslint-disable-next-line no-restricted-syntax
      for (const item of state.agentFromXP) {
        if (
          platformeAgents.filter(elm => elm.user_id === item.user_id).length ===
          0
        ) {
          handleAddAgent(item);
        }
      }
    }

    if (!status) {
      let localService = JSON.parse(localStorage.getItem("cr_services"));
      localService[0].agents = [];
      localService[0].agentstodelete = [];

      /*
              action de décocher tous les agents disponible
            */
      // eslint-disable-next-line no-restricted-syntax
      for (const item of state.agentFromXP) {
        if (
          agentFromPlatform.filter(elm => elm.user_id === item.user_id)
            .length !== 0
        ) {
          localService[0].agentstodelete.push(item);
        }
      }

      setPlatformeAgents([]);
      setassignedAgents(0);
      localStorage.setItem("cr_services", JSON.stringify(localService));
    }
  };
  const [allstatus, setallstatus] = React.useState();
  React.useEffect(() => {
    if (allstatus === "all") {
      let localService = JSON.parse(localStorage.getItem("cr_services"));
      localService[0].agents = [];
      localService[0].agentstodelete = [];

      for (const item of state.agentFromXP) {
        // if (platformeAgents.filter(elm => elm.user_id === item.user_id).length !== 0) {
        localService[0].agents.push(item);
        // }
      }

      setPlatformeAgents(state.agentFromXP);
      setassignedAgents(state.agentFromXP.length);
      localStorage.setItem("cr_services", JSON.stringify(localService));
      setallstatus(Math.random().toString());
    }

    if (allstatus === "none") {
      let localService = JSON.parse(localStorage.getItem("cr_services"));
      localService[0].agents = [];
      localService[0].agentstodelete = [];

      for (const item of state.agentFromXP) {
        // if (agentFromPlatform.filter(elm => elm.user_id === item.user_id).length !== 0) {
        localService[0].agentstodelete.push(item);
        // }
      }

      setPlatformeAgents([]);
      setassignedAgents(0);
      localStorage.setItem("cr_services", JSON.stringify(localService));
      setallstatus(Math.random().toString());
    }
  }, [allstatus]);

  const handleSelectAll = status => {
    setallstatus(status ? "all" : "none");
  };

  const handleSelectAllAgents = select => {
    const status = !select;
    setSelectAll(status);
    handleSelectAll(status);
  };

  const checkboxSelectAll = (
    <input
      type="checkbox"
      checked={selectAll}
      onClick={() => handleSelectAllAgents(selectAll)}
    />
  );

  return (
    <div className="column">
      <div className="card analytics-card-parent analytics-card-parent-agent">
        <div className="card-content analytics-card">
          <p className="subtitle" style={{ marginBottom: "0px" }}>
            {t("dashboard.number_of_agents")}
          </p>
          <p className="statistic-ticket">
            {agentCount || platformeAgents.length}
          </p>
          <button
            className="button is-secondary is-blue-secondary is-outlined is-secondairy-test"
            onClick={handleAddRessourceModal}
            style={{ marginTop: "9px", width: "53% !important" }}
          >
            {platformeAgents && platformeAgents.length > 0
              ? platformeAgents.map((elm, index) => {
                  return index < 3 ? (
                    <img
                      src={ProfileIcon}
                      alt="profil icon"
                      style={{ width: "1.2rem", marginRight: "2px" }}
                    />
                  ) : (
                    false
                  );
                })
              : ""}
            <span>+</span>
            {platformeAgents && platformeAgents.length > 0
              ? ""
              : t("dashboard.add_agent")}
          </button>
        </div>
      </div>
      <Modal
        t={t}
        content={listAgents}
        handleContinue={handleContinue}
        //removeAgent={handleRemoveAgent}
        checkboxSelectAll={checkboxSelectAll}
        kind="agent"
        buttonText={t("onboard.steps.continue")}
        agentModal={referedModal}
        agentCount={state.initAgents.length}
        countAgentFromXp={agentCount}
        // checkedServices={checkedServices}
        handleCloseRessourceModal={state => handleCloseRessourceModal(state)}
        handleSearchAgent={handleSearchAgent}
        title={t("onboard.steps.add_agents")}
        handleSelectAll={handleSelectAll}
      />
    </div>
  );
};

Agents.propTypes = {
  t: PropTypes.func.isRequired
};

const AgentsWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <Agents {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);

export default AgentsWithSocket;
