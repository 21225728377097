import React from "react";
import PropTypes from "prop-types";
import DatePicker from "../../common/DatePicker";

const PendingTicket = props => {
  const {
    t,
    MoreIcon,
    CalendarIcon,
    last,
    ticketStats,
    ticketPendingDateFilter
  } = props;

  return (
    <div className="column dashbordticket-column">
      <div className="card analytics-card-parent analytics-card-parent-pending-request">
        <div className="card-content analytics-card">
          <p className="subtitle">{t("tickets.pending_ticket")}</p>
          <div className="title title-manageticket">
            <span className="statistic-ticket">{ticketStats}</span>
            <div className="arrawbtn" style={{ background: "#ffc5054f" }}>
              <button
                className="uptriangle"
                style={{
                  background: "#FFC505",
                  border: "none",
                  margin: "auto"
                }}
              ></button>
            </div>
          </div>
          <div className="button is-secondary is-outlined calender-Cont">
            <DatePicker
              t={t}
              getTimeFilter={(start, end) =>
                ticketPendingDateFilter(start, end)
              }
            />
            <img className="calendar-icon" src={CalendarIcon} alt="caneldar" />
          </div>
        </div>
      </div>
    </div>
  );
};

PendingTicket.propTypes = {
  t: PropTypes.func.isRequired
};

export default PendingTicket;
