import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import NoCategoryIcon from "../../../../assets/images/settings/no-category.svg";

const NoCategory = props => {
  const { t, i18n, handleCreateCategoryButtonClick } = props;

  return (
    <div className="text-center">
      <img src={NoCategoryIcon} className="no-category-icon text-center" />

      <br />
      {/* <div className=""> */}
      <span className="no-category-span text-center">
        {t("settings.tickets_category_content.no_category")}
      </span>
      {/* </div> */}
      <br />

      <button
        className="button-create-category h-37 mt-3"
        onClick={handleCreateCategoryButtonClick}
      >
        <span className="add-category-span">
          {t("settings.tickets_category_content.create_category")}
        </span>
      </button>
    </div>
  );
};

export default NoCategory;
