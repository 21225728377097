import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import TicketDashboard from "./tickets/TicketDashboard";
import CreateTicket from "./tickets/CreateTicket";
import DuplicateTicket from "./tickets/DuplicateTicket";
import MessageTicket from "./tickets/MessageTicket";
// import { render } from 'enzyme';

import { SharedDataContext } from "../app/UseContext";

const TicketAnalytics = props => {
  const { i18n, t, kind, ticketStats, defaultLang, step } = props;

  // const [isOpen, setIsOpen] = useState(false);
  //const [update, setUpdate] = setUpdate(false);
  const [idTicket, setIdTicket] = useState("");
  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );

  //console.log('sharedDataContext',sharedDataContext)
  const handleMessageTicket = (statusValid, data, idNewTicket) => {
    // console.log("data parameter : ", statusValid);

    if (statusValid === "success") {
      //setStatus(2);
      setIdTicket(idNewTicket);
      setSharedDataContext({
        ...sharedDataContext,
        status: 2,
        notification: {
          active: true,
          status: "success",
          content: {
            // title: "Tickets",
            msg: t("notification.msg_create_ticket_success") + data
          }
        }
      });

      return;
    }
    if (statusValid === "successDashbord") {
      //setStatus(1);
      //setIdTicket(idNewTicket);
      setSharedDataContext({
        ...sharedDataContext,
        status: 1,
        notification: {
          active: true,
          status: "success",
          content: {
            // title: "Tickets",
            msg: t("notification.msg_create_ticket_success") + data
          }
        }
      });

      return;
    }
    if (statusValid === "successUpdate") {
      // setUpdate(true)
      //console.log("successUpdate",sharedDataContext.status)
      setSharedDataContext({
        ...sharedDataContext,
        status: sharedDataContext.status === 0 ? 0 : sharedDataContext.status,
        notification: {
          active: true,
          status: "success",
          content: {
            // title: "Tickets",
            msg: t("notification.msg")
          }
        }
      });

      return;
      // //sharedDataContext.status === 0 ? setSharedDataContext({
      //   ...sharedDataContext,status: 0}) : setSharedDataContext({
      //   ...sharedDataContext,status: 2})
    }
    if (statusValid === "test-Delete-success") {
      setIdTicket(idNewTicket);
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "success",
          content: {
            //title: "Tickets",
            msg:
              t("notification.ticket_notifi") +
              data +
              " " +
              t("notification.msg_delete_ticket_success")
          }
        }
      });

      return;
    }
    if (statusValid === "show-ticket-detail") {
      //console.log("show-ticket-detail")
      setIdTicket(idNewTicket);
      setSharedDataContext({
        ...sharedDataContext,
        status: 2,
        notification: {
          active: false,
          status: "success",
          content: {
            title: "Tickets",
            msg: t("notification.msg_delete_ticket_success")
          }
        }
      });

      return;
    }

    if (statusValid === "error") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: { title: "", msg: t("notification.msg_create_ticket_error") }
        }
      });
    }
    if (statusValid === "Error-Delete-ticket") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: { title: "", msg: t("notification.msg_delete_ticket_error") }
        }
      });
    }
    if (statusValid === "error-all-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_field_empty")
          }
        }
      });
    }
    if (statusValid === "error-Customer-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t(
              "notification.msg_create_ticket_error_Customer's_field_empty"
            )
          }
        }
      });
    }
    /*if (statusValid === "error-email-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_email_field_empty")
          }
        }
      });
    }*/
    if (statusValid === "error-file-name-upload") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_file_name_error_upload")
          }
        }
      });
    }
    //erreur file size
    if (statusValid === "error-file-size") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.error-file-size")
          }
        }
      });
    }
    //erreur file size
    if (statusValid === "error-file-exist") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.error-file-exist")
          }
        }
      });
    }
    //error-file-delete
    if (statusValid === "error-file-delete") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.error-file-delete")
          }
        }
      });
    }
    //error-file-upload-progress
    if (statusValid === "error-file-upload-progress") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.error-file-upload-progress")
          }
        }
      });
    }
    if (statusValid === "error-file-upload") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.error-file-upload")
          }
        }
      });
    }
    if (statusValid === "error-Subject-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_Subject_field_empty")
          }
        }
      });
    }
    if (statusValid === "error-Priority-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_Priority_field_empty")
          }
        }
      });
    }
    //Ticket Nature Start
    if (statusValid === "error-Nature-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_Nature_field_empty")
          }
        }
      });
    }
    // Ticket Nature End
    if (statusValid === "error-Message-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_Message_field_empty")
          }
        }
      });
    }
    if (statusValid === "error-custom-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.error-custom-field-empty")
          }
        }
      });
    }
    if (statusValid === "error-File-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_File_field_empty")
          }
        }
      });
    }
    if (statusValid === "error-Agent-field-empty") {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.msg_create_ticket_error_Agent_field_empty")
          }
        }
      });
    }

    // if (statusValid === "error-Customer-field-empty") {
    //   setSharedDataContext({
    //     ...sharedDataContext,
    //     notification: {
    //       active: true,
    //       status: "danger",
    //       content: {
    //         title: "",
    //         msg: t("notification.msg_create_ticket_error_field_empty")
    //       }
    //     }
    //   });
    // }
    // if (statusValid ==="show-ticket-detail") {

    //   setStatus(2);
    //   setIdTicket(idNewTicket);

    // }

    if (!statusValid && data && !idNewTicket) {
      // console.log("!statusValid && data && !idNewTicket")
      setSharedDataContext({
        ...sharedDataContext,
        status: 2
      });
    }
  };

  const handleCreateTicket = () => {
    // setStatus(1);
    setSharedDataContext({
      ...sharedDataContext,
      status: 1
    });
  };
  const handleUpdateTicket = () => {
    // setStatus(1);
    setSharedDataContext({
      ...sharedDataContext,
      status: 3
    });
  };
  // console.log('sharedDataContext',sharedDataContext)
  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <TicketDashboard
            t={t}
            defaultLang={defaultLang}
            i18n={i18n}
            kind="tickets"
            handleCreateTicket={() => handleCreateTicket()}
            handleMessageTicket={(status, data, idNewTicket) =>
              handleMessageTicket(status, data, idNewTicket)
            }
          />
        );
      case 1:
        return (
          <CreateTicket
            t={t}
            i18n={i18n}
            defaultLang={defaultLang}
            kind="tickets"
            handleCreateTicket={() => handleCreateTicket()}
            handleMessageTicket={(status, data, idNewTicket) =>
              handleMessageTicket(status, data, idNewTicket)
            }
          />
        );
      case 2:
        return (
          <MessageTicket
            defaultLang={defaultLang}
            t={t}
            i18n={i18n}
            kind="tickets"
            handleCreateTicket={() => handleCreateTicket()}
            handleUpdateTicket={() => handleUpdateTicket()}
            ticketId={idTicket}
            handleMessageTicket={(status, data, idNewTicket) =>
              handleMessageTicket(status, data, idNewTicket)
            }
          />
        );
      case 3:
        return (
          <DuplicateTicket
            t={t}
            i18n={i18n}
            defaultLang={defaultLang}
            kind="tickets"
            handleCreateTicket={() => handleCreateTicket()}
            handleMessageTicket={(status, data, idNewTicket) =>
              handleMessageTicket(status, data, idNewTicket)
            }
          />
        );
      default:
        return "Unknown step";
    }
  };

  return <>{getStepContent(step||sharedDataContext.status)}</>;
};

TicketAnalytics.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  step: PropTypes.number
};

export default TicketAnalytics;
