import React, { Component } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
import Footer from "../../layouts/Footer";
import CopyIcon from "../../../assets/images/webform/copy.svg";
import SearchIcon from "../../../assets/images/profile/search.svg";
import ProfileIcon from "../../../assets/images/profile/user.svg";

import Switch from "react-switch";
import { Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { CopyToClipboard } from "react-copy-to-clipboard";

import WebformPreview from "./WebformPreview";

import {
  CreateTicketHttpService,
  ConversationalFormHttpService
} from "../../../services/HttpService";

import { SIO_AGENT_PLATFORM } from "../../../constants/Constants";

const popover = (
  <Popover id="popover-basic">
    <Popover.Content>Copied !!!</Popover.Content>
  </Popover>
);

const WebFormNotifyModal = props => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="webform-notification-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title id="webform-notification-modal">
          <div className="text-center">Notification</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 p-3">
          <h4 className="mb-3 text-center">
            Give your conversational form a title so you can save it
          </h4>
          <button className="modal-close-btn" onClick={props.onHide}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const WebFormPreviewModal = props => {
  const { t, ...modal } = props;

  return (
    <Modal {...modal} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>{modal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WebformPreview t={t} />
      </Modal.Body>
      <Modal.Footer>
        <button className="cf-close-button" onClick={modal.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

class WebformCreate extends Component {
  _isMounted = false;

  static contextType = SharedDataContext;

  constructor(props) {
    super(props);

    //
    this.state = {
      receptor: {},
      //
      formId: "",
      formTitle: "",
      formDesc: "",
      formLang: this.props.defaultLang.value,
      formLink: `https://bit.ly/${Math.floor(Math.random() * 10000)}`,
      formStatus: true,
      modalShow: false,
      agents: [],
      //
      cfState: false,
      //
      assignedModalOpen: false,
      initAgents: [],
      storeInitAgents: [],
      addAgent: [],
      userData: {},
      searchAgent: "",
      //
      popoverShow: false
    };

    // Preview modal.
    this.togglePreviewCfModal = this.togglePreviewCfModal.bind(this);
    // Affect modal view.
    this.handleCreateNewForm = this.handleCreateNewForm.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    // Specify if this form is active or not.
    this.handleFormStatus = this.handleFormStatus.bind(this);
    this.handleLangChange = this.handleLangChange.bind(this);
    // Update form title.
    this.handleTitleText = this.handleTitleText.bind(this);
    this.handleDescriptionText = this.handleDescriptionText.bind(this);
    //
    this.handlePopover = this.handlePopover.bind(this);
    //
    this.eventAssignedAgent = this.eventAssignedAgent.bind(this);
    //
    this.handleModalAgent = this.handleModalAgent.bind(this);
    //
    this.handleAddAgent = this.handleAddAgent.bind(this);
    this.handleRemoveAgent = this.handleRemoveAgent.bind(this);
    //
    this.initSocketGetAgentPlatform = this.initSocketGetAgentPlatform.bind(
      this
    );
    this.readConverationalFormDetails = this.readConverationalFormDetails.bind(
      this
    );
  }

  componentDidMount() {
    this._isMounted = true;
    //
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };

    // Retrieve shared context data.
    const dataId = context.sharedDataContext.conversational.data.id;
    if (dataId) {
      this.readConverationalFormDetails(dataId, receptor);
    }

    // Save current user and language.
    this.setState({
      userData: {
        ...context.sharedDataContext.currentUser,
        checked: false,
        user_id: context.sharedDataContext.currentUser.userid
      },
      lang: context.sharedDataContext.defaultLang.value,
      receptor: receptor,
      formId: dataId
    });

    // Get agent list.
    this.initSocketGetAgentPlatform(
      context.sharedDataContext.currentUser.userid,
      receptor
    );

    // Add listener on assigned agent modal.
    document.addEventListener("mousedown", this.eventAssignedAgent, false);
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Remove listener on assigned agent modal.
    document.removeEventListener("mousedown", this.eventAssignedAgent, false);
  }

  readConverationalFormDetails(dataId, receptor) {
    ConversationalFormHttpService.getOne(dataId, receptor)
      .then(response => {
        if (response.status === 200) {
          // Prefill form with data.
          this.setState({
            formTitle: response.data.data.inputs.title,
            formDesc: response.data.data.inputs.description,
            formLang: response.data.data.settings.language,
            formLink: response.data.data.settings.link,
            formStatus: response.data.data.settings.status,
            agents: response.data.data.settings.agents
          });
        }
      })
      .catch(e => console.log("[ConversationalFormHttpService.getOne]", e));
  }

  initSocketGetAgentPlatform(userId, receptor) {
    const { socket } = this.props;

    socket.on(SIO_AGENT_PLATFORM, event => {
      const response = JSON.parse(event);
      if (this._isMounted) {
        this.setState({ initAgents: response.data });
        this.setState({ storeInitAgents: response.data });

        // No dataId found. So we can affect...
        if (!this.state.formId || this.state.formId == "") {
          // Assign current user by default.
          this.handleAddAgent(this.state.userData.userid);
        } else {
          if (this.state.agents.length > 0) {
            // Agent been affected to current form.
            this.state.agents.forEach(agentId => this.handleAddAgent(agentId));
          }
        }
      }
    });

    CreateTicketHttpService.getAgentPlatFrom(userId, receptor).catch(e =>
      console.log("[CreateTicketHttpService.getAgentPlatFrom]", e)
    );
  }

  handleAddAgent(userId) {
    // User not defined.
    if (!userId) {
      // Bad value.
      return;
    }

    const { userData, addAgent, initAgents } = this.state;

    let foundIndex = addAgent.findIndex(a => a.user_id === userId);
    if (foundIndex !== -1) {
      // Already added.
      return;
    }

    if (userData.user_id == userId) {
      // Add current user to the assigned agent.
      userData.checked = true;
      addAgent.push(userData);
    } else {
      // Check user in the agent's list.
      const agentIndex = initAgents.findIndex(a => a.user_id === userId);

      // Avoid state update.
      if (agentIndex === -1) {
        return;
      }

      initAgents[agentIndex].checked = true;
      addAgent.push(initAgents[agentIndex]);
    }

    this.setState({ addAgent, userData, initAgents });
  }

  handleRemoveAgent(userId) {
    let userData = this.state.userData;
    const updatedAgents = this.state.initAgents;

    const isExist = this.state.initAgents.findIndex(
      agent => agent.user_id === userId
    );

    if (isExist !== -1) {
      updatedAgents[isExist].checked = false;
    } else {
      userData.checked = false;
    }

    const removeAgent = this.state.addAgent.filter(
      agent => agent.user_id !== userId
    );

    this.setState({
      addAgent: removeAgent,
      userData: userData,
      initAgents: updatedAgents
    });
  }

  toggleModalView(status) {
    this.setState({ modalShow: status });
  }

  //close assigned modal anywhere you click
  eventAssignedAgent(e) {
    if (this.nodeAssignedAgent && this.nodeAssignedAgent.contains(e.target)) {
      return;
    }
    this.setState({
      assignedModalOpen: false
    });
  }

  handlePopover() {
    if (!this.state.popoverShow) {
      this.setState({ popoverShow: true });
      setTimeout(() => this.setState({ popoverShow: false }), 4000);
    }
  }

  handleCloseModal() {
    this.toggleModalView(false);
  }

  handleFormStatus() {
    this.setState({ formStatus: !this.state.formStatus });
  }

  handleModalAgent() {
    this.setState({
      assignedModalOpen: !this.state.assignedModalOpen
    });
  }

  handleTitleText(e) {
    this.setState({ formTitle: e.target.value });
  }

  handleDescriptionText(e) {
    this.setState({ formDesc: e.target.value });
  }

  handleLangChange(e) {
    let { formLang } = this.state;
    formLang = formLang == "fr" ? "en" : "fr";
    this.setState({ formLang });
  }

  handleCreateNewForm() {
    // Get current title.
    const {
      formId,
      formTitle,
      formDesc,
      formStatus,
      formLink,
      formLang,
      addAgent,
      receptor
    } = this.state;

    if (formTitle.length < 2) {
      // Print notification, if title is empty.
      this.toggleModalView(true);
    } else {
      const params = {
        title: formTitle,
        description: formDesc,
        fullname: "string",
        phone: "string",
        email: "string",
        message: "string",
        attach: "string",
        category: "string",
        status: formStatus,
        language: formLang,
        link: formLink,
        agents: addAgent.map(a => (a && a.user_id ? a.user_id : a.userid))
      };

      const notification = {
        notify: true
      };

      //
      if (!formId || formId == "") {
        ConversationalFormHttpService.create(params, receptor).catch(e => {
          console.log("[ConversationalFormHttpService.create]", e);
        });
        //
        notification.notifyMsg = `${formTitle} as been created.`;
      } else {
        params.id = formId;
        ConversationalFormHttpService.update(params, receptor).catch(e => {
          console.log("[ConversationalFormHttpService.update]", e);
        });
        notification.notifyMsg = `${formTitle} as been updated.`;
      }

      // Print alert message.
      this.props.handleStateWebForm(notification);
      //
      this.props.handleSharedDataContext(0);
    }
  }

  togglePreviewCfModal() {
    const cfState = !this.state.cfState;
    this.setState({ cfState: cfState });
  }

  render() {
    const { t } = this.props;
    const { assignedModalOpen } = this.state;

    return (
      <>
        <div className="header-indicator">
          <h3 className="header-indic-title1">Conversational Form</h3>
          {" > "}
          <p className="header-indic-title2">Create</p>
        </div>

        <WebFormNotifyModal
          show={this.state.modalShow}
          onHide={this.handleCloseModal}
        />

        <WebFormPreviewModal
          t={t}
          show={this.state.cfState}
          title={this.state.formTitle}
          onHide={this.togglePreviewCfModal}
        />

        <div className="section-container">
          <div className="section-block">
            <div className="columns">
              <div className="column mr-2 p-2">
                <div className="field is-horizontal mb-4">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input fill-form-title"
                          type="text"
                          value={this.state.formTitle}
                          onChange={this.handleTitleText}
                          placeholder="Form name"
                          autoFocus={true}
                        />
                      </div>
                      <div className="control">
                        <input
                          className="input fill-form-desc"
                          type="text"
                          value={this.state.formDesc}
                          onChange={this.handleDescriptionText}
                          placeholder="Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label>Full Name</label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      placeholder="Full Name"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label>Telephone Number</label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      placeholder="Telephone Number"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label>Email Address</label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      placeholder="Email Address"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <div>
                    <label>Category/Sub category</label>
                  </div>
                  <div className="select" style={{ width: "100%" }}>
                    <select style={{ width: "100%" }} disabled={true}>
                      <option>Category/Sub category</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>

                <div className="field ">
                  <label>Message</label>
                  <div className="control ">
                    <textarea
                      className="textarea"
                      placeholder="Message"
                      readOnly={true}
                    ></textarea>
                  </div>
                </div>

                <label htmlFor="">Attach</label>
                <div className="flex-space-between webform-custom-file mb-5">
                  <div>Browse file</div>
                  <div>Upload File</div>
                </div>

                <div className="flex-space-between webform-custom-btn mb-5">
                  <button onClick={this.handleCreateNewForm}>create</button>
                  <button onClick={this.togglePreviewCfModal}>preview</button>
                </div>
              </div>

              <div className="column" style={{ backgroundColor: "#FAFAFA" }}>
                SETTINGS
                <div className="pl-3 pr-3">
                  <div className="flex-space-between form-status">
                    <div>Form Status</div>
                    <div>
                      <Switch
                        height={20}
                        width={40}
                        className={"button-switch"}
                        onColor={"#6edd99"}
                        onChange={this.handleFormStatus}
                        checked={this.state.formStatus}
                      />
                    </div>
                  </div>

                  <hr style={{ marginTop: "-20px" }} />

                  <div className="flex-space-between form-select">
                    <div>
                      <label>Language</label>
                    </div>
                    <div className="select">
                      <select
                        value={this.state.formLang}
                        onChange={this.handleLangChange}
                      >
                        <option value="en">English</option>
                        <option value="fr">French</option>
                      </select>
                    </div>
                  </div>

                  <hr style={{ marginTop: "20px" }} />

                  <label htmlFor="">Conversational Form Link</label>
                  <div className="flex-space-between webform-copy-link">
                    <div>{this.state.formLink}</div>
                    <CopyToClipboard text={this.state.formLink}>
                      <div>
                        <img src={CopyIcon} alt="copy" />
                        <OverlayTrigger
                          trigger="click"
                          placement="top"
                          show={this.state.popoverShow}
                          onToggle={this.handlePopover}
                          overlay={popover}
                        >
                          <span>Copy link</span>
                        </OverlayTrigger>
                      </div>
                    </CopyToClipboard>
                  </div>

                  <hr style={{ marginTop: "20px" }} />

                  <div className="flex-space-between">
                    <div>Assigned Agent(s)</div>
                    <div className="assign-agent-Container">
                      <span
                        className=" assign-agent-btn"
                        onClick={this.handleModalAgent}
                      >
                        +
                      </span>
                      {/* Modal add agent */}
                      <div
                        className="assign-agent-text-modal"
                        style={{
                          display: `${assignedModalOpen ? "flex" : "none"}`
                        }}
                        ref={node => (this.nodeAssignedAgent = node)}
                      >
                        <h2 className="title assign-modal-title">
                          Assign Agent(s) to Ticket
                        </h2>
                        <ul className="menu-list menu-list-ticket">
                          {" "}
                          <li className="assign-self">
                            <img src={ProfileIcon} alt="portrait" />
                            <span className="user-name">
                              {t("tickets.create_ticket.assign_ticket_myself")}
                            </span>
                            {this.state.userData.checked ||
                            this.state.userData.checked === true ? (
                              <span
                                className="remove-user"
                                onClick={e =>
                                  this.handleRemoveAgent(
                                    this.state.userData.user_id
                                  )
                                }
                              >
                                -
                              </span>
                            ) : (
                              <span
                                className="add-user"
                                onClick={e =>
                                  this.handleAddAgent(
                                    this.state.userData.user_id
                                  )
                                }
                              >
                                +
                              </span>
                            )}
                          </li>
                        </ul>
                        <div className="search-box assign-search">
                          <input
                            className="input"
                            type="text"
                            placeholder={t(
                              "tickets.create_ticket.search_agent_input"
                            )}
                            value={this.state.searchAgent}
                            onChange={e => this.handleInputSearchChange(e)}
                          />
                          <img src={SearchIcon} alt="search" />
                        </div>
                        {this.state.storeInitAgents.length === 0 ? (
                          <div>
                            {t("tickets.create_ticket.search.user_list_empty")}
                          </div>
                        ) : (
                          this.state.initAgents.length === 0 && (
                            <div>
                              {t("tickets.create_ticket.search.user_not_found")}
                            </div>
                          )
                        )}
                        <section
                          className="modal-card-body"
                          style={{ width: "100%" }}
                        >
                          <ul className=" menu-list menu-list-ticket">
                            {this.state.initAgents &&
                              this.state.initAgents.map((item, i) => (
                                <li key={i}>
                                  <img src={ProfileIcon} alt="portrait" />
                                  <span className="user-name">
                                    {item.firstname} {item.lastname}
                                  </span>
                                  {item.checked || item.checked === true ? (
                                    <span
                                      className="remove-user"
                                      onClick={e =>
                                        this.handleRemoveAgent(item.user_id)
                                      }
                                    >
                                      -
                                    </span>
                                  ) : (
                                    <span
                                      className="add-user"
                                      onClick={e =>
                                        this.handleAddAgent(item.user_id)
                                      }
                                    >
                                      +
                                    </span>
                                  )}
                                </li>
                              ))}
                          </ul>
                          {/*  */}
                        </section>
                        <footer className="assign-modal-footer">
                          <button
                            className="button is-primary"
                            aria-label="close"
                            style={{ width: "100%" }}
                            onClick={this.handleModalAgent}
                          >
                            ASSIGN
                          </button>
                        </footer>
                      </div>
                      {/* End Modal add agent */}
                    </div>
                  </div>

                  <ul className="webform-card-ul">
                    {this.state.addAgent &&
                      this.state.addAgent.map((item, i) => (
                        <li key={i}>
                          <img src={ProfileIcon} alt="portrait" />
                          <span
                            className="user-name"
                            style={{ fontWeight: "bold" }}
                          >
                            {item.firstname} {item.lastname}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer t={t} />
      </>
    );
  }
}

WebformCreate.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired
};

//export default WebformCreate;

const WebformCreateWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <WebformCreate {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);

export default WebformCreateWithSocket;
