import React, { useEffect, useState } from "react";
import { Overlay, Popover, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { MdAccessTimeFilled } from "react-icons/md";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SplitTime, isNumeric } from "../../../../utils/general";
import i18nConfig from "../../../../configs/i18nConfig";

const CategoryDetailsPopover = props => {
  const {
    myRef,
    show,
    target,
    handleClick,
    t,
    categoryData,
    priorities,
    actualPriority,
    handleUpdateTicketSubject,
    categoriesParentList,
    parentId
  } = props;

  const [priorityItems, setPriorityItems] = useState();

  console.log(i18nConfig.language);
  console.log(actualPriority)
  // const [actualPriority, setActualPriority] = useState({ value: null, label: null });
  const [prio, setPrio] = useState(actualPriority);
  const states = useSelector(state => state, shallowEqual);
  const dispatch = useDispatch();

  const [categorySla, setCategorySla] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
    fullSLA: ""
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ddd" : "#fff",
      color: state.isSelected ? "#000" : "#000",
      cursor: "pointer !important"
    })
  };
  useEffect(() => {
    // if(i18nConfig.language == 'fr' && actualPriority?.value in ['High', 'Low', 'medium']) {
    //   console.log(actualPriority?.label)
    // }else if(i18nConfig.language == 'en' && actualPriority?.value in ['High', 'Low', 'medium']){

    // }else{
    //   setPrio({ value: actualPriority?.label, label: actualPriority?.value });
    // }
    setPrio({ value: actualPriority?.label, label: actualPriority?.value });

    
  }, [actualPriority]);

  const handlePriorityChange = e => {
    const temp = { value: e.value, label: e.label };

    setPrio(temp);
  };
  // const handleSlaKeyDown = event => {
  //   ["e", "E", "+", "-", ".", ","].includes(event.key) &&
  //     event.preventDefault();
  // };

  const handleSlaKeyDown = event => {
    //["e", "E", "+", "-", ".", ","].includes(event.key) && event.preventDefault();
  };

  const handlePriorityUpdate = () => {
    // {value: 'Urgent', label: 'Medium'}
    let priority = { label: prio.label, name: prio.value };
    let sla =
      typeof categoryData.sla === "undefined" ? "00:00:00" : categoryData.sla;
    handleUpdateTicketSubject(
      categoryData.title,
      priority,
      categoryData.id,
      sla
    );
    handleClick(target);
    // }
  };

  useEffect(() => {
    let r = [];
    let s = [];
    if (priorities?.length) {
      priorities.map(item => r.push({ value: item.label, label: item.name }));
    }
    setPriorityItems(r);
  }, [priorities]);

  useEffect(() => {
    let slaToPush = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
      fullSLA: "00:00:00"
    };
    if (typeof categoryData.sla !== "undefined") {
      const slaArray = categoryData.sla.split(":");

      let hoursToDays = SplitTime(slaArray[0]);

      let Days =
        String(hoursToDays.Days).length === 1
          ? `0${hoursToDays.Days}`
          : hoursToDays.Days;
      let Hours =
        String(hoursToDays.Hours).length === 1
          ? `0${hoursToDays.Hours}`
          : hoursToDays.Hours;
      let Minutes =
        hoursToDays.Minutes > 0
          ? parseInt(slaArray[1]) + hoursToDays.Minutes
          : parseInt(slaArray[1]);
      Minutes = String(Minutes).length === 1 ? `0${Minutes}` : Minutes;
      let Seconds =
        String(slaArray[2]).length === 1 ? `0${slaArray[2]}` : slaArray[2];
      slaToPush = {
        days: Days,
        hours: Hours,
        minutes: Minutes,
        seconds: Seconds,
        fullSLA: categoryData.sla
      };
    }

    setCategorySla({});
    setCategorySla(slaToPush);
  }, [categoryData.sla, states.resetSlaInBosx]); //, states.resetSlaInBosx

  useEffect(() => {}, [categorySla]);

  const handleSlaChange = (e, type) => {
    let slaNumber = e.target.value;
    // if (checkIfValueIsInt(slaNumber)) {
    if (isNumeric(slaNumber)) {
      slaNumber = parseInt(slaNumber, 10);
      slaNumber = String(slaNumber).length <= 1 ? `0${slaNumber}` : slaNumber;
      let daysToHours = 0;
      let newHours = 0;
      let slaTotal = 0;
      switch (type) {
        case "days":
          if (parseInt(slaNumber) >= 366) {
            break;
          } else {
            daysToHours = slaNumber * 24;
            newHours = parseInt(categorySla.hours) + parseInt(daysToHours);
            slaTotal =
              newHours + ":" + categorySla.minutes + ":" + categorySla.seconds;

            setCategorySla({
              ...categorySla,
              days: slaNumber,
              fullSLA: slaTotal
            });
            break;
          }
        case "hours":
          if (parseInt(slaNumber) >= 60) {
            break;
          } else {
            daysToHours =
              typeof categorySla.days == "undefined"
                ? 0
                : categorySla.days * 24;
            newHours = parseInt(slaNumber) + parseInt(daysToHours);
            slaTotal =
              newHours + ":" + categorySla.minutes + ":" + categorySla.seconds;

            setCategorySla({
              ...categorySla,
              hours: slaNumber,
              fullSLA: slaTotal
            });
            break;
          }
        case "minutes":
          if (parseInt(slaNumber) >= 60) {
            break;
          } else {
            daysToHours =
              typeof categorySla.days == "undefined"
                ? 0
                : categorySla.days * 24;
            newHours = parseInt(categorySla.hours) + parseInt(daysToHours);
            slaTotal = newHours + ":" + slaNumber + ":" + categorySla.seconds;
            setCategorySla({
              ...categorySla,
              minutes: slaNumber,
              fullSLA: slaTotal
            });
            break;
          }
        case "seconds":
          if (parseInt(slaNumber) >= 60) {
            break;
          } else {
            daysToHours =
              typeof categorySla.days == "undefined"
                ? 0
                : categorySla.days * 24;
            newHours = parseInt(categorySla.hours) + parseInt(daysToHours);
            slaTotal = newHours + ":" + categorySla.minutes + ":" + slaNumber;
            setCategorySla({
              ...categorySla,
              seconds: slaNumber,
              fullSLA: slaTotal
            });
            break;
          }
        default:
          break;
      }
    }
  };

  const handlePriorityAndSlaApply = () => {
    // {value: 'Urgent', label: 'Medium'}
    let priority = { label: prio.label, name: prio.value };
    let slaTime =
      typeof categorySla.fullSLA === "undefined"
        ? "00:00:00"
        : categorySla.fullSLA;

    handleUpdateTicketSubject(
      categoryData.title,
      priority,
      categoryData.id,
      slaTime,
      categoriesParentList,
      parentId
    );
    handleClick(target);
  };
  const formatPriority = priority => {
    const p = {
      value:
        priority?.label === "Very urgent" || priority?.label === "Très urgente"
          ? t("settings.tickets_priority_content.very_urgent")
          : priority?.label === "Not urgent" ||
            priority?.label === "Pas urgente"
          ? t("settings.tickets_priority_content.not_urgent")
          : t("settings.tickets_priority_content.urgent"),
      label:
        priority?.name === "High" || priority?.name === "Haute"
          ? t("settings.tickets_priority_content.hight")
          : priority?.name === "Low" || priority?.name === "Faible"
          ? t("settings.tickets_priority_content.low")
          : t("settings.tickets_priority_content.medium")
    };
    return p;
  };
  return (
    <div>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={myRef?.current}
        containerPadding={20}
      >
        <Popover id="popover-contained" className="cat-details-popover">
          <Row className="cat-details-priority-label">
            {t("settings.auto_priorization.select_priority")}
          </Row>
          <Row>
            <Select
              options={priorityItems}
              value={prio}
              onChange={e => handlePriorityChange(e)}
              isSearchable={false}
              className="select-priority"
              placeholder={t("tickets.create_ticket.ticket_priority_input")}
              styles={customStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#eee",
                  primary25: "#eee"
                }
              })}
            />
          </Row>

          <Row className="cat-details-sla-label">
            {t("settings.tickets_category_content.set_sla_for_category")}
          </Row>
          <Row className="cat-details-sla-deadline">
            {t("settings.tickets_category_content.set_deadline")}
          </Row>
          <Row>
            <div className="form-row d-flex flex-nowrap mb-5">
              <div className="sla-time-div d-flex col-sm-5">
                <span className="sla-icon-span ">
                  <MdAccessTimeFilled className="sla-icon" />
                </span>
                <input
                  // type="number"
                  //onKeyDown={e => handleSlaKeyDown(e)}
                  value={
                    parseInt(categorySla.days) === 0 ? "" : categorySla.days
                  }
                  onChange={e => handleSlaChange(e, "days")}
                  className="sla-day-input inputBox"
                  placeholder={t("settings.tickets_category_content.day")}
                ></input>
                <input
                  // type="number"
                  //onKeyDown={e => handleSlaKeyDown(e)}
                  value={
                    parseInt(categorySla.hours) === 0 ? "" : categorySla.hours
                  }
                  onChange={e => handleSlaChange(e, "hours")}
                  className="sla-hour-input inputBox"
                  placeholder={t("settings.tickets_category_content.hour")}
                ></input>
                <input
                  // type="number"
                  //onKeyDown={e => handleSlaKeyDown(e)}
                  value={
                    parseInt(categorySla.minutes) === 0
                      ? ""
                      : categorySla.minutes
                  }
                  onChange={e => handleSlaChange(e, "minutes")}
                  className="sla-minute-input inputBox"
                  placeholder={t("settings.tickets_category_content.minute")}
                ></input>
                <input
                  // type="number"
                  //onKeyDown={e => handleSlaKeyDown(e)}
                  value={
                    parseInt(categorySla.seconds) === 0
                      ? ""
                      : categorySla.seconds
                  }
                  onChange={e => handleSlaChange(e, "seconds")}
                  className="sla-second-input inputBox"
                  placeholder={t("settings.tickets_category_content.second")}
                ></input>
              </div>
              <div className="col-sm-3 d-flex justify-content-around"></div>
            </div>
          </Row>

          <Row>
            <button
              className="button-save-sla text-center"
              // onClick={handlePriorityUpdate}
              onClick={handlePriorityAndSlaApply}
            >
              <span className="save-sla-span">
                {t("settings.tickets_category_content.apply")}
              </span>
            </button>
          </Row>
        </Popover>
      </Overlay>
    </div>
  );
};

export default CategoryDetailsPopover;
