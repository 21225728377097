import React from "react";
import PropTypes from "prop-types";

const TicketsPriorityContent = props => {
  const {
    t,
    handleAddRessourceModal,
    handleCloseRessourceModal,
    priorityItems
  } = props;

  return (
    <>
      <div className="ticket-content">
        <div className="ticket-priorities">
          <div className="ticket-prio-item-Container">
            <div className="ticket-prio-item" style={{ height: "50px" }}>
              <span className="ticket-prio-item-text">
                {t("settings.tickets_priority_content.hight")}{" "}
              </span>
            </div>

            <div
              className="ticket-prio-value"
              style={{
                background: "rgba(235, 89, 35, 0.05)"
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "#EB5923",
                  opacity: "100%",
                  borderRadius: "4px"
                }}
              >
                {" "}
                {t("settings.tickets_priority_content.very_urgent")}
              </span>
            </div>
          </div>

          <div className="ticket-prio-item-Container">
            <div className="ticket-prio-item" style={{ height: "50px" }}>
              <span className="ticket-prio-item-text">
                {t("settings.tickets_priority_content.medium")}
              </span>
            </div>

            <div
              className="ticket-prio-value"
              style={{
                background: "rgba(255, 155, 33, 0.05)"
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "#FF9B21",
                  opacity: "100%",
                  borderRadius: "4px"
                }}
              >
                {t("settings.tickets_priority_content.urgent")}
              </span>
            </div>
          </div>

          <div className="ticket-prio-item-Container">
            <div className="ticket-prio-item" style={{ height: "50px" }}>
              <span className="ticket-prio-item-text">
                {" "}
                {t("settings.tickets_priority_content.low")}{" "}
              </span>
            </div>

            <div
              className="ticket-prio-value"
              style={{
                background: "rgba(101, 114, 136, 0.05)",

                marginTop: "6px"
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "#657288",
                  opacity: "100%",
                  borderRadius: "4px"
                }}
              >
                {t("settings.tickets_priority_content.not_urgent")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TicketsPriorityContent.propTypes = {
  t: PropTypes.func.isRequired,
  handleAddRessourceModal: PropTypes.func.isRequired,
  handleCloseRessourceModal: PropTypes.func.isRequired,
  priorityItems: PropTypes.arrayOf.isRequired
};

export default TicketsPriorityContent;
