import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useHttpService from "../../services/HttpService";
// Use Socket io - import
import io from "socket.io-client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Welcome from "../onboarding/Welcome";
import Steps from "../onboarding/Steps";
import Dashboard from "../dashboard/Dashboard";
import Settings from "../dashboard/Settings";
import Tickets from "../dashboard/Tickets";
import WebForm from "../dashboard/Webform";

import ReactGa from "react-ga";

import { SharedDataContext, DataSocketContext } from "./UseContext";

/* START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
import { SOCKET, LOCAL_API, APISID } from "../../constants/Constants";
import Axios from "axios";

const socket = io(SOCKET.BASE_URL);
/* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

const Routing = props => {
  const {
    t,
    changeLang,
    defaultLang,
    isLogged,
    handleChooseService,
    checkedServices,
    activeServices,
    selectServiceRef,
    handleSimulateChooseServices,
    containerWidth,
    i18n
  } = props;
  //chechsession request
  const {
    send: sendRequestCheckSession,
    data: sessionResponse,
    loading: onLoadingSession,
    error: onErrorSession
  } = useHttpService("CHECK_SESSION");

  const [user, setUser] = useState(null);
  const hasMount = useRef(false);
  const unmounted = useRef(false);
  const [stateCheckSession, setStateCheckSession] = useState("loading");

  const [sharedDataContext, setSharedDataContext] = useState({
    socketConnected: false,
    userLogged: false,
    status: 0,
    currentUser: {},
    notification: {
      active: false, // false , true
      status: "", // success , danger,
      content: { title: "", msg: "" }
    },
    conversational: {
      page: 0
    }
  });

  const providerSharedDataContext = useMemo(
    () => ({ sharedDataContext, setSharedDataContext }),
    [sharedDataContext, setSharedDataContext]
  );

  const path = window.location.pathname + window.location.search;

  //Initialize react google analytics
  useEffect(() => {
    ReactGa.initialize("UA-171570583-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, [path]);

  //check session useEffect
  useEffect(() => {
    if (!hasMount.current) {
      // componentDidMount
      socket.on("connect", () => {
        //  setSharedDataContext({ ...sharedDataContext, socketConnected: true });
      });
      checkSession();
      //console.log("componentDidMount");
      hasMount.current = true;
    }
    return () => {
      socket.on("disconnect", () => {
        //  console.log("Disconnected socket");
        setSharedDataContext({
          ...sharedDataContext,
          socketConnected: false
        });
      });
      unmounted.current = true;
      // componentWillUnmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  useEffect(() => {
    if (!unmounted.current) {
      if (!onLoadingSession && onErrorSession) {
        setStateCheckSession("error");
      }
      if (sessionResponse) {
        if (sessionResponse.status === 200) {
          // user session is ok
          // set user session
          sessionResponse.user = sessionResponse.data;
          sessionResponse.user.apisid = APISID;
          sessionResponse.user.userid = sessionResponse.data.user_id;
          updateUserInfo(sessionResponse.user);
          //updatecontext(sessionResponse.user);
        } else {
          window.location = LOCAL_API.LOGOUT;
        }
      }
    }
  }, [sessionResponse]);

  useEffect(() => {
    if (typeof sharedDataContext.currentUser.subdomain !== "undefined") {
      let currentUser = sharedDataContext.currentUser;
      let userInfo = {
        user_id: currentUser.userid,
        fullname: currentUser.firstname + " " + currentUser.lastname,
        email: currentUser.email,
        subdomain: currentUser.subdomain
      };
      //window.hj("identify", currentUser.userid, userInfo);
    }
  }, [sharedDataContext.currentUser]);

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     setSharedDataContext({ ...sharedDataContext, socketConnected: true });
  //   });
  //   // socket.on("disconnect", () => {
  //   //   // console.log("Disconnected socket");
  //   //   setSharedDataContext({
  //   //     ...sharedDataContext,
  //   //     socketConnected: false
  //   //   });
  //   // });
  //   return () => {
  //     socket.on("disconnect", () => {
  //       //  console.log("Disconnected socket");
  //       setSharedDataContext({
  //         ...sharedDataContext,
  //         socketConnected: false
  //       });
  //     });
  //   };
  // });
  function checkSession() {
    sendRequestCheckSession();
  }

  function updateUserInfo(user) {
    setUser(user);
    //sessionStorage.setItem("session_id", user.session_id);
    setSharedDataContext({
      ...sharedDataContext,
      currentUser: user,
      userLogged: true,
      defaultLang: defaultLang,
      socketConnected: true,
      status: 0
    });
  }
  // console.log(" ROUTING RENDER",sharedDataContext);
  let haveRights =
    sharedDataContext?.currentUser?.is_owner ||
    sharedDataContext?.currentUser?.full_rights?.length;
  return (
    <DataSocketContext.Provider value={socket}>
      <Router>
        <SharedDataContext.Provider value={providerSharedDataContext}>
          {user ? (
            <Switch>
              <Route exact path="/">
                <Welcome
                  haveRights={haveRights}
                  t={t}
                  changeLang={changeLang}
                  defaultLang={defaultLang}
                  kind="onboard"
                  isLogged={false}
                  containerWidth={containerWidth}
                />
              </Route>
              <Switch>
                <Route path="/onboard">
                  <Steps
                    haveRights={haveRights}
                    t={t}
                    changeLang={changeLang}
                    defaultLang={defaultLang}
                    kind="app"
                    isLogged={isLogged}
                    handleChooseService={handleChooseService}
                    checkedServices={checkedServices}
                    activeServices={activeServices}
                    selectServiceRef={selectServiceRef}
                    handleSimulateChooseServices={handleSimulateChooseServices}
                    containerWidth={containerWidth}
                  />
                </Route>
                <Route path="/dashboard">
                  <Dashboard
                    haveRights={haveRights}
                    t={t}
                    changeLang={changeLang}
                    defaultLang={defaultLang}
                    kind="dashboard"
                    isLogged={isLogged}
                    i18n={i18n}
                  />
                </Route>
                <Route path="/webform">
                  <WebForm
                    haveRights={haveRights}
                    t={t}
                    changeLang={changeLang}
                    defaultLang={defaultLang}
                    kind="webform"
                    isLogged={isLogged}
                    i18n={i18n}
                  />
                </Route>
                <Route path="/tickets">
                  <Tickets
                    haveRights={haveRights}
                    t={t}
                    changeLang={changeLang}
                    defaultLang={defaultLang}
                    kind="tickets"
                    isLogged={isLogged}
                    i18n={i18n}
                  />
                </Route>
                <Route path="/settings">
                  <Settings
                    haveRights={haveRights}
                    t={t}
                    changeLang={changeLang}
                    defaultLang={defaultLang}
                    kind="settings"
                    isLogged={isLogged}
                    i18n={i18n}
                  />
                </Route>
              </Switch>
            </Switch>
          ) : (
            <div></div>
          )}
        </SharedDataContext.Provider>
      </Router>
    </DataSocketContext.Provider>
  );
};

Routing.propTypes = {
  t: PropTypes.func.isRequired,
  changeLang: PropTypes.func.isRequired,
  defaultLang: PropTypes.shape({}).isRequired,
  isLogged: PropTypes.bool.isRequired,
  containerWidth: PropTypes.number.isRequired,
  handleChooseService: PropTypes.func.isRequired,
  checkedServices: PropTypes.objectOf({}).isRequired,
  activeServices: PropTypes.arrayOf([]).isRequired,
  selectServiceRef: PropTypes.func.isRequired,
  handleSimulateChooseServices: PropTypes.func.isRequired
};

export default Routing;
