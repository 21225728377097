import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Header from "./shared/Header";
import Content from "./shared/Content";
import ProfileIcon from "../../assets/images/profile/user.svg";

const Carder = props => {
  const {
    t,
    kind,
    name,
    nameFr,
    type,
    content,
    assignedAgents,
    buttonText,
    isChannelEmpty,
    channelSelected,
    serviceCount,
    agentAssigned,
    checkedServices,
    currentStep,
    handleCountAgentSelected,
    agentFromXP,
    i18n
  } = props;
  //console.log("assignedAgents", assignedAgents);
  // chargement de la liste des agents & init du array default de addAgentsChannel
  const [state, setState] = useState({
    addAgentsChannel:
      assignedAgents &&
      agentFromXP.filter(agent => assignedAgents.includes(agent.user_id)),
    initAgents: [],
    agentFromXP
  });

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = status => {
    if (status) {
      /*
              action de cocher tous les agents disponible
            */
      // eslint-disable-next-line no-restricted-syntax
      for (const item of state.agentFromXP) {
        const localService = JSON.parse(localStorage.getItem("cr_services"));
        const correspondingChannel = localService.find(option =>
          option.type.toLowerCase().includes(type.toLowerCase())
        );
        if (!correspondingChannel.agents.includes(item.user_id)) {
          correspondingChannel.agents.push(item.user_id);
        }
        localStorage.setItem("cr_services", JSON.stringify(localService));
        const updatedAgents = state.agentFromXP.filter(agent =>
          correspondingChannel.agents.includes(agent.user_id)
        );
        setState(prevState => ({
          ...prevState,
          tempAddAgentsChannel: updatedAgents
        }));

        // handleCountAgentSelected(
        //     JSON.parse(localStorage.getItem("cr_services"))[0].agents.length
        // );
      }
    }

    if (!status) {
      /*
              action de décocher tous les agents disponible
            */
      // eslint-disable-next-line no-restricted-syntax
      for (const item of state.agentFromXP) {
        const localService = JSON.parse(localStorage.getItem("cr_services"));
        const { agents } = localService.find(option =>
          option.type.toLowerCase().includes(type.toLowerCase())
        );
        if (agents.includes(item.user_id)) {
          const index = agents.indexOf(item.user_id);
          if (index > -1) {
            agents.splice(index, 1);
          }
          localStorage.setItem("cr_services", JSON.stringify(localService));
          const updatedAgents = state.agentFromXP.filter(agent =>
            agents.includes(agent.user_id)
          );
          setState(prevState => ({
            ...prevState,
            tempAddAgentsChannel: updatedAgents
          }));

          // handleCountAgentSelected(
          //     JSON.parse(localStorage.getItem("cr_services"))[0].agents.length
          // );
        }
      }
    }
  };

  const handleSelectAllAgents = select => {
    const status = !select;
    setSelectAll(status);
    handleSelectAll(status);
  };

  const checkboxSelectAll = (
    <input
      type="checkbox"
      checked={selectAll}
      onClick={() => handleSelectAllAgents(selectAll)}
    />
  );

  const handleAddAgent = userId => {
    const localService = JSON.parse(localStorage.getItem("cr_services"));
    const correspondingChannel = localService.find(option =>
      option.type.toLowerCase().includes(type.toLowerCase())
    );
    if (!correspondingChannel.agents.includes(userId)) {
      correspondingChannel.agents.push(userId);
    }
    localStorage.setItem("cr_services", JSON.stringify(localService));
    const updatedAgents = state.agentFromXP.filter(agent =>
      correspondingChannel.agents.includes(agent.user_id)
    );
    setState(prevState => ({
      ...prevState,
      tempAddAgentsChannel: updatedAgents
    }));

    // handleCountAgentSelected(
    //     JSON.parse(localStorage.getItem("cr_services"))[0].agents.length
    // );

    const { agents } = localService.find(option =>
      option.type.toLowerCase().includes(type.toLowerCase())
    );
    if (agents.length === state.agentFromXP.length) {
      setSelectAll(true);
      return;
    }
    setSelectAll(false);
  };

  const handleRemoveAgent = userId => {
    const localService = JSON.parse(localStorage.getItem("cr_services"));
    const { agents } = localService.find(option =>
      option.type.toLowerCase().includes(type.toLowerCase())
    );
    if (agents.includes(userId)) {
      const index = agents.indexOf(userId);
      if (index > -1) {
        agents.splice(index, 1);
      }
      localStorage.setItem("cr_services", JSON.stringify(localService));
      const updatedAgents = state.agentFromXP.filter(agent =>
        agents.includes(agent.user_id)
      );
      setState(prevState => ({
        ...prevState,
        tempAddAgentsChannel: updatedAgents
      }));

      // handleCountAgentSelected(
      //     JSON.parse(localStorage.getItem("cr_services"))[0].agents.length
      // );

      if (agents.length === state.agentFromXP.length) {
        setSelectAll(true);
        return;
      }
      setSelectAll(false);
    }
  };

  const fetchDatas = id =>
    JSON.parse(localStorage.getItem("cr_services"))
      .find(option => option.type.toLowerCase().includes(type.toLowerCase()))
      .agents.includes(id);

  const listAgents = (
    <ul className="menu-list">
      {state.initAgents &&
        state.initAgents.map((item, i) => (
          <li key={i}>
            <img src={ProfileIcon} alt="portrait" />
            <span className="user-name">
              {item.firstname} {item.lastname}
            </span>
            {fetchDatas(item.user_id) ? (
              <span
                className="remove-user"
                onClick={e => handleRemoveAgent(item.user_id)}
              >
                {" "}
                -{" "}
              </span>
            ) : (
              <span
                className="add-user"
                onClick={e => handleAddAgent(item.user_id)}
              >
                {" "}
                +{" "}
              </span>
            )}
          </li>
        ))}
    </ul>
  );
  //console.log("fetchDatas", fetchDatas);
  const handleSearchAgent = contentSearch => {
    const newFilter = contentSearch;
    if (newFilter !== "") {
      setState(prevState => ({
        ...prevState,
        initAgents: state.agentFromXP.filter(
          option =>
            option.firstname.toLowerCase().includes(newFilter.toLowerCase()) ||
            option.lastname.toLowerCase().includes(newFilter.toLowerCase())
        )
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        initAgents: state.agentFromXP
      }));
    }
  };

  const agentModal = React.createRef();
  const channelModal = React.createRef();
  const referedModal = kind === "agent" ? agentModal : channelModal;

  const handleAddRessourceModal = () => {
    document.body.classList.add("modal-opened");
    kind === "agent"
      ? agentModal.current.classList.add("is-active")
      : channelModal.current.classList.add("is-active");
  };

  const handleCloseRessourceModal = () => {
    document.body.classList.remove("modal-opened");
    kind === "agent"
      ? agentModal.current.classList.remove("is-active")
      : channelModal.current.classList.remove("is-active");
  };

  const handleContinue = () => {
    console.log("continue");
    handleCountAgentSelected(
      JSON.parse(localStorage.getItem("cr_services"))[0].agents.length
    );
    setState(prevState => ({
      ...prevState,
      addAgentsChannel: state.tempAddAgentsChannel
    }));
    document.body.classList.remove("modal-opened");
    kind === "agent"
      ? agentModal.current.classList.remove("is-active")
      : channelModal.current.classList.remove("is-active");
  };

  const cardStyle = {
    agentStyle: {
      padding:
        channelSelected || kind === "channel"
          ? "padding: 1.3125rem"
          : "2.5rem 1rem"
    },
    emptyChannel: {
      background:
        state.addAgentsChannel && state.addAgentsChannel.length !== 0
          ? "#fff"
          : "#fff"
    }
  };

  useEffect(() => {
    setState({ ...state, initAgents: agentFromXP, agentFromXP });
    return () => {
      // cleanup
    };
  }, [agentFromXP]);

  return (
    <div
      className={
        [1, 2, 4, 5].indexOf(serviceCount) !== -1 ? "card-custom" : "card"
      }
      style={cardStyle.emptyChannel}
    >
      <Header
        t={t}
        kind={kind}
        addAgentsChannel={state.addAgentsChannel}
        name={name}
        nameFr={nameFr}
        i18n={i18n}
      />
      <Content
        agentAssigned={agentAssigned}
        addAgentsChannel={state.addAgentsChannel}
        handleAddRessourceModal={handleAddRessourceModal}
        channelSelected={channelSelected}
        kind={kind}
        t={t}
        isChannelEmpty={isChannelEmpty}
        content={content}
        buttonText={buttonText}
        cardStyle={cardStyle.agentStyle}
        currentStep={currentStep}
      />
      <Modal
        t={t}
        content={listAgents}
        checkboxSelectAll={checkboxSelectAll}
        kind={kind}
        buttonText={t("onboard.steps.continue")}
        agentModal={referedModal}
        agentCount={state.initAgents.length}
        countAgentFromXP={state.agentFromXP.length}
        checkedServices={checkedServices}
        handleCloseRessourceModal={handleCloseRessourceModal}
        handleSearchAgent={handleSearchAgent}
        title={
          kind === "agent"
            ? t("onboard.steps.add_agents")
            : t("onboard.steps.add_channel")
        }
        handleSelectAll={handleSelectAll}
        handleNewContinue={handleContinue}
      />
    </div>
  );
};

Carder.propTypes = {
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameFr: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  assignedAgents: PropTypes.shape({}).isRequired,
  buttonText: PropTypes.string.isRequired,
  isChannelEmpty: PropTypes.bool.isRequired,
  channelSelected: PropTypes.bool.isRequired,
  serviceCount: PropTypes.number.isRequired,
  agentAssigned: PropTypes.bool.isRequired,
  checkedServices: PropTypes.shape({}).isRequired,
  currentStep: PropTypes.number.isRequired,
  handleCountAgentSelected: PropTypes.func.isRequired,
  agentFromXP: PropTypes.arrayOf.isRequired,
  i18n: PropTypes.shape({}).isRequired
};

export default Carder;
