import React, { useState, useContext } from "react";
import "react-dates/initialize";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { SharedDataContext } from "../../app/UseContext";
import { isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

function DatePicker(props) {
  const { getTimeFilter, t } = props;

  let day = new Date();
  let momenday = moment(day);
  let dateDefault = momenday.format("MM/DD/YYYY HH:mm:ss").slice(0, 11);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );

  const handleDatesChange = ({ startDate, endDate }) => {
    const currentDay = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 11);
    const startDateString = startDate
      .format("YYYY-MM-DD HH:mm:ss")
      .slice(0, 11);
    if (moment(startDateString).isAfter(currentDay)) {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.date-start-filter-error")
          }
        }
      });
    } else {
      setStartDate(startDate);
      if (endDate !== null) {
        const endDateString = endDate
          .format("YYYY-MM-DD HH:mm:ss")
          .slice(0, 11);
        if (moment(endDateString).isAfter(currentDay)) {
          setSharedDataContext({
            ...sharedDataContext,
            notification: {
              active: true,
              status: "danger",
              content: {
                title: "",
                msg: t("notification.date-start-filter-error")
              }
            }
          });
          setEndDate(null);
        } else {
          setEndDate(endDate);
        }
      }
    }
    getTimeFilter(startDate, endDate);
  };

  //moment("12/25/1995", "MM-DD-YYYY");
  return (
    <div className="Apps">
      <DateRangePicker
        startDatePlaceholderText={dateDefault}
        endDatePlaceholderText={dateDefault}
        startDate={startDate}
        startDateId="tata-start-date"
        endDate={endDate}
        endDateId="tata-end-date"
        isOutsideRange={day => {
          return day.isAfter(moment().subtract(0, "months"));
        }}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        numberOfMonths={1}
        minimumNights={0}
      />
    </div>
  );
}

export default DatePicker;
