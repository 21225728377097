import React from "react";
import PropTypes from "prop-types";
import Tabs from "./components/Tabs";
import MobileAnalytics from "./MobileAnalytics";

const SettingsManager = props => {
  const { i18n, t, kind, containerWidth } = props;

  return (
    <>
      {containerWidth > 768 && <Tabs t={t} kind={kind} i18n={i18n} />}
      {containerWidth <= 768 && (
        <MobileAnalytics t={t} containerWidth={containerWidth} i18n={i18n} />
      )}
    </>
  );
};

SettingsManager.propTypes = {
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  i18n: PropTypes.shape({}).isRequired,
  containerWidth: PropTypes.number.isRequired
};

export default SettingsManager;
