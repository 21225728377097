import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Popover,
  Overlay,
  OverlayTrigger,
  Button
} from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Select from "react-select";

const LinkedCatList = props => {
  const { t, i18n, categories, customStyles, level, handlerOnChange } = props;
  const [selectList, setSelectList] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [defaultCat, setDefaultCat] = useState(null);
  //   const [selectList, setSelectList] = useState([]);
  //   const [show, setShow] = useState(false);
  //   const states = useSelector(state => state, shallowEqual);
  //   const dispatch = useDispatch();

  useEffect(() => {
    // console.log('lastSelected ', lastSelected)
    handlerOnChange(lastSelected);
  }, [lastSelected]);

  useEffect(() => {
    setSelectList([...[categories]]);
  }, [categories]);

  //   const myRef = useRef()
  let myRef = [];

  //   useEffect(() => {
  //     setSelectList([...[categories]]);
  //   }, [level]);

  useEffect(() => {
    // console.log(selectList);
  }, [selectList]);

  const handleSelectChange = (e, i) => {
    // console.log(e);
    // console.log(i);
    // console.log(`${i} ==>`, myRef[i]);
    // console.log(`${i} ==>`, myRef[i]?.select);
    // console.log(`${i} ==>`, myRef[i]?.select.getValue());
    // myRef.current.select.clearValue();
    if (myRef[i + 1]) {
      myRef[i + 1].select.clearValue();
    }
    if (e?.items.length > 0) {
      setLastSelected(null);
      // console.log("do again");

      let tempData = [];
      e.items.map(item => {
        tempData.push({
          value: item.id,
          label: item.title,
          sla: typeof item.sla !== "undefined" ? item.sla : "00:00:00",
          items: typeof item.items !== "undefined" ? item.items : [],
          cat_parents_ids:
            typeof item.all_parent_id !== "undefined" ? item.all_parent_id : [],
          priority: typeof item.priority !== "undefined" ? item.priority : {}
        });
      });

      setSelectList([...selectList.slice(0, i + 1), ...[tempData]]);
      let tempSelectedValue = selectedValue;
      tempSelectedValue[i] = e;
      setSelectedValue(tempSelectedValue);
    } else {
      setSelectList([...selectList.slice(0, i + 1)]);
      setLastSelected(e);
    }
  };

  // useEffect(()=>{ console.log(selectedValue) },[selectedValue])
  useEffect(()=>{
    if (props.duplicationData && selectList.length){
      selectList.map((l,i)=> {
        const dcl = l.filter(c=>c.value === props.duplicationData.subject_id)
        setDefaultCat(dcl[0])
        handlerOnChange(dcl[0])
      })
    }
  },[props.duplicationData, selectList])
  return (
    <div className="category-select-box">
      {selectList.map((item, i) => {
        return (
          <Select
            ref={element => {
              myRef[i] = element;
            }}
            key={i}
            options={item}
            defaultValue={defaultCat}
            noOptionsMessage={({ inputValue }) =>
              t("tickets.create_ticket.no_option_found")
            }
            //   onChange={e => this.handleTicketSubjectChange(e)}
            onChange={e => handleSelectChange(e, i)}
            isSearchable={false}
            className="select-priority select-priority-list category-select"
            placeholder={
              i <= 0
                ? t("tickets.create_ticket.select_subject")
                : `${t("tickets.create_ticket.select_sub_subject")} ${
                    selectedValue[i - 1]?.label
                  }`
            }
            styles={customStyles}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#eee",
                primary25: "#eee"
              }
            })}
          />
        );
      })}
    </div>
  );
};

export default LinkedCatList;
