import React from "react";
import PropTypes from "prop-types";

const TicketsStatusContent = props => {
  const {
    t,
    kind,
    handleCloseRessourceModal,
    handleAddRessourceModal,
    statusItems
  } = props;

  return (
    <>
      <div className="ticket-content">
        <div className="ticket-priorities">
          <div className="ticket-prio-item-Container">
            <div className="ticket-prio-item" style={{ height: "50px" }}>
              <span className="ticket-prio-item-text">
                {t("settings.tickets_status_content.resolved")}
              </span>
            </div>

            <div
              className="ticket-prio-value"
              style={{
                background: "rgba(0, 189, 57, 0.05)"
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "#00BD39",
                  opacity: "100%",
                  borderRadius: "4px"
                }}
              >
                {t("settings.tickets_status_content.ticket_solved_close")}
              </span>
            </div>
          </div>

          <div className="ticket-prio-item-Container">
            <div className="ticket-prio-item" style={{ height: "50px" }}>
              <span className="ticket-prio-item-text">
                {t("settings.tickets_status_content.pending")}{" "}
              </span>
            </div>

            <div
              className="ticket-prio-value"
              style={{
                background: "rgba(255, 155, 33, 0.05)"
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "#FF9B21",
                  opacity: "100%",
                  borderRadius: "4px"
                }}
              >
                {t("settings.tickets_status_content.on_going_ticket")}
              </span>
            </div>
          </div>

          <div className="ticket-prio-item-Container">
            <div className="ticket-prio-item" style={{ height: "50px" }}>
              <span className="ticket-prio-item-text">
                {t("settings.tickets_status_content.new")}{" "}
              </span>
            </div>

            <div
              className="ticket-prio-value"
              style={{
                background: "rgba(0, 137, 225, 0.05)"
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "#0089E1",
                  opacity: "100%",
                  borderRadius: "4px"
                }}
              >
                {t(
                  "settings.tickets_status_content.ticket_without_agent_assign"
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TicketsStatusContent.propTypes = {
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  initialAgents: PropTypes.shape({}).isRequired,
  handleCloseRessourceModal: PropTypes.func.isRequired,
  handleAddRessourceModal: PropTypes.func.isRequired,
  z: PropTypes.arrayOf.isRequired
};

export default TicketsStatusContent;
