import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//import io from "socket.io-client";
import moment from "moment";
import CreateTicket from "../ticketAnalytics/CreateTicket";
import ResolveTicket from "../ticketAnalytics/ResolveTicket";
import PendingTicket from "../ticketAnalytics/PendingTicket";
import TotalTicket from "../ticketAnalytics/TotalTicket";
import MoreIcon from "../../../assets/images/dashboard/more.svg";
import CalendarIcon from "../../../assets/images/dashboard/calendar.svg";
import TicketPagination from "../ticketAnalytics/TicketPagination";
import FilterPagination from "../ticketAnalytics/FilterPagination";
import SearchIcon from "../../../assets/images/profile/search.svg";
import TicketsList from "../ticketAnalytics/TicketsList"; // SortBtn
import SortBtn from "../../../assets/images/tickets/sortBtn.svg";
import {
  CustomTicketFieldHttpService,
  GetAllTicketsHttpService,
  UpdateTicketHttpService
} from "../../../services/HttpService";
import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
import Footer from "../../layouts/Footer";
import TicketSearchBar from "././TicketSearchBar";
import LoaderOval from "../../../assets/images/tickets/oval.svg";
import ExportIcon from "../../../assets/images/tickets/ExportIcon.svg";
import ExportButton from "../../utils/ExportButton";
import DatePicker from "../../../components/common/DatePicker";
import { TicketSettingsHttpService } from "../../../services/HttpService";

import {
  SOCKET,
  SIO_GET_TICKET,
  SIO_TICKET_STATS,
  SIO_TICKET_INFO,
  SIO_TICKET_DELETE,
  SIO_GET_TICKET_NATURE,
  SIO_GET_TICKET_SUBJECT,
  SIO_GET_TICKET_NATURE_SETTINGS,
  SIO_GET_TICKET_SUBJECT_SETTINGS
} from "../../../constants/Constants";
import Paginator from "../ticketAnalytics/Paginator";
import { filter } from "compression";
import i18n from "i18next";

//const socket = io(SOCKET.BASE_URL);

const makeUperCase = str => {
  return str.charAt(0).toLocaleUpperCase() + str.substring(1, str.length);
};

class TicketDashboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      lang: "",
      isOpen: false,
      status: 1,
      allPriority: false,
      high: false,
      medium: false,
      low: false,
      allNature: false,
      allSubject: false,
      generalNature: false,
      incident: false,
      request: false,
      allStatus: false,
      resolved: false,
      pending: false,
      new: false,
      allCategory: false,
      technical: false,
      support: false,
      enquires: false,
      allTickets: [],
      ticketfiltred: [],
      keyfilters: [],
      ticketToList: [],
      ticketToListFiltred: [],
      ticketInfos: {},
      searchticket: "",
      tickets: [],
      customTicketFields: [],
      customTicketFieldsAll: [],
      ticketNatureSetting: {},
      ticketSubjectSetting: {},
      initTicketSubjectList: [],
      ticketSubject: {},
      ticketNature: {},
      natureTable: {},
      subjectTable: {},

      isExporting: false,
      searchTicketsFiltres: [],
      ticketStats: { complete: 0, pending: 0, total: 0, new: 0 },
      date: {},
      timeout: null,
      paramsPagination: {
        sio_channel: SIO_TICKET_INFO,
        page: 1,
        nbre_per_page: 7,
        filter: { customFields: {}, lang: i18n.language }
      },
      pagination: {
        from: 0,
        count: 7
      },
      mount: false,
      visibleFields: []
    };
  }

  static contextType = SharedDataContext;

  load(idTicket) {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
    this.initSocketTicketStats(this.state.date, receptor);
  }

  loadAll() {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };

    this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
    this.initSocketTicketNature(receptor);
    this.initSocketTicketSubject(receptor);
    this.setState({
      lang: SharedDataContext.defaultLang?.value
    });

    // get new ticket for this day
    let day = new Date();
    let momenday = moment(day);
    let dateDefault = momenday.format("YYYY-MM-DD");

    const date = {
      date_complete_start: dateDefault,
      date_complete_end: dateDefault,
      date_pending_start: dateDefault,
      date_pending_end: dateDefault,
      date_new_start: dateDefault,
      date_new_end: dateDefault,
      date_total_start: dateDefault,
      date_total_end: dateDefault
    };

    //
    this.setState({ date });

    //
    this.initSocketTicketStats(date, receptor);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadAll();
    document.addEventListener("mousedown", this.handelClick, false);
    this.GetCustomTicketFields();
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handelClick, false);
  }

  //close modam anywhere you click
  handelClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      isOpen: false
    });
  };

  //get ticket info
  initSocketgetInfoTicket = (data, receptor) => {
    // console.log("initSocketgetInfoTicket ** : ");
    const { socket } = this.props;
    socket.on(SIO_TICKET_INFO, event => {
      const response = JSON.parse(event);

      if (this._isMounted) {
        this.setState({
          ticketToList: response.data.datas,
          ticketInfos: response.data,
          ticketToListFiltred: response.data.datas
        });
      }
    });

    GetAllTicketsHttpService.ticketInfo(data, receptor).then(response => {
      //  console.log("ticketStatsPerDate : ", response.data);

      if (response.status === 200 || response.status === 202) {
        // console.log("test success : ", response);
      }
    });
  };

  initSocketTicketNature = receptor => {
    const { socket } = this.props;
    socket.on(SIO_GET_TICKET_NATURE_SETTINGS, event => {
      const response = JSON.parse(event);

      if (this._isMounted) {
        this.onSocketGetTicketNature(response);
      }
    });

    TicketSettingsHttpService.getTicketNatureSettings(receptor).then(e =>
      // console.log("[TicketSettingsHttpService.getTicketNatureSettings]", e)
      console.log("[TicketSettingsHttpService.getTicketNatureSettings]")
    );
  };

  onSocketGetTicketNature = response => {
    if (response && (response.status === 200 || response.status === 202)) {
      let r = [];
      response.data.map(item => {
        r.push({
          value: item.id,
          label: item.labels[this.context.sharedDataContext.defaultLang.value],
          status: item.status
        });
        this.state.natureTable["nature_" + item.id] = false;
        this.state["nature_" + item.id] = false;
        // console.log("Nature Table ===> ", this.state.natureTable);
      });
      // console.log("natureList ==> ", r);
      this.setState({
        ticketNatureList: r.filter(
          item =>
            item.status !== 0 &&
            item.value !== "cc9e61a2-3f64-11eb-beea-2d88284e608e"
        )
      });
    }
  };
  initSocketTicketSubject = receptor => {
    const { socket } = this.props;
    socket.on(SIO_GET_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);

      if (this._isMounted) {
        this.onSocketGetTicketSubject(response);
      }
    });

    TicketSettingsHttpService.getTicketSubject(receptor).catch(e =>
      console.log("[TicketSettingsHttpService.getTicketSubject]", e)
    );
  };

  onSocketGetTicketSubject = response => {
    // console.log("Ticket subject http response : ", response.data);
    if (response && (response.status === 200 || response.status === 202)) {
      let r = [];

      response.data.list.map(item => {
        r.push({ value: item.id, label: item.title });
        // console.log("response ==> ", item);
        this.state.subjectTable["subject_" + item.id] = false;
        this.state["subject_" + item.id] = false;
        // console.log("Subject Table ===> ", this.state.subjectTable);
      });

      // console.log("List of Ticket subject ===> ", r);
      this.setState({
        ticketSubjectList: r,
        initTicketSubjectList: response.data.list,
        ticketSubjectSetting: response.data.settings[0]
      });
      // console.log(
      //   "This is Ticket Subject List ===> ",
      //   this.state.ticketSubjectList
      // );
    }
  };

  onSocketgetInfoTicket = response => {
    // console.log("onSocketgetInfoTicket : ", response.data);

    if (response && (response.status === 200 || response.status === 202)) {
      //this.setState({ ticketStats: response.data });
    }
  };

  generateTicketReport = fileType => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    this.setState({
      isExporting: true
    });

    const params = {
      file_type: fileType,
      lang: context.sharedDataContext.defaultLang.value,
      filter: this.state.paramsPagination.filter,
      fields: this.state.visibleFields
        .filter(f => f.visible === true)
        .map(f => f.field)
    };
    GetAllTicketsHttpService.ticketReport(params, {
      responseType: "blob",
      headers: receptor.headers
    })
      .then(response => {
        this.downloadReportFile(response, fileType);
      })
      .catch(error => {
        // console.log("error", error);
      });
  };

  downloadReportFile = (response, fileType) => {
    const context = this.context;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const fileName =
      context.sharedDataContext.defaultLang.value === "fr"
        ? "Rapport"
        : "Report";
    link.setAttribute("download", `${fileName}.${fileType}`); //or any other extension
    document.body.appendChild(link);
    link.click();
    this.setState({
      isExporting: true
    });
  };

  toNextPage = () => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    this.setState(
      {
        paramsPagination: {
          ...this.state.paramsPagination,
          page: this.state.paramsPagination.page + 1
        }
      },
      () => {
        this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
      }
    );
  };

  toPreviousPage = () => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    this.setState(
      {
        paramsPagination: {
          ...this.state.paramsPagination,
          page: this.state.paramsPagination.page - 1
        }
      },
      () => {
        this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
      }
    );
  };

  gotToPage = index => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    // console.log(index)
    this.setState(
      {
        paramsPagination: { ...this.state.paramsPagination, page: index }
      },
      () => {
        this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
      }
    );
  };

  //TICKET STAT
  initSocketTicketStats = (date, receptor) => {
    // console.log("initSocketTicketStats ** : ");
    const { socket } = this.props;
    socket.on(SIO_TICKET_STATS, event => {
      const response = JSON.parse(event);
      let ticketStats = {};
      let resp = [];
      resp = Object.entries(response.data);
      resp.length === 0
        ? (ticketStats = {
            agents: 0,
            complete: 0,
            pending: 0,
            new: 0,
            total: 0
          })
        : (ticketStats = response.data);
      //console.log("SIO_TICKET_STATS : ", response);
      this.onSocketTicketStats(response);
      // ticketStats= {agents: 0, complete: 0, pending:0, new: 0, total: 0} : ticketStats=response.data

      this.setState({ ticketStats: ticketStats });
    });

    GetAllTicketsHttpService.ticketStatsPerDate(date, receptor).then(
      response => {
        // console.log("ticketStatsPerDate : ", response);

        if (response.status === 200 || response.status === 202) {
          // console.log("test success : ", response);
        }
      }
    );
  };

  onSocketTicketStats = response => {
    // console.log("onSocketTicketStats : ", response.data);

    if (response && (response.status === 200 || response.status === 202)) {
      this.setState({ ticketStats: response.data });
    }
  };

  //initSocketTicketSettings
  initSocketgetAllTicket = receptor => {
    const { socket } = this.props;
    socket.on(SIO_GET_TICKET, event => {
      const response = JSON.parse(event);

      if (this._isMounted) {
        this.setState({ tickets: response.data });
      }
    });

    GetAllTicketsHttpService.getAllTicket(receptor).then(response => {
      //console.log("getAllTicket : ", response);

      if (response.status === 200 || response.status === 202) {
        // console.log('test success : ', response);
      }
    });
  };

  handleChange = event => {
    // console.log("event", event.target.value + ":" + event.target.checked);
    let prio = event.target.value;
    let bool = event.target.checked;
    //console.log(prio, "/ ", bool);
    this.setState({ [event.target.value]: event.target.checked }, () => {
      if (bool) {
        if (prio === "allPriority") {
          this.setState(
            {
              high: true,
              medium: true,
              low: true
            },
            () =>
              this.setState({
                paramsPagination: {
                  ...this.state.paramsPagination,
                  filter: {
                    ...this.state.paramsPagination.filter,
                    xpriority: [
                      "High",
                      "Haute",
                      "Moyenne",
                      "Medium",
                      "Faible",
                      "Low"
                    ],
                    priority: []
                  }
                }
              })
          );
        } else if (prio === "generalNature") {
          var naturesFilter = this.state.paramsPagination.filter.nature
            ? this.state.paramsPagination.filter.nature
            : [];
          naturesFilter.push("", "cc9e61a2-3f64-11eb-beea-2d88284e608e");
          // console.log(naturesFilter);
          this.setState({
            paramsPagination: {
              ...this.state.paramsPagination,
              filter: {
                ...this.state.paramsPagination.filter,
                nature: naturesFilter
              }
            }
          });
        } else if (prio === "allNature") {
          var naturesUpdated = {};
          var naturesFilter = [];
          var count = 0;
          naturesFilter.push("", "cc9e61a2-3f64-11eb-beea-2d88284e608e");
          this.state.generalNature = true;
          this.state.ticketNatureList.forEach(item => {
            naturesUpdated["nature_" + item.value] = true;
            naturesFilter.push(item.value);
            count++;
            // console.log("natureUpdated ===> ", naturesUpdated);
            // console.log("natureFilter ==> ", naturesFilter);
            if (count == this.state.ticketNatureList.length) {
              this.setState(naturesUpdated, () =>
                this.setState({
                  paramsPagination: {
                    ...this.state.paramsPagination,
                    filter: {
                      ...this.state.paramsPagination.filter,
                      xnature: naturesFilter,
                      nature: []
                    }
                  }
                })
              );
            }
          });
        } else if (prio === "allSubject") {
          var subjectsUpdated = {};
          var subjectsFilter = [];
          var count = 0;
          // naturesFilter.push("", "cc9e61a2-3f64-11eb-beea-2d88284e608e");
          // this.state.generalNature = true;
          this.state.ticketSubjectList.forEach(item => {
            subjectsUpdated["subject_" + item.value] = true;
            subjectsFilter.push(item.value);
            count++;
            // console.log("subjectsUpdated ===> ", subjectsUpdated);
            // console.log("subjectsFilter ==> ", subjectsFilter);
            if (count == this.state.ticketSubjectList.length) {
              this.setState(subjectsUpdated, () =>
                this.setState({
                  paramsPagination: {
                    ...this.state.paramsPagination,
                    filter: {
                      ...this.state.paramsPagination.filter,
                      xsubject: subjectsFilter,
                      subject: []
                    }
                  }
                })
              );
            }
          });
        } else if (prio.includes("allcf_")) {
          this.setState(naturesUpdated, () =>
            this.setState({
              paramsPagination: {
                ...this.state.paramsPagination,
                filter: {
                  ...this.state.paramsPagination.filter,
                  customFields: {
                    ...this.state.paramsPagination?.filter?.customFields,
                    [prio.split("_")[1]]: undefined
                  }
                }
              }
            })
          );
        } else if (prio === "allStatus") {
          this.setState({ resolved: true, pending: true, new: true }, () =>
            this.setState({
              paramsPagination: {
                ...this.state.paramsPagination,
                filter: {
                  ...this.state.paramsPagination.filter,
                  xstatus: [
                    "Resolved",
                    "Résolu",
                    "Pending",
                    "En attente",
                    "Nouveau",
                    "New"
                  ],
                  status: []
                }
              }
            })
          );
        } else if (prio === "allCategory") {
          this.setState(
            {
              technical: true,
              support: true,
              enquires: true
            },
            () =>
              this.setState({
                paramsPagination: {
                  ...this.state.paramsPagination,
                  filter: {
                    ...this.state.paramsPagination.filter,
                    xcategory: [
                      "Technical",
                      "Technique",
                      "Support",
                      "Enquires",
                      "Demande"
                    ],
                    category: []
                  }
                }
              })
          );
        } else {
          let keyfilters = this.state.keyfilters;

          if (prio === "high") keyfilters.push("High", "Haute");
          if (prio === "medium") keyfilters.push("Medium", "Moyenne");
          if (prio === "low") keyfilters.push("Low", "Faible");
          if (prio === "incident") keyfilters.push("Incident", "Incident");
          if (prio === "request") keyfilters.push("Request", "Demande");
          if (prio === "resolved") keyfilters.push("Resolved", "Résolu");
          if (prio === "pending") keyfilters.push("Pending", "En attente");
          if (prio === "new") keyfilters.push("New", "Nouveau");
          if (prio === "technical") keyfilters.push("Technical", "Technique");
          if (prio === "support") keyfilters.push("Support");
          if (prio === "enquires") keyfilters.push("Enquires", "Demande");
          if (prio.indexOf("nature_") !== -1) keyfilters.push(prio);
          if (prio.indexOf("subject_") !== -1) keyfilters.push(prio);
          //custom fileds filter
          // if (prio.indexOf("cf_") !== -1) keyfilters.push(prio);

          this.setState(
            {
              keyfilters: keyfilters // this.state.keyfilters.concat(prio)
            },
            () => {
              let filter = {
                priority: [],
                nature: this.state.paramsPagination.filter.nature
                  ? this.state.paramsPagination.filter.nature
                  : [],
                subject: this.state.paramsPagination.filter.subject
                  ? this.state.paramsPagination.filter.subject
                  : [],
                customFields: this.state.paramsPagination.filter[
                  "field_" + prio.split("_")[1]
                ]
                  ? this.state.paramsPagination.filter[
                      "field_" + prio.split("_")[1]
                    ]
                  : [],
                status: [],
                category: []
              };
              for (let i of this.state.keyfilters) {
                if (
                  i === "High" ||
                  i === "Haute" ||
                  i === "Moyenne" ||
                  i === "Medium" ||
                  i === "Low" ||
                  i === "Faible"
                )
                  filter.priority.push(makeUperCase(i));
                else if (
                  i === "Resolved" ||
                  i === "Résolu" ||
                  i === "Pending" ||
                  i === "En attente" ||
                  i === "New" ||
                  i === "Nouveau"
                )
                  filter.status.push(makeUperCase(i));
                else if (
                  i === "Technical" ||
                  i === "Technique" ||
                  i === "Support" ||
                  i === "Enquires" ||
                  i === "Demande"
                )
                  filter.category.push(makeUperCase(i));
                else if (prio.indexOf("nature_") !== -1)
                  filter.nature.push(prio.replace("nature_", ""));
                else if (prio.indexOf("subject_") !== -1)
                  filter.subject.push(prio.replace("subject_", ""));
                else if (prio.indexOf("cf_") !== -1)
                  filter.customFields["field_" + prio.split("_")[1]].push(
                    prio.replace("cf_", "")
                  );
              }
              this.setState({
                paramsPagination: {
                  ...this.state.paramsPagination,
                  filter: {
                    ...this.state.paramsPagination.filter,
                    priority: filter.priority,
                    nature: filter.nature,
                    subject: filter.subject,
                    category: filter.category,
                    status: filter.status
                  }
                }
              });
            }
          );
        }
      } else {
        if (prio === "allPriority") {
          this.setState(
            {
              high: false,
              medium: false,
              low: false
            },
            () =>
              this.setState({
                paramsPagination: {
                  ...this.state.paramsPagination,
                  filter: {
                    ...this.state.paramsPagination.filter,
                    priority: []
                  }
                },
                keyfilters: this.state.keyfilters.filter(
                  elm =>
                    elm !== "High" &&
                    elm !== "Haute" &&
                    elm !== "Medium" &&
                    elm !== "Moyenne" &&
                    elm !== "low" &&
                    elm !== "Faible"
                )
              })
          );
        } else if (prio === "generalNature") {
          var natureList = this.state.paramsPagination.filter.nature
            ? this.state.paramsPagination.filter.nature
            : [];
          var index = natureList.indexOf("");
          if (index > -1) {
            natureList.splice(index, 1);
          }

          index = natureList.indexOf("cc9e61a2-3f64-11eb-beea-2d88284e608e");
          if (index > -1) {
            natureList.splice(index, 1);
          }
          this.setState({
            paramsPagination: {
              ...this.state.paramsPagination,
              filter: {
                ...this.state.paramsPagination.filter,
                nature: natureList
              }
            }
          });
        } else if (prio === "allNature") {
          var naturesUpdated = this.state.natureTable;
          this.state.generalNature = false;
          this.setState(this.state.natureTable, () => {
            this.setState({
              paramsPagination: {
                ...this.state.paramsPagination,
                filter: {
                  ...this.state.paramsPagination.filter,
                  nature: []
                }
              },
              keyfilters: this.state.keyfilters.filter(
                elm => elm.indexOf("nature_") === -1
              )
            });
          });
        } else if (prio === "allSubject") {
          var subjectsUpdated = this.state.subjectTable;
          // this.state.generalNature = false;
          this.setState(this.state.subjectTable, () => {
            this.setState({
              paramsPagination: {
                ...this.state.paramsPagination,
                filter: {
                  ...this.state.paramsPagination.filter,
                  subject: []
                }
              },
              keyfilters: this.state.keyfilters.filter(
                elm => elm.indexOf("subject_") === -1
              )
            });
          });
        } else if (prio.includes("allcf_")) {
          this.setState(naturesUpdated, () =>
            this.setState({
              paramsPagination: {
                ...this.state.paramsPagination,
                filter: {
                  ...this.state.paramsPagination.filter,
                  customFields: {
                    ...this.state.paramsPagination?.filter?.customFields,
                    [prio.split("_")[1]]: undefined
                  }
                }
              }
            })
          );
        } else if (prio === "allStatus") {
          this.setState({ resolved: false, pending: false, new: false }, () =>
            this.setState({
              paramsPagination: {
                ...this.state.paramsPagination,
                filter: {
                  ...this.state.paramsPagination.filter,
                  status: []
                }
              },
              keyfilters: this.state.keyfilters.filter(
                elm =>
                  elm !== "Resolved" &&
                  elm !== "Résolu" &&
                  elm !== "Pending" &&
                  elm !== "En attente" &&
                  elm !== "New" &&
                  elm !== "Nouveau"
              )
            })
          );
        } else if (prio === "allCategory") {
          this.setState(
            {
              technical: false,
              support: false,
              enquires: false
            },
            () =>
              this.setState({
                paramsPagination: {
                  ...this.state.paramsPagination,
                  filter: {
                    ...this.state.paramsPagination.filter,
                    category: []
                  }
                },
                keyfilters: this.state.keyfilters.filter(
                  elm =>
                    elm !== "Technical" &&
                    elm !== "Technique" &&
                    elm !== "support" &&
                    elm !== "Demande" &&
                    elm !== "enquires"
                )
              })
          );
        } else {
          let keyfilters = this.state.keyfilters;
          //console.log(keyfilters)
          if (prio === "high")
            keyfilters = keyfilters.filter(
              elm => elm !== "High" && elm !== "Haute"
            );
          if (prio === "medium")
            keyfilters = keyfilters.filter(
              elm => elm !== "Medium" && elm !== "Moyenne"
            ); //keyfilters.push("Medium","Moyenne")
          if (prio === "low")
            keyfilters = keyfilters.filter(
              elm => elm !== "Low" && elm !== "Faible"
            ); //keyfilters.push("Low","Faible")
          if (prio === "incident")
            keyfilters = keyfilters.filter(
              elm => elm !== "Incident" && elm !== "Incident"
            );
          if (prio === "request")
            keyfilters = keyfilters.filter(
              elm => elm !== "Request" && elm !== "Demande"
            );
          if (prio === "resolved")
            keyfilters = keyfilters.filter(
              elm => elm !== "Resolved" && elm !== "Résolu"
            ); //keyfilters.push("Resolved","Résolu");
          if (prio === "pending")
            keyfilters = keyfilters.filter(
              elm => elm !== "Pending" && elm !== "En attente"
            ); //keyfilters.push("Pending","En attente");
          if (prio === "new")
            keyfilters = keyfilters.filter(
              elm => elm !== "New" && elm !== "Nouveau"
            ); //keyfilters.push("New" ,"Nouveau");
          if (prio === "technical")
            keyfilters = keyfilters.filter(
              elm => elm !== "Technical" && elm !== "Technique"
            ); //keyfilters.push("Technical","Technique")
          if (prio === "support")
            keyfilters = keyfilters.filter(elm => elm !== "Support"); //keyfilters.push("Support")
          if (prio === "enquires")
            keyfilters = keyfilters.filter(
              elm => elm !== "Enquires" && elm !== "Demande"
            ); //keyfilters.push("Enquires","Demande")
          if (prio.indexOf("nature_") !== -1) {
            this.state.allNature = false;
            keyfilters = keyfilters.filter(elm => elm !== prio);
          }
          if (prio.indexOf("cf_") !== -1) {
            //this.state.allNature = false;
            keyfilters = keyfilters.filter(elm => elm !== prio);
          }
          this.setState(
            {
              keyfilters: keyfilters // this.state.keyfilters.filter(elm => elm !== prio)
            },
            () => {
              let filter = {
                priority: [],
                status: [],
                category: [],
                nature: [],
                ["field_" + prio.split("_")[1]]: []
              };
              for (let i of this.state.keyfilters) {
                if (
                  i === "High" ||
                  i === "Haute" ||
                  i === "Moyenne" ||
                  i === "Medium" ||
                  i === "Low" ||
                  i === "Faible"
                )
                  filter.priority.push(makeUperCase(i));
                else if (i === "Incident" || i === "Request")
                  filter.nature.push(makeUperCase(i));
                else if (
                  i === "Resolved" ||
                  i === "Résolu" ||
                  i === "Pending" ||
                  i === "En attente" ||
                  i === "New" ||
                  i === "Nouveau"
                )
                  filter.status.push(makeUperCase(i));
                else if (
                  i === "Technical" ||
                  i === "Technique" ||
                  i === "Support" ||
                  i === "Enquires" ||
                  i === "Demande"
                )
                  filter.category.push(makeUperCase(i));
                else if (i.indexOf("nature_") !== -1) {
                  filter.nature.push(i.replace("nature_", ""));
                } else if (i.indexOf("cf_") !== -1) {
                  filter["field_" + prio.split("_")[1]].push(
                    i.replace("cf_", "")
                  );
                }
                // let filter = { priority: [], status: [], category: [] };
                // for (let i of this.state.keyfilters) {
                //   if (i === "high" || i === "medium" || i === "low")
                //     filter.priority.push(makeUperCase(i));
                //   else if (i === "resolved" || i === "pending" || i === "new")
                //     filter.status.push(makeUperCase(i));
                //   else if (
                //     i === "technical" ||
                //     i === "support" ||
                //     i === "enquires"
                //   )
                //     filter.category.push(makeUperCase(i));
              }
              this.setState({
                paramsPagination: {
                  ...this.state.paramsPagination,
                  filter: {
                    ...this.state.paramsPagination.filter,
                    priority: filter.priority,
                    nature: filter.nature,
                    subject: filter.subject,
                    category: filter.category,
                    status: filter.status
                  }
                }
              });
            }
          );
        }
      }
    });

    if (prio.indexOf("cf_") !== -1) {
      if (bool) {
        this.setState({
          paramsPagination: {
            ...this.state.paramsPagination,
            filter: {
              ...this.state.paramsPagination.filter,
              customFields: {
                ...this.state.paramsPagination.filter.customFields,
                [prio.split("_")[5]]: this.state.paramsPagination.filter
                  .customFields[prio.split("_")[5]]
                  ? [
                      ...this.state.paramsPagination.filter.customFields[
                        prio.split("_")[5]
                      ],
                      prio.split("_")[3]
                    ]
                  : [prio.split("_")[3]]
              }
            }
          }
        });
      } else {
        this.setState({
          paramsPagination: {
            ...this.state.paramsPagination,
            filter: {
              ...this.state.paramsPagination.filter,
              customFields: {
                ...this.state.paramsPagination.filter.customFields,
                [prio.split(
                  "_"
                )[5]]: this.state.paramsPagination.filter.customFields[
                  prio.split("_")[5]
                ]?.filter(x => x !== prio.split("_")[3])
              }
            }
          }
        });
      }
    }
  };

  //applyFilter
  applyFilter = () => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
    this.setState({ isOpen: false });
  };

  clearfilter = () => {
    let nt = this.state.natureTable;
    for (let x of this.state.ticketNatureList) {
      nt["nature_" + x.value] = false;
    }
    let st = this.state.subjectTable;
    for (let x of this.state.ticketSubjectList) {
      nt["subject_" + x.value] = false;
    }

    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };

    this.setState({
      allPriority: false,
      high: false,
      medium: false,
      low: false,
      allStatus: false,
      resolved: false,
      pending: false,
      new: false,
      allCategory: false,
      technical: false,
      support: false,
      enquires: false,
      allNature: false,
      allSubject: false,
      generalNature: false,
      natureTable: { ...nt },
      subjectTable: { ...st },
      ...nt
    });

    this.setState(
      {
        paramsPagination: {
          sio_channel: SIO_TICKET_INFO,
          page: 1,
          nbre_per_page: 7,
          filter: { customFields: {}, lang: i18n.language }
        },
        keyfilters: []
      },
      () => {
        this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
      }
    );
  };

  closeFilter = () => {
    this.setState({
      isOpen: false,
      keyfilters: []
    });
    this.clearfilter();
  };

  //filter Total ticket
  ticketTotalDateFilter = (timeStart, timeEnd) => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    // let day = new Date();
    // let momenday = moment(day);
    // let dateDefault = momenday.format("YYYY-MM-DD")
    const timeStartmoment = moment(timeStart).format("YYYY-MM-DD");

    const timeEndmoment = moment(timeEnd).format("YYYY-MM-DD");

    this.setState(
      {
        date: {
          ...this.state.date,
          date_total_start: timeStartmoment,
          date_total_end: timeEndmoment
        }
      },
      () => {
        this.initSocketTicketStats(this.state.date, receptor);
      }
    );
  };

  //COMPLETE TICKET DATE FILTER
  ticketCompleteDateFilter = (timeStart, timeEnd) => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    // let day = new Date();
    // let momenday = moment(day);
    // let dateDefault = momenday.format("YYYY-MM-DD")

    const timeStartmoment = moment(timeStart).format("YYYY-MM-DD");

    const timeEndmoment = moment(timeEnd).format("YYYY-MM-DD");

    this.setState(
      {
        date: {
          ...this.state.date,
          date_complete_start: timeStartmoment,
          date_complete_end: timeEndmoment
        }
      },
      () => {
        this.initSocketTicketStats(this.state.date, receptor);
      }
    );
  };

  //PENDING TICKET DATE FILTER

  ticketPendingDateFilter = (timeStart, timeEnd) => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    // let day = new Date();
    // let momenday = moment(day);
    // let dateDefault = momenday.format("YYYY-MM-DD")

    const timeStartmoment = moment(timeStart).format("YYYY-MM-DD");

    const timeEndmoment = moment(timeEnd).format("YYYY-MM-DD");

    this.setState(
      {
        date: {
          ...this.state.date,
          date_pending_start: timeStartmoment,
          date_pending_end: timeEndmoment
        }
      },
      () => {
        this.initSocketTicketStats(this.state.date, receptor);
      }
    );
  };

  //ALL TICKET DATE FILTER
  allTicketDateFilter = (timeStart, timeEnd) => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    const now = moment().format("YYYY-MM-DD");

    const timeStartmoment =
      timeStart == null ? now : moment(timeStart).format("YYYY-MM-DD");

    const timeEndmoment =
      timeEnd == null ? now : moment(timeEnd).format("YYYY-MM-DD");

    this.setState(
      {
        paramsPagination: {
          ...this.state.paramsPagination,
          filter: {
            ...this.state.paramsPagination.filter,
            date_ticket: {
              start: timeStartmoment,
              end: timeEndmoment
            }
          }
        }
      },
      () => {
        this.initSocketgetInfoTicket(this.state.paramsPagination, receptor);
      }
    );
  };

  handleSearch = e => {
    e.persist();
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      this.setState(
        {
          paramsPagination: {
            ...this.state.paramsPagination,
            page: 1,
            filter: {
              ...this.state.paramsPagination.filter,
              name: e.target.value
            }
          }
        },
        () => {
          this.applyFilter();
        }
      );
    }, 500);
    /*this.setState({searchticket: e.target.value}, () => {
                                    this.setState({
                                        searchTicketsFiltres: this.state.tickets.filter(
                                            elm =>
                                                elm.number.includes(this.state.searchticket) ||
                                                elm.subject
                                                    .toLowerCase()
                                                    .includes(this.state.searchticket.toLowerCase())
                                        )
                                    });
                                });*/
  };

  //DELETE TICKET
  deleteTicket = (IdTicket, id) => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    //console.log(IdTicket,'  ',id)
    this.initSocketDeleteTicket(IdTicket, id, receptor);
    //this.handleAddRessourceModal()
  };

  //SOCKET FOR DELETE TICKET
  //get ticket info
  initSocketDeleteTicket = (idTicket, id, receptor) => {
    const { socket } = this.props;
    // console.log("initSocketDeleteTicket ** : ");

    socket.on(SIO_TICKET_DELETE, response => {
      //const response = JSON.parse(event);
    });

    UpdateTicketHttpService.deleteTicket(idTicket, receptor).then(response => {
      // console.log("ticketStatsPerDate : ", response.data);

      if (response.status === 200 || response.status === 202) {
        // console.log("test-Delete-success : ", response);
        // Reload After ticket Delete

        this.load(idTicket);
        this.props.handleMessageTicket("test-Delete-success", id, idTicket);
      } else {
        this.props.handleMessageTicket("Error-Delete-ticket", id, idTicket);
      }
    });
  };
  // onSocketDeleteTicket = response => {
  //   //   console.log("onSocketDeleteTicket : ", response);

  //   if (response && (response.status === 200 || response.status === 202)) {
  //     // console.log(response.data);
  //   }
  // };

  //filter Pagination
  gotToPageOnsearch = index => {
    this.setState({
      pagination: { ...this.state.pagination, from: index * 7 }
    });
  };
  setVisibleFields = vf => {
    this.setState({ visibleFields: vf });
  };

  //get custom ticket fields
  GetCustomTicketFields = () => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };

    CustomTicketFieldHttpService.getAll(receptor)
      .then(r => {
        if (r && r.data && r.data.status == 200) {
          // Set
          this.setState({
            customTicketFields: r.data?.data?.items?.filter(
              f => !f.default && f.field_type?.name === "Options"
            )
          });
          this.setState({
            customTicketFieldsAll: r.data?.data?.items?.filter(f => !f.default)
          });
        }
      })
      .catch(e => {
        // console.log("[CustomTicketFieldHttpService.getAll]", e);
      })
      .finally(f => {
        // Close reload.
        // console.log(f);
      });
  };

  render() {
    const {
      i18n,
      t,
      kind,
      handleCreateTicket,
      handleMessageTicket
    } = this.props;

    const {
      isOpen,
      allTickets,
      ticketStats,
      date,
      ticketfiltred,
      keyfilters,
      ticketToList,
      ticketToListFiltred,
      ticketInfos,
      searchTicketsFiltres,
      searchticket,
      pagination: { from, count }
    } = this.state;
    // console.log('searchTicketsFiltres',searchTicketsFiltres.length)
    return (
      <>
        <div className="ticketnalytics-header">
          <h2 className="dashboard-title titledashboard">
            {kind === "tickets"
              ? t("tickets.tickets_overview")
              : kind === "dashboard"
              ? t("dashboard.dashboard_overview")
              : t("settings.settings_overview")}
          </h2>
          <button
            className="create_ticketbtn"
            onClick={() => handleCreateTicket()}
          >
            + {t("tickets.ticket_btn_create").toUpperCase()}
          </button>
        </div>
        <div className="columns analytics-columns">
          {/* //from here */}
          <CreateTicket
            t={t}
            CalendarIcon={CalendarIcon}
            MoreIcon={MoreIcon}
            ticketStats={ticketStats.new}
          />

          <ResolveTicket
            t={t}
            CalendarIcon={CalendarIcon}
            MoreIcon={MoreIcon}
            ticketStats={ticketStats.complete}
            ticketCompleteDateFilter={(start, end) =>
              this.ticketCompleteDateFilter(start, end)
            }
          />

          <PendingTicket
            t={t}
            CalendarIcon={CalendarIcon}
            MoreIcon={MoreIcon}
            ticketStats={ticketStats.pending}
            ticketPendingDateFilter={(start, end) =>
              this.ticketPendingDateFilter(start, end)
            }
          />

          <TotalTicket
            t={t}
            CalendarIcon={CalendarIcon}
            MoreIcon={MoreIcon}
            ticketStats={ticketStats.total}
            ticketTotalDateFilter={(start, end) =>
              this.ticketTotalDateFilter(start, end)
            }
          />
          {/* To here */}
        </div>
        <div className="columns chart-columns custom-columns">
          <div className="column column-chart ticketContainer container-dashboard-ticket ">
            {/* <TicketSearchBar
             toggleOpen={toggleOpen}
               t={t}
              handleSearch={handleSearch}
               handleExport={generateTicketReport}
               handleChangeDate={(start, end) => allTicketDateFilter(start, end)}
              iconExport={state.isExporting ? LoaderOval : ExportIcon}

            /> */}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "top"
              }}
            >
              <div className="searchBar">
                <div className={"filter-elements"}>
                  <button className="buttonserch">
                    <img
                      className="view-more"
                      src={SearchIcon}
                      alt="SearchIcon"
                    />
                  </button>

                  <input
                    className="input input-search"
                    type="text"
                    placeholder={t("tickets.search_placeholder")}
                    onChange={this.handleSearch}
                    style={{ padding: "3px" }}
                  />

                  <button
                    className="button buttonFilter"
                    onClick={() => {
                      this.setState({
                        isOpen: !isOpen
                      });
                      //this.clearfilter();
                    }}
                  >
                    <img
                      className="view-more"
                      src={SortBtn}
                      alt="Sort Button"
                    />
                    {t("tickets.search_filter")}
                    <img className="view-more" src={MoreIcon} alt="caneldar" />
                  </button>
                </div>

                <div style={{ display: "flex" }}>
                  <div
                    className="button is-outlined"
                    style={{
                      width: "203px",
                      borderRadius: "5px !important",
                      height: "42px",
                      marginRight: "15px"
                    }}
                  >
                    <img
                      className="calendar-icon"
                      src={CalendarIcon}
                      alt="caneldar"
                    />

                    <DatePicker
                      t={t}
                      getTimeFilter={(start, end) =>
                        this.allTicketDateFilter(start, end)
                      }
                    />
                  </div>
                  <ExportButton
                    t={t}
                    handleExport={this.generateTicketReport}
                    iconExport={ExportIcon}
                    disable={ticketToList?.length === 0}
                  />
                </div>
              </div>
            </div>
            <div
              className="modalSearch"
              style={{ display: `${isOpen ? "flex" : "none"}` }}
              ref={node => (this.node = node)}
            >
              <div className="priorityContainer">
                {" "}
                <span className="text-filter">
                  {" "}
                  {t("tickets.search_filter_content.priority")}{" "}
                </span>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allPriority}
                        onChange={this.handleChange}
                        value="allPriority"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.priority_filter.all"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.high}
                        onChange={this.handleChange}
                        value="high"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.priority_filter.high"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.medium}
                        onChange={this.handleChange}
                        value="medium"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.priority_filter.medium"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.low}
                        onChange={this.handleChange}
                        value="low"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.priority_filter.low"
                    )}
                  />
                </FormGroup>
              </div>
              {/* Start Ticket Nature */}
              <div className="priorityContainer">
                {" "}
                <span className="text-filter">
                  {" "}
                  {t("tickets.search_filter_content.nature")}{" "}
                </span>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allNature}
                        onChange={this.handleChange}
                        value="allNature"
                        color="primary"
                      />
                    }
                    label={t("tickets.search_filter_content.nature_filter.all")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.generalNature}
                        onChange={this.handleChange}
                        value="generalNature"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.nature_filter.general"
                    )}
                  />

                  {this.state.ticketNatureList?.map((item, index) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={
                            this.state["nature_" + item.value]
                              ? this.state["nature_" + item.value]
                              : false
                          }
                          onChange={this.handleChange}
                          value={"nature_" + item.value}
                          // value={""}
                          color="primary"
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </div>
              {/* End ticket nature */}
              {/* Start Ticket Object */}
              <div className="priorityContainer">
                {" "}
                <span className="text-filter">
                  {" "}
                  {t("tickets.search_filter_content.category")}{" "}
                </span>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allSubject}
                        onChange={this.handleChange}
                        value="allSubject"
                        color="primary"
                      />
                    }
                    label={t("tickets.search_filter_content.nature_filter.all")}
                  />

                  {this.state.ticketSubjectList?.map((item, index) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={
                            this.state["subject_" + item.value]
                              ? this.state["subject_" + item.value]
                              : false
                          }
                          onChange={this.handleChange}
                          value={"subject_" + item.value}
                          // value={""}
                          color="primary"
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </div>
              {/* End ticket nature */}
              <div className="statusContainer">
                {" "}
                <span className="text-filter">
                  {" "}
                  {t("tickets.search_filter_content.status")}{" "}
                </span>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allStatus}
                        onChange={this.handleChange}
                        value="allStatus"
                        color="primary"
                      />
                    }
                    label={t("tickets.search_filter_content.status_filter.all")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.new}
                        onChange={this.handleChange}
                        value="new"
                        color="primary"
                      />
                    }
                    label={t("tickets.search_filter_content.status_filter.new")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.pending}
                        onChange={this.handleChange}
                        value="pending"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.status_filter.pending"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.resolved}
                        onChange={this.handleChange}
                        value="resolved"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.status_filter.resolve"
                    )}
                  />
                </FormGroup>
              </div>
              {/* <div className="CategorieContainer">
                {" "}
                <span className="text-filter">
                  {" "}
                  {t("tickets.search_filter_content.category")}{" "}
                </span>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allCategory}
                        onChange={this.handleChange}
                        value="allCategory"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.category_filter.all"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.technical}
                        onChange={this.handleChange}
                        value="technical"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.category_filter.technical"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.support}
                        onChange={this.handleChange}
                        value="support"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.category_filter.support"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.enquires}
                        onChange={this.handleChange}
                        value="enquires"
                        color="primary"
                      />
                    }
                    label={t(
                      "tickets.search_filter_content.category_filter.enquires"
                    )}
                  />
                </FormGroup>
              </div> */}

              {/* ticket custom fields*/}
              {/*

              {this.state.customTicketFields.map(cf => (
                <div className="priorityContainer">
                  {" "}
                  <span
                    className="text-filter"
                    style={{ textTransform: "capitalize" }}
                  >
                    {" "}
                    {cf["label_" + i18n.language]}{" "}
                  </span>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state["allcf_" + cf.id]}
                          onChange={this.handleChange}
                          value={"allcf_" + cf.id}
                          color="primary"
                        />
                      }
                      label={t(
                        "tickets.search_filter_content.nature_filter.all"
                      )}
                    />

                    {cf.field_type.options?.map((item, index) => (
                      <FormControlLabel
                        key={item[i18n.language]}
                        control={
                          <Checkbox
                            checked={
                              this.state["allcf_" + cf.id]
                                ? this.state["allcf_" + cf.id]
                                : this.state[
                                    "cf_" +
                                      cf.id +
                                      "_" +
                                      "value_" +
                                      item[i18n.language] +
                                      "_label_" +
                                      cf["label_" + i18n.language]
                                  ]
                                ? this.state[
                                    "cf_" +
                                      cf.id +
                                      "_" +
                                      "value_" +
                                      item[i18n.language] +
                                      "_label_" +
                                      cf["label_" + i18n.language]
                                  ]
                                : false
                            }
                            onChange={this.handleChange}
                            value={
                              "cf_" +
                              cf.id +
                              "_" +
                              "value_" +
                              item[i18n.language] +
                              "_label_" +
                              cf["label_" + i18n.language]
                            }
                            // value={""}
                            color="primary"
                          />
                        }
                        label={item[i18n.language]}
                      />
                    ))}
                  </FormGroup>
                </div>
              ))}
              */}
              {/* */}

              <div className="modalSerachSetting">
                <p className="clear-Filter" onClick={this.clearfilter}>
                  {t("tickets.search_filter_content.clear_all_filters")}
                </p>
                <p>
                  <span
                    style={{
                      color: "#94A4BE",
                      marginRight: "32px",
                      cursor: "pointer"
                    }}
                    onClick={() => this.setState({ isOpen: false })}
                  >
                    {t("tickets.search_filter_content.cancel")}
                  </span>
                  <span
                    style={{ color: "##0089E1", cursor: "pointer" }}
                    onClick={() => this.applyFilter()}
                  >
                    {t("tickets.search_filter_content.apply")}
                  </span>
                </p>
              </div>
            </div>
            <TicketsList
              t={t}
              setVisibleFields={this.setVisibleFields}
              currentLang={this.state.lang}
              handleMessageTicket={handleMessageTicket}
              allTickets={
                searchticket === ""
                  ? ticketToListFiltred
                  : searchTicketsFiltres.slice(from, from + count)
              }
              deleteTicket={(isTicket, id) => this.deleteTicket(isTicket, id)}
              page={this.state.ticketInfos.page}
              customTicketFields={this.state.customTicketFields}
              customTicketFieldsAll={this.state.customTicketFieldsAll}
              //  searchTicketsFiltres={searchTicketsFiltres}
              // searchticket={searchticket}
            />
          </div>
        </div>
        {searchticket === "" ? (
          <Paginator
            t={t}
            ticketInfot={ticketInfos}
            gotToPage={index => this.gotToPage(index)}
            nextPage={() => this.toNextPage()}
            previousPage={() => this.toPreviousPage()}
          />
        ) : (
          <FilterPagination
            t={t}
            from={from}
            count={count}
            total={
              searchTicketsFiltres.length % 7 === 0
                ? searchTicketsFiltres.length / 7
                : Math.floor(searchTicketsFiltres.length / 7) + 1
            }
            totalPage={searchTicketsFiltres.length}
            // onChangeCount={count => {
            //   this.setState({
            //     pagination: {
            //       ...this.state.pagination,
            //       count
            //     }
            //   })
            // }}
            gotToPageOnsearch={index => this.gotToPageOnsearch(index)}
            onPrev={() =>
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  from: Math.max(0, from - count)
                }
              })
            }
            onNext={() => {
              if (from + count >= searchTicketsFiltres.length) return;
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  from: from + count
                }
              });
            }}
          />
        )}
        <Footer t={t} />
      </>
    );
  }
}

TicketDashboard.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  handleCreateTicket: PropTypes.func.isRequired,
  handleMessageTicket: PropTypes.func.isRequired
};

const TicketDashboardWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <TicketDashboard {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);

export default TicketDashboardWithSocket;
