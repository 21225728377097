import React from "react";
import { I18nextProvider } from "react-i18next";
import i18nConfig from "../configs/i18nConfig";
import App from "./app/App";

import { Provider } from "react-redux";
import store from "../redux/store";

const Root = () => {
  return (
    <Provider store={store}>
    <I18nextProvider i18n={i18nConfig}>
      <App />
    </I18nextProvider>
    </Provider>
  );
};

export default Root;
