import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { RobotMessage, UserMessage } from "./utils/FormElements";
import {
  REGEX_EMAIL,
  REGEX_NUMBER,
  REGEX_TEXT
} from "../../../constants/Constants";

class WebformPreview extends React.Component {
  constructor(props) {
    super(props);
    //
    this.hasErrors = this.hasErrors.bind(this);
    this.handleUserName = this.handleUserName.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.handleSubCategory = this.handleSubCategory.bind(this);
    this.handleEmailAddress = this.handleEmailAddress.bind(this);
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleAttach = this.handleAttach.bind(this);
    //
    this.state = {
      name: "",
      category: "",
      subcategory: "",
      message: "",
      file: { name: "Browse File" },
      email: "",
      phone: "",
      errors: {
        name: "",
        category: "",
        subcategory: "",
        message: "",
        file: "",
        email: "",
        phone: ""
      },
      step: 1
    };
    //
    this.timer = null;
  }

  hasErrors() {
    const { errors } = this.state;
    return Object.values(errors).includes(true);
  }

  handleFormStep(nextStep) {
    const { step } = this.state;
    if (nextStep == step + 1 && step < 6) {
      this.setState({ step: step + 1 });
    }
  }

  handleUserName(e) {
    const { step } = this.state;
    const errors = this.state.errors;
    const current = e.target.value;
    this.setState({ name: current });
    //
    if (REGEX_TEXT.test(current) && current.length >= 5) {
      // Clear error under name
      errors.name = "";
      // Pass to the next step.
      this.handleFormStep(2);
      // Load next select part.
      if (step + 1 == 2) {
        const that = this;
        this.timer = setTimeout(() => {
          that.handleFormStep(3);
          clearTimeout(that.timer);
        }, 4000);
      }
    } else {
      errors.name = "Incorrect name";
    }
    //
    this.setState({ errors });
  }

  handleCategory(current) {
    this.setState({ category: current });
    // TODO: Backend service to load sub category.
    const that = this;
    this.timer = setTimeout(() => {
      that.handleFormStep(3);
    }, 4000);
  }

  handleSubCategory(current) {
    this.setState({ category: current });
    // Clear timer
    clearTimeout(this.timer);
    // Pass to the next step.
    this.handleFormStep(3);
  }

  handleMessage(e) {
    const errors = this.state.errors;
    const current = e.target.value;
    this.setState({ message: current });
    //
    if (REGEX_TEXT.test(current) && current.length >= 5) {
      // Clear error under name
      errors.message = "";
      // Pass to the next step.
      this.handleFormStep(4);
    } else {
      errors.message = "Invalid text content";
    }
    this.setState({ errors });
  }

  handleAttach(e = null) {
    if (e) {
      const current = e.target.files[0];
      this.setState({
        file: {
          name: current.name
        }
      });
      // TODO: Send attach to CDN.
    }
    // Pass to the next step.
    this.handleFormStep(5);
  }

  handleEmailAddress(e) {
    const errors = this.state.errors;
    const current = e.target.value;
    this.setState({ email: current });
    //
    if (current != "" && REGEX_EMAIL.test(current)) {
      // Clear error on email.
      errors.email = "";
    } else {
      errors.email = "Invalid email";
    }
    this.setState({ errors });
  }

  handlePhoneNumber(current) {
    const errors = this.state.errors;
    this.setState({ phone: current });
    //
    if (current && current != "" && isValidPhoneNumber(current)) {
      // Clear error on phone number.
      errors.phone = "";
    } else {
      errors.phone = "Invalid phone number";
    }
    this.setState({ errors });
  }

  render() {
    const { step } = this.state;

    return (
      <main className="cf-context">
        {step >= 1 && (
          <div>
            <RobotMessage text="Hello!!! We are here to help. Kindly let us know how…" />
            <RobotMessage text="What’s your name?" />
            <UserMessage
              type="text"
              label="Enter Name"
              data={this.state.name}
              callback={this.handleUserName}
              error={this.state.errors.name}
            />
          </div>
        )}

        {step >= 2 && (
          <div>
            <RobotMessage text="What problem are you facing?" />
            <UserMessage
              type="select"
              label="Select category"
              data={this.state.category}
              callback={this.handleCategory}
              error={this.state.errors.category}
            />
            <UserMessage
              type="select"
              label="Select Subcategory for (Support)"
              data={this.state.subcategory}
              callback={this.handleSubCategory}
              error={this.state.errors.subcategory}
            />
          </div>
        )}

        {step >= 3 && (
          <div>
            <RobotMessage text="Tell us more" />
            <UserMessage
              type="textarea"
              label="Enter Message"
              data={this.state.message}
              callback={this.handleMessage}
              error={this.state.errors.message}
            />
          </div>
        )}

        {step >= 4 && (
          <div>
            <RobotMessage
              close
              callback={this.handleAttach}
              text="Alright James. Attach a file to shed more light on the issue. Click on the close icon to ignore this if you do not need to do this"
            />
            <UserMessage
              type="file"
              label="Upload Image"
              data={this.state.file}
              callback={this.handleAttach}
              error={this.state.errors.file}
            />
          </div>
        )}

        {step >= 5 && (
          <div>
            <RobotMessage text="Just one last thing. Provide your contact info so we can reach out to you" />
            <UserMessage
              type="email"
              label="Enter email address"
              data={this.state.email}
              callback={this.handleEmailAddress}
              error={this.state.errors.email}
            />
            <UserMessage
              type="phone"
              label="Enter Telephone"
              data={this.state.phone}
              callback={this.handlePhoneNumber}
              error={this.state.errors.phone}
            />
          </div>
        )}
      </main>
    );
  }
}

export default WebformPreview;
