import React, { useState, useEffect, useContext } from "react";
import "react-dates/initialize";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import CalendarIcon from "../../";
import { SharedDataContext } from "../app/UseContext";

moment.locale("fr", {
  months: "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split(
    "_"
  ),
  monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
    "_"
  ),
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "/DIM_/LUN_/MAR_/MER_/JEU_/VEN_/SAM".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  ordinal: function(number) {
    return number + (number === 1 ? "er" : "ème");
  }
});

function DatePicker(props) {
  const { getTimeFilter, CalendarIcon, t } = props;
  const [focusedInput, setFocusedInput] = useState(null);

  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );

  moment.locale(sharedDataContext.defaultLang.value);

  let day = new Date();
  let outRange = moment([
    day.getFullYear(),
    day.getMonth(),
    day.getDate(),
    23,
    59,
    59
  ]);
  let momenday = moment(day);
  // let dateDefault = momenday.format("MM/DD/YYYY HH:mm:ss").slice(0, 11);
  let dateDefault = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 11);
  const [startDate, setStartDate] = useState(momenday);
  const [endDate, setEndDate] = useState(momenday);

  const handleDatesChange = ({ startDate, endDate }) => {
    const currentDay = momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 11);
    const startDateString =
      startDate == null
        ? currentDay
        : startDate.format("YYYY-MM-DD HH:mm:ss").slice(0, 11);
    if (moment(startDateString).isAfter(currentDay)) {
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: {
            title: "",
            msg: t("notification.date-start-filter-error")
          }
        }
      });
    } else {
      setStartDate(startDate);
      if (endDate !== null) {
        const endDateString = endDate
          .format("YYYY-MM-DD HH:mm:ss")
          .slice(0, 11);
        if (moment(endDateString).isAfter(currentDay)) {
          setSharedDataContext({
            ...sharedDataContext,
            notification: {
              active: true,
              status: "danger",
              content: {
                title: "",
                msg: t("notification.date-start-filter-error")
              }
            }
          });
          setEndDate(momenday);
        } else {
          setEndDate(endDate);
        }
      } else {
        setEndDate(momenday);
      }
    }
    // console.log('startDate',startDate)
    // console.log('endDate',endDate)
    getTimeFilter(startDate || moment(), endDate || moment());
  };

  return (
    <div className="Apps">
      <DateRangePicker
        startDatePlaceholderText={dateDefault}
        endDatePlaceholderText={dateDefault}
        isOutsideRange={day => {
          return day.isAfter(outRange);
        }}
        startDate={startDate}
        startDateId="tata-start-date"
        endDate={endDate}
        endDateId="tata-end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        minimumNights={0}
      />
    </div>
  );
}

export default DatePicker;
