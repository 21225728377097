import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "../Analytics/DatePickerSingleMonth";
import Chart from "react-apexcharts";
import { SharedDataContext } from "../../app/UseContext";
//import Bar from "./Bar";

const ChartDatas = props => {
  const {
    ticketStatsChart,
    t,
    i18n,
    graphTicketDateFilter,
    CalendarIcon,
    MoreIcon
  } = props;
  const { sharedDataContext } = useContext(SharedDataContext);
  const [modalText, setMdalText] = useState({
    newTixket: "Nouveau ticket",
    pendinTicket: "Ticket en attente",
    reolveTicket: "Ticket résolu"
  });
  const series = [
    {
      data: [
        ticketStatsChart ? ticketStatsChart.new : 0,
        ticketStatsChart ? ticketStatsChart.pending : 0,
        ticketStatsChart ? ticketStatsChart.resolved : 0
      ]
    }
  ];
  useEffect(() => {
    if (sharedDataContext.defaultLang) {
      if (sharedDataContext.defaultLang.value === "en")
        setMdalText({
          newTixket: "New ticket",
          pendinTicket: "Pending ticket",
          reolveTicket: "Resolved ticket"
        });
    }
    return () => {
      // cleanup
    };
  }, [sharedDataContext]);

  const options = {
    chart: {
      height: 350,
      type: "bar"
    },
    colors: ["#2E93fA", "#ff9800", "#00BD39"],
    plotOptions: {
      bar: {
        columnWidth: "15%",
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        [modalText.newTixket],
        [modalText.pendinTicket],
        [modalText.reolveTicket]
      ],
      labels: {
        style: {
          //colors: ['#546E7A'],
          fontSize: "12px",
          border: "1px solid red"
        }
      }
    }
  };

  return (
    <div className="chart-dashboard-container">
      <div className="button is-outlined datapick-chartDatas">
        <img className="calendar-icon" src={CalendarIcon} alt="caneldar" />
        <DatePicker
          t={t}
          getTimeFilter={(start, end) => graphTicketDateFilter(start, end)}
          graphTicketDateFilter
        />
      </div>
      <div style={{ margin: "auto" }}>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={350}
          width={400}
        />
      </div>
    </div>
  );
};

// ChartDatas.propTypes = {
//   i18n: PropTypes.shape({}).isRequired,
//   data: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
// };

export default ChartDatas;
