import React, { useEffect, useState } from "react";
import { Overlay, Popover, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { MdAccessTimeFilled } from "react-icons/md";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { isNumeric, SplitTime } from "../../../../utils/general";

const CreateCategoryDetailsPopover = props => {
  const {
    myRef,
    show,
    target,
    handleClick,
    t,
    categoryData,
    priorities,
    actualPriority,
    handleUpdateTicketSubject,
    nature,
    parentSla,
    parentPriority
  } = props;

  const [priorityItems, setPriorityItems] = useState();

  // const [actualPriority, setActualPriority] = useState({ value: null, label: null });
  const [prio, setPrio] = useState(null);
  const [slaObject, setSlaObject] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
    fullSLA: "00:00:00"
  });
  const states = useSelector(state => state, shallowEqual);
  const dispatch = useDispatch();

  // useEffect(() => {

  //     let daysToHours = (typeof slaObject?.days == 'undefined') ? 0 : slaObject?.days * 24;
  //     let newHours = parseInt(slaObject?.hours) + parseInt(daysToHours);
  //     let slaTotal = newHours + ":" + slaObject?.minutes + ":" + slaObject?.seconds;

  //     setSlaObject({ ...slaObject, fullSLA: slaTotal })

  // }, [slaObject.days]);

  useEffect(() => {
    let slaToPush = {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
      fullSLA: "00:00:00"
    };
    if (typeof parentSla !== "undefined") {
      const slaArray = parentSla.split(":");
      slaArray[0] = slaArray[0] == "" ? "00" : slaArray[0];
      slaArray[1] = slaArray[1] == "" ? "00" : slaArray[1];
      slaArray[2] = slaArray[2] == "" ? "00" : slaArray[2];
      const fullSLA = `${slaArray[0]}:${slaArray[1]}:${slaArray[2]}`;

      let hoursToDays = SplitTime(slaArray[0]);

      let Days =
        String(hoursToDays.Days).length === 1
          ? `0${hoursToDays.Days}`
          : hoursToDays.Days;
      let Hours =
        String(hoursToDays.Hours).length === 1
          ? `0${hoursToDays.Hours}`
          : hoursToDays.Hours;
      let Minutes =
        hoursToDays.Minutes > 0
          ? parseInt(slaArray[1]) + hoursToDays.Minutes
          : parseInt(slaArray[1]);
      Minutes = String(Minutes).length === 1 ? `0${Minutes}` : Minutes;
      let Seconds =
        String(slaArray[2]).length === 1 ? `0${slaArray[2]}` : slaArray[2];
      slaToPush = {
        days: Days,
        hours: Hours,
        minutes: Minutes,
        seconds: Seconds,
        fullSLA: fullSLA
      };
    }
    setSlaObject(slaToPush);
  }, [parentSla, states.resetSlaInBosx]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ddd" : "#fff",
      color: state.isSelected ? "#000" : "#000",
      cursor: "pointer !important"
    })
  };

  const handleSlaKeyDown = event => {
    ["e", "E", "+", "-", ".", ","].includes(event.key) &&
      event.preventDefault();
  };
  const handleSetSLA = (e, type) => {
    let slaNumber = e.target.value;
    if (isNumeric(slaNumber)) {
      slaNumber = parseInt(slaNumber, 10);
      slaNumber = String(slaNumber).length === 1 ? `0${slaNumber}` : slaNumber;
      let daysToHours = 0;
      let newHours = 0;
      let slaTotal = 0;
      switch (type) {
        case "days":
          if (parseInt(slaNumber) >= 366) {
            break;
          } else {
            daysToHours = slaNumber * 24;
            newHours = parseInt(slaObject.hours) + parseInt(daysToHours);
            slaTotal =
              newHours + ":" + slaObject.minutes + ":" + slaObject.seconds;

            setSlaObject({ ...slaObject, days: slaNumber, fullSLA: slaTotal });
            break;
          }
        case "hours":
          if (parseInt(slaNumber) >= 60) {
            break;
          } else {
            daysToHours =
              typeof slaObject.days == "undefined" ? 0 : slaObject.days * 24;
            newHours = parseInt(slaNumber) + parseInt(daysToHours);
            slaTotal =
              newHours + ":" + slaObject.minutes + ":" + slaObject.seconds;

            setSlaObject({ ...slaObject, hours: slaNumber, fullSLA: slaTotal });
            break;
          }
        case "minutes":
          if (parseInt(slaNumber) >= 60) {
            break;
          } else {
            daysToHours =
              typeof slaObject.days == "undefined" ? 0 : slaObject.days * 24;
            newHours = parseInt(slaObject.hours) + parseInt(daysToHours);
            slaTotal = newHours + ":" + slaNumber + ":" + slaObject.seconds;
            setSlaObject({
              ...slaObject,
              minutes: slaNumber,
              fullSLA: slaTotal
            });
            break;
          }
        case "seconds":
          if (parseInt(slaNumber) >= 60) {
            break;
          } else {
            daysToHours =
              typeof slaObject.days == "undefined" ? 0 : slaObject.days * 24;
            newHours = parseInt(slaObject.hours) + parseInt(daysToHours);
            slaTotal = newHours + ":" + slaObject.minutes + ":" + slaNumber;
            setSlaObject({
              ...slaObject,
              seconds: slaNumber,
              fullSLA: slaTotal
            });
            break;
          }
        default:
          break;
      }
    }
  };

  const handlePriorityChange = e => {
    const temp = { value: e.value, label: e.label };
    setPrio(temp);
  };

  const handlePriorityAndSlaApply = () => {
    dispatch({ type: "SET", key: "categorySLA", payload: slaObject.fullSLA });
    dispatch({ type: "SET", key: "categoryPriority", payload: prio });
    handleClick(target);
  };

  useEffect(() => {
    if (parentPriority) {
      const temp = { value: parentPriority.name, label: parentPriority.label };
      setPrio(temp);
    }
  }, [parentPriority]);

  useEffect(() => {
    let r = [];
    let s = [];
    if (priorities?.length) {
      priorities.map(item => r.push({ value: item.label, label: item.name }));
    }
    setPriorityItems(r);
  }, [priorities]);

  const formatPriority = priority => {
    const p = {
      value:
        priority?.label === "Very urgent" || priority?.label === "Très urgente"
          ? t("settings.tickets_priority_content.very_urgent")
          : priority?.label === "Not urgent" ||
            priority?.label === "Pas urgente"
          ? t("settings.tickets_priority_content.not_urgent")
          : t("settings.tickets_priority_content.urgent"),
      label:
        priority?.name === "High" || priority?.name === "Haute"
          ? t("settings.tickets_priority_content.hight")
          : priority?.name === "Low" || priority?.name === "Faible"
          ? t("settings.tickets_priority_content.low")
          : t("settings.tickets_priority_content.medium")
    };
    return p;
  };

  return (
    <div>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={myRef?.current}
        containerPadding={20}
      >
        <Popover id="popover-contained" className="cat-details-popover">
          <Row className="cat-details-priority-label">
            {t("settings.auto_priorization.select_priority")}
          </Row>
          <Row>
            <Select
              options={priorityItems}
              value={
                prio !== null ? prio : parentPriority ? parentPriority : ""
              }
              onChange={e => handlePriorityChange(e)}
              isSearchable={false}
              className="select-priority"
              placeholder={t("tickets.create_ticket.ticket_priority_input")}
              styles={customStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#eee",
                  primary25: "#eee"
                }
              })}
            />
          </Row>

          <Row className="cat-details-sla-label">
            {t("settings.tickets_category_content.set_sla_for_category")}
          </Row>
          <Row className="cat-details-sla-deadline">
            {t("settings.tickets_category_content.set_deadline")}
          </Row>
          <Row>
            <div className="form-row d-flex flex-nowrap mb-5">
              <div className="sla-time-div d-flex col-sm-5">
                <span className="sla-icon-span ">
                  <MdAccessTimeFilled className="sla-icon" />
                </span>
                <input
                  // type="number"
                  onKeyDown={e => handleSlaKeyDown(e)}
                  value={parseInt(slaObject.days) === 0 ? "" : slaObject.days}
                  onChange={e => handleSetSLA(e, "days")}
                  className="sla-day-input inputBox"
                  placeholder={t("settings.tickets_category_content.day")}
                ></input>
                <input
                  //   type="number"
                  onKeyDown={e => handleSlaKeyDown(e)}
                  value={parseInt(slaObject.hours) === 0 ? "" : slaObject.hours}
                  onChange={e => handleSetSLA(e, "hours")}
                  className="sla-hour-input inputBox"
                  placeholder={t("settings.tickets_category_content.hour")}
                ></input>
                <input
                  // type="number"
                  onKeyDown={e => handleSlaKeyDown(e)}
                  value={
                    parseInt(slaObject.minutes) === 0 ? "" : slaObject.minutes
                  }
                  onChange={e => handleSetSLA(e, "minutes")}
                  className="sla-minute-input inputBox"
                  placeholder={t("settings.tickets_category_content.minute")}
                ></input>
                <input
                  // type="number"
                  onKeyDown={e => handleSlaKeyDown(e)}
                  value={
                    parseInt(slaObject.seconds) === 0 ? "" : slaObject.seconds
                  }
                  onChange={e => handleSetSLA(e, "seconds")}
                  className="sla-second-input inputBox"
                  placeholder={t("settings.tickets_category_content.second")}
                ></input>
              </div>
              <div className="col-sm-3 d-flex justify-content-around"></div>
            </div>
          </Row>

          <Row>
            <button
              className="button-save-sla text-center"
              onClick={handlePriorityAndSlaApply}
            >
              <span className="save-sla-span">
                {t("settings.tickets_category_content.apply")}
              </span>
            </button>
          </Row>
        </Popover>
      </Overlay>
    </div>
  );
};

export default CreateCategoryDetailsPopover;
