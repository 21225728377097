import React from "react";
import PropTypes from "prop-types";
import DatePicker from "../../common/DatePicker";

const NewRequest = props => {
  const {
    t,
    CalendarIcon,
    MoreIcon,
    ticketStats,
    ticketTotalDateFilter
  } = props;

  const status = "new";

  return (
    <div className="column">
      <div className="card analytics-card-parent analytics-card-parent-new-request">
        <div className="card-content analytics-card">
          <p className="subtitle">{t("dashboard.total_request")}</p>
          <div className="title title-manageticket">
            <span className="statistic-ticket">{ticketStats}</span>
            <div className="arrawbtn" style={{ background: "#eb592352" }}>
              <button
                className="uptriangle"
                style={{
                  background: "#EB5923",
                  border: "none",
                  margin: "auto"
                }}
              ></button>
            </div>
          </div>
          <div className="button is-secondary is-outlined">
            {/* <img className="view-more" src={MoreIcon} alt='caneldar' /> */}
            <DatePicker
              t={t}
              getTimeFilter={(start, end) => ticketTotalDateFilter(start, end)}
            />
            <img className="calendar-icon" src={CalendarIcon} alt="caneldar" />
          </div>
        </div>
      </div>
    </div>
  );
};

NewRequest.propTypes = {
  t: PropTypes.func.isRequired,
  CalendarIcon: PropTypes.string.isRequired,
  MoreIcon: PropTypes.string.isRequired
};

export default NewRequest;
