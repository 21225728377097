/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";

// Use Socket io - import
//import io from "socket.io-client";

import { Formik, Form, Field } from "formik";
import Upload from "./upload/Upload";
//import FileUploadProgress from "react-fileupload-progress";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
// import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Select from "react-select";

import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
// import Progress from "./progress/Progress";
import SearchIcon from "../../../assets/images/profile/search.svg";
import ProfileIcon from "../../../assets/images/profile/user.svg";
import Footer from "../../layouts/Footer";
/* START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
import {
  TicketSettingsHttpService,
  CreateTicketHttpService,
  CustomTicketFieldHttpService
} from "../../../services/HttpService";
import {
  // SOCKET,
  SIO_TICKET_SETTINGS,
  SIO_CREATE_TICKET,
  REGEX_EMAIL,
  REGEX_TEXT,
  REGEX_NUMBER,
  REGEX_DATE,
  SIO_AGENT_PLATFORM,
  SIO_GET_TICKET_SUBJECT,
  // SIO_GET_TICKET_NATURE,
  SIO_GET_TICKET_NATURE_SETTINGS
} from "../../../constants/Constants";
import i18next from "i18next";
import { event } from "react-ga";
import LinkedCatList from "./LinkedCatList";
import RightComCDN from "../../../RightComCDN";
// import { CONSTANT } from "../../../constants/browser";

//const socket = io(SOCKET.BASE_URL);
/* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

function replaceMessage(retStr) {
  if (Object.keys(retStr)[0] === "_immutable") {
    return false;
  }
  String.prototype.allReplace = function(obj) {
    var retStr = this;
    for (var x in obj) {
      retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
    }
    return retStr;
  };
  return retStr.allReplace({
    "<p>": "",
    "</p>": "",
    "&nbsp;": "",
    "&nbs;": ""
  });
}

//valid Phone Number
// function testingPhone(str) {
//   return !REGEX_NUMBER.test(str)
//     ? str.replace(str.charAt(str.length - 1), "")
//     : str;
//   //return str ? str.match(REGEX_NUMBER) ? str : '':''
//   // console.log(str)
// }

//console.log(str.allReplace({'<p>': '', '</p>': '', '&nbsp;': '', '&nbs;': ''}));
class CreateTicket extends Component {
  static contextType = SharedDataContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    //
    this.state = {
      lang: "",
      ticketLang: "",
      ticketOnCreation: {},
      assegneeModalOpen: false,
      initAgents: [],
      storeInitAgents: [],
      fileToUpload: [],
      uploadedFile: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: true,
      ticketSettingsInput: [],
      prioritySetting: [],

      ticketSettingsInputActive: true,
      prioritySettingActive: true,
      allFileUploadedsuccess: false,
      dataInputTicket: [],
      ticketSubjectList: [],
      ticketNatureList: [],
      ticketSubjectSetting: {},
      ticketNatureSetting: {},
      initTicketSubjectList: [],
      initTicketNatureList: [],
      ticketSubject: {},
      ticketNature: {},
      objetTicket: "",
      priorityTicket: {},
      customFieldsData: [],
      messageTicket: EditorState.createEmpty(),

      errorValidator: [],
      checkError: false,
      addAgent: [],
      userData: {},
      validCreateTicket: false,
      searchAgent: "",

      customTicketFields: [],
      errorMessage: {}
    };
  }

  componentDidMount() {
    // Set component is mounted.
    this._isMounted = true;

    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };

    this.initSocketTicketSettings(receptor);
    this.initSocketTicketSubject(receptor);
    this.initSocketTicketNature(receptor);
    this.initSocketGetAgentPlatform(
      context.sharedDataContext.currentUser.userid,
      receptor
    );
    //
    this.GetCustomTicketFields(receptor);
    //
    this.setState({
      userData: { ...context.sharedDataContext.currentUser, checked: false },
      lang: context.sharedDataContext.defaultLang.value
    });

    //
    document.addEventListener("mousedown", this.handelClick, false);
  }

  componentWillUnmount() {
    // Set component is unmounted.
    this._isMounted = false;

    document.removeEventListener("mousedown", this.handelClick, false);
  }

  // close modam anywhere you click
  handelClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    if (this.state.assegneeModalOpen) {
      this.setState({
        assegneeModalOpen: false
      });
    }
  };

  /* START GET TICKETS SETTINGS $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
  //onSocketGetTicketSettings = response => {};

  initSocketTicketSettings = receptor => {
    const { socket } = this.props;
    socket.on(SIO_TICKET_SETTINGS, event => {
      const response = JSON.parse(event);
      // console.log("socket response : ", response);
      const { lang } = this.state;
      if (
        this._isMounted &&
        response &&
        (response.status === 200 || response.status === 202)
      ) {
        let responseDataPriority = [];

        // State property to update at the end.
        const updateState = {};

        updateState.ticketSettingsInput =
          response.data[0][`lang_${lang}`].customer_information.items;
        updateState.ticketSettingsInput =
          response.data[0][`lang_${lang}`].customer_information.items;
        updateState.ticketSettingsInputActive =
          response.data[0][`lang_${lang}`].customer_information.active;
        updateState.prioritySettingActive =
          response.data[0][`lang_${lang}`].priority.active;
        //
        responseDataPriority =
          response.data[0][`lang_${this.state.lang}`].priority.items;

        /** mise à true, car pas de champ Input */
        if (!this.state.ticketSettingsInputActive) {
          updateState.validCreateTicket = true;
        }

        // refact datas priority
        const refactPriority = [];
        responseDataPriority.map(item => {
          refactPriority.push({ value: item.name, label: item.name });
        });
        updateState.prioritySetting = refactPriority;

        this.setState(updateState);
      }
    });

    TicketSettingsHttpService.getDatasTicketSettings(receptor).catch(e =>
      console.log("[TicketSettingsHttpService.getDatasTicketSettings]", e)
    );
  };
  /* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

  /* START GET AGENT PLATFORM $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

  initSocketGetAgentPlatform = (userid, receptor) => {
    const { socket } = this.props;
    //  console.log("initSocketGetAgentPlatform ** : ");

    socket.on(SIO_AGENT_PLATFORM, event => {
      const response = JSON.parse(event);

      if (this._isMounted) {
        this.setState({
          initAgents: response.data,
          storeInitAgents: response.data
        });
      }
    });

    CreateTicketHttpService.getAgentPlatFrom(userid, receptor).catch(e =>
      console.log("[CreateTicketHttpService.getAgentPlatFrom]", e)
    );
  };
  /* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

  changeAvatar = event => {
    const image = event.target.files[0];
    const fd = new FormData();
    fd.append("image", image);
  };

  handleAddAgent = userId => {
    const updateState = {};
    //
    const updatedAgents = this.state.initAgents;
    const isExist = this.state.initAgents.findIndex(
      agent => agent.user_id === userId
    );
    for (let agent of updatedAgents) {
      agent.checked = false;
    }
    if (isExist !== -1) {
      updatedAgents[isExist].checked = true;
      //
      const add = this.state.initAgents.find(agent => agent.user_id === userId);
      //
      updateState.addAgent = [add];
      updateState.userData = { ...this.state.userData, checked: false };
    }
    updateState.initAgents = updatedAgents;

    //
    this.setState(updateState);
  };

  handleRemoveAgent = userId => {
    const updateState = {};
    const updatedAgents = this.state.initAgents;
    const isExist = this.state.initAgents.findIndex(
      agent => agent.user_id === userId
    );

    if (isExist !== -1) {
      updatedAgents[isExist].checked = false;
      const removeAgent = this.state.addAgent.filter(
        agent => agent.user_id !== userId
      );
      updateState.addAgent = removeAgent;
    }
    updateState.initAgents = updatedAgents;
    //
    this.setState(updateState);
  };

  handleAddMe = user => {
    const updatedAgents = this.state.initAgents;
    const isExist = this.state.addAgent.findIndex(
      agent => agent.user_id === user.user_id
    );

    if (isExist === -1) {
      const { userData } = this.state;
      userData.checked = true;
      for (let agent of updatedAgents) {
        agent.checked = false;
      }
      this.setState({ userData, addAgent: [userData] });
    }
  };

  handleRemoveMe = user => {
    const isExist = this.state.addAgent.findIndex(
      agent => agent.user_id === user.user_id
    );

    if (isExist !== -1) {
      const { userData } = this.state;
      userData.checked = false;

      const removeAgent = this.state.addAgent.filter(
        agent => agent.user_id !== user.user_id
      );
      this.setState({ userData, addAgent: removeAgent });
    }
  };

  handleInputChange = (event, item, type, i) => {
    const { dataInputTicket } = this.state;
    //  console.log("dataInputTicket", dataInputTicket);
    const { value } = event.currentTarget;
    dataInputTicket[i] = {
      type: item.type,
      name: item.name,
      value: value
    };
    this.setState({ dataInputTicket });
  };

  handleverification = i => {
    // console.log(i);
    const { t } = this.props;
    const { dataInputTicket } = this.state;
    const { errorValidator } = this.state;
    const inputvalue = dataInputTicket[i];

    if (inputvalue !== undefined) {
      switch (dataInputTicket[i].type.toLowerCase()) {
        case "email":
          if (
            !REGEX_EMAIL.test(dataInputTicket[i].value) &&
            dataInputTicket[i].value !== ""
          ) {
            errorValidator[i] = {
              text: t("validation_Field_form.error_field_email")
            };
            this.setState({
              ...errorValidator,
              errorValidator,
              validCreateTicket: false
            });
          } else {
            this.setState({
              errorValidator: errorValidator.map((elm, index) =>
                i === index ? null : elm
              ),
              validCreateTicket: true
            });
          }
          break;
        case "text":
          if (
            !REGEX_TEXT.test(dataInputTicket[i].value) &&
            dataInputTicket[i].value !== ""
          ) {
            errorValidator[i] = {
              text: t("validation_Field_form.error_field_text")
            };
            this.setState({ errorValidator, validCreateTicket: false });
          } else {
            this.setState({
              errorValidator: errorValidator.map((elm, index) =>
                i === index ? null : elm
              ),
              validCreateTicket: true
            });
          }
          break;
        case "number":
          if (
            (dataInputTicket[i].value !== "" &&
              dataInputTicket[i].value.length < 1) ||
            (dataInputTicket[i].value.length > 0 &&
              !REGEX_NUMBER.test(dataInputTicket[i].value))
          ) {
            errorValidator[i] = {
              text: t("validation_Field_form.error_filed_number")
            };
            this.setState({ errorValidator, validCreateTicket: false });
          } else {
            this.setState({
              errorValidator: errorValidator.map((elm, index) =>
                i === index ? null : elm
              ),
              validCreateTicket: true
            });
          }
          break;
        case "date":
          if (
            !REGEX_DATE.test(dataInputTicket[i].value) &&
            dataInputTicket[i].value !== ""
          ) {
            errorValidator[i] = {
              text: t("validation_Field_form.error_field_date")
            };
            this.setState({ errorValidator, validCreateTicket: false });
          } else {
            this.setState({
              errorValidator: errorValidator.map((elm, index) =>
                i === index ? null : elm
              ),
              validCreateTicket: true
            });
          }
          break;
        default:
          errorValidator[i] = {
            text: t("validation_Field_form.error_field_not_found")
          };
          this.setState({ errorValidator, validCreateTicket: false });
      }
    } else {
      return false;
    }
  };

  handleObjetChange = event => {
    const { value } = event.currentTarget;
    this.setState({ objetTicket: value });
  };

  handleNatureChange = event => {
    const { value } = event.currentTarget;
    this.setState({ natureTicket: value });
  };

  handlePriorityChange = value => {
    this.setState({ priorityTicket: value });
  };

  onEditorStateChange = messageTicket => {
    const valueEditor = draftToHtml(
      convertToRaw(messageTicket.getCurrentContent())
    );
    this.setState({ messageTicket: valueEditor });
  };

  handleSubmitCreateTicket() {
    const {
      dataInputTicket,
      objetTicket,
      ticketSubject,
      priorityTicket,
      messageTicket,
      ticketNature,
      fileToUpload,
      errorValidator,
      successfullUploaded,
      customFieldsData
    } = this.state;

    var newcustomFieldsData = [];
    var errorCustomFieldEmpty = false;
    this.state.customTicketFields.map((elm, index) => {
      if (!elm.default) {
        const hideItem = this.hideFormItem(elm.id);
        if (!hideItem) {
          var localError = false;
          const formItemValue = customFieldsData.filter(
            f => f.label_en === elm?.label_en && f.label_fr === elm?.label_fr
          )[0];
          if (formItemValue == "undefined" || formItemValue == null) {
            localError = true;
            errorCustomFieldEmpty = true;
          }
          if (
            formItemValue &&
            elm.mandatory &&
              (formItemValue.value == undefined ||
                formItemValue.value == "" ||
                formItemValue.value == null)
          ) {
            localError = true;
            errorCustomFieldEmpty = true;
          }
          if (!localError) {
            newcustomFieldsData.push(formItemValue);
          }
        }
      }
    });

    if (!successfullUploaded) {
      this.props.handleMessageTicket("error-file-upload-progress", "--", "--");
    } else if (
      (this.state.ticketSettingsInputActive === true &&
        dataInputTicket.length === 0) ||
      (dataInputTicket.length >= 1 &&
        (dataInputTicket[0] === undefined ||
          dataInputTicket[0].value.trim() === ""))
    ) {
      this.props.handleMessageTicket("error-Customer-field-empty", "--", "--");
    }
    //  else if (objetTicket.trim() === "") {
    //   this.props.handleMessageTicket("error-Subject-field-empty", "--", "--");
    // }
    else if (Object.keys(ticketSubject).length === 0 || ticketSubject === {}) {
      this.props.handleMessageTicket("error-Subject-field-empty", "--", "--");
    } else if (Object.keys(priorityTicket).length === 0) {
      this.props.handleMessageTicket("error-Priority-field-empty", "--", "--");
    } else if (Object.values(this.state.errorMessage).filter(e => e).length) {
      this.props.handleMessageTicket("error-invalid-field-email", "--", "--");
    } else if (
      !replaceMessage(messageTicket) ||
      (replaceMessage(messageTicket) &&
        replaceMessage(messageTicket).charCodeAt(0) === 10)
    ) {
      this.props.handleMessageTicket("error-Message-field-empty", "--", "--");
    }
    //els if(fileToUpload.length===0) this.props.handleMessageTicket("error-File-field-empty", "--", "--")
    else if (this.state.addAgent.length === 0) {
      this.props.handleMessageTicket("error-Agent-field-empty", "--", "--");
    } else if (errorValidator.join("") !== "") {
      return false;
    } else if (errorCustomFieldEmpty) {
      this.props.handleMessageTicket("error-custom-field-empty", "--", "--");
    } else {
      dataInputTicket[1] =
        typeof dataInputTicket[1] === "undefined" || dataInputTicket[1] == null
          ? {
              type: "text",
              name: this.state.lang === "fr" ? "Prénom" : "Last name",
              value: ""
            }
          : dataInputTicket[1];
      dataInputTicket[2] =
        typeof dataInputTicket[2] === "undefined" || dataInputTicket[2] == null
          ? {
              type: "number",
              name:
                this.state.lang === "fr" ? "Adresse Email" : "Email Address",
              value: ""
            }
          : dataInputTicket[2];
      dataInputTicket[3] =
        typeof dataInputTicket[3] === "undefined" || dataInputTicket[3] == null
          ? {
              type: "number",
              name: "Telephone",
              value: ""
            }
          : dataInputTicket[3];

      this.buildDataCreateTicket(
        dataInputTicket,
        objetTicket,
        ticketSubject,
        ticketNature,
        priorityTicket,
        messageTicket,
        fileToUpload,
        newcustomFieldsData
      );

      this.initSocketCreateTicket();
    }
  }

  /** Start - send customerFiled */
  buildDataCreateTicket = (
    dataInputTicket,
    objetTicket,
    ticketSubject,
    ticketNature,
    priorityTicket,
    messageTicket,
    uploadedFile,
    customFieldsData
  ) => {
    const prio = {
      name: priorityTicket.label,
      label: priorityTicket.label
    };

    const createTicket = {
      sio_channel: SIO_CREATE_TICKET,
      lang: this.state.lang,
      ticket_information: {
        // subject: objetTicket,
        subject: ticketSubject.label,
        subject_id: ticketSubject.value,
        cat_parents_ids: ticketSubject.cat_parents_ids,
        sla: ticketSubject.sla,
        nature: ticketNature,
        message: messageTicket,
        files: uploadedFile,
        priority: prio,
        custom_ticket_fields: customFieldsData,
        category: {
          label: "Technical" // Technical, Customer care, Enquires
        },
        // Ticket Nature code here
        customer_information: dataInputTicket,
        status: {
          name: "New",
          label: "Ticket without agent assign"
        },
        assigned_agent:
          this.state.addAgent.length > 0 ? this.state.addAgent[0] : {},
        created_by: {
          agent_id: this.state.userData.userid,
          first_name: this.state.userData.firstname,
          last_name: this.state.userData.lastname,
          email: this.state.userData.email,
          telephone: this.state.userData.phone
        },
        closed: false
      }
    };

    if (localStorage && createTicket) {
      localStorage.setItem(
        "sv_tmp_create_ticket",
        JSON.stringify(createTicket)
      );
    }
    // console.log("ticketSubject ", ticketSubject);
  };

  handleCreateTicketSubmit = id => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    const dataCreateTicket = JSON.parse(
      localStorage.getItem("sv_tmp_create_ticket")
    );

    // console.log("dataCreateTicket : ", dataCreateTicket);

    CreateTicketHttpService.createTicket(dataCreateTicket, receptor)
      .then(response => {
        // console.log("CreateTicketHttpService : ", response);

        if (response && response.data && response.data.status === 202) {
          this.setState({
            dataInputTicket: [],
            fileToUpload: [],
            objetTicket: "",
            messageTicket: EditorState.createEmpty()
          });

          localStorage.removeItem("sv_tmp_create_ticket");
        } else {
          this.props.handleMessageTicket("error", "--", "--");
        }
      })
      .catch(e => this.props.handleMessageTicket("error", "--", "--"));
  };

  onSocketCreateTicket = response => {
    if (response && response.status === 200) {
      this.props.handleMessageTicket(
        "success",
        response.data.number,
        response.data.id
      );
    }
  };

  initSocketCreateTicket = () => {
    const { socket } = this.props;
    socket.on(SIO_CREATE_TICKET, event => {
      const response = JSON.parse(event);
      // console.log(response);
      this.onSocketCreateTicket(response);
    });
    this.handleCreateTicketSubmit();
  };
  /** End - send customerFiled */

  initSocketTicketSubject = receptor => {
    const { socket } = this.props;
    socket.on(SIO_GET_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);

      if (this._isMounted) {
        this.onSocketGetTicketSubject(response);
      }
    });

    TicketSettingsHttpService.getTicketSubject(receptor).catch(e =>
      console.log("[TicketSettingsHttpService.getTicketSubject]", e)
    );
  };

  initSocketTicketNature = receptor => {
    const { socket } = this.props;
    socket.on(SIO_GET_TICKET_NATURE_SETTINGS, event => {
      const response = JSON.parse(event);
      if (this._isMounted) {
        this.onSocketGetTicketNature(response);
      }
    });

    TicketSettingsHttpService.getTicketNatureSettings(receptor).catch(e =>
      console.log("[TicketSettingsHttpService.getTicketNatureSettings]", e)
    );
  };

  onSocketGetTicketNature = response => {
    if (response && (response.status === 200 || response.status === 202)) {
      let r = [];
      response.data.map(item => {
        r.push({
          value: item.id,
          label: item.labels[this.state.lang],
          status: item.status
        });
      });

      this.setState({ ticketNatureList: r });
    }
  };
  onSocketGetTicketSubject = response => {
    // console.log("Ticket subject http response : ", response.data);
    if (response && (response.status === 200 || response.status === 202)) {
      let r = [];
      // console.log("response data ", response.data);
      response.data.list.map(item => {
        r.push({
          value: item.id,
          label: item.title,
          sla: typeof item.sla !== "undefined" ? item.sla : "00:00:00",
          items: typeof item.items !== "undefined" ? item.items : [],
          cat_parents_ids:
            typeof item.all_parent_id !== "undefined" ? item.all_parent_id : [],
          priority: typeof item.priority !== "undefined" ? item.priority : {}
        });
      });
      // console.log(`List of Ticket subject ${r}`);
      this.setState({
        ticketSubjectList: r,
        initTicketSubjectList: response.data.list,
        ticketSubjectSetting: response.data.settings[0]
      });
    }
  };

  handleTicketSubjectChangeOld = e => {
    // console.log("e ", e);
    this.state.initTicketSubjectList.map(item => {
      if (item.title === e.label) {
        let p = { value: item.priority.label, label: item.priority.label };

        // this.setState({ ticketSubject: e, objetTicket: e.label });
        this.setState({ ticketSubject: e });
        this.handlePriorityChange(p);
      }
    });
  };

  componentWillUpdate(nextProps, nextState) {
    // console.log(this.state);
    // if (nextState.open == true && this.state.open == false) {
    //   this.props.onWillOpen();
    // }
  }

  handleTicketSubjectChange = e => {
    if (e != null) {
      let p = { value: e.priority.label, label: e.priority.label };

      // this.setState({ ticketSubject: e, objetTicket: e.label });
      this.setState({ ticketSubject: e });
      this.handlePriorityChange(p);
    } else {
      this.setState({ ticketSubject: {} });
      this.handlePriorityChange({});
    }
  };

  handleTicketNatureChange = e => {
    this.setState({ ticketNature: e.value, natureTicket: e.label });
  };

  handleInputSearchChange = event => {
    const { value } = event.currentTarget;
    this.setState({ searchAgent: value });

    const contentSearch = value;

    if (contentSearch !== "") {
      this.setState(prevState => ({
        ...prevState,
        initAgents: this.state.storeInitAgents.filter(
          option =>
            option.firstname
              .toLowerCase()
              .includes(contentSearch.toLowerCase()) ||
            option.lastname.toLowerCase().includes(contentSearch.toLowerCase())
        )
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        initAgents: this.state.storeInitAgents
      }));
    }
  };

  //get file
  getFiles = files => {
    // console.log("files from create ticket",files)
    //  console.log("files from create ticket",files)
    this.setState({ fileToUpload: files });
  };

  fileprogresse = progresse => {
    //console.log("progree", progresse);
    let fileNotYest = [];
    fileNotYest = progresse.filter(elm => elm.loadStatus !== 4);
    return fileNotYest.length === 0
      ? this.setState({ successfullUploaded: true })
      : this.setState({ successfullUploaded: false });
  };

  GetCustomTicketFields(receptor) {
    CustomTicketFieldHttpService.getAll(receptor)
      .then(r => {
        if (r && r.data && r.data.status == 200) {
          // Set
          this.setState({ customTicketFields: r.data.data.items });
        }
      })
      .catch(e => console.log("[CustomTicketFieldHttpService.getAll]", e));
  }

  updateCustomTicketField(label_en, label_fr, type, mandatory, value) {
    // console.log("updateCustomTicketField", label_en, label_fr, type, value);
    const { customFieldsData } = this.state;
    //
    // console.log("customFieldsData", customFieldsData);
    //
    const isThere = customFieldsData.findIndex(
      f => f.label_en === label_en && f.label_fr === label_fr
    );
    if (isThere !== -1) {
      // Open Text || Options
      customFieldsData[isThere].type = type;
      // Field value.
      customFieldsData[isThere].value = value;
    } else {
      customFieldsData.push({ label_en, label_fr, type, mandatory, value });
    }
    this.setState({ customFieldsData });
  }

  hideFormItem = id => {
    const { customTicketFields, customFieldsData } = this.state;
    const isThere = customTicketFields.findIndex(f => f.id === id);
    if (isThere !== -1) {
      const item = customTicketFields[isThere];
      if (item.conditional) {
        const conditionParentIndex = customFieldsData.findIndex(
          f =>
            f.label_en === item?.conditionalField?.label_en &&
            f.label_fr === item?.conditionalField?.label_fr
        );
        if (conditionParentIndex !== -1) {
          if (
            customFieldsData[conditionParentIndex]?.value ==
              item?.conditionalFieldValue?.label &&
            item?.conditionalAction?.label == "Hidden"
          ) {
            return true;
          } else if (
            customFieldsData[conditionParentIndex]?.value !=
              item?.conditionalFieldValue?.label &&
            item?.conditionalAction?.label == "Hidden"
          ) {
            return false;
          } else if (
            customFieldsData[conditionParentIndex]?.value ==
              item?.conditionalFieldValue?.label &&
            item?.conditionalAction?.label == "Visible"
          ) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  };

  render() {
    // console.log("render create");
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#222" : "#222",
        text: "center",
        opacity: "10"
      })
    };
    const alias = window.location.hostname.split(".")[0];
    const mentionAgents = (this.state.initAgents || []).map(a => {
      return {
        text: a.firstname + " " + a.lastname,
        value: a.firstname + " " + a.lastname,
        url: `https://${alias}.admin.sunubank.tg/users/list`
      };
    });
    const suggestions = mentionAgents || [];

    // console.log("this.state.ticketSubjectList", this.state.ticketSubjectList);

    return (
      <>
        <div className="header-indicator">
          <h3 className="header-indic-title1">
            {t("tickets.details_ticket.header.ticket_table")}
          </h3>
          {" > "}
          <p className="header-indic-title2">
            {t("tickets.create_ticket.text_create")}
          </p>
        </div>
        <div className="ticketnalytics-header">
          <h2 className="dashboard-title">
            {t("tickets.create_ticket.text_create")}
          </h2>
        </div>
        <div className="columns analytics-columns createTicket-conaitner">
          <div className="section1 ">
            <div className="firstInput-container">
              <div className="header-create-ticket">
                <div className="createTicket-div">
                  <img
                    src={ProfileIcon}
                    className="profilepicture-assignee"
                    alt="agent picture"
                  />
                  <span className="createTicket-div-text">
                    {`${this.state.userData.firstname} ${this.state.userData.lastname}`}
                  </span>
                </div>
                <div className="createTicket-div">
                  <span className="createTicket-div-text">
                    {this.state.userData.email}
                  </span>
                </div>
              </div>
              <h3 className="customer-text">
                {t("tickets.create_ticket.customer_details")}
              </h3>

              <div className="input-ticket-setting">
                <Formik
                  initialValues={{
                    email: "",
                    text: "",
                    number: "",
                    date: ""
                  }}
                >
                  {({ errors, touched }) => (
                    <Form className="display-input">
                      {this.state.ticketSettingsInputActive === true &&
                        this.state.ticketSettingsInput.length !== 0 &&
                        this.state.ticketSettingsInput.map((item, i) =>
                          i < 4 ? (
                            <div className="div-input" key={i}>
                              <Field
                                className="input"
                                name={i}
                                onChange={e =>
                                  this.handleInputChange(e, item, item.type, i)
                                }
                                onBlur={() => this.handleverification(i)}
                                value={
                                  this.state.dataInputTicket[i] &&
                                  this.state.dataInputTicket[i].value
                                }
                                autoComplete="off"
                                type={
                                  item.type === "number" ? "text" : item.type
                                }
                                placeholder={
                                  item.name === "Nom"
                                    ? "Saisir le nom de famille"
                                    : item.name === "Prénom"
                                    ? "Saisir le prénom"
                                    : item.name === "Adresse email"
                                    ? "Saisir l’adresse email"
                                    : item.name === "Telephone" &&
                                      this.state.lang === "fr"
                                    ? "Saisir le numéro de téléphone"
                                    : `${t("enter_placeholder_input")} ${
                                        item.name
                                      }`
                                }
                                maxLength={96}
                              />

                              <span style={{ color: "#721c24" }}>
                                {this.state.errorValidator[i] &&
                                  this.state.errorValidator[i].text}
                              </span>
                            </div>
                          ) : (
                            false
                          )
                        )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="secontInput-container">
              <div>
                <h3 className="textInputcontainer">
                  {t("tickets.create_ticket.ticket_subject")} {"*"}
                </h3>
                {/* {this.state.ticketSubjectSetting.enable_ticket_subject ? ( */}
                {/* <Select
                  options={this.state.ticketSubjectList}
                  noOptionsMessage={({ inputValue }) =>
                    t("tickets.create_ticket.no_option_found")
                  }
                  onChange={e => this.handleTicketSubjectChange(e)}
                  isSearchable={false}
                  className="select-priority select-priority-list"
                  placeholder={t("tickets.create_ticket.select_subject")}
                  styles={customStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#eee",
                      primary25: "#eee"
                    }
                  })}
                /> */}
                <LinkedCatList
                  categories={this.state.ticketSubjectList}
                  handlerOnChange={this.handleTicketSubjectChange}
                  customStyles={customStyles}
                  t={t}
                  i18n={i18next}
                  level={0}
                />
                {/* ) : (
                  <div>
                    <input
                      className="input //createTicket-large"
                      type="text"
                      placeholder={t(
                        "tickets.create_ticket.ticket_subject_input"
                      )}
                      onChange={e => this.handleObjetChange(e)}
                      value={this.state.objetTicket}
                      autoComplete="off"
                      maxLength={96}
                    />
                  </div>
                )} */}
              </div>

              {this.state.prioritySettingActive === true && (
                <div>
                  <h3 className="textInputcontainer">
                    {t("tickets.create_ticket.ticket_priority")} {"*"}
                  </h3>
                  <div>
                    <Select
                      options={this.state.prioritySetting}
                      value={this.state.priorityTicket}
                      onChange={e => this.handlePriorityChange(e)}
                      isSearchable={false}
                      isDisabled={true}
                      className="ticket-Select"
                      placeholder={t(
                        "tickets.create_ticket.ticket_priority_input"
                      )}
                      styles={customStyles}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#eee",
                          primary25: "#eee"
                        }
                      })}
                    />

                    {/* this.state.ticketSubjectSetting.enable_ticket_subject ? (
                      <Select
                        options={this.state.prioritySetting}
                        value={this.state.priorityTicket}
                        onChange={e => this.handlePriorityChange(e)}
                        isSearchable={false}
                        isDisabled={true}
                        className="ticket-Select"
                        placeholder={t(
                          "tickets.create_ticket.ticket_priority_input"
                        )}
                        styles={customStyles}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#eee",
                            primary25: "#eee"
                          }
                        })}
                      />
                    ) : (
                      <Select
                        options={this.state.prioritySetting}
                        onChange={e => this.handlePriorityChange(e)}
                        isSearchable={false}
                        className="ticket-Select select-priority-list"
                        placeholder={t(
                          "tickets.create_ticket.ticket_priority_input"
                        )}
                        styles={customStyles}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#eee",
                            primary25: "#eee"
                          }
                        })}
                      />
                    ) */}
                  </div>
                </div>
              )}
              {/*Start Ticket Nature code here */}

              <div>
                <h3 className="textInputcontainer">
                  {t("tickets.create_ticket.ticket_nature")} {"*"}
                </h3>
                <Select
                  options={this.state.ticketNatureList.filter(
                    nature => nature.status !== 0
                  )}
                  noOptionsMessage={({ inputValue }) =>
                    t("tickets.create_ticket.no_option_found")
                  }
                  onChange={e => this.handleTicketNatureChange(e)}
                  isSearchable={false}
                  className="select-priority select-nature-list"
                  placeholder={t("tickets.create_ticket.ticket_nature_input")}
                  styles={customStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#eee",
                      primary25: "#eee"
                    }
                  })}
                />
              </div>

              {/*End Ticket Nature code here */}

              {/* Start custom field */}

              {this.state.customTicketFields.map((field, key) => {
                // console.log(key, field);
                if (!field.default) {
                  const hideItem = this.hideFormItem(field["id"]);

                  if (field.field_type.name == "Open Text") {
                    const currentValue = this.state.customFieldsData[key]
                      ? this.state.customFieldsData[key].value
                      : "";
                    return (
                      <div key={key} hidden={hideItem}>
                        <h3 className="textInputcontainer">
                          {field[`label_${this.state.lang}`]}{" "}
                          {field.mandatory && "*"}
                        </h3>
                        <input
                          disabled={hideItem ? "disabled" : ""}
                          className="input //createTicket-large"
                          type={field.field_type.type}
                          placeholder={
                            (i18next.language === "en" ? "Enter " : "Entrer ") +
                            field[`label_${this.state.lang}`]
                          }
                          required={field.mandatory}
                          autoComplete="off"
                          defaultValue={currentValue}
                          onChange={e => {
                            if (e.target.value.length <= 96) {
                              this.updateCustomTicketField(
                                field.label_en,
                                field.label_fr,
                                field.field_type.name,
                                field.mandatory,
                                e.target.value
                              );

                              if (field.field_type.type === "email") {
                                //@TODO check if email
                                const regex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
                                let isEmail = regex.test(e.target.value);
                                this.setState({
                                  errorMessage: {
                                    [key]: isEmail
                                      ? undefined
                                      : t(
                                          "validation_Field_form.error_field_email"
                                        )
                                  }
                                });
                              }
                              if (field.field_type.type === "number") {
                                //@TODO check if number
                                const regex = /[0-9]+/g;
                                let isNumber = regex.test(e.target.value);
                                this.setState({
                                  errorMessage: {
                                    [key]: isNumber
                                      ? undefined
                                      : t(
                                          "validation_Field_form.error_filed_number"
                                        )
                                  }
                                });
                              }
                            } else return false;
                          }}
                          maxLength={96}
                          onKeyDown={evt => {
                            let v = this.state.customFieldsData.filter(
                              f => f.label_en === field.label_en
                            );
                            //console.log(v[0]?.value.length)
                            if (v[0]?.value.length >= 96) evt.preventDefault();

                            if (field.field_type.type === "number") {
                              evt.key === "e" && evt.preventDefault();
                            }
                            if (field.field_type.type === "number") {
                              const regex = [
                                "0",
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "ArrowRight",
                                "ArrowLeft",
                                "ArrowUp",
                                "ArrowDown",
                                "Enter",
                                "Backspace",
                                "Delete"
                              ];
                              let x = regex.includes(evt.key);
                              !x && evt.preventDefault();
                              // console.log(evt.key);
                            }
                            if (field.field_type.type === "number") {
                              evt.key === "¨" && evt.preventDefault();
                            }

                            // if (field.field_type.type === "email") {
                            //     const regex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
                            //     let isEmail =  regex.test(email);
                            //     console.log(isEmail)
                            // }
                          }}
                        />
                        <span className={"text-danger"}>
                          <small>{this.state.errorMessage[key]}</small>
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div key={key} hidden={hideItem}>
                        <h3 className="textInputcontainer">
                          {field[`label_${this.state.lang}`]}{" "}
                          {field.mandatory && "*"}
                        </h3>
                        <Select
                          options={field.field_type.options.map(o => {
                            return {
                              value: o[i18next.language],
                              label: o[i18next.language]
                            };
                          })}
                          noOptionsMessage={_ => "No option found!"}
                          onChange={e => {
                            this.updateCustomTicketField(
                              field.label_en,
                              field.label_fr,
                              field.field_type.name,
                              field.mandatory,
                              e.value
                            );
                          }}
                          isSearchable={false}
                          className="select-priority select-nature-list"
                          placeholder={
                            t("translations.select") +
                            field[`label_${this.state.lang}`]
                          }
                          styles={customStyles}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#eee",
                              primary25: "#eee"
                            }
                          })}
                        />
                        <span className={"text-danger"}>
                          <small>{this.state.errorMessage[key]}</small>
                        </span>
                      </div>
                    );
                  }
                }
              })}

              {/* End of custom field */}

              <div>
                <h3 className="textInputcontainer">
                  {t("tickets.create_ticket.ticket_message")} {"*"}
                </h3>
                <Editor
                  // onChange={(e) => this.handleMessageChange(e)}
                  messageTicket={this.state.messageTicket}
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    fontSize: { className: "fontSizetoolbar" },
                    fontFamily: { className: "fontFamilytoolbar" },
                    textAlign: { inDropdown: true },
                    link: { className: "linktoolbar" },
                    emoji: { className: "emojitoolbar" },
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: false
                    },
                    remove: { className: "removetoolbar" },
                    blockType: { className: "blockTypetoolbar" },
                    embedded: { className: "embeddedtoolbar" },
                    inline: {
                      strikethrough: { className: "strikethroughtoolbar" },
                      monospace: { className: "monospacetoolbar" }
                    },
                    list: {
                      indent: { className: "indenttoolbar" },
                      outdent: { className: "outdenttoolbar" }
                    }
                  }}
                  mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: suggestions
                  }}
                  toolbarCustomButtons={[<RightComCDN />]}
                />
              </div>

              <button
                className="Submit-ticketbtn"
                onClick={() => this.handleSubmitCreateTicket()} //
              >
                {t("tickets.create_ticket.ticket_btn_submit")}
              </button>
            </div>
          </div>
          <div className="section2">
            <Upload
              t={t}
              getfileListe={files => this.getFiles(files)}
              fileprogresse={progress => this.fileprogresse(progress)}
              handleMessageTicket={(status, data, idNewTicket) =>
                this.props.handleMessageTicket(status, data, idNewTicket)
              }
            />

            {/* modal assign agent */}
            <div className="assegnee-Container">
              <div className="assign-text-Contain">
                <p style={{ color: "#657288", marginRight: "20px" }}>
                  {t("tickets.create_ticket.assignee")}
                </p>
                <div className="assign-agent-Container">
                  <span
                    className=" assign-agent-btn"
                    onClick={() =>
                      this.setState({
                        assegneeModalOpen: !this.state.assegneeModalOpen
                      })
                    }
                  >
                    +
                  </span>

                  {/* Modal add agent */}
                  <div
                    className="assign-text-modal"
                    style={{
                      display: `${
                        this.state.assegneeModalOpen ? "flex" : "none"
                      }`
                    }}
                    ref={node => (this.node = node)}
                  >
                    <h2 className="title assign-modal-title">
                      {t("tickets.create_ticket.assign_agent_ticket")}
                    </h2>
                    <ul className="menu-list menu-list-ticket">
                      {" "}
                      <li className="assign-self">
                        <img src={ProfileIcon} alt="portrait" />
                        <span className="user-name">
                          {t("tickets.create_ticket.assign_ticket_myself")}
                        </span>
                        {this.state.userData.checked ||
                        this.state.userData.checked === true ? (
                          <span
                            className="remove-user"
                            onClick={e =>
                              this.handleRemoveMe(this.state.userData)
                            }
                          >
                            -
                          </span>
                        ) : (
                          <span
                            className="add-user"
                            onClick={e => this.handleAddMe(this.state.userData)}
                          >
                            +
                          </span>
                        )}
                      </li>
                    </ul>
                    <div className="search-box assign-search">
                      <input
                        className="input"
                        type="text"
                        placeholder={t(
                          "tickets.create_ticket.search_agent_input"
                        )}
                        value={this.state.searchAgent}
                        onChange={e => this.handleInputSearchChange(e)}
                        maxLength={96}
                      />
                      <img src={SearchIcon} alt="search" />
                    </div>
                    {this.state.storeInitAgents.length === 0 ? (
                      <div>
                        {t("tickets.create_ticket.search.user_list_empty")}
                      </div>
                    ) : (
                      this.state.initAgents.length === 0 && (
                        <div>
                          {t("tickets.create_ticket.search.user_not_found")}
                        </div>
                      )
                    )}
                    <section
                      className="modal-card-body"
                      style={{ width: "100%" }}
                    >
                      {/*  Composant list add Agent Create Ticket */}
                      <ul className=" menu-list menu-list-ticket">
                        {this.state.initAgents &&
                          this.state.initAgents.map((item, i) => (
                            <li key={i}>
                              <img src={ProfileIcon} alt="portrait" />
                              <span className="user-name">
                                {item.firstname} {item.lastname}
                              </span>
                              {item.checked || item.checked === true ? (
                                <span
                                  className="remove-user"
                                  onClick={e =>
                                    this.handleRemoveAgent(item.user_id)
                                  }
                                >
                                  -
                                </span>
                              ) : (
                                <span
                                  className="add-user"
                                  onClick={e =>
                                    this.handleAddAgent(item.user_id)
                                  }
                                >
                                  +
                                </span>
                              )}
                            </li>
                          ))}
                      </ul>
                      {/*  */}
                    </section>
                    <footer className="assign-modal-footer">
                      <button
                        className="button is-primary"
                        aria-label="close"
                        style={{ width: "100%" }}
                        onClick={() =>
                          this.setState({
                            assegneeModalOpen: !this.state.assegneeModalOpen
                          })
                        }
                      >
                        {t("tickets.create_ticket.Assign")}
                      </button>
                    </footer>
                  </div>
                  {/* End Modal add agnet */}
                </div>
              </div>
            </div>
            {/* ** ** */}

            {/* Affich add agent */}
            <div style={{ width: "100%" }}>
              {/* <h2
                className="title assign-modal-title align-point-text"
                style={{ paddingTop: "2rem" }}
              >
                <div className="black-point-head">&nbsp;</div>
                {t("tickets.create_ticket.assign_agent_added")}
              </h2> */}
              <section className="//modal-card-body" style={{ width: "100%" }}>
                <ul className="menu-agent-added menu-list-ticket">
                  {this.state.addAgent &&
                    this.state.addAgent.map((item, i) => (
                      <li key={i}>
                        <img src={ProfileIcon} alt="portrait" />
                        <span
                          className="user-name"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.firstname} {item.lastname}
                        </span>
                      </li>
                    ))}
                </ul>
              </section>

              {/* //<hr style={{ color: "#eee" }} /> */}
            </div>
            {/* End Affich add agnet */}
          </div>
        </div>
        <Footer t={t} />
      </>
    );
  }
}

CreateTicket.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  handleMessageTicket: PropTypes.func.isRequired
};

const CreateTicketWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <CreateTicket {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);

export default CreateTicketWithSocket;
