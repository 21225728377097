import React, { Component } from "react";
//import './pagination.css'

const FilterPagination = props => {
  const {
    from,
    count,
    total,
    onPrev,
    onNext,
    onChangeCount,
    t,
    totalPage,
    gotToPageOnsearch
  } = props;

  // console.log('total : ',total)
  const listPagination = nbpagination => {
    let tab = [];
    for (let i = 1; i <= nbpagination; i++) {
      tab.push(i);
    }
    return tab.map((elm, i) => (
      <button
        key={i}
        className="pageNumberbtn"
        onClick={() => gotToPageOnsearch(i)}
        style={{
          background: `${
            Math.floor(from / count) === i ? "#C8D3D6" : "#FFFFFF"
          }`
        }}
      >
        {elm}
      </button>
    ));
  };
  return (
    <div className="pagination-container">
      {/* showing items {from} - {Math.min(total, from + count)} of {total}. */}
      {/* <input type="button" value="< Prev" onClick={onPrev} />
        <input type="button" value="Next >" onClick={onNext} /> */}
      <button
        className="previousButton"
        onClick={onPrev}
        disabled={total === 0 || from === 0}
        style={{
          color: `${total === 0 || from === 0 ? "#C8D3D6" : "#4C4C4C"}`
        }}
      >
        {t("tickets.table_list.prePage")}
      </button>
      <div>{listPagination(total)}</div>
      <button
        className="nextButton"
        onClick={onNext}
        disabled={total === 0 || from + count >= totalPage}
        style={{
          color: `${
            total === 0 || from + count >= totalPage ? "#C8D3D6" : "#4C4C4C"
          }`
        }}
      >
        {t("tickets.table_list.nextPage")}
      </button>
    </div>
  );
};

export default FilterPagination;
