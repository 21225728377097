import React from "react";
import PropTypes from "prop-types";

const CustomerInformationContent = props => {
  const { t, handleAddRessourceModal, customerFieldsItems } = props;

  return (
    <>
      <div className="ticket-content">
        <div className="ticket-priorities" style={{ flexWrap: "wrap" }}>
          <div
            className="ticket-prio-item-Container"
            style={{ height: "50px" }}
          >
            <div className="ticket-prio-item">
              <span className="ticket-prio-item-text">
                {t("settings.customer_informations_content.first_name")}
              </span>
            </div>
          </div>

          <div
            className="ticket-prio-item-Container"
            style={{ height: "50px" }}
          >
            <div className="ticket-prio-item">
              <span className="ticket-prio-item-text">
                {t("settings.customer_informations_content.last_name")}
              </span>
            </div>
          </div>

          <div
            className="ticket-prio-item-Container"
            style={{ height: "50px" }}
          >
            <div className="ticket-prio-item">
              <span className="ticket-prio-item-text">
                {t("settings.customer_informations_content.email")}
              </span>
            </div>
          </div>

          <div
            className="ticket-prio-item-Container"
            style={{ height: "50px" }}
          >
            <div className="ticket-prio-item">
              <span className="ticket-prio-item-text">
                {t("settings.customer_informations_content.telephone")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CustomerInformationContent.propTypes = {
  t: PropTypes.func.isRequired,
  handleAddRessourceModal: PropTypes.func.isRequired,
  customerFieldsItems: PropTypes.arrayOf.isRequired
};

export default CustomerInformationContent;
