import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import CreateCategory from "./CreateCategory";
import Category from "./Category";
import { BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CategoryLevel from "./CategoryLevel";
import { isFullScreen } from "../../../../utils/general";
import Loading from "./Loading";
const CategoryList = props => {
  // const [categories, setCategories] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);

  const {
    t,
    i18n,
    ticketSubject,
    handleCreateTicketSubject,
    handleUpdateTicketSubject,
    handleDeleteTicketSubject,
    priority,
    nature
  } = props;

  useEffect(() => {
    dispatch({ type: "SET", key: "priorities", payload: priority });
    setPriorities(priority);
  }, [priority]);

  useEffect(() => {
    dispatch({ type: "SET", key: "categories", payload: [] });
    dispatch({ type: "SET", key: "categories", payload: ticketSubject });
  }, [ticketSubject]);
  const parentRef = useRef();

  // console.log('ticketSubject', ticketSubject);

  useEffect(() => {
    if (
      typeof parentRef != "undefined" &&
      typeof states?.parentRef == "undefined"
    ) {
      // console.log('parentRef ', parentRef)
      dispatch({ type: "SET", key: "parentRef", payload: parentRef });
    }
  }, [parentRef]);

  return (
    <div
      id={isFullScreen() ? "CategoryListDivFullScreen" : "CategoryListDiv"}
      className="category-list"
      style={{
        overflowX: "auto",
        minHeight: "500px",
        marginBottom: "40px",
        paddingBottom: "320px"
      }}
      ref={parentRef}
    >
      {states?.categories && states?.categories.length >= 0 ? (
        <CategoryLevel
          t={t}
          i18n={i18n}
          level={0}
          parentList={[]}
          categories={states.categories}
          priorities={states.priorities}
          handleCreateTicketSubject={handleCreateTicketSubject}
          handleUpdateTicketSubject={handleUpdateTicketSubject}
          handleDeleteTicketSubject={handleDeleteTicketSubject}
          priority={priority}
          nature={nature}
          parentSla="00:00:00"
          parentPriority={null}
        />
      ) : (
        <span style={{ textAlign: "center", height: "100%" }}>
          <Loading />
        </span>
      )}

      {/*       <CategoryLevel
        t={t}
        i18n={i18n}
        level={0}
        parentList={[]}
        categories={states.categories}
        priorities={states.priorities}
        handleCreateTicketSubject={handleCreateTicketSubject}
        handleUpdateTicketSubject={handleUpdateTicketSubject}
        handleDeleteTicketSubject={handleDeleteTicketSubject}
        priority={priority}
        nature={nature}
        parentSla="00:00:00"
        parentPriority={null}
      /> */}
    </div>
  );
};

export default CategoryList;
