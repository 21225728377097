import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Modal } from "react-bootstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const { deleteTicket, t, container } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(props.handleClickOpen);
  };

  const handleClose = () => {
    setOpen(!props.handleClickOpen);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      {/*       <Dialog
        open={props.handleClickOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.dialogAlertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleCloseAccepte()} color="primary">
            {t("tickets.delete_ticket.delete_ticket_agree")}
          </Button>
          <Button onClick={() => props.handleClose()} color="primary">
            {t("tickets.delete_ticket.delete_ticket_disagree")}
          </Button>
        </DialogActions>
      </Dialog> */}

      <Modal
        container={container}
        show={props.handleClickOpen}
        onHide={() => props.handleClose()}
        backdrop={true}
        // keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ border: "0px" }}
      >
        <Modal.Body style={{ maxWidth: "600px", border: "0px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            {props.dialogAlertMessage}
          </DialogContentText>
        </Modal.Body>
        <DialogActions>
          <Button onClick={() => props.handleCloseAccepte()} color="primary">
            {t("tickets.delete_ticket.delete_ticket_agree")}
          </Button>
          <Button onClick={() => props.handleClose()} color="primary">
            {t("tickets.delete_ticket.delete_ticket_disagree")}
          </Button>
        </DialogActions>
      </Modal>
    </div>
  );
}
