/* Importation */
import uuid1 from "uuid/v1";
import Cookies from "universal-cookie";

const cookies = new Cookies();
/* eslint-disable no-nested-ternary */
export const APP_ENV = "PROD"; // DEV, PROD, LOCAL, STAGING
export const DEBUG = true;
export const TIMEOUT_HTTP_REQUEST = 3000;
export const TIMEOUT_SOCKET_RESPONSE = 5000;

export const PROTOCOL = "https";

export const RC_API_BASE_DOMAIN_PROD = "rightcareapi.sunubank.tg";
export const RC_API_BASE_DOMAIN_DEV = "betarcapi.right-com.com";
export const RC_API_BASE_DOMAIN_LOCAL = "10.10.14.116:715";
export const RC_API_BASE_DOMAIN_STAGING = "rcapi-staging.right-com.com";
export const RC_API_SERVICE_PATH = "/api/v1";

export const RC_SOCKET_BASE_DOMAIN_PROD = "wsrightcom.sunubank.tg";
export const RC_SOCKET_BASE_DOMAIN_DEV = "wsrightcom.rightcomtech.com";
export const RC_SOCKET_BASE_DOMAIN_LOCAL = "10.10.14.116:714";
export const RC_SOCKET_BASE_DOMAIN_STAGING = "wsrightcom-staging.right-com.com";
export const RC_SOCKET_SERVICE_PATH = "/myrc";

export const API = {
  BASE_URL:
    APP_ENV === "DEV"
      ? `${PROTOCOL}://${RC_API_BASE_DOMAIN_DEV}${RC_API_SERVICE_PATH}`
      : APP_ENV === "PROD"
      ? `${PROTOCOL}://${RC_API_BASE_DOMAIN_PROD}${RC_API_SERVICE_PATH}`
      : APP_ENV === "LOCAL"
      ? `${PROTOCOL}://${RC_API_BASE_DOMAIN_LOCAL}${RC_API_SERVICE_PATH}`
      : APP_ENV === "STAGING"
      ? `${PROTOCOL}://${RC_API_BASE_DOMAIN_STAGING}${RC_API_SERVICE_PATH}`
      : ""
  // SIGNIN: '/signin',
  // CHECK_SESSION: '/decoder',
};

export const SOCKET = {
  BASE_URL:
    APP_ENV === "DEV"
      ? `${PROTOCOL}://${RC_SOCKET_BASE_DOMAIN_DEV}${RC_SOCKET_SERVICE_PATH}`
      : APP_ENV === "PROD"
      ? `${PROTOCOL}://${RC_SOCKET_BASE_DOMAIN_PROD}${RC_SOCKET_SERVICE_PATH}`
      : APP_ENV === "LOCAL"
      ? `${PROTOCOL}://${RC_SOCKET_BASE_DOMAIN_LOCAL}${RC_SOCKET_SERVICE_PATH}`
      : APP_ENV === "STAGING"
      ? `${PROTOCOL}://${RC_SOCKET_BASE_DOMAIN_STAGING}${RC_SOCKET_SERVICE_PATH}`
      : ""
};

export const NAVIGATION = {
  HOME: "/",
  LOGIN: "/login",
  ONBOARDING: "/onboard",
  DASHBOARD: "/dashboard",

  APP: "/app",
  APP_DASHBOARD: "/app/dashboard",
  APP_ONBOARDING: "/app/onboard",

  NOT_FOUND: "/404",
  FORBIDDEN: "/403",
  INTERNAL_ERROR: "/500"
};

export const CONSTANTS_LANG = {
  LOCAL_STORAGE_LANG_KEY: "RC_LANG_KEY",
  LANGUAGES: [
    {
      key: "en",
      name: "english"
    },
    {
      key: "fr",
      name: "french"
    }
  ]
};

export const REGEX_EMAIL = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[A-Z]{2,4}$/i;
export const REGEX_TEXT = /\s*[a-zA-Z0-9._àäâçéèêëîïôöùûü\s]+\s*$/i;
export const REGEX_NUMBER = /^(\+?\d)(\d*)$/;
export const REGEX_FILE_NAME = /^[a-zA-Z0-9._-\s]+$/i;
export const REGEX_DATE = /^\d{1,2}\/\d{1,2}\/\d{4}$/i;

export const SIO_TICKET_SETTINGS = `ticket_setting_${uuid1()}`;
export const SIO_CREATE_CUSTOMER_TICKET_SETTINGS = `create_customer_ticket_setting_${uuid1()}`;
export const SIO_UPDATE_SWITCH_TICKET_SETTINGS = `update_switch_ticket_setting_${uuid1()}`;
export const SIO_UPDATE_SWITCH_ENABLE_TICKET_CUSTOM = `update_switch_enable_ticket_custom_${uuid1()}`;
export const SIO_CREATE_TICKET_SUBJECT = `create_ticket_subject_${uuid1()}`;
export const SIO_UPDATE_TICKET_SUBJECT = `update_ticket_subject_${uuid1()}`;
export const SIO_GET_TICKET_SUBJECT = `get_ticket_subject_${uuid1()}`;
export const SIO_GET_TICKET_SUBJECT_SETTINGS = `get_ticket_subject_settings_${uuid1()}`;
export const SIO_DELETE_TICKET_SUBJECT = `delete_ticket_subject_${uuid1()}`;

export const SIO_CREATE_TICKET = `create_ticket_${uuid1()}`;
export const SIO_TICKET_STATS = `ticket_stats${uuid1()}`;
export const SIO_AGENT_FORM_XP = `agent_from_xp_${uuid1()}`;
export const SIO_AGENT_SINCE_CARE = `save_agent_since_care_${uuid1()}`;
export const SIO_AGENT_PLATFORM = `agent_platform_${uuid1()}`;
export const SIO_DELETE_AGENT_PLATFORM = `delete_agent_platform_${uuid1()}`;
export const SIO_GET_TICKET = `all_ticket_${uuid1()}`;
export const SIO_GET_TICKET_DETAILS = `ticket_details${uuid1()}`;
export const SIO_TICKET_UPDATE = `ticket_update${uuid1()}`;
export const SIO_GET_LATEST_TICKET = `ticket_latest${uuid1()}`;
export const SIO_TICKET_CHART = `ticket_chart_stats${uuid1()}`;
export const SIO_TICKET_INFO = `ticket_infos_filter${uuid1()}`;
export const SIO_TICKET_DELETE = `ticket_delete${uuid1()}`;

export const SIO_CREATE_TICKET_NATURE = `create_ticket_nature${uuid1()}`;
export const SIO_UPDATE_TICKET_NATURE = `update_ticket_nature${uuid1()}`;
export const SIO_DELETE_TICKET_NATURE = `delete_ticket_nature${uuid1()}`;
export const SIO_GET_TICKET_NATURE = `get_ticket_nature${uuid1()}`;
export const SIO_GET_TICKET_NATURE_SETTINGS = `get_ticket_nature_settings${uuid1()}`;
//
export const APISID = cookies.get("APISID");
export const SUBDOMAIN = window.location.hostname.split(".")[0];

export const LOCAL_API = {
  CHECK_SESSION:
    "https://accapi-v1.sunubank.tg/api/decoder?app_id=rightcare&alias=" +
    SUBDOMAIN +
    "&sid=" +
    APISID,
  _CHECK_SESSION: "/api/decoder",
  LOGOUT:
    "https://" +
    SUBDOMAIN +
    ".account.sunubank.tg/login?continue=" +
    window.location,
  _LOGOUT: "/api/logout"
};
