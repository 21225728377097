import React, {useState} from "react";
import {EditorState, Modifier, AtomicBlockUtils} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';

const RightComCDN = props => {
    const insertInEditor = (cdnUrl, name) => {
        const {editorState, onChange} = props;

        // const entityKey = editorState
        //     .getCurrentContent()
        //     .createEntity("LINK", "MUTABLE", {
        //         url: cdnUrl, text:name, name:name, title:name
        //     })
        //     .getLastCreatedEntityKey();

        const entityKey = editorState
            .getCurrentContent()
            .createEntity('MENTION', 'IMMUTABLE', {text: "   " +name, value: name, url: cdnUrl})
            .getLastCreatedEntityKey();

        // const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        //     editorState,
        //     entityKey,
        //     name
        // );
        //
        // onChange(newEditorState);
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            name,
            editorState.getCurrentInlineStyle(),
            entityKey
        );
        onChange(EditorState.push(editorState, contentState, "insert-characters"));


    };
    const uploadToCdn = e => {
        let url = " https://rccdn.sunubank.tg/api/1.0/upload/normal"
        const theFile = e.target.files[0]
        const time = new Date().getTime();

        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Dcfb51409eb9f42f8b87e2f0248573f03D");

        let formdata = new FormData();

        formdata.append("file", theFile, "rc_ticket_" + time + "_" + theFile?.name);
        formdata.append("file_type", theFile?.type);
        formdata.append("app_name", "rightcare");
        formdata.append("serviceId", "rightcare");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    insertInEditor(
                        "https://rccdn.sunubank.tg/api/1.0/download?app_name=rightcare&file_id=" + result.data.file_details.file_id + "&as_attachment=0",
                        theFile?.name
                    )
                }

            })
            .catch((error) => console.log(error))
    }
    const openUploadDialog = e => {
        document.querySelector("#commnetFileUpload").click();
    }
    return <div onClick={openUploadDialog}>
        <div
            style={{
                padding: "0px 3px",
                border: "solid 1px #eee",
                cursor: "pointer",
                marginBottom: 6
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#333"
                className="bi bi-paperclip"
                viewBox="0 0 16 16"
            >
                <path
                    d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
            </svg>
            <div style={{width: 0, height: 0, overflow: "hidden"}}>
                <input type="file" id={"commnetFileUpload"} onChange={uploadToCdn}/>
            </div>
        </div>
    </div>
}

export default RightComCDN
