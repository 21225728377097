import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ProfileIcon from "../../../assets/images/profile/user.svg";
import { SharedDataContext } from "../../app/UseContext";
const LiveActivity = props => {
  const { t, img, title, time, status, index, number } = props;
  const { sharedDataContext } = useContext(SharedDataContext);

  const [date, setDate] = useState("");
  let now = moment();
  let updated = moment(time);
  const days = now.diff(updated, "days");
  const hours = now.subtract(days, "days").diff(updated, "hours");
  const minutes = now.subtract(hours, "hours").diff(updated, "minutes");

  useEffect(() => {
    if (sharedDataContext.defaultLang) {
      setDate(sharedDataContext.defaultLang.value);
    }
    return () => {
      // cleanup
    };
  }, [sharedDataContext]);
  //console.log(days, hours, minutes);

  return (
    <div
      className={`column-content column-content-o content-child-${index}`}
      style={{ width: "100%", flex: "1", position: "relative" }}
    >
      <div className="activity-a">
        <img src={ProfileIcon} alt="Picture" className="activity-img" />
      </div>
      <div className="activity-b">
        {/* <p className="activity-text">Ticket : {number}</p> */}
        <p className="activity-text">{title}</p>

        <p className="activity-time">
          {date === "en"
            ? days !== 0
              ? days + " days ago"
              : hours === 0 || hours === 1
              ? minutes + " min ago"
              : hours + " hours ago"
            : days !== 0
            ? `Il y a ${days} jour(s)`
            : hours === 0 || hours === 1
            ? `Il y a ${minutes} minute(s)`
            : `Il y a ${hours} heure(s)`}
        </p>
      </div>
      <div className="activity-c" style={{ position: "absolute", right: 0 }}>
        <span className="activity-badge">{status}</span>
      </div>
    </div>
  );
};

LiveActivity.propTypes = {
  t: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default LiveActivity;
