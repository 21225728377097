import React from "react";

import EditCustomField from "../../../../../assets/images/settings/edit-custom-field.svg";
import DeleteCustomField from "../../../../../assets/images/settings/delete-custom-field.svg";

const CustomFieldContainer = props => {
  const {
    t,
    data,
    defaultLang,
    handleModalView,
    loadModalData,
    launchConfirmActionModal
  } = props;

  const editField = () => {
    // Load data in the modal.
    loadModalData(data);
    // Show modal.
    handleModalView();
  };

  const confirmRemoveField = () => {
    // Open action confirmation modal.
    launchConfirmActionModal(data.id, data[`label_${defaultLang}`]);
  };
  return (
    <div key={data.id} className="custom-field-container">
      <label htmlFor="">{t("translations.field_name")}</label>
      <div className="custom-field-row">
        <div className="custom-field-block-text">
          <div className="field-input">
            <input
              maxLength={128}
              key={data.id}
              type="text"
              disabled
              readOnly
              value={defaultLang === "fr" ? data.label_fr : data.label_en}
            />
          </div>
          <div className="field-nature">{t(data.field_type.name)}</div>
        </div>
        <div className="custom-field-block-action">
          <div onClick={editField}>
            <img
              src={EditCustomField}
              alt="Edit"
              srcSet=""
              className="action-img"
            />
          </div>
          {!data.default && (
            <div onClick={confirmRemoveField}>
              <img
                src={DeleteCustomField}
                alt="Delete"
                srcSet=""
                className="action-img"
              />
            </div>
          )}
        </div>
      </div>
      {/* <div>
                {JSON.stringify(data)}
            </div> */}
      <p className="mt-2 mb-3">
        <span className="mr-1">{t("translations.mark_mandatory")}</span>
        <input
          name="name"
          type="checkbox"
          checked={data.mandatory}
          onChange={() => {}}
        />
      </p>
    </div>
  );
};

export default CustomFieldContainer;
