import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import PersonIcon from "../../../../assets/images/profile/idpic.png";
import HelpIcon from "../../../../assets/images/profile/helper-icon.png";
import i18nConfig from "../../../../configs/i18nConfig";

const ParseText = (text, data = {}) => {
  return text;
};

export const RobotMessage = props => {
  const ByPass = e => {
    props.callback(null);
  };

  return (
    <div className="cf-robot-app">
      <div className="person-icon">
        <img src={PersonIcon} alt="Person" className="robot-image" />
      </div>
      <div className="message-bubble">
        <div
          className="speech-bubble"
          style={props.close && { borderRight: "1px solid #e0e0e0" }}
        >
          {ParseText(props.text)}
        </div>
        {props.close && (
          <div className="btn-bubble" onClick={ByPass}>
            &times;
          </div>
        )}
      </div>
    </div>
  );
};

export const UserMessage = props => {
  const htmlElement = () => {
    if (props.type == "textarea") {
      return textarea();
    } else if (/^(text)|(email)$/.test(props.type)) {
      return input();
    } else if (props.type == "phone") {
      return <PhoneInput value={props.data} onChange={props.callback} />;
    } else if (props.type == "file") {
      return (
        <Form.File
          id="inputGroupFile01"
          label={props.data.name}
          data-browse="Upload File"
          onChange={props.callback}
          custom
        />
      );
    } else if (props.type == "select") {
      return select();
    } else {
      return "";
    }
  };

  const textarea = () => {
    return (
      <textarea
        className="textarea"
        cols="30"
        rows="5"
        value={props.data}
        onChange={props.callback}
      />
    );
  };

  const input = () => {
    return (
      <input
        className="input"
        type={props.type}
        required={true}
        value={props.data}
        onChange={props.callback}
      />
    );
  };

  const select = () => {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        textTransform: "uppercase",
        fontSize: "13px",
        backgroundColor: state.isSelected ? "#ddd" : "#fff",
        color: "#000"
      }),

      menu: (provided, _) => ({
        ...provided,
        padding: 0
      }),

      control: (provided, _) => ({
        ...provided,
        border: "none"
      })
    };

    const options = [
      {
        value: "support",
        label: "Support"
      },
      {
        value: "technical",
        label: "Technical"
      }
    ];

    return (
      <Select
        placeholder={i18nConfig.t("translations.select")}
        styles={customStyles}
        options={options}
        value={options[0]}
        onChange={() => {}}
        className="select"
        isSearchable={false}
      />
    );
  };

  return (
    <div className="cf-user-app">
      <div className="content">
        <div className="zone">
          <div className="label">{ParseText(props.label)}</div>
          <div className="">{htmlElement()}</div>
        </div>
        <div className="">
          <img src={HelpIcon} alt="Person" className="user-image" />
        </div>
      </div>
      <div className="text-danger">{props.error}</div>
    </div>
  );
};
