import React, { Component } from "react";
import upload from "../../../../assets/images/tickets/upload.svg";
import "./Dropzone.css";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false, fileToUp: [] };
    this.fileInputRef = React.createRef();

    //   this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  //   openFileDialog() {
  //    // if (this.props.disabled) return;
  //  //   this.fileInputRef.current.click();
  //   }

  onFilesAdded(evt) {
    //  alert('hi')
    // if (this.props.disabled) return;

    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      this.fileListToArray(files);
      this.props.onFilesAdded(files);
      evt.currentTarget.value = null;
    }
    //console.log("files", files);
  }

  fileListToArray(list) {
    for (var i = 0; i < list.length; i++) {
      this.setState({
        fileToUp: this.state.fileToUp.concat(list.item(i))
      });
      // array.push();
    }
    // console.log(array);
    // return array;
  }

  render() {
    const { t } = this.props;
    const { fileToUp } = this.state;
    //console.log('fileToUp',fileToUp)
    return (
      <div className="upload-file-container">
        <img
          className="btn-edit"
          src={upload}
          style={{ cursor: "pointer" }}
          onClick={() => this.fileInput.click()}
        />

        <input
          type="file"
          multiple
          ref={fileInput => (this.fileInput = fileInput)}
          onChange={this.onFilesAdded}
          // onClick={this.openFileDialog}
          style={{ display: "none" }}
        />
        <p style={{ color: "#C8D3D6" }}>
          {t("tickets.create_ticket.upload_file")}
        </p>
      </div>
      // <div
      //   className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
      //   onClick={this.openFileDialog}
      //   style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      // >
      //   <input
      //     ref={this.fileInputRef}
      //     className="FileInput"
      //     type="file"
      //     multiple
      //     onChange={this.onFilesAdded}
      //   />
      //   <img alt="upload" className="btn-edit" src={upload} />
      //   <p style={{ color: "#C8D3D6" }}>
      //     {t("tickets.create_ticket.upload_file")}
      //   </p>
      // </div>
    );
  }
}

export default Dropzone;
