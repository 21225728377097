import React from "react";
import PropTypes from "prop-types";

const CreateTicket = props => {
  const { t, CalendarIcon, ticketStats } = props;

  return (
    <div className="column dashbordticket-column">
      <div className="card analytics-card-parent analytics-card-parent-agent">
        <div className="card-content analytics-card">
          <p className="subtitle">{t("tickets.new_ticket")}</p>
          <div className="title title-manageticket">
            <span className="statistic-ticket">{ticketStats}</span>
            <div className="arrawbtn" style={{ background: "#0089e13b" }}>
              <button
                className="uptriangle"
                style={{
                  background: "#0089E1",
                  border: "none",
                  margin: "auto"
                }}
              />
            </div>
          </div>
          <div
            className="button is-secondary createTicketCalender is-secondairy-test"
            style={{ cursor: "default" }}
          >
            <img
              className="calendar-icon-Newticket"
              src={CalendarIcon}
              alt="caneldar"
              style={{ marginRight: "5px" }}
            />
            {t("tickets.today")}
          </div>
        </div>
      </div>
    </div>
  );
};

CreateTicket.propTypes = {
  t: PropTypes.func.isRequired
};

export default CreateTicket;
