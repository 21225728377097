import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import {
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiFillPushpin
} from "react-icons/ai";
import { CustomTicketFieldHttpService } from "../../../../../services/HttpService";

const OneCustomTicketField = ({
  index,
  label,
  visible,
  toggleVisibility,
  field,
  visibleFields,
  customFields,
  cf,
  vf,
  max
}) => {
  const updateCase = field => {
    if (field !== "idTicket") toggleVisibility(field.id);
  };
  const [n, setn] = React.useState(Object.values(vf).filter(x => x).length);
  React.useEffect(() => {
    // console.log(visible)
    // console.log(visibleFields.filter(f => f.visible))
    setn(Object.values(vf).filter(x => x).length);
  }, [vf[cf.id]]);
  return field !== "idTicket" ? (
    <div
      className="TicketTableListColBody"
      style={{ border: visible ? "1px solid #0092E0" : "1px solid #BFBFBF" }}
    >
      <span>{label}</span>
      {!vf[cf.id] && (
        <button
          className="TicketTableListColButton"
          onClick={() => (max < 5 ? updateCase(cf) : console.log("max column"))}
        >
          <AiFillPlusCircle color="#0192E0" size={32} />
        </button>
      )}
      {vf[cf.id] && (
        <button
          className="TicketTableListColButton"
          onClick={() => updateCase(cf)}
        >
          <AiFillMinusCircle color="#EF7F6C" size={32} />
        </button>
      )}
    </div>
  ) : (
    <div
      className="TicketTableListColBody"
      style={{ border: "1px solid #BFBFBF", cursor: "default" }}
    >
      <span>{label}</span>
      {!visible && (
        <button
          className="TicketTableListColButton"
          onClick={() => (max < 5 ? updateCase(cf) : console.log("max column"))}
        >
          <AiFillPlusCircle color="#ffffff" size={32} />
        </button>
      )}
      {visible && (
        <button
          className="TicketTableListColButton"
          onClick={() => updateCase(cf)}
        >
          <AiFillMinusCircle color="#ffffff" size={32} />
        </button>
      )}
    </div>
  );
};

const AuthCustomTicketField = props => {
  const {
    t,
    visibleFields,
    currentLang,
    closeModal,
    showModal,
    toggleVisibility,
    context,
    vf
  } = props;
  const [customFields, setCustomFields] = React.useState([]);
  //get custom ticket fields
  const GetCustomTicketFields = () => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };

    CustomTicketFieldHttpService.getAll(receptor)
      .then(r => {
        if (r && r.data && r.data.status == 200) {
          // Set
          setCustomFields(
            r.data.data?.items
              ?.filter(f => !f.default)
              .map(i => {
                let a = i;
                a.visible = false;
                return a;
              })
          );
        }
      })
      .catch(e => {
        // console.log("[CustomTicketFieldHttpService.getAll]", e);
      })
      .finally(f => {
        // Close reload.
        // console.log(f);
      });
  };
  React.useEffect(() => {
    GetCustomTicketFields();
  }, []);
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="ServiceFieldsModal"
      backdrop={"static"}
      centered
    >
      <Modal.Body className="TicketTableList">
        <div className="TicketTableListHeader">
          {t("translations.ticket_table_list")}
          <div>
            {Object.values(vf).filter(x => x === true).length === 5 && (
              <small style={{ fontSize: 13 }} className={"text-danger"}>
                {t("only_6_column")}
              </small>
            )}
          </div>
        </div>
        <Row>
          {visibleFields.map((item, key) => {
            return (
              <Col key={key} xs={6} className="TicketTableListCol">
                <OneCustomTicketField
                  index={key}
                  field={item.field}
                  visible={item.visible}
                  label={item[`label_${currentLang}`]}
                  toggleVisibility={toggleVisibility}
                  visibleFields={visibleFields}
                  cf={item}
                  vf={vf}
                  customFields={customFields}
                  max={Object.values(vf).filter(x => x === true).length}
                />
              </Col>
            );
          })}
          {customFields?.map((item, key) => {
            return (
              <Col key={key} xs={6} className="TicketTableListCol">
                <OneCustomTicketField
                  index={key}
                  field={item.field}
                  visible={item.visible}
                  label={item[`label_${currentLang}`]}
                  toggleVisibility={toggleVisibility}
                  visibleFields={visibleFields}
                  cf={item}
                  vf={vf}
                  customFields={customFields}
                  max={Object.values(vf).filter(x => x === true).length}
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col className="d-flex" xs={12} sm={12} md={12}>
            <Button onClick={closeModal} className="TicketTableListButton">
              <span>{t("translations.save")}</span>
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AuthCustomTicketField;
