/* eslint-disable import/prefer-default-export */
import { createContext } from "react";

export const SharedDataContext = createContext({
  t: null,
  i18n: null,
  socketConnected: false,
  userLogged: false,
  defaultLang: null,
  status: 0,
  currentUser: {},
  notification: {
    active: false, // false , true
    status: "", // success , danger,
    content: { title: "", msg: "" }
  }
});
export const DataSocketContext = createContext({});
