import React, { Component } from "react";
import axios from "axios";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import { REGEX_FILE_NAME } from "../../../../constants/Constants";

function bytesToSize(bytes) {
  var sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const changeLongFileName = function(fileName) {
  let tab = [];
  tab = fileName.split(".");
  let typeFile = tab[tab.length - 1];
  tab.pop();
  return tab.join(".").length > 23
    ? tab.join(".").substring(0, 20) + "...." + typeFile
    : tab.join(".") + "." + typeFile;
};

function testingPhone(str) {
  let bool = true;
  let tabNameFile = str.split("");
  // console.log(tabNameFile);
  for (let i = 0; i < tabNameFile.length - 1; i++) {
    if (tabNameFile[i] === " " && tabNameFile[i + 1] === " ") bool = false;
  }
  return REGEX_FILE_NAME.test(str) && bool;
}

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      uploadedFile: [],
      uploadProgressAll: {},
      fileRemovedSucees: false,
      fileOnload: []
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    // this.uploadFiles = this.uploadFiles.bind(this);
    //this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(list) {
    let tab = [];
    for (var i = 0; i < list.length; i++) {
      if (list.item(i).size > 10485760) {
        this.props.handleMessageTicket("error-file-size", "--", "--");
      } else if (
        this.state.uploadedFile.length > 0 &&
        this.state.uploadedFile.filter(
          el => el.file_name === list.item(i).name.replace(/\s/g, "_")
        ).length !== 0
      ) {
        this.props.handleMessageTicket("error-file-exist", "--", "--");
      } else {
        if (testingPhone(list.item(i).name)) {
          //console.log("list.item(i)", list.item(i).name);
          tab.push(list.item(i));
          this.uploadFiles(list.item(i));
        } else {
          this.props.handleMessageTicket("error-file-name-upload", "--", "--");
        }
      }
    }
    this.setState({
      files: this.state.files.concat(tab)
    });
  }

  //upload file
  async uploadFiles(files) {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    await promises.push(this.sendRequest(files));
    // await files.forEach(file => {
    //   promises.push(this.sendRequest(file));
    // });
    // console.log("promises", promises);
    try {
      Promise.all(promises);
      // setTimeout(() => {
      //   this.handleSubmitCreateTicket();
      // }, 100);
      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  //Send request to CDN
  sendRequest = file => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      //  console.log('req',req)
      // console.log("file", file);
      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_type", file.type);
      formData.append("app_name", "rightcare");
      for (var pair of formData) {
        //  console.log(pair);
      }
      // console.log('formData',formData)
      req.responseType = "json";
      req.open("POST", "https://rccdn.sunubank.tg/api/1.0/upload/normal");

      req.setRequestHeader(
        "Authorization",
        "Dcfb51409eb9f42f8b87e2f0248573f03D"
      );
      req.send(formData);
      req.onerror = err => {
        this.props.handleMessageTicket("error-file-upload", "--", "--");
        //console.log("** An error occurred during the transaction",err);
      };
      this.setState(
        {
          fileOnload: this.state.fileOnload.concat({
            file: file.name,
            loadStatus: req.readyState
          })
        },
        () => this.props.fileprogresse(this.state.fileOnload)
      );

      req.onload = () => {
        if (req.readyState === req.DONE) {
          if (req.status === 200) {
            if (req.response.code === 200) {
              let fileResp = req.response.data;
              // console.log(req.response);
              this.setState(
                {
                  fileOnload: this.state.fileOnload.map(elm =>
                    elm.file === file.name
                      ? { file: file.name, loadStatus: req.readyState }
                      : elm
                  ),
                  uploadedFile: this.state.uploadedFile.concat({
                    file_name: fileResp.file_details.file_name.replace(
                      /\s/g,
                      "_"
                    ),
                    file_id: fileResp.file_details.file_id
                  }),
                  uploadProgressAll: {
                    ...this.state.uploadProgressAll,
                    ...this.state.uploadProgress
                  }
                },
                () => {
                  // console.log(this.state.uploadedFile);
                  let tabIdFile = [];
                  for (let i of this.state.uploadedFile) {
                    tabIdFile.push(i.file_id);
                  }
                  this.props.fileprogresse(this.state.fileOnload);
                  this.props.getfileListe(tabIdFile);
                }
              );
            } else {
              //  console.log("erreur", req.response);
              const copy = { ...this.state.uploadProgress };
              copy[file.name] = {
                state: "pending",
                percentage: 50
              };
              this.setState(
                {
                  uploadProgress: copy,
                  fileOnload: this.state.fileOnload.map(elm =>
                    elm.file === file.name
                      ? { file: file.name, loadStatus: 4 }
                      : elm
                  )
                },
                () => {
                  this.props.fileprogresse(this.state.fileOnload);
                  this.setState({
                    uploadProgressAll: {
                      ...this.state.uploadProgressAll,
                      ...this.state.uploadProgress
                    }
                  });
                }
              );
            }
          }
        }
      };
    });
  };
  //remove File
  removeFile = (file, progress) => {
    let fileName = file.name;
    // console.log('file.name : ', file.name);
    const { uploadedFile, successfullUploaded, uploadProgressAll } = this.state;
    let FileToDelete = uploadedFile.filter(
      elm => elm.file_name === file.name.replace(/\s/g, "_")
    );
    // console.log("file", file);
    // console.log("fileuploadedFile", uploadedFile);
    // console.log("FileToDelete", FileToDelete);
    if (progress.percentage === 100) {
      // console.log("remove me with api" )
      if (FileToDelete[0].file_id) {
        axios
          .delete(
            `https://rccdn.sunubank.tg/api/1.0/remove?file_id=${FileToDelete[0].file_id}`,
            {
              headers: {
                Authorization: "Dcfb51409eb9f42f8b87e2f0248573f03D"
              }
            }
          )
          .then(response => {
            // console.log("deleteFile : ", response);

            // console.log("test deleteFile success : ", response);
            if (
              response.status === 200 &&
              response.data.message === "File deleted with success"
            ) {
              //   console.log("afterremove me with api",uploadProgressAll);

              this.setState(
                {
                  files: this.state.files.filter(elm => elm.name !== file.name),
                  uploadedFile: this.state.uploadedFile.filter(
                    elm => elm.file_name !== file.name.replace(/\s/g, "_")
                  )
                },
                () => {
                  let tabIdFile = [];
                  let uploadProgressAll = {};
                  for (let i of this.state.uploadedFile) {
                    tabIdFile.push(i.file_id);
                  }
                  //remove file progresse from uploadProgressAll
                  uploadProgressAll = this.state.uploadProgressAll;
                  delete uploadProgressAll[fileName];
                  this.props.getfileListe(tabIdFile);
                  this.setState({
                    uploadProgressAll: uploadProgressAll
                  });
                }
              );
            }
            //console.log("can not remove me" )
          })
          .catch(err => {
            this.props.handleMessageTicket("error-file-delete", "--", "--");
          });
      } else {
        this.props.handleMessageTicket("error-file-delete", "--", "--");
      }
    } else {
      this.setState({
        files: this.state.files.filter(elm => elm.name !== file.name)
      });
    }
  };
  //render progress

  renderProgress(file) {
    const { uploadProgressAll } = this.state;

    const uploadProgress = uploadProgressAll[file.name];

    if (
      (this.state.uploading || this.state.successfullUploaded) &&
      Object.keys(uploadProgressAll).length !== 0
    ) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          {uploadProgress ? (
            uploadProgress.percentage === 100 ||
            uploadProgress.percentage === 50 ? (
              <button
                className="arrawbtn"
                style={{
                  color: "#C8D3D6",
                  border: "2px solid #C8D3D6",
                  background: "#FFFFFF",
                  marginLeft: "5px",
                  cursor: "pointer"
                }}
                onClick={() => this.removeFile(file, uploadProgress)}
              >
                X
              </button>
            ) : (
              false
            )
          ) : (
            false
          )}
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          className="button-action"
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          className="button-action"
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  render() {
    const { t } = this.props;
    const { uploadedFile, files, uploadProgressAll, fileOnload } = this.state;
    // console.log("fileOnload", fileOnload);
    //console.log("uploadedFile", uploadedFile);

    return (
      <div className="Upload">
        <div className="Content">
          <div>
            <Dropzone
              t={t}
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
        </div>
        <div
          className="Files"
          style={{
            maxHeight: `${this.state.files.length > 0 ? "51%" : "0"}`
          }}
        >
          {this.state.files.length > 0
            ? this.state.files.map(file => {
                return (
                  <div
                    key={file.name}
                    className="Row"
                    style={{ height: "6rem" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <span className="Filename">
                        {changeLongFileName(file.name)}
                      </span>
                      <span className="Filename">{bytesToSize(file.size)}</span>
                    </div>
                    {this.renderProgress(file)}
                  </div>
                );
              })
            : false}
        </div>
        {/* <div className="Actions">{this.renderActions()}</div> */}
      </div>
    );
  }
}

export default Upload;
