import React, { Component } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import CalendarIcon from "../../../assets/images/dashboard/calendar.svg";
import ExcelIconColor from "../../../assets/images/webform/information-color.svg";
import ExcelIconNoColor from "../../../assets/images/webform/information-nocolor.svg";
import PersonIcon from "../../../assets/images/profile/idpic.png";
import PointIcon from "../../../assets/images/webform/point.svg";
import CopyIcon from "../../../assets/images/webform/copy.svg";
import EmptyListIcon from "../../../assets/images/webform/empty_form_list.png";
import Footer from "../../layouts/Footer";
import DatePicker from "../../common/DatePicker";
import { Alert } from "react-bootstrap";

import { ConversationalFormHttpService } from "../../../services/HttpService";

const ButtonAction = props => {
  const buttons = [];
  // let nodeFormAction = null;
  const [formAction, setFormAction] = React.useState(false);

  // Add view details.
  buttons.push(
    <span
      key={`view-details-${props.elementIndex}`}
      className="view-details"
      onClick={() =>
        props.handleSharedDataContext(1, { id: props.elementIndex })
      }
    >
      {props.t("conversational_form.btn_views_details")}
    </span>
  );

  if (props.active) {
    // Add deactivate button
    buttons.push(
      <span
        key={`deactivate-form-${props.elementIndex}`}
        className="deactivate-form"
        onClick={() =>
          props.changeFormStatus(
            props.elementIndex,
            props.active,
            props.elementTitle
          )
        }
      >
        {props.t("conversational_form.btn_deactivate_form")}
      </span>
    );
  } else {
    buttons.push(
      <span
        key={`activate-form-${props.elementIndex}`}
        className="activate-form"
        onClick={() =>
          props.changeFormStatus(
            props.elementIndex,
            props.active,
            props.elementTitle
          )
        }
      >
        {props.t("conversational_form.btn_reactivate_form")}
      </span>
    );
    buttons.push(
      <span
        key={`delete-form-${props.elementIndex}`}
        className="delete-form"
        onClick={() =>
          props.deleteFormCallback(props.elementIndex, props.elementTitle)
        }
      >
        {props.t("conversational_form.btn_delete_form")}
      </span>
    );
  }

  const toggleFormAction = () => {
    setFormAction(!formAction);
  };

  // React.useEffect(() => {
  //   document.addEventListener("mousedown", eventFormAction, false);
  //   return () => {
  //     document.removeEventListener("mousedown", eventFormAction, false);
  //   };
  // }, []);

  //close assigned modal anywhere you click
  // const eventFormAction = e => {
  //   if (nodeFormAction && nodeFormAction.contains(e.target)) {
  //     setFormAction(true);
  //   } else {
  //     setFormAction(false);
  //   }
  // };

  return (
    <div className="dropdown-main">
      <img
        src={PointIcon}
        alt="..."
        id="dropdownMenuButton"
        // ref={node => (nodeFormAction = node)}
        onClick={toggleFormAction}
      />
      <div
        className="dropdown-content"
        style={{ display: formAction ? "block" : "none" }}
      >
        {buttons}
      </div>
    </div>
  );
};

const WedFormCard = props => {
  const render = [];
  //
  props.elements.forEach(element => {
    render.push(
      <div
        key={element.id}
        className="cadre-origin cadre-border"
        style={{
          backgroundColor: element.settings.status ? "inherit" : "#d8e3e6"
        }}
      >
        <div className="cadre line">
          <div className="images">
            <img
              src={element.settings.status ? ExcelIconColor : ExcelIconNoColor}
              alt="Excel"
            />
            <img src={PersonIcon} alt="Person" className="person" />
          </div>
          <h5>{element.inputs.title}</h5>
          <div className="conteneur">
            <a className="lien" href="#">
              {element.settings.link}
            </a>
            <img src={CopyIcon} alt="copy" />
          </div>
        </div>
        <div className="block">
          <p>{Math.floor(Math.random() * 100)} Responses</p>
          <ButtonAction
            t={props.t}
            handleSharedDataContext={props.handleSharedDataContext}
            active={element.settings.status}
            elementIndex={element.id}
            elementTitle={element.inputs.title}
            changeFormStatus={props.changeFormStatus}
            deleteFormCallback={props.deleteForm}
          />
        </div>
      </div>
    );
  });
  //
  return <>{render}</>;
};

const AlertNotification = props => {
  if (props.showAlert) {
    // Close alert message after 5 secondes.
    setTimeout(() => props.closeNotification(), 6000);
    //
    return (
      <Alert variant="success" onClose={props.closeNotification} dismissible>
        <p>{props.alertMsg}</p>
      </Alert>
    );
  }
  return <></>;
};

class WebformDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receptor: {},
      data: [],
      totalForm: 0,
      activeForm: 0,
      paginator: 1
    };

    this.formAction = React.createRef();

    this.closeNotification = this.closeNotification.bind(this);
    this.getManyForm = this.getManyForm.bind(this);
    this.goToPrevPage = this.goToPrevPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.changeFormStatus = this.changeFormStatus.bind(this);
    this.deleteForm = this.deleteForm.bind(this);
  }

  componentDidMount() {
    const { sharedDataContext } = this.props;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    //
    this.getManyForm(receptor);
    this.setState({ receptor });
    //
  }

  changeFormStatus(id, status, title) {
    // Get current page.
    const { paginator, receptor } = this.state;
    // Construct request params.
    const params = { id, status: !status };
    // Update current form status.
    ConversationalFormHttpService.changeStatus(params, receptor)
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          // Get new conversational form list.
          this.getManyForm(receptor, paginator);
          // Show notification message.
          this.handleNotificationAlert(
            true,
            `${title} ${this.props.t("conversational_form.update_alert")}`
          );
        }
      })
      .catch(e => {
        console.log("[ConversationalFormHttpService.changeStatus]", e);
      });
  }

  deleteForm(id, title) {
    // Get current page.
    const { paginator, receptor } = this.state;
    // Delete current form status.
    ConversationalFormHttpService.delete(id, receptor)
      .then(response => {
        if (response.status === 200 || response.status === 202) {
          // Get new conversational form list.
          this.getManyForm(receptor, paginator);
          // Show notification message.
          this.handleNotificationAlert(
            true,
            `${title} ${this.props.t("conversational_form.delete_alert")}`
          );
        }
      })
      .catch(e => {
        console.log("[ConversationalFormHttpService.delete]", e);
      });
  }

  getManyForm(receptor, page = 1) {
    const query = { page, limit: 9 };
    //
    ConversationalFormHttpService.getMany(query, receptor)
      .then(response => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          // Set forms data and total forms value.
          this.setState({
            data: response.data.data.forms,
            totalForm: response.data.data.total,
            activeForm: response.data.data.total
          });
        }
      })
      .catch(e => {
        console.log("[ConversationalFormHttpService.getMany]", e);
      });
  }

  getpageNumber(limit = 9) {
    const { totalForm } = this.state;
    let page = totalForm / limit;
    page = page == Math.floor(page) ? page : page + 1;
    return Math.floor(page);
  }

  goToPrevPage() {
    const { receptor } = this.state;
    let paginator = parseInt(this.state.paginator);
    if (paginator > 1) {
      paginator -= 1;
      this.getManyForm(receptor, paginator);
      this.setState({ paginator });
    }
  }

  goToNextPage() {
    const { receptor } = this.state;
    let paginator = parseInt(this.state.paginator);
    const totalForm = this.getpageNumber();
    if (paginator < totalForm) {
      paginator += 1;
      this.getManyForm(receptor, paginator);
      this.setState({ paginator });
    }
  }

  handleNotificationAlert(show, msg) {
    this.props.handleStateWebForm({ notify: show, notifyMsg: msg });
  }

  closeNotification() {
    this.handleNotificationAlert(false, "");
  }

  render() {
    const { t, handleSharedDataContext } = this.props;

    return (
      <>
        <div className="webform-container">
          <div className="webform-top">
            <div className="webform-header">
              <h2>{t("conversational_form.label")}</h2>
              <div>
                <div
                  className="button"
                  style={{
                    width: "203px",
                    borderRadius: "5px !important",
                    height: "auto"
                  }}
                >
                  <img
                    className="calendar-icon"
                    src={CalendarIcon}
                    alt="calendar"
                  />
                  <DatePicker t={t} />
                </div>
              </div>
            </div>

            <div className="webform-stats">
              <div className="stats-numbers">
                <div className="card analytics-card-parent analytics-card-parent-left">
                  <div className="card-content analytics-card">
                    <p className="subtitle">
                      {t("conversational_form.total_form")}
                    </p>
                    <div className="title title-manageticket">
                      <span className="statistic-ticket">
                        {this.state.totalForm}
                      </span>
                      <div
                        className="my-circle"
                        style={{ background: "#0089E152" }}
                      >
                        <button
                          className="small-circle blue-circle"
                          style={{ border: "none", margin: "auto" }}
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card analytics-card-parent analytics-card-parent-right">
                  <div className="card-content analytics-card">
                    <p className="subtitle">
                      {t("conversational_form.active_form")}
                    </p>
                    <div className="title title-manageticket">
                      <span className="statistic-ticket">
                        {this.state.activeForm}
                      </span>
                      <div
                        className="my-circle"
                        style={{ background: "#00BD3952" }}
                      >
                        <button
                          className="small-circle green-circle"
                          style={{ border: "none", margin: "auto" }}
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="webform-section">
            <div className="row row-header">
              <div className="col-md-8">
                <h2 className="section-title">
                  {t("conversational_form.list_form")}
                </h2>
              </div>
              <div className="col-md-4 p-0">
                <input
                  type="text"
                  className="section-search-bar"
                  placeholder={t("translations.search_form")}
                />
              </div>
            </div>

            <AlertNotification
              showAlert={this.props.stateWebForm.notify}
              alertMsg={this.props.stateWebForm.notifyMsg}
              closeNotification={this.closeNotification}
            />

            {this.state.data.length == 0 ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="section-empty-box">
                    <div className="center-element">
                      <img
                        src={EmptyListIcon}
                        alt="Missing Image"
                        className="mb-4"
                        srcSet=""
                      />
                      <div
                        className="create-button"
                        onClick={() => handleSharedDataContext(1)}
                      >
                        {t("conversational_form.label")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="middle">
                <div key="create-new-form" className="cadre-origin cadre-new">
                  <div
                    className="new-webform"
                    onClick={() => handleSharedDataContext(1)}
                  >
                    <div className="btn-plus"></div>
                    <div className="btn-text-form">
                      {t("conversational_form.create_form")}
                    </div>
                  </div>
                </div>
                <WedFormCard
                  t={t}
                  elements={this.state.data}
                  handleSharedDataContext={handleSharedDataContext}
                  changeFormStatus={this.changeFormStatus}
                  deleteForm={this.deleteForm}
                />
                <div className="paginator-container">
                  <div className="flex-space-between paginator">
                    <div onClick={this.goToPrevPage}>Prev</div>
                    <div className="paginator-number">
                      {this.state.paginator}
                    </div>
                    <div onClick={this.goToNextPage}>Next</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer t={t} />
      </>
    );
  }
}

WebformDashboard.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired
};

export default WebformDashboard;
