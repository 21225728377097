import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import logoRC from "../../assets/images/logo/rightcom-logo.svg";
import moment from "moment";

function newDate() {
  let day = new Date();
  let momenday = moment(day);
  return momenday.format("YYYY-MM-DD HH:mm:ss").slice(0, 4);
}
function openUrl(url) {
  return window.open(url);
}

const Footer = props => {
  const { t } = props;
  return (
    <div className="footer-container">
      <div className="footer-list">
        <p
          className="footer-text-list"
          style={{ color: "#0089E1", cursor: "default" }}
        >
          RightDesk (c) {newDate()}
        </p>
        <p
          onClick={() =>
            openUrl("https://right-com.com/solution/customer-care/")
          }
          className="footer-text-list"
        >
          {t("footer.learn_about")}
        </p>
        <p
          onClick={() => openUrl("https://rightcom.com/")}
          className="footer-text-list"
        >
          {t("footer.more_product")}
        </p>
        <p
          onClick={() => openUrl("https://rightcom.com/platform/")}
          className="footer-text-list"
        >
          {t("footer.go_to_xp")}
        </p>
      </div>

      <div className="footer-logo-container">
        <p className="footer-text-list" style={{ cursor: "default" }}>
          {t("footer.powred_by")}
        </p>
        <img src={logoRC} alt="logo-right-com" style={{ height: "22%" }} />
      </div>
    </div>
  );
};

export default Footer;
