import React, { Component, createRef, useState } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
import Footer from "../../layouts/Footer";
import CopyIcon from "../../../assets/images/webform/copy.svg";
import SearchIcon from "../../../assets/images/profile/search.svg";
import ProfileIcon from "../../../assets/images/profile/user.svg";
import Switch from "react-switch";
import { Modal } from "react-bootstrap";

import { CreateTicketHttpService } from "../../../services/HttpService";

import { SOCKET, SIO_AGENT_PLATFORM } from "../../../constants/Constants";

const WebFormNotificationModal = props => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="webform-notification-modal"
      centered
    >
      <Modal.Header>
        <Modal.Title id="webform-notification-modal">
          <div className="text-center">Notification</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 p-3">
          <h4 className="mb-3 text-center">
            Give your conversational form a title so you can save it
          </h4>
          <button className="modal-close-btn" onClick={props.onHide}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

class WebformDetails extends Component {
  static contextType = SharedDataContext;

  constructor(props) {
    super(props);
    //
    this.state = {
      formTitle: "GTBank - Accra",
      formDesc: "Process user ticket",
      formStatus: true,
      modalShow: false,
      //
      assegneeModalOpen: false,
      initAgents: [],
      storeInitAgents: [],
      addAgent: [],
      userData: {},
      searchAgent: ""
    };

    // Affect modal view.
    this.handleCreateNewForm = this.handleCreateNewForm.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    // Specify if this form is active or not.
    this.handleFormStatus = this.handleFormStatus.bind(this);

    // Update form title.
    this.handleTitleText = this.handleTitleText.bind(this);
    this.handleDescriptionText = this.handleDescriptionText.bind(this);

    //
    this.handleClick = this.handleClick.bind(this);

    //
    this.handleModalAgent = this.handleModalAgent.bind(this);
    this.handleAddAgent = this.handleAddAgent.bind(this);
    this.handleRemoveAgent = this.handleRemoveAgent.bind(this);
  }

  componentDidMount() {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    //
    this.initSocketGetAgentPlatform(
      context.sharedDataContext.currentUser.userid,
      receptor
    );
    //
    this.setState({
      userData: { ...context.sharedDataContext.currentUser, checked: false },
      lang: context.sharedDataContext.defaultLang.value
    });
    //
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  //close modam anywhere you click
  handleClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      assegneeModalOpen: false
    });
  }

  initSocketGetAgentPlatform(userid, receptor) {
    const { socket } = this.props;

    socket.on(SIO_AGENT_PLATFORM, event => {
      const response = JSON.parse(event);
      this.setState({ initAgents: response.data });
      this.setState({ storeInitAgents: response.data });
      // Assign current user by default.
      this.handleAddAgent(this.state.userData.userid);
    });

    CreateTicketHttpService.getAgentPlatFrom(userid, receptor).then(
      response => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 202
        ) {
          console.log(
            "[CreateTicketHttpService] getAgentPlatFrom.data:",
            response
          );
        }
      }
    );
  }

  handleAddAgent(userId) {
    let userData = this.state.userData;
    const addAgent = this.state.addAgent;
    const updatedAgents = this.state.initAgents;
    let isExist = this.state.initAgents.findIndex(
      agent => agent.user_id === userId
    );

    //
    if (isExist !== -1) {
      updatedAgents[isExist].checked = true;
      const index = this.state.addAgent.findIndex(
        agent => agent.user_id === userId
      );
      //
      if (index === -1) {
        addAgent.push(updatedAgents[isExist]);
      }
    } else {
      // Assign myself.
      userData.checked = true;
      addAgent.push(userData);
    }

    this.setState({
      addAgent: addAgent,
      userData: userData,
      initAgents: updatedAgents
    });
  }

  handleRemoveAgent(userId) {
    let userData = this.state.userData;
    const updatedAgents = this.state.initAgents;
    const isExist = this.state.initAgents.findIndex(
      agent => agent.user_id === userId
    );

    if (isExist !== -1) {
      updatedAgents[isExist].checked = false;
    } else {
      userData.checked = false;
    }

    const removeAgent = this.state.addAgent.filter(
      agent => agent.user_id !== userId
    );

    this.setState({
      addAgent: removeAgent,
      userData: userData,
      initAgents: updatedAgents
    });
  }

  toggleModalView(status) {
    this.setState({ modalShow: status });
  }

  handleCloseModal() {
    this.toggleModalView(false);
  }

  handleFormStatus() {
    this.setState({ formStatus: !this.state.formStatus });
  }

  handleModalAgent() {
    this.setState({
      assegneeModalOpen: !this.state.assegneeModalOpen
    });
  }

  handleTitleText(e) {
    this.setState({
      formTitle: e.target.value
    });
  }

  handleDescriptionText(e) {
    this.setState({
      formDesc: e.target.value
    });
  }

  handleCreateNewForm() {
    // Get current title.
    const { formTitle, formDesc, addAgent } = this.state;

    if (formTitle.length == 0) {
      // Print notification, if title is empty.
      this.toggleModalView(true);
    } else {
      // TODO: Call Backend API to create the new form.
      this.props.handleStateWebForm({
        notify: true
      });
      //
      this.props.handleSharedDataContext(0);
    }
  }

  render() {
    const { t } = this.props;

    const { assegneeModalOpen, addAgent } = this.state;

    return (
      <>
        <div className="header-indicator">
          <h3 className="header-indic-title1">Conversational Form</h3>
          {" > "}
          <p className="header-indic-title2">Create</p>
        </div>

        <WebFormNotificationModal
          show={this.state.modalShow}
          onHide={this.handleCloseModal}
        />

        <div className="section-container">
          <div className="section-block">
            <div className="columns">
              <div className="column mr-2 p-2">
                <div className="field is-horizontal mb-4">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input fill-form-title"
                          type="text"
                          value={this.state.formTitle}
                          onChange={this.handleTitleText}
                          placeholder="Form name"
                          autoFocus={true}
                        />
                      </div>
                      <div className="control">
                        <input
                          className="input fill-form-desc"
                          type="text"
                          value={this.state.formDesc}
                          onChange={this.handleDescriptionText}
                          placeholder="Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label>Full Name</label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      placeholder="Full Name"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label>Telephone Number</label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      placeholder="Telephone Number"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label>Email Address</label>
                  <div className="control ">
                    <input
                      className="input"
                      type="text"
                      placeholder="Email Address"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <div>
                    <label>Category/Sub category</label>
                  </div>
                  <div className="select" style={{ width: "100%" }}>
                    <select style={{ width: "100%" }} disabled={true}>
                      <option>Category/Sub category</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>

                <div className="field ">
                  <label>Message</label>
                  <div className="control ">
                    <textarea
                      className="textarea"
                      placeholder="Message"
                      readOnly={true}
                    ></textarea>
                  </div>
                </div>

                <label htmlFor="">Attach</label>
                <div className="flex-space-between webform-custom-file mb-5">
                  <div>Browse file</div>
                  <div>Upload File</div>
                </div>

                <div className="flex-space-between webform-custom-btn mb-5">
                  <button onClick={this.handleCreateNewForm}>create</button>
                  <button>preview</button>
                </div>
              </div>

              <div className="column" style={{ backgroundColor: "#FAFAFA" }}>
                SETTINGS
                <div className="pl-3 pr-3">
                  <div className="flex-space-between form-status">
                    <div>Form Status</div>
                    <div>
                      <Switch
                        height={20}
                        width={40}
                        className={"button-switch"}
                        onColor={"#6edd99"}
                        onChange={this.handleFormStatus}
                        checked={this.state.formStatus}
                      />
                    </div>
                  </div>

                  <hr style={{ marginTop: "-20px" }} />

                  <div className="flex-space-between form-select">
                    <div>
                      <label>Language</label>
                    </div>
                    <div className="select">
                      <select>
                        <option>English</option>
                        <option>French</option>
                      </select>
                    </div>
                  </div>

                  <hr style={{ marginTop: "20px" }} />

                  <label htmlFor="">Conversational Form Link</label>
                  <div className="flex-space-between webform-copy-link">
                    <div>http://bit.ly/untitledform</div>
                    <div>
                      <img src={CopyIcon} alt="copy" />
                      <span>Copy code</span>
                    </div>
                  </div>

                  <hr style={{ marginTop: "20px" }} />

                  <div className="flex-space-between">
                    <div>Assigned Agent(s)</div>
                    <div className="assign-agent-Container">
                      <span
                        className=" assign-agent-btn"
                        onClick={this.handleModalAgent}
                      >
                        +
                      </span>
                      {/* Modal add agent */}
                      <div
                        className="assign-agent-text-modal"
                        style={{
                          display: `${assegneeModalOpen ? "flex" : "none"}`
                        }}
                        ref={node => (this.node = node)}
                      >
                        <h2 className="title assign-modal-title">
                          Assign Agent(s) to Ticket
                        </h2>
                        <ul className="menu-list menu-list-ticket">
                          {" "}
                          <li className="assign-self">
                            <img src={ProfileIcon} alt="portrait" />
                            <span className="user-name">
                              {t("tickets.create_ticket.assign_ticket_myself")}
                            </span>
                            {this.state.userData.checked ||
                            this.state.userData.checked === true ? (
                              <span
                                className="remove-user"
                                onClick={e =>
                                  this.handleRemoveAgent(
                                    this.state.userData.user_id
                                  )
                                }
                              >
                                -
                              </span>
                            ) : (
                              <span
                                className="add-user"
                                onClick={e =>
                                  this.handleAddAgent(
                                    this.state.userData.user_id
                                  )
                                }
                              >
                                +
                              </span>
                            )}
                          </li>
                        </ul>
                        <div className="search-box assign-search">
                          <input
                            className="input"
                            type="text"
                            placeholder={t(
                              "tickets.create_ticket.search_agent_input"
                            )}
                            value={this.state.searchAgent}
                            onChange={e => this.handleInputSearchChange(e)}
                          />
                          <img src={SearchIcon} alt="search" />
                        </div>
                        {this.state.storeInitAgents.length === 0 ? (
                          <div>
                            {t("tickets.create_ticket.search.user_list_empty")}
                          </div>
                        ) : (
                          this.state.initAgents.length === 0 && (
                            <div>
                              {t("tickets.create_ticket.search.user_not_found")}
                            </div>
                          )
                        )}
                        <section
                          className="modal-card-body"
                          style={{ width: "100%" }}
                        >
                          <ul className=" menu-list menu-list-ticket">
                            {this.state.initAgents &&
                              this.state.initAgents.map((item, i) => (
                                <li key={i}>
                                  <img src={ProfileIcon} alt="portrait" />
                                  <span className="user-name">
                                    {item.firstname} {item.lastname}
                                  </span>
                                  {item.checked || item.checked === true ? (
                                    <span
                                      className="remove-user"
                                      onClick={e =>
                                        this.handleRemoveAgent(item.user_id)
                                      }
                                    >
                                      -
                                    </span>
                                  ) : (
                                    <span
                                      className="add-user"
                                      onClick={e =>
                                        this.handleAddAgent(item.user_id)
                                      }
                                    >
                                      +
                                    </span>
                                  )}
                                </li>
                              ))}
                          </ul>
                          {/*  */}
                        </section>
                        <footer className="assign-modal-footer">
                          <button
                            className="button is-primary"
                            aria-label="close"
                            style={{ width: "100%" }}
                            onClick={() =>
                              this.setState({
                                assegneeModalOpen: !this.state.assegneeModalOpen
                              })
                            }
                          >
                            ASSIGN
                          </button>
                        </footer>
                      </div>
                      {/* End Modal add agent */}
                    </div>
                  </div>

                  <ul className="webform-card-ul">
                    {this.state.addAgent &&
                      this.state.addAgent.map((item, i) => (
                        <li key={i}>
                          <img src={ProfileIcon} alt="portrait" />
                          <span
                            className="user-name"
                            style={{ fontWeight: "bold" }}
                          >
                            {item.firstname} {item.lastname}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer t={t} />
      </>
    );
  }
}

WebformDetails.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired
};

//export default WebformDetails;

const WebformDetailsWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <WebformDetails {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);

export default WebformDetailsWithSocket;
