import React, { useState, useEffect, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Card from "react-bootstrap/Card";
import Switch from "react-switch";
import Select from "react-select";
import Modal from "../../../utils/tickets/Modal";

const Naturalisation = props => {
  const {
    t,
    handleCreationTicketNature,
    ticketNatureItems,
    handleUpdateTicketNature,
    handleDeleteTicketNature
  } = props;

  const [natureState, setNatureState] = useState({
    nature_en: "",
    nature_fr: ""
  });

  const [natureItemsFr, setNatureItemsFr] = useState([]);
  const [natureItemsEn, setNatureItemsEn] = useState([]);
  const [compt, setCompt] = useState(0);

  const [state, setState] = useState({
    //show: false,
    showCreateForm: false,
    title: "",
    openModal: false,
    ticketNatureSelected: null,
    isSelected: false
  });

  const cardStyle = {
    emptyChannel: {
      background: "#ffffff"
    },
    titleContainer: {
      padding: "0 5rem"
    },
    h2: {
      padding: "1.5rem 0",
      fontSize: "2rem"
    },
    card: {
      margin: "0 10px 20px 10px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      borderBottom: "1px solid rgba(200,211,214,1)"
    },
    p: {
      color: "#4c4c4c",
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: 0
    },
    switch: {
      marginLeft: "10px",
      marginTop: "10px"
    }
  };

  const openModal = item => {
    setState({ ...state, ticketNatureSelected: item, openModal: true });
  };
  //

  const handleTitleChange = event => {
    const { value } = event.currentTarget;
    setState({ ...state, title: value });
  };

  const handleInputChangeFr = (event, item, i) => {
    const { value } = event.currentTarget;
    const clonedItem = { ...item };
    clonedItem["label"] = value;
    const clonedItems = [...natureItemsFr];
    clonedItems[i] = clonedItem;
    setNatureItemsFr(clonedItems);
  };

  const handleInputChangeEn = (event, item, i) => {
    const { value } = event.currentTarget;
    const clonedItem = { ...item };
    clonedItem["label"] = value;
    const clonedItems = [...natureItemsEn];
    clonedItems[i] = clonedItem;
    setNatureItemsFr(clonedItems);
  };

  const handleCloseModalDelete = () => {
    setState({ ...state, openModal: false });
    // console.log(state);
    handleDeleteTicketNature(state.ticketNatureSelected);
  };

  const handleTitleUpdate = (e, item, i) => {
    const { value } = e.currentTarget;
    if (value !== "" && state.initTicketNatureList[i].title !== value)
      handleUpdateTicketNature(value, item.nature, item.id);
  };

  useEffect(() => {
    // console.log("NatureItemsFr : ", natureItemsFr);
    // console.log("NatureItemsEn : ", natureItemsEn);
    if (ticketNatureItems) {
      setNatureItemsFr(ticketNatureItems);
    }
  }, [ticketNatureItems, natureItemsFr, compt]);

  //
  const [natureEnError, setNatureEnError] = React.useState(undefined);
  const [natureFrError, setNatureFrError] = React.useState(undefined);
  const handleNatureEnChange = event => {
    setNatureEnError(undefined);
    const { value } = event.currentTarget;
    setNatureState({ ...natureState, nature_en: value });
  };

  const handleNatureFrChange = event => {
    setNatureFrError(undefined);
    const { value } = event.currentTarget;
    setNatureState({ ...natureState, nature_fr: value });
  };
  React.useEffect(() => {
    setNatureEnError(
      natureItemsFr.filter(
        i => i.labels.en.toLowerCase() === natureState.nature_en.toLowerCase()
      ).length
        ? "translations.nature_name_not_uniq"
        : undefined
    );
    setNatureFrError(
      natureItemsFr.filter(
        i => i.labels.fr.toLowerCase() === natureState.nature_fr.toLowerCase()
      ).length
        ? "translations.nature_name_not_uniq"
        : undefined
    );
  }, [natureState]);

  const handleCreateTicketNature = () => {
    if (natureEnError || natureFrError) {
      return false;
    } else {
      const varia = handleCreationTicketNature(
        natureState.nature_en,
        natureState.nature_fr
      );
      setCompt(compt + 1);
      natureState.nature_en = "";
      natureState.nature_fr = "";
    }
  };

  return (
    <Accordion defaultActiveKey={"0"}>
      <Card style={cardStyle.card}>
        <Card.Header>
          <p className="card-header-title" style={cardStyle.p}>
            {t("settings.naturalisation.ticket_nature")}
          </p>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {natureItemsFr &&
              natureItemsFr.map((item, i) => (
                <div className="form-row" key={i}>
                  <div className="form-group col-5">
                    <label htmlFor="title">
                      {t("settings.naturalisation.ticket_nature_en")}
                    </label>
                    <input
                      type="nature"
                      value={natureItemsFr[i].labels["en"]}
                      disabled="disabled"
                      onChange={e => handleInputChangeEn(e, item, i)}
                      className="form-control"
                      id="nature"
                      autoComplete={"off"}
                      placeholder={t(
                        "settings.naturalisation.enter_ticket_nature_en"
                      )}
                    />
                  </div>
                  <div className="form-group col-5">
                    <label htmlFor="title">
                      {t("settings.naturalisation.ticket_nature_fr")}
                    </label>
                    <input
                      type="nature"
                      disabled="disabled"
                      value={natureItemsFr[i].labels["fr"]}
                      onChange={e => handleInputChangeFr(e, item, i)}
                      className="form-control"
                      id="nature"
                      autoComplete={"off"}
                      placeholder={t(
                        "settings.naturalisation.enter_ticket_nature_fr"
                      )}
                    />
                  </div>

                  <div className="form-group col-2 delete-button">
                    <div
                      onClick={() => openModal(item)}
                      className="col-md-1 btn btn-default"
                    >
                      X
                    </div>
                  </div>
                </div>
              ))}

            {/*Start Champ de nouvelle nature */}
            <div className="form-row">
              <div className="form-group col-5">
                <label htmlFor="title">
                  {t("settings.naturalisation.ticket_nature_en")}
                </label>
                <input
                  type="nature"
                  value={natureState.nature_en}
                  onChange={e => handleNatureEnChange(e)}
                  className="form-control"
                  id="nature"
                  autoComplete={"off"}
                  placeholder={t(
                    "settings.naturalisation.enter_ticket_nature_en"
                  )}
                />
                {natureEnError ? (
                  <span className={"text-danger"}>{t(natureEnError)}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group col-5">
                <label htmlFor="title">
                  {t("settings.naturalisation.ticket_nature_fr")}
                </label>
                <input
                  type="nature"
                  value={natureState.nature_fr}
                  onChange={e => handleNatureFrChange(e)}
                  className="form-control"
                  id="nature"
                  autoComplete={"off"}
                  placeholder={t(
                    "settings.naturalisation.enter_ticket_nature_fr"
                  )}
                />
                {natureFrError ? (
                  <span className={"text-danger"}>{t(natureFrError)}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="form-group col-2 delete-button">
                <div
                  //   onClick={() => openModal(item)}
                  className="col-md-1 btn btn-default"
                >
                  {/* X */}
                </div>
              </div>
            </div>
            {/*End Champ de nouvelle nature  */}
            {/*
            {ticketSubject &&
              ticketSubject.map((item, i) => (
                <div className="form-row" key={i}>
                  <div className="form-group col-md-7">
                    <label htmlFor="title">
                      {t("settings.auto_priorization.ticket_subject")}
                    </label>
                    <input
                      name={i}
                      type="title"
                      value={ticketSubject[i] && ticketSubject[i].title}
                      onBlur={e => handleTitleUpdate(e, item, i)}
                      onChange={e => handleInputChange(e, item, i)}
                      className="form-control"
                      id={i}
                      autoComplete={"off"}
                      placeholder={t(
                        "settings.auto_priorization.enter_ticket_subject"
                      )}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="priority">
                      {t("settings.auto_priorization.select_priority")}
                    </label>
                    <Select
                      options={state.priorityItems}
                      value={formatPriority(ticketSubject[i].priority)}
                      defaultValue={formatPriority(ticketSubject[i].priority)}
                      onChange={e => handlePriorityUpdate(e, item, i)}
                      isSearchable={false}
                      className="select-priority"
                      placeholder={t(
                        "tickets.create_ticket.ticket_priority_input"
                      )}
                      styles={customStyles}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#eee",
                          primary25: "#eee"
                        }
                      })}
                    />
                  </div>
                  <div className="form-group col-md-1 delete-button">
                    <div
                      onClick={() => openModal(item)}
                      className="col-md-1 btn btn-default"
                    >
                      X
                    </div>
                  </div>
                </div>
              ))} */}
            <button
              onClick={() => handleCreateTicketNature()}
              className="btn btn-primary button-add-subject"
            >
              <span>
                + &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                {t("settings.naturalisation.new_ticket_nature")}
              </span>
            </button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Modal
        t={t}
        dialogAlertMessage={t("settings.delete_ticket_nature.message")}
        handleClickOpen={state.openModal}
        handleCloseAccepte={() => handleCloseModalDelete()}
        handleClose={() => setState({ ...state, openModal: false })}
      />
    </Accordion>
  );
};

export default Naturalisation;
