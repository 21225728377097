import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";
import ExportIcon from "../../assets/images/tickets/ExportIcon.svg";
import { SharedDataContext } from "./UseContext";

export default class ExportButton extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { open: false };
  }

  toggle() {
    this.setState(prevState => {
      return { open: !prevState.open };
    });
  }

  render() {
    const { t, handleExport, iconExport, disable } = this.props;
    return disable ? (
      <div className={"button-export-disable"}>
        <img src={iconExport} alt="" />
        <span>{t("tickets.export_data")}</span>
      </div>
    ) : (
      <Dropdown
        open={this.state.open}
        toggle={this.toggle}
        className="d-table"
        disabled={disable}
      >
        <DropdownToggle className={"button-export"}>
          <img src={iconExport} alt="" />
          <span>{t("tickets.export_data")}</span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => handleExport("xlsx")}>
            EXCEL
          </DropdownItem>
          {/* <DropdownItem onClick={() => handleExport("pdf")}>PDF</DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
