import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";

import { CustomTicketFieldHttpService } from "../../../../services/HttpService";
import CreateNewFieldModal from "./utils/CreateNewFieldModal";
import CustomFieldContainer from "./utils/CustomFieldContainer";
import GlobalModalNotification from "../../../utils/tickets/Modal";
import i18nConfig from "../../../../configs/i18nConfig";

const CustomiseTicketDetails = props => {
  const {
    t,
    reload,
    sharedDataContext,
    setSharedDataContext,
    customTicketFieldItems
  } = props;

  //
  const [modalState, toggleModal] = useState(false);

  //
  const [confirmActionModalState, setConfirmActionModalState] = useState(false);

  const [stateData, setStateData] = useState({
    status: false,
    items: [],
    current: null,
    defaultLang: sharedDataContext.defaultLang.value,
    swapData: {}
  });

  useEffect(() => {
    if (Object.values(customTicketFieldItems).length != 0) {
      setStateData({
        status: customTicketFieldItems.status,
        items: customTicketFieldItems.items,
        defaultLang: sharedDataContext.defaultLang.value
      });
    }
  }, [customTicketFieldItems]);

  const globalNotification = (status, msg) => {
    setSharedDataContext({
      ...sharedDataContext,
      notification: {
        active: true,
        status: status,
        content: { title: "", msg }
      }
    });
  };

  const handleModalView = () => {
    toggleModal(!modalState);
  };

  const loadModalData = item => {
    setStateData({ ...stateData, current: item });
  };

  const cleanModalData = () => {
    // Load data in the modal.
    loadModalData(null);
    // Show modal.
    handleModalView();
  };

  const launchConfirmActionModal = (id, label) => {
    setStateData({ ...stateData, swapData: { id, label } });
    setConfirmActionModalState(true);
  };

  const handleRemoveCustomTicketField = () => {
    const { id, label } = stateData.swapData;
    deleteCallback(id, label);
    setConfirmActionModalState(false);
  };

  // Delete callback.
  const deleteCallback = (itemId, label) => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };

    CustomTicketFieldHttpService.deleteOne(itemId, receptor)
      .then(r => {
        globalNotification(
          "success",
          i18nConfig.language === "fr"
            ? `Le champs personnalisé ${label} a été supprimé avec succès`
            : `Custom Ticket Field ${label} was deleted successfully`
        );
        reload(true);
      })
      .catch(e => {
        // console.log("[CustomTicketFieldHttpService.deleteOne]", e);
        globalNotification(
          "danger",
          i18nConfig.language === "fr"
            ? `Echec de la suppression du champ ${label}`
            : `Failed to delete the custom field ${label}`
        );
      });
  };

  return (
    <Accordion defaultActiveKey={"0"}>
      <Card className="custom-field-modal">
        <Card.Header>
          <p className="card-header-title">
            {t("translations.customise_ticket_details")}
          </p>
        </Card.Header>
        <Card.Body className="custom-field-body">
          {stateData.items.length == 0 ? (
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "2em" }}
            >
              {t("translations.no_custom_fields")}
            </div>
          ) : (
            stateData.items.map((item, index) => {
              if (!item.default) {
                return (
                  <CustomFieldContainer
                    key={index}
                    t={t}
                    data={item}
                    defaultLang={stateData.defaultLang}
                    launchConfirmActionModal={launchConfirmActionModal}
                    handleModalView={handleModalView}
                    loadModalData={loadModalData}
                  />
                );
              }
            })
          )}

          {/** this ia the create ticket button at the settings page */}
          <button className="button-add-field" onClick={cleanModalData}>
            <span>+ &nbsp;&nbsp;{t("translations.create_field")}</span>
          </button>
        </Card.Body>
      </Card>
      <CreateNewFieldModal
        t={t}
        reload={reload}
        show={modalState}
        onHide={handleModalView}
        current={stateData.current}
        globalNotification={globalNotification}
        sharedDataContext={sharedDataContext}
        setSharedDataContext={setSharedDataContext}
        customTicketFieldItems={customTicketFieldItems}
        currentCustomTickets={stateData.items}
      />

      <GlobalModalNotification
        t={t}
        dialogAlertMessage={
          i18nConfig.language === "fr"
            ? "Etes-vous sur de vouloir supprimer ?"
            : "Are you sure you want to delete ?"
        }
        handleClickOpen={confirmActionModalState}
        handleCloseAccepte={() => handleRemoveCustomTicketField()}
        handleClose={() => setConfirmActionModalState(false)}
      />
    </Accordion>
  );
};

export default CustomiseTicketDetails;
