import React from "react";
import PropTypes from "prop-types";
import DatePicker from "../../common/DatePicker";

const PendingRequest = props => {
  const {
    t,
    CalendarIcon,
    MoreIcon,
    ticketStats,
    ticketPendingDateFilter
  } = props;

  const status = "pending";
  // function ticketPendingDateFilter(start, end){
  //   console.log('startDate',start)
  //   console.log('endDate',end)
  // }
  return (
    <div className="column">
      <div className="card analytics-card-parent analytics-card-parent-pending-request">
        <div className="card-content analytics-card">
          <p className="subtitle">{t("dashboard.pending_request")}</p>
          <div className="title title-manageticket">
            <span className="statistic-ticket">{ticketStats}</span>
            <div className="arrawbtn" style={{ background: "#ffc5054f" }}>
              <button
                className="uptriangle"
                style={{
                  background: "#FFC505",
                  border: "none",
                  margin: "auto"
                }}
              ></button>
            </div>
          </div>
          <div className="button is-secondary is-outlined">
            {/* <img className="view-more" src={MoreIcon} alt='caneldar' /> */}
            <DatePicker
              t={t}
              getTimeFilter={(start, end) =>
                ticketPendingDateFilter(start, end)
              }
            />
            <img className="calendar-icon" src={CalendarIcon} alt="caneldar" />
          </div>
        </div>
      </div>
    </div>
  );
};

PendingRequest.propTypes = {
  t: PropTypes.func.isRequired,
  CalendarIcon: PropTypes.string.isRequired,
  MoreIcon: PropTypes.string.isRequired
};

export default PendingRequest;
