import React, { Component } from "react";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.css";

class Paginator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.props.gotToPage(pageNumber);
  }

  render() {
    const { ticketInfot, t } = this.props;
    return (
      <div className="pagination-container">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          prevPageText={t("tickets.table_list.prePage")}
          nextPageText={t("tickets.table_list.nextPage")}
          activePage={ticketInfot.page}
          itemsCountPerPage={ticketInfot.nbre_per_page}
          totalItemsCount={ticketInfot.total_datas}
          pageRangeDisplayed={15}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    );
  }
}

export default Paginator;
