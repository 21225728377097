import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useRouter from "../app/useRouter";
import Header from "../layouts/Header";
import AssignAgent from "./steps/AssignAgent";
import AccountSummary from "./steps/AccountSummary";
import { options } from "../../configs/options";
// import SelectAgent from '../../assets/images/onboard/get-started/select-agent.png';
// import SingleService from '../../assets/images/onboard/group.png';
import WelcomeImg from "../../assets/images/onboard/welcomeImg.png";

import { SharedDataContext, DataSocketContext } from "../app/UseContext";
import {
  SOCKET,
  SIO_AGENT_FORM_XP,
  SIO_AGENT_SINCE_CARE
} from "../../constants/Constants";
import {
  AgentFromXPHttpService,
  check_onboardingHttpService
} from "../../services/HttpService";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "3rem",
    paddingLeft: 0,
    marginLeft: "-4rem"
  },
  steppers: {
    width: "28rem",
    cursor: "pointer"
  },
  stepLabel: {
    cursor: "pointer"
  },
  button: {
    marginRight: theme.spacing(1.5),
    backgroundColor: "#0089e1",
    borderRadius: "10px",
    padding: "1rem 3rem",
    textTransform: "capitalize",
    fontSize: "1rem",
    color: "#ffffff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#0089e1"
    }
  },
  buttonOutlined: {
    backgroundColor: "transparent",
    color: "#0089e1",
    borderColor: "#0089e1",
    borderRadius: "10px",
    padding: "1rem 3rem",
    textTransform: "capitalize",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "#0089e1",
      color: "#ffffff",
      borderColor: "#0089e1"
    }
  },
  buttonOutlined2: {
    backgroundColor: "transparent",
    color: "#0089e1",
    borderColor: "#0089e1",
    borderRadius: "10px",
    padding: "1rem 3rem",
    marginRight: "1rem",
    textTransform: "capitalize",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "#0089e1",
      color: "#ffffff",
      borderColor: "#0089e1"
    }
  },
  instructions: {
    marginTop: "1rem",
    marginBottom: "2rem"
  }
}));

const getSteps = () => ["Add agents", "Account config summary"];

const Steps = props => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [stateCheckonboarding, setstateCheckonboarding] = useState(false);
  const steps = getSteps();
  const classes = useStyles();
  const isStepSkipped = step => skipped.has(step);

  const [countAgentSelected, setCountAgentSelected] = React.useState(0);

  const {
    t,
    kind,
    defaultLang,
    changeLang,
    isLogged,
    checkedServices,
    activeServices,
    handleSimulateChooseServices,
    selectServiceRef,
    containerWidth,
    socket
  } = props;

  const router = useRouter();

  const [agentFromXP, setAgentFromXP] = useState([]);

  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const getStepContent = (
    _t,
    _step,
    _checkedServices,
    _activeServices,
    _handleBack,
    _containerWidth,
    _handleCountAgentSelected,
    _agentFromXP
  ) => {
    switch (_step) {
      case 0:
        return (
          <AssignAgent
            t={_t}
            checkedServices={_checkedServices}
            containerWidth={_containerWidth}
            handleCountAgentSelected={_handleCountAgentSelected}
            agentFromXP={_agentFromXP}
          />
        );
      case 1:
        return (
          <AccountSummary
            checkedServices={_checkedServices}
            activeServices={_activeServices}
            containerWidth={_containerWidth}
            agentFromXP={_agentFromXP}
          />
        );
      default:
        return t("onboard.steps.unknown_step");
    }
  };

  const handleNext = () => {
    props.selectServiceRef.current.click();
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  /*
  const handleSkip = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    }
  };
  */

  const handleCountAgentSelected = count => {
    setCountAgentSelected(count);
  };

  /* START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
  const onSocketGetAgentFromXP = response => {
    // console.log("onSocketGetAgentFromXP : ", response.data);

    if (response && (response.status === 200 || response.status === 202)) {
      setAgentFromXP(response.data);
    }
  };

  const initSocketAgentFromXP = () => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.publickey
            : false,
        apisid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.apisid
            : false,
        sessionid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.session_id
            : false
      }
    };

    //console.log("initSocketAgentFromXP : **** ");

    socket.on(SIO_AGENT_FORM_XP, event => {
      const response = JSON.parse(event);
      // console.log("agent list response: ", response);
      onSocketGetAgentFromXP(response);
    });

    AgentFromXPHttpService.getDatasAgentFromXP(receptor).then(response => {
      //console.log("getDatasAgentFromXP : ", response);

      if (response.status === 200 || response.status === 202) {
        //  console.log("test success : ", response);
      } else {
        // console.log('test error : ', response);
      }
    });
  };
  /* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

  /** Start - Save Agent */
  const handleSaveAgentSubmit = () => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.publickey
            : false,
        apisid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.apisid
            : false,
        sessionid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.session_id
            : false
      }
    };
    const dataSaveAgent = JSON.parse(localStorage.getItem("sv_tmp_save_agent"));

    AgentFromXPHttpService.SaveAgentSinceCare(dataSaveAgent, receptor)
      .then(response => {
        // console.log("AgentFromXPHttpService : ", response);

        if (response && response.data && response.data.status === 202) {
          localStorage.removeItem("sv_tmp_save_agent");

          // message de notification success
          setSharedDataContext({
            ...sharedDataContext,
            notification: {
              active: true,
              status: "success",
              content: {
                title: "",
                msg: t("notification.msg_save_agent_success")
              }
            }
          });
          check_onboardingHttpService
            .update_onboarding({}, receptor)
            .then(response => {
              // console.log("update_onboarding   : ", response);
              if (response.status === 200 || response.status === 202) {
                router.push("/dashboard");
              } else {
                //  console.log("test error : ", response);
              }
            });
        } else {
          // message de notification error
          setSharedDataContext({
            ...sharedDataContext,
            notification: {
              active: true,
              status: "danger",
              content: {
                title: "",
                msg: t("notification.msg_save_agent_error")
              }
            }
          });

          router.push("/onboard");
        }
      })
      .catch(error => {
        // console.log("**** print error ****", error);

        // message de notification error
        setSharedDataContext({
          ...sharedDataContext,
          notification: {
            active: true,
            status: "danger",
            content: { title: "", msg: t("notification.msg_save_agent_error") }
          }
        });

        router.push("/onboard");
      });
  };

  const buildDataSaveAgent = () => {
    const storageAgent = JSON.parse(localStorage.getItem("cr_services"));
    const refactData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of storageAgent[0].agents) {
      const data = agentFromXP.find(elt => elt.user_id === item);
      const agents = {
        user_id: data.user_id,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phone: data.phone,
        language: data.language,
        image: data.image,
        sexe: data.sexe,
        status: 1
      };
      refactData.push(agents);
    }

    const saveAgent = {
      sio_channel: SIO_AGENT_SINCE_CARE,
      user_connected_id: sharedDataContext.currentUser.userid,
      agents: refactData
    };

    if (localStorage && saveAgent) {
      localStorage.setItem("sv_tmp_save_agent", JSON.stringify(saveAgent));
      initSocketSaveAgent();
    }
  };

  const initSocketSaveAgent = () => {
    socket.on(SIO_AGENT_SINCE_CARE, event => {
      const response = JSON.parse(event);
      //   console.log("initSocketSaveAgent : ", response);
      onSocketSaveAgent(response);
    });
    handleSaveAgentSubmit();
  };

  const onSocketSaveAgent = response => {
    if (response && response.status === 200) {
      //  console.log("onSocketSaveAgent : ", response);
    }
  };

  useEffect(() => {
    if (sharedDataContext.socketConnected) {
      checkonboarding();
    }
    return () => {
      // cleanup
    };
  }, [sharedDataContext, router]);
  function checkonboarding() {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.publickey
            : false,
        apisid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.apisid
            : false,
        sessionid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.session_id
            : false
      }
    };

    check_onboardingHttpService.check_onboarding(receptor).then(response => {
      if (response.status === 200 || response.status === 202) {
        if (response.data.data.state) router.push("/dashboard");
        setstateCheckonboarding(response.data.data.state);
        initSocketAgentFromXP();
      } else {
        // console.log("test error : ", response);
      }
    });
  }

  return (
    <>
      <Header
        options={options}
        defaultLang={defaultLang}
        changeLang={changeLang}
        kind={kind}
        isLogged={isLogged}
        haveRights={props.haveRights}
        t={t}
      />

      <div className="steps-container">
        {props.haveRights ? (
          <>
            <div className="columns">
              <div className="column is-three-fifths steps-column">
                {activeStep === 0 && (
                  <>
                    <h2 className="common-medium-title">
                      {t("onboard.add_agent")}
                    </h2>
                    <p>{t("onboard.add_agent_to_your_platform")}</p>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <h2 className="common-medium-title">
                      {t("onboard.account_setup_summary")}
                    </h2>
                    <p>{t("onboard.onboarding_process")}</p>
                  </>
                )}

                <div className={classes.root}>
                  <div>
                    <div className="content-selector">
                      <div className={classes.instructions}>
                        {getStepContent(
                          t,
                          activeStep,
                          checkedServices,
                          activeServices,
                          handleBack,
                          containerWidth,
                          handleCountAgentSelected,
                          agentFromXP
                        )}
                      </div>
                      <div className="next-back-container">
                        <Button
                          onClick={handleSimulateChooseServices}
                          ref={selectServiceRef}
                          style={{ display: "none" }}
                        >
                          Simulate
                        </Button>
                        {activeStep === steps.length - 1 ? (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleBack}
                              className={classes.buttonOutlined2}
                            >
                              {t("onboard.back")}
                            </Button>

                            {/* <Link to="/dashboard" style={{ color: '#ffffff' }}> */}
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={buildDataSaveAgent}
                            >
                              {t("onboard.finish")}
                            </Button>
                            {/* </Link> */}
                          </>
                        ) : (
                          <>
                            {countAgentSelected > 0 && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                                style={{ textTransform: "uppercase" }}
                              >
                                {t("onboard.continue")}
                              </Button>
                            )}
                          </>
                        )}

                        {/* {activeStep !== steps.length - 1 && (
                      <Link to="/dashboard" style={{ color: '#ffffff' }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.buttonOutlined}
                        >
                          {t('onboard.skip')}
                        </Button>
                      </Link>
                    )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Affichage dynamique des images de droite */}
              {activeStep === 0 && (
                <div className="column">
                  <img
                    src={WelcomeImg}
                    alt="select agent"
                    className="select-image"
                  />
                </div>
              )}
              {activeStep === 1 && (
                <div className="column column-image-container">
                  <img
                    src={WelcomeImg}
                    alt="select service"
                    className="select-image"
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div> </div>
        )}
      </div>
    </>
  );
};

Steps.propTypes = {
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  defaultLang: PropTypes.shape({}).isRequired,
  changeLang: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
  checkedServices: PropTypes.shape({}).isRequired,
  selectServiceRef: PropTypes.shape({}).isRequired,
  activeServices: PropTypes.shape(PropTypes.array.isRequired).isRequired,
  handleSimulateChooseServices: PropTypes.func.isRequired
};

const StepsWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <Steps {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);

export default StepsWithSocket;
