import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Popover, OverlayTrigger, Overlay } from "react-bootstrap";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineDown } from "react-icons/ai";
import { MdDone } from "react-icons/md";
import { BsPencilFill } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import More from "../../../../assets/images/settings/drag.svg";
import Modal from "../../../utils/tickets/ModalB";
import CategoryLevel from "./CategoryLevel";
import AddLeveBox from "./AddLeveBox";
import CategoryDetailsPopover from "./CategoryDetailsPopover";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const Category = props => {
  const {
    t,
    i18n,
    title,
    sla,
    priority,
    handleCreateTicketSubject,
    handleDeleteTicketSubject,
    handleUpdateTicketSubject,
    enable,
    createdAt,
    updatedAt,
    id,
    parentId,
    items,
    level,
    priorities,
    parentList,
    handlerOpenLevel,
    nature,
    categories,
    categoryListRef
  } = props;
  const [editable, setEditable] = useState(false);
  const [openModalState, setOpenModalState] = useState(false);
  const [categoriesParentList, setCategoriesParentList] = useState([]);
  const [actualPriority, setActualPriority] = useState({
    value: priority.label,
    label: priority.name
  });
  // const [actualPriority, setActualPriority] = useState({ value: priority.name, label: priority.label });
  const [actualTitle, setActualTitle] = useState(null);

  const [subjectError, setSubjectError] = React.useState(undefined);
  // const [subjectErrors, setSubjectErrors] = React.useState({});

  useEffect(() => {
    setActualTitle(title);
  }, [title]);

  useEffect(() => {
    setActualPriority({ value: priority.label, label: priority.name });
  }, [priority]);

  const handleInputChange = event => {
    const { value } = event.currentTarget;

    let clonedCategories = categories.filter(
      t => t.title.toLowerCase().trim() !== props.title.toLowerCase().trim()
    );

    setSubjectError(
      clonedCategories.filter(
        t => t.title.toLowerCase().trim() === value.toLowerCase().trim()
      ).length
        ? "translations.subject_name_not_uniq"
        : undefined
    );

    setActualTitle(value);
  };

  const handleTitleUpdate = () => {
    if (subjectError) {
    } else if (title === actualTitle) {
      setEditable(!editable);
    } else if (actualTitle === "") {
      setSubjectError("translations.subject_name_empty");
    } else {
      let sla =
        typeof categoryData.sla === "undefined" ? "00:00:00" : categoryData.sla;
      handleUpdateTicketSubject(
        actualTitle,
        categoryData.priority,
        nature,
        id,
        sla
      );
      setEditable(!editable);
    }
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const myRef = useRef(null);

  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);

  // (states.opened[`category_${parentId}`] == false || states.opened.hasOwnProperty(`category_${parentId}`) == false)

  // console.log('categoryListRef ', states?.parentRef)

  useEffect(() => {
    if (
      states.opened[`category_${id}`] == false ||
      states.opened.hasOwnProperty(`category_${id}`) == false
    ) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [states.opened]);

  const [categoryData, setCategoryData] = useState({
    title: title,
    priority: {
      name: priority.name,
      label: priority.label
    },
    enable: enable,
    sla: sla,
    created_at: createdAt,
    updated_at: updatedAt,
    id: id,
    items: items
  });

  useEffect(() => {
    const tempData = {
      title: title,
      priority: {
        name: priority.name,
        label: priority.label
      },
      enable: enable,
      sla: sla,
      created_at: createdAt,
      updated_at: updatedAt,
      id: id,
      items: items
    };
    setCategoryData(tempData);
  }, [title, sla, id, priority]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  useEffect(() => {
    setShow(false);
  }, [states.closeAllPopover]);

  const openModal = item => {
    setCategoryData(item);
    setOpenModalState(true);
  };

  const handeCategoryEditable = () => {
    setEditable(!editable);
  };

  const handleCloseModalDelete = () => {
    setOpenModalState(false);
    handleDeleteTicketSubject(categoryData);
  };

  useEffect(() => {
    if (subjectError != "undefined") {
      setTimeout(() => {
        setSubjectError(undefined);
      }, 3000);
    }
  }, [subjectError]);

  useEffect(() => {
    if (
      parentList != "undefined" &&
      parentList != null &&
      parentId != "undefined" &&
      parentId != null &&
      parentId != ""
    ) {
      // setCategoriesParentList([...categoriesParentList, ...parentList, ...[parentId]]);
      setCategoriesParentList([...parentList, ...[parentId]]);
    }
  }, [parentList, parentId]);

  return (
    <div className="category-box" style={{ position: "relative" }}>
      {/* <Row className="category-box-header d-flex justify-content-between">
        <span className="parent-category-header-span">Parent Category</span>
        <BiChevronRight className="right-arrow-category-header" />
      </Row> */}
      <Row
        className={
          isActive
            ? "category-box-content mt-2 d-flex justify-content-around category-selected"
            : "category-box-content mt-2 d-flex justify-content-around"
        }
      >
        <img
          src={More}
          className="more-icon"
          onClick={() => {
            dispatch({
              type: "SET",
              key: "closeAllPopover",
              payload: Math.random()
            });
            if (items.length <= 0) {
            }
            {
              if(states.opened[`category_${id}`] == true && states.opened.hasOwnProperty(`category_${id}`) == true) {
                handlerOpenLevel(`category_${categoriesParentList.at(-1)}`, categoriesParentList);
              }else{
                handlerOpenLevel(`category_${id}`, categoriesParentList);
              }
            }
          }}
        />
        <input
          disabled={!editable}
          className={
            isActive
              ? "category-content-input input-category-selected"
              : "category-content-input"
          }
          style={{ border: subjectError ? "2px red solid" : "" }}
          placeholder={editable == false ? actualTitle : ""}
          value={editable == true ? actualTitle : ""}
          title={actualTitle}
          // onBlur={e => handleTitleUpdate(e)}
          onChange={e => handleInputChange(e)}
        />

        {editable === false ? (
          <>
            <span>
              <BsPencilFill
                className="modify-icon-span"
                onClick={handeCategoryEditable}
              />
            </span>
            <span>
              <RiDeleteBinLine
                className="delete-icon-span"
                onClick={() => openModal(categoryData)}
              />
            </span>
          </>
        ) : (
          <>
            {/* <OverlayTrigger
      trigger="click"
      key={'bottom'}
      placement={'bottom'}
      overlay={
        <CategoryDetailsPopover
        ref={myRef}
        target={myRef}
        show={show}
        t={t}
        categoryData={categoryData}
        priorities={priorities}
        actualPriority={actualPriority}
        nature={nature}
        handleUpdateTicketSubject={handleUpdateTicketSubject}
        handleClick={handleClick}
        parentId={parentId}
        categoriesParentList={categoriesParentList}
        />
      }
      >
      <AiOutlineDown
      // ref={myRef}
      className="down-icon-span"
      // onClick={handleClick}
      />
    </OverlayTrigger> */}

            <span ref={myRef}>
              <AiOutlineDown
                // ref={myRef}
                className="down-icon-span"
                onClick={handleClick}
              />

              <CategoryDetailsPopover
                // ref={myRef}
                target={myRef}
                myRef={categoryListRef}
                show={show}
                t={t}
                categoryData={categoryData}
                priorities={priorities}
                actualPriority={actualPriority}
                // nature={nature}
                handleUpdateTicketSubject={handleUpdateTicketSubject}
                handleClick={handleClick}
                parentId={parentId}
                categoriesParentList={categoriesParentList}
              />
            </span>

            <span>
              <MdDone className="done-icon-span" onClick={handleTitleUpdate} />
            </span>
          </>
        )}
        <br />
        {subjectError ? (
          <span className={"text-danger"}>{t(subjectError)}</span>
        ) : (
          ""
        )}
      </Row>
      <span className="category-selected-arrow"></span>
      {items.length <= 0 ? (
        <div
          style={{
            marginLeft: "311px",
            marginTop: "-50px",
            position: "absolute",
            zIndex: 999
          }}
        >
          <AddLeveBox
            t={t}
            i18n={i18n}
            // id={id}
            level={level}
            handlerOpenLevel={handlerOpenLevel}
            parentId={id}
            categoriesParentList={categoriesParentList}
            handleCreateTicketSubject={handleCreateTicketSubject}
            handleUpdateTicketSubject={handleUpdateTicketSubject}
            handleDeleteTicketSubject={handleDeleteTicketSubject}
            parentSla={sla}
            parentPriority={priority}
          />
        </div>
      ) : (
        <div
          style={{
            marginLeft: "311px",
            marginTop: "-50px",
            position: "absolute",
            zIndex:
              states.opened[`category_${id}`] == false ||
              states.opened.hasOwnProperty(`category_${id}`) == false
                ? 0
                : 99
          }}
        >
          <CategoryLevel
            t={t}
            i18n={i18n}
            categories={items}
            level={level}
            parentList={categoriesParentList}
            //priorities={priorities}
            handleCreateTicketSubject={handleCreateTicketSubject}
            handleUpdateTicketSubject={handleUpdateTicketSubject}
            handleDeleteTicketSubject={handleDeleteTicketSubject}
            priorities={states.priorities}
            parentSla={sla}
            parentPriority={priority}
            categoryListRef={categoryListRef}
          />
        </div>
      )}
      {/* <div
        style={{
          marginLeft: "311px",
          marginTop: "-50px",
          position: "absolute",
        }}
      >
        {items.length <= 0 ? (
          <AddLeveBox
            t={t}
            i18n={i18n}
            id={id}
            level={level}
            handlerOpenLevel={handlerOpenLevel}
            parentId={id}
            categoriesParentList={categoriesParentList}
            handleCreateTicketSubject={handleCreateTicketSubject}
            handleUpdateTicketSubject={handleUpdateTicketSubject}
            handleDeleteTicketSubject={handleDeleteTicketSubject}
          />
        ) : (
          <CategoryLevel
            t={t}
            i18n={i18n}
            categories={items}
            level={level}
            parentList={categoriesParentList}
            //priorities={priorities}
            handleCreateTicketSubject={handleCreateTicketSubject}
            handleUpdateTicketSubject={handleUpdateTicketSubject}
            handleDeleteTicketSubject={handleDeleteTicketSubject}
            priorities={states.priorities}
          />
        )}
      </div> */}

      <Modal
        t={t}
        container={states.parentRef.current}
        dialogAlertMessage={t("settings.delete_ticket_subject.message")}
        handleClickOpen={openModalState}
        handleCloseAccepte={() => handleCloseModalDelete()}
        handleClose={() => setOpenModalState(false)}
      />
    </div>
  );
};

export default Category;
