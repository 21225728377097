import React, { useContext } from "react";
import PropTypes from "prop-types";
import WebformDashboard from "./webform/WebformDashboard";
import WebformCreate from "./webform/WebformCreate";
import WebformDetails from "./webform/WebformDetails";

import { SharedDataContext } from "../app/UseContext";

const WebformAnalytics = props => {
  const { i18n, t, kind, defaultLang } = props;

  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );

  const [stateWebForm, setStateWebForm] = React.useState({
    notify: false,
    notifyMsg: ""
  });

  const handleSharedDataContext = (page, data = {}) => {
    setSharedDataContext({
      ...sharedDataContext,
      conversational: { page, data }
    });
  };

  const getStepContent = step => {
    // console.log(step, "step");
    switch (step) {
      case 1:
        return (
          <WebformCreate
            t={t}
            defaultLang={defaultLang}
            i18n={i18n}
            kind={kind}
            sharedDataContext={sharedDataContext}
            handleStateWebForm={setStateWebForm}
            handleSharedDataContext={handleSharedDataContext}
          />
        );
      default:
        return (
          <WebformDashboard
            t={t}
            defaultLang={defaultLang}
            i18n={i18n}
            kind={kind}
            stateWebForm={stateWebForm}
            handleStateWebForm={setStateWebForm}
            sharedDataContext={sharedDataContext}
            handleSharedDataContext={handleSharedDataContext}
          />
        );
    }
  };

  return <>{getStepContent(sharedDataContext.conversational.page)}</>;
};

WebformAnalytics.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired
};

export default WebformAnalytics;
