import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import NoCategory from "./AutoPriorization/NoCategory";
// Use Socket io - import
//import io from "socket.io-client";
import { Provider } from "react-redux";
// import store from './redux/store'
import store from "../../../redux/store";

import "react-tabs/style/react-tabs.css";
import { Tab, Tabs as Tabber, TabList, TabPanel } from "react-tabs";
import Content from "./Content";

/* START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
import { TicketSettingsHttpService } from "../../../services/HttpService";
import {
  SOCKET,
  SIO_CREATE_TICKET_SUBJECT,
  SIO_UPDATE_TICKET_SUBJECT,
  SIO_DELETE_TICKET_SUBJECT,
  SIO_TICKET_SETTINGS,
  SIO_GET_TICKET_SUBJECT
} from "../../../constants/Constants";
/* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
import { CONSTANT } from "../../../constants/browser";
import Footer from "../../layouts/Footer";
import CategoryList from "./AutoPriorization/CategoryList";

import { AiOutlineFullscreen } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { goFullScreen } from "../../../utils/general";
import ReportingSettings from "../ReportingSettings";

//const socket = io(SOCKET.BASE_URL);

const Tabs = props => {
  const { t, kind, i18n, socket } = props;

  const cardStyle = {
    titleContainer: {
      padding: "0 6rem"
    },
    h2: {
      padding: "1.5rem 0",
      fontSize: "2rem"
    },
    card: {
      width: "initial",
      maxWidth: "initial"
    }
  };

  // const { sharedDataContext } = useContext(SharedDataContext); // setSharedDataContext

  const [ticketSettings, setTicketSettings] = useState([]);
  const [ticketSubject, setTicketSubject] = useState(null);
  const [ticketSubjectSetting, setTicketSubjectSetting] = useState({});
  const [ticketSubjectItems, setTicketSubjectItems] = useState([]);

  const [activeTab, setActiveTab] = useState(1);

  const [createCategory, setCreateCategory] = useState(null);

  useEffect(() => {
    if (ticketSubject != null) {
      if (ticketSubject?.length == 0) {
        setCreateCategory(false);
      } else {
        setCreateCategory(true);
      }
    }
  }, [ticketSubject]);

  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );
  const {
    priority,
    nature,
    status,
    category,
    customer_information
  } = ticketSettings;

  /* START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
  const onSocketGetTicketSettings = response => {
    //  console.log("onSocketGetTicketSettings : ", response);

    if (response && (response.status === 200 || response.status === 202)) {
      if (!localStorage.getItem(CONSTANT.LOCAL_STORAGE_LANG_KEY)) {
        localStorage.setItem(CONSTANT.LOCAL_STORAGE_LANG_KEY, "en");
      }

      if (localStorage.getItem(CONSTANT.LOCAL_STORAGE_LANG_KEY) === "en") {
        setTicketSettings(response.data[0].lang_en);
      }
      if (localStorage.getItem(CONSTANT.LOCAL_STORAGE_LANG_KEY) === "fr") {
        setTicketSettings(response.data[0].lang_fr);
      }
    }
  };

  const onSocketGetTicketSubject = response => {
    if (response && (response.status === 200 || response.status === 202)) {
      setTicketSubject(response.data.list);
      setTicketSubjectSetting(response.data.settings[0]);
    }
  };

  const initSocketTicketSettings = () => {
    //  console.log("initSocketTicketSettings : **** ");
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.publickey
            : false,
        apisid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.apisid
            : false,
        sessionid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.session_id
            : false
      }
    };

    socket.on(SIO_TICKET_SETTINGS, event => {
      const response = JSON.parse(event);
      //  console.log("SIO_TICKET_SETTINGS : ", response);
      onSocketGetTicketSettings(response);
    });

    TicketSettingsHttpService.getDatasTicketSettings(receptor)
      .then(response => {
        //console.log("getDatasTicketSettings : ", response);

        if (response.status === 200 || response.status === 202) {
          //  console.log("test success : ", response);
        } else {
          //  console.log("test error : ", response);
        }
      })
      .catch(e => {
        //console.log(e);
      });
  };
  /* END $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

  const initSocketTicketSubject = () => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };

    socket.on(SIO_GET_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);
      onSocketGetTicketSubject(response);
    });

    TicketSettingsHttpService.getTicketSubject(receptor).then(response => {
      if (response.status === 200 || response.status === 202) {
        //  console.log("test success : ", response);
      } else {
        //  console.log("test error : ", response);
      }
    });
  };

  const handleCreateCategoryButtonClick = () => {
    setCreateCategory(true);
  };

  const handleCreateTicketSubject = params => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.createTicketSubject(params, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const updateTicketSubject = (id, params) => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.updateTicketSubject(id, params, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const deleteTicketSubject = id => {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: sharedDataContext.currentUser.publickey,
        apisid: sharedDataContext.currentUser.apisid,
        sessionid: sharedDataContext.currentUser.session_id
      }
    };
    TicketSettingsHttpService.deleteTicketSubject(id, receptor)
      .then(response => {
        if (response && response.data && response.data.status === 202) {
        } else {
        }
      })
      .catch(error => {});
  };

  const onSocketTicketSubject = (response, params, type) => {
    if (response && response.status === 200) {
      //console.log("params", params);
      const msg =
        type === "create"
          ? t("notification.msg_create_ticket_subject_success")
          : type === "update"
          ? t("notification.msg_update_ticket_subject_success")
          : t("notification.msg_delete_ticket_subject_success");
      const msg_success =
        t("settings.auto_priorization.ticket_subject") +
        " " +
        params.title +
        " " +
        msg;
      setTicketSubjectItems(response.data);
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "success",
          content: { title: "", msg: msg_success }
        }
      });
    } else if (
      response &&
      response.status === 500 &&
      response?.error === "CATEGORY_NAME_EXIST"
    ) {
      const msg_error =
        type === "create"
          ? t("translations.subject_name_not_uniq")
          : type === "update"
          ? t("translations.subject_name_not_uniq")
          : t("translations.subject_name_not_uniq");

      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: { title: "", msg: msg_error }
        }
      });
    } else {
      const msg_error =
        type === "create"
          ? t("notification.msg_create_ticket_subject_error")
          : type === "update"
          ? t("notification.msg_update_ticket_subject_error")
          : t("notification.msg_delete_ticket_subject_error");
      setSharedDataContext({
        ...sharedDataContext,
        notification: {
          active: true,
          status: "danger",
          content: { title: "", msg: msg_error }
        }
      });
    }
  };

  const initSocketCreateTicketSubject = params => {
    socket.on(SIO_CREATE_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);
      onSocketTicketSubject(response, params, "create");
    });
    handleCreateTicketSubject(params);
  };

  const initSocketUpdateTicketSubject = (id, params) => {
    socket.on(SIO_UPDATE_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);
      onSocketTicketSubject(response, params, "update");
    });
    updateTicketSubject(id, params);
  };

  const initSocketDeleteTicketSubject = params => {
    socket.on(SIO_DELETE_TICKET_SUBJECT, event => {
      const response = JSON.parse(event);
      onSocketTicketSubject(response, params, "delete");
    });
    deleteTicketSubject(params.id);
  };

  const handleCreationTicketSubject = (
    title,
    priority,
    enable,
    sla,
    all_parent_id,
    parent_id
  ) => {
    const createTicketSubject = {
      sio_channel: SIO_CREATE_TICKET_SUBJECT,
      title: title,
      enable: enable,
      priority: priority,
      sla: sla,
      all_parent_id: all_parent_id == null ? [] : all_parent_id,
      parent_id: parent_id == null ? "" : parent_id
    };
    initSocketCreateTicketSubject(createTicketSubject);
  };

  const handleUpdateTicketSubject = (
    title,
    priority,
    id,
    sla,
    all_parent_id,
    parent_id
  ) => {
    const updateTicketSubject = {
      sio_channel: SIO_UPDATE_TICKET_SUBJECT,
      title: title,
      priority: priority,
      sla: sla,
      all_parent_id: all_parent_id == null ? [] : all_parent_id,
      parent_id: parent_id == null ? "" : parent_id
    };
    initSocketUpdateTicketSubject(id, updateTicketSubject);
  };

  const handleDeleteTicketSubject = params => {
    initSocketDeleteTicketSubject(params);
  };

  useEffect(() => {
    if (sharedDataContext.socketConnected) {
      initSocketTicketSettings();
      initSocketTicketSubject();
    }
    return () => {
      // cleanup
    };
  }, [sharedDataContext]);

  return (
    <>
      <div style={cardStyle.titleContainer}>
        <h2 style={cardStyle.h2}>
          {kind === "dashboard"
            ? t("dashboard.dashboard_overview")
            : t("settings.settings_overview")}
        </h2>
        <div className="card tickets-card">
          <Tabber>
            <TabList>
              <Tab
                onClick={e => {
                  setActiveTab(1);
                }}
              >
                {t("settings.ticket_settings")}
              </Tab>
              <Tab
                onClick={e => {
                  setActiveTab(2);
                }}
              >
                {t("settings.tickets_category_content.category")}
              </Tab>
              <Tab
                onClick={e => {
                  setActiveTab(3);
                }}
              >
                {t("settings.reporting_settings")}
              </Tab>
              {/* Start Fullscreen button */}
              <div
                hidden={activeTab === 1 || activeTab === 3}
                style={{ width: "40px", height: "40px", float: "right" }}
              >
                <Button
                  hidden={ticketSubject?.length <= 0}
                  variant="link"
                  onClick={e => goFullScreen("CategoryListDiv")}
                >
                  <AiOutlineFullscreen size={30} color="#0089e1" />
                </Button>{" "}
              </div>
              {/* End Fullscreen button */}
            </TabList>
            <TabPanel>
              <Content
                kind={kind}
                t={t}
                i18n={i18n}
                ticketSubject={ticketSubject}
                ticketSubjectSetting={ticketSubjectSetting}
                priority={priority}
                nature={nature}
                status={status}
                category={category}
                customerInformation={customer_information}
              />
            </TabPanel>
            <TabPanel style={{ minHeight: "500px" }}>
              {createCategory == null ? (
                <div></div>
              ) : createCategory == false ? (
                <NoCategory
                  handleCreateCategoryButtonClick={
                    handleCreateCategoryButtonClick
                  }
                  t={t}
                  i18n={i18n}
                />
              ) : (
                <Provider store={store}>
                  <CategoryList
                    t={t}
                    i18n={i18n}
                    ticketSubject={ticketSubject}
                    ticketSubjectSetting={ticketSubjectSetting}
                    priority={priority?.items}
                    handleCreateTicketSubject={handleCreationTicketSubject}
                    handleUpdateTicketSubject={handleUpdateTicketSubject}
                    handleDeleteTicketSubject={handleDeleteTicketSubject}
                    nature={nature}
                  />
                </Provider>
              )}
            </TabPanel>
            <TabPanel>
              <Provider store={store}>
                <ReportingSettings
                  kind={kind}
                  t={t}
                  i18n={i18n}
                  ticketSubject={ticketSubject}
                  ticketSubjectSetting={ticketSubjectSetting}
                  priority={priority}
                  nature={nature}
                  status={status}
                  category={category}
                  customerInformation={customer_information}
                />
              </Provider>
            </TabPanel>
          </Tabber>
        </div>
      </div>
      <Footer t={t} />
    </>
  );
};

Tabs.propTypes = {
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired
};
const TabsWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <Tabs {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);
export default TabsWithSocket;
