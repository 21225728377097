import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LoadingCircle } from "../../assets/images/loading.svg";

const Loader = props => {
  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 100,
        height: 100
      }}
    >
      <LoadingCircle
        style={{
          width: props.width ? props.width : null,
          height: props.height ? props.height : null
        }}
      />
    </div>
  );
};

Loader.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

export default Loader;
