import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { IoMdAddCircle } from "react-icons/io";
import CategoryLevel from "./CategoryLevel";
import { Button, Row } from "react-bootstrap";
import { BiChevronRight } from "react-icons/bi";
import CreateCategory from "./CreateCategory";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const AddLeveBox = props => {
  const {
    t,
    i18n,
    id,
    handlerOpenLevel,
    level,
    parentId,
    categoriesParentList,
    handleCreateTicketSubject,
    handleUpdateTicketSubject,
    handleDeleteTicketSubject,
    parentSla,
    parentPriority
  } = props;
  const [addCatLevel, setAddCatLevel] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);

  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);

  const handleChangeAddCatLevel = () => {
    setAddCatLevel(!addCatLevel);
    //handlerOpenLevel(parentId, []);
  };

  useEffect(() => {
    setCurrentLevel(level + 1);
  }, [level]);
  return (
    <div
      hidden={
        states.opened[`category_${parentId}`] == false ||
        states.opened.hasOwnProperty(`category_${parentId}`) == false
      }
      style={{ marginBottom: "60px", marginRight: "60px" }}
    >
      {addCatLevel == true ? (
        //     <CategoryLevel
        //     t={t}
        //     i18n={i18n}
        //     categories={[]}
        //     level={level}
        //     parentList={parentList}
        //     handleCreateTicketSubject={handleCreateTicketSubject}
        //     handleUpdateTicketSubject={handleUpdateTicketSubject}
        //     handleDeleteTicketSubject={handleDeleteTicketSubject}
        //     priorities={states.priorities}
        //   />
        <div style={{ minHeight: `100px`, width: `317px` }}>
          <Row
            style={{ margin: `0` }}
            className="category-level-box-header d-flex justify-content-between"
          >
            <span className="parent-category-header-span">
              {currentLevel == 1
                ? t("settings.tickets_category_content.parent_category")
                : `${t(
                    "settings.tickets_category_content.sub_cat_level"
                  )} ${currentLevel}`}
            </span>
            <BiChevronRight className="right-arrow-category-header" />
          </Row>
          <Row style={{ paddingLeft: `15px` }}>
            <CreateCategory
              t={t}
              i18n={i18n}
              categories={[]}
              level={currentLevel}
              parentId={parentId}
              categoriesParentList={categoriesParentList}
              priorities={states.priorities}
              handleCreateTicketSubject={handleCreateTicketSubject}
              parentSla={parentSla}
              parentPriority={parentPriority}
              //nature={nature}
            />
            <br />
          </Row>
        </div>
      ) : (
        <div className="addLevelBox">
          <div className="addLevelBoxConteneur">
            {/* <a onClick={(e) => handleChangeAddCatLevel()} style={{cursor: 'pointer', border: '1px solid yellow'}}> */}
            <Button
              variant="link"
              onClick={e => handleChangeAddCatLevel()}
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              <IoMdAddCircle color="#0089E1" size={45} />
              <p className="addLevelBoxSpan">
                {t("settings.tickets_category_content.add_level")}
              </p>
            </Button>
            {/* </a> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddLeveBox;
