// export function checkIfValueIsInt(value) {
//     var regex = /^[0-9]+$/;
//     if (value.match(regex)) {
//         // Must input numbers
//         console.log("False")
//         return false;

//     } else {
//         console.log("True")
//         return true;
//     }
// }

export function checkIfValueIsInt(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export function SplitTime(numberOfHours) {
  var Days = Math.floor(numberOfHours / 24);
  var Remainder = numberOfHours % 24;
  var Hours = Math.floor(Remainder);
  var Minutes = Math.floor(60 * (Remainder - Hours));
  return { Days: Days, Hours: Hours, Minutes: Minutes };
}
export function isNumeric(value) {
  return /^\d+$/.test(value);
}

export function goFullScreen(temp) {
  var elem = document.getElementById(temp);

  // if (document.body.requestFullscreen) {
  //   document.body.requestFullscreen();
  // } else if (document.body.mozRequestFullScreen) { /* Firefox */
  //  document.body.mozRequestFullScreen();
  // } else if (document.body.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
  //  document.body.webkitRequestFullscreen();
  // } else if (document.body.msRequestFullscreen) { /* IE/Edge */
  //  document.body.msRequestFullscreen();
  // }

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
}

export function goFullScreen1(temp) {
  var elem = document.getElementById(temp);

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
}

export function goFullScreenOld(temp) {
  var elem = document.getElementById(temp);
  elem.style.height = window?.innerHeight;
  elem.style.width = window?.innerWidth;
}

// export function isFullScreen(temp) {
//   var screen = document.getElementById(temp);
//   if (screen?.offsetWidth == window?.innerWidth && screen?.offsetHeight == window?.innerHeight) {
//     return "OUI";
//   } else {
//     return "NON";
//   }
// }
export function isFullScreen() {
  if (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  ) {
    return true;
  }
  return false;
}
