import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "../../../assets/images/onboard/search.svg";
//import Services from "../onboarding/steps/Services";
import { APP_ENV } from "../../../constants/Constants.js";

const Modal = props => {
  const {
    t,
    title,
    content,
    checkboxSelectAll,
    kind,
    handleContinue,
    buttonText,
    agentModal,
    agentCount,
    countAgentFromXp,
    handleChooseService,
    checkedServices,
    handleCloseRessourceModal,
    handleSearchAgent,
    handleSelectAll
  } = props;

  const modalStyle = {
    title: {
      paddingBottom: kind === "channel" ? 0 : "1.125rem"
    }
  };

  const [state, setState] = useState({ input: "" });

  const addAgentsRef = React.createRef();

  const handleSubmit = () => {
    addAgentsRef.current.click();
    handleContinue();
    setState({ input: "" });
    handleSearchAgent("");
    handleCloseRessourceModal(false);
  };

  const handleCloseModal = () => {
    setState({ input: "" });
    handleCloseRessourceModal(true);
    handleSearchAgent("");
  };

  const handleInputChange = event => {
    const { value } = event.currentTarget;
    setState({ input: value });
    handleSearchAgent(value);
  };

  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = "";

  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  let LINK = "";
  if (parts.length >= 3) {
    subdomain = parts[0];
    const env_admin_link =
      APP_ENV === "DEV"
        ? "admin.rightcomtech.com/roles/manage/"
        : APP_ENV === "STAGING"
        ? "admin-staging.right-com.com/roles/manage/"
        : APP_ENV === "PROD"
        ? "admin.sunubank.tg/roles/manage/"
        : "";
    LINK = `https://${subdomain}.${env_admin_link}`;
  }

  return (
    <div className="modal" ref={agentModal}>
      <div className="modal-background" />
      <div className="modal-card">
        <button
          className="modal-close modal-close-incard"
          aria-label="close"
          //  onClick={handleContinue}
        />
        <button
          className="modal-close is-large"
          aria-label="close"
          ref={addAgentsRef}
          onClick={handleCloseModal}
        />
        <header className="modal-card-head">
          <div className="title-container" style={modalStyle.title}>
            <h2 className="title">{title}</h2>
          </div>
          {kind === "agent" && (countAgentFromXp !== 0 || agentCount !== 0) && (
            <div className="search-box">
              <input
                className="input"
                type="text"
                placeholder={t("onboard.steps.search_agent")}
                value={state.input}
                maxLength={96}
                onChange={e => handleInputChange(e)}
              />
              <img src={SearchIcon} alt="search" />
            </div>
          )}
          {agentCount !== 0 && (
            <div className="search-select">
              <span className="search-select-text">
                {t("onboard.steps.select_all_agent")}
              </span>
              <span className="search-select-check">{checkboxSelectAll}</span>
            </div>
          )}
        </header>
        <section className="modal-card-body">
          {kind === "agent" ? (
            <div>
              {countAgentFromXp === 0 && agentCount === 0 ? (
                <span>
                  {t("onboard.user_list_empty")}
                  <a target={"_blank"} href={LINK}>
                    {t("onboard.here.text")}
                  </a>
                  .
                </span>
              ) : (
                agentCount === 0 && <div>{t("onboard.user_not_found")}</div>
              )}
              {content}
            </div>
          ) : (
            <></>
          )}
          {/* // <Services
            //   kind={kind}
            //   handleChooseService={handleChooseService}
            //   checkedServices={checkedServices}
            // /> */}
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-primary"
            aria-label="close"
            onClick={handleSubmit}
          >
            {buttonText}
          </button>
        </footer>
      </div>
    </div>
  );
};

Modal.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  agentCount: PropTypes.number.isRequired,
  countAgentFromXp: PropTypes.number.isRequired,
  content: PropTypes.shape({}).isRequired,
  checkboxSelectAll: PropTypes.shape({}).isRequired,
  kind: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  agentModal: PropTypes.shape({}).isRequired,
  handleCloseRessourceModal: PropTypes.func.isRequired,
  handleSearchAgent: PropTypes.func.isRequired,
  handleChooseService: PropTypes.func.isRequired,
  checkedServices: PropTypes.shape({}).isRequired,
  handleSelectAll: PropTypes.func.isRequired
};

export default Modal;
