import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Popover,
  Overlay,
  OverlayTrigger,
  Button
} from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineDown } from "react-icons/ai";
import { MdDone } from "react-icons/md";
import More from "../../../../assets/images/settings/drag.svg";
import CreateCategoryDetailsPopover from "./CreateCategoryDetailsPopover";

const CreateCategory = props => {
  const {
    t,
    priorities,
    i18n,
    categories,
    level,
    parentList,
    parentId,
    categoriesParentList,
    nature,
    handleCreateTicketSubject,
    parentSla,
    parentPriority
  } = props;
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("");
  const [allParentIdToSend, setAllParentIdToSend] = useState([]);
  const [subjectError, setSubjectError] = useState(undefined);
  const states = useSelector(state => state, shallowEqual);

  const dispatch = useDispatch();

  const myRef = useRef(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleTitleChange = event => {
    const { value } = event.currentTarget;
    if (value.length <= 64) {
      setCurrentTitle(value);
    }
  };

  useEffect(() => {
    if (categoriesParentList.includes(parentId)) {
      setAllParentIdToSend(categoriesParentList);
    } else {
      setAllParentIdToSend([...categoriesParentList, ...[parentId]]);
    }
  }, [categoriesParentList, parentId]);

  useEffect(() => {
    if (categories == null) {
    } else {
      setSubjectError(
        categories?.filter(
          i =>
            i.title?.toLowerCase().trim() === currentTitle?.toLowerCase().trim()
        ).length
          ? "translations.subject_name_not_uniq"
          : undefined
      );
    }
  }, [currentTitle]);

  const handleCreate = () => {
    if (subjectError) {
      return false;
    } else if (currentTitle == "") {
      setSubjectError("translations.subject_name_empty");
    } else if (
      states.categoryPriority == "undefined" ||
      states.categoryPriority == {} ||
      states.categoryPriority == null
    ) {
      setSubjectError("translations.subject_priority_not_defined");
      setTimeout(() => {
        setSubjectError(undefined);
      }, 2000);
    } else {
      let p = {
        name: states.categoryPriority.value,
        label: states.categoryPriority.label
      };
      let slaTime =
        typeof states.categorySLA === "undefined"
          ? "00:00:00"
          : states.categorySLA;
      handleCreateTicketSubject(
        currentTitle,
        p,
        true,
        slaTime,
        categoriesParentList,
        parentId
      );
      setCurrentTitle("");
      dispatch({ type: "SET", key: "categorySLA", payload: null });
      dispatch({ type: "SET", key: "categoryPriority", payload: null });
      dispatch({ type: "SET", key: "resetSlaInBosx", payload: Math.random() });
    }
  };
  useEffect(() => {
    if (subjectError != "undefined") {
      setTimeout(() => {
        setSubjectError(undefined);
      }, 3000);
    }
  }, [subjectError]);

  return (
    <div className="category-box">
      {/* <div ref={myRef}>
        <Button onClick={handleClick}>Holy guacamole!</Button>
        <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={myRef.current}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Title as="h3">Popover bottom</Popover.Title>
          <Popover.Content>
            <strong>Holy guacamole!</strong> Check this info.
          </Popover.Content>
        </Popover>
      </Overlay>
      </div> */}

      {/* <Row className="category-box-header d-flex justify-content-between">
        <span className="parent-category-header-span">Parent Category</span>
        <BiChevronRight className="right-arrow-category-header" />
      </Row> */}
      <Row className="category-box-content mt-2 d-flex justify-content-around">
        {/* <img src={More} className="more-icon" /> */}
        <span className="more-icon" />
        <input
          className="category-content-input"
          style={{
            marginLeft: "10px",
            border: subjectError ? "2px red solid" : ""
          }}
          value={currentTitle}
          onChange={e => handleTitleChange(e)}
          // style={{marginLeft: "17px", marginRight: "-7px"}}
          placeholder={t("settings.tickets_category_content.enter_name")}
        />
        {/*  */}
        <div ref={myRef}>
          <span className="">
            <AiOutlineDown onClick={handleClick} className="down-icon-span" />
          </span>
          {/* {subjectError ? <Row>Okay Error</Row> : null} */}
          <CreateCategoryDetailsPopover
            myRef={myRef}
            target={target}
            show={show}
            t={t}
            priorities={priorities}
            handleClick={handleClick}
            parentSla={parentSla}
            parentPriority={parentPriority}
          />
        </div>
        {/*  */}
        <span>
          <MdDone className="done-icon-span" onClick={handleCreate} />
        </span>
        <br />
        {subjectError ? (
          <span className={"text-danger"}>{t(subjectError)}</span>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
};

export default CreateCategory;
