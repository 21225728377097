import React from "react";
import PropTypes from "prop-types";
import DatePicker from "../../common/DatePicker";

const CompleteRequest = props => {
  const {
    t,
    CalendarIcon,
    MoreIcon,
    ticketStats,
    ticketCompleteDateFilter
  } = props;
  const status = "completed";

  return (
    <div className="column">
      <div className="card analytics-card-parent analytics-card-parent-complete-request">
        <div className="card-content analytics-card">
          <p className="subtitle">{t("dashboard.complete_request")}</p>
          <div className="title title-manageticket">
            <span className="statistic-ticket">{ticketStats}</span>
            <div className="arrawbtn" style={{ background: "#00bd394a" }}>
              <button
                className="uptriangle"
                style={{
                  background: "#00BD39",
                  border: "none",
                  margin: "auto"
                }}
              ></button>
            </div>
          </div>
          <div
            className="button is-secondary is-outlined"
            style={{ width: "203px" }}
          >
            {/* <img className="view-more" src={MoreIcon} alt='caneldar' /> */}
            <DatePicker
              t={t}
              getTimeFilter={(start, end) =>
                ticketCompleteDateFilter(start, end)
              }
            />
            <img className="calendar-icon" src={CalendarIcon} alt="caneldar" />
          </div>
        </div>
      </div>
    </div>
  );
};

CompleteRequest.propTypes = {
  t: PropTypes.func.isRequired,
  CalendarIcon: PropTypes.string.isRequired,
  MoreIcon: PropTypes.string.isRequired
};

export default CompleteRequest;
