import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import { options } from "../../configs/options";
import WelcomeImg from "../../assets/images/onboard/welcome.png";
import { NAVIGATION } from "../../constants/Constants";
import { check_onboardingHttpService } from "../../services/HttpService";
import { SharedDataContext } from "../app/UseContext";
import useRouter from "../app/useRouter";
import Loader from "../common/Loader";

const Welcome = props => {
  const { sharedDataContext } = useContext(SharedDataContext);

  //check session useEffect
  const { t, kind, defaultLang, changeLang, isLogged, containerWidth } = props;
  const router = useRouter();
  const hasMount = useRef(false);
  const unmounted = useRef(false);
  const [stateCheckonboarding, setstateCheckonboarding] = useState(false);
  const [loading, setLoading] = useState(true);

  //check session useEffect
  useEffect(() => {
    if (!hasMount.current) {
      // componentDidMount
      checkonboarding();

      hasMount.current = true;
    }
    return () => {
      unmounted.current = true;
      // componentWillUnmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkonboarding() {
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.publickey
            : false,
        apisid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.apisid
            : false,
        sessionid:
          sharedDataContext && sharedDataContext.currentUser
            ? sharedDataContext.currentUser.session_id
            : false
      }
    };

    check_onboardingHttpService.check_onboarding(receptor).then(response => {
      if (response.status === 200 || response.status === 202) {
        if (response.data.data.state) router.push("/dashboard");
        setLoading(false);
        setstateCheckonboarding(response.data.data.state);
      } else {
        // console.log("test error : ", response);
      }
    });
  }
  // console.log("stateCheckonboarding: ", stateCheckonboarding);
  let haveRights =
    sharedDataContext?.currentUser?.is_owner ||
    sharedDataContext?.currentUser?.full_rights?.length;
  return loading ? (
    <Loader width={60} height={60} />
  ) : (
    <>
      <Header
        haveRights={haveRights}
        options={options}
        defaultLang={defaultLang}
        changeLang={changeLang}
        kind={kind}
        isLogged={isLogged}
        t={t}
        containerWidth={containerWidth}
      />
      {props.haveRights && (
        <div className="home-container">
          <div className="columns">
            <div className="column is-three-fifths">
              <h1>{t("home.customer_engagement_on_one_plateform")}</h1>
              <p>{t("home.resolve_your_customer")}</p>
              <Link to={NAVIGATION.ONBOARDING}>
                <button className="button is-primary">
                  {t("home.get_started")}
                </button>
              </Link>
            </div>
            <div className="column">
              <img src={WelcomeImg} alt="onboard img" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Welcome.propTypes = {
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  defaultLang: PropTypes.shape({}).isRequired,
  changeLang: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired
};

export default Welcome;
