import React, { useContext } from "react";
import { SharedDataContext } from "../app/UseContext";
import { API } from "../../constants/Constants";
const ReportingSettings = props => {
  const { t, kind, i18n, socket } = props;
  const [enable, setEnable] = React.useState(false);
  const [recipients, setRecipients] = React.useState([]);
  const [newRecipients, setNewRecipients] = React.useState(null);
  const [setting, setSettings] = React.useState({});
  const [frequence, setFrequence] = React.useState({});
  const [isDirty, setIsDirty] = React.useState(false);
  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );
  const [nextJob, setNextJob] = React.useState("");
  const enableSettings = () => {
    setEnable(!enable);
    if (!enable === false) {
      saveDisableConfig();
    }
  };
  const definePeriod = e => {
    setFrequence(e.target.value);
    setIsDirty(true);
  };
  const addRecipient = () => {
    let recipientsList = [...recipients, newRecipients];
    setRecipients(recipientsList);
    setNewRecipients(null);
    setIsDirty(true);
  };
  const removeRecipient = key => {
    let r = recipients.filter(e => e != key);
    setRecipients([...r]);
    setIsDirty(true);
  };
  const defineConfigs = response => {
    let s = response.data?.settings[0];
    if (s.company) {
      setSettings(s);
      setRecipients([...s.recipients]);
      setFrequence(s.frequence);
      // console.log(s["enable"] === 1);
      setEnable(s["enabled"] === 1);
      setNextJob(response.data?.job.split("next run at:")[1].split(")")[0]);
    }
  };
  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const saveConfig = () => {
    var myHeaders = new Headers();
    myHeaders.append("apisid", sharedDataContext?.currentUser?.apisid);
    myHeaders.append("publickey", sharedDataContext?.currentUser?.publickey);
    myHeaders.append("sessionid", sharedDataContext?.currentUser?.session_id);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      frequence: frequence,
      recipients: recipients,
      enabled: enable ? 1 : 0
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw
    };

    fetch(API.BASE_URL + "/reporting", requestOptions)
      .then(response => response.text())
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));
  };
  const saveDisableConfig = () => {
    var myHeaders = new Headers();
    myHeaders.append("apisid", sharedDataContext?.currentUser?.apisid);
    myHeaders.append("publickey", sharedDataContext?.currentUser?.publickey);
    myHeaders.append("sessionid", sharedDataContext?.currentUser?.session_id);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      frequence: "none",
      recipients: [],
      enabled: 0
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw
    };

    fetch(API.BASE_URL + "/reporting", requestOptions)
      .then(response => response.text())
      // .then(result => console.log(result))
      .catch(error => console.log("error", error));
  };
  React.useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("apisid", sharedDataContext?.currentUser?.apisid);
    myHeaders.append("publickey", sharedDataContext?.currentUser?.publickey);
    myHeaders.append("sessionid", sharedDataContext?.currentUser?.session_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders
    };

    fetch(API.BASE_URL + "/reporting", requestOptions)
      .then(response => response.json())
      .then(result => defineConfigs(result))
      .catch(error => console.log("error", error));

    console.log(sharedDataContext);
    console.log(setSharedDataContext);
  }, []);
  return (
    <div className={"m-4"}>
      <div>
        <div className={"mb-4"}>
          <div className="form-check">
            <input
              checked={!!enable}
              className="form-check-input"
              type="checkbox"
              value={true}
              id="flexCheckDefault"
              onChange={enableSettings}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              {/*{t("settings.reporting_settings_contents.enable")}*/}
              {t("settings.reporting_settings_contents.enable_desc")}
            </label>
          </div>
          {enable && (
            <p>
              <small className={"muted"}>
                {t("settings.reporting_settings_contents.next_run") +
                  " : " +
                  nextJob}
              </small>
            </p>
          )}
        </div>

        {enable && (
          <div className={"mb-4 w-50"}>
            <h3>{t("settings.reporting_settings_contents.frequence")}</h3>
            <select
              className="form-select form-control mb-3"
              aria-label=".form-select-lg example"
              onChange={definePeriod}
            >
              <option selected>
                {t("settings.reporting_settings_contents.frequence_select")}
              </option>
              <option selected={setting.frequence === "month"} value="month">
                {t("settings.reporting_settings_contents.month")}
              </option>
              <option selected={setting.frequence === "week"} value="week">
                {t("settings.reporting_settings_contents.week")}
              </option>
              <option selected={setting.frequence === "day"} value="day">
                {t("settings.reporting_settings_contents.day")}
              </option>
            </select>
          </div>
        )}
        {enable && (
          <div className={"mb-4 w-50"}>
            <h3>{t("settings.reporting_settings_contents.recipients")}</h3>
            <ul>
              {recipients.map((r, k) => (
                <li
                  key={k}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "space-between"
                  }}
                >
                  {r}
                  <button
                    className="btn btn-default btn-sm"
                    onClick={e => removeRecipient(r)}
                  >
                    <svg
                      viewBox="0 0 16 16"
                      className="bi bi-trash3"
                      fill="red"
                      height="13"
                      width="13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6.5 1a.5.5 0 0 0-.5.5v1h4v-1a.5.5 0 0 0-.5-.5h-3ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1H3.042l.846 10.58a1 1 0 0 0 .997.92h6.23a1 1 0 0 0 .997-.92l.846-10.58Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                      ></path>
                    </svg>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {enable && (
          <div className={"mb-4 w-50"}>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                {t("settings.reporting_settings_contents.add_recipient")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
                value={newRecipients ? newRecipients : ""}
                onChange={e => {
                  setNewRecipients(e.target.value);
                }}
              />
            </div>
            {validateEmail(newRecipients) && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={addRecipient}
              >
                {t("settings.reporting_settings_contents.add")}
              </button>
            )}
          </div>
        )}
        {!isDirty ||
          (enable && (
            <>
              <hr />
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveConfig}
              >
                {t("settings.reporting_settings_contents.save_settings")}
              </button>
            </>
          ))}
      </div>
    </div>
  );
};

export default ReportingSettings;
