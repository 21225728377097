/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import AnalyticsManager from "../dashboard/AnalyticsManager";
import SettingsManager from "../dashboard/SettingsManager";
import TicketsManager from "../dashboard/TicketsManager";
import TicketsManagerDuplicate from "../dashboard/TicketsManagerDuplicate";
import WebformManager from "../dashboard/WebformManager";
import DrawerLayout from "./DrawerLayout";
import Notification from "./utilities/Notification";

import DesktopLogo from "../../assets/images/logo/rightdesk-logo-full.svg";
import ProfileIcon from "../../assets/images/profile/user.svg";
//import NotifIcon from "../../assets/images/profile/notif.svg";
//import SearchIcon from "../../assets/images/profile/search.svg";
import DrawerIcon from "../../assets/images/dashboard/drawer.svg";

import MenuHeader from "../../assets/images/dashboard/header/menu.svg";

import { SharedDataContext } from "../app/UseContext";
import i18next from "i18next";

const Header = props => {
  const {
    i18n,
    t,
    options,
    defaultLang,
    changeLang,
    kind,
    isLogged,
    containerWidth,
    containerHeight,
    agentFromXP,
    agentFromPlatform,
    agentCount,
    ticketCompleteDateFilter,
    ticketPendingDateFilter,
    ticketTotalDateFilter,
    ticketStats,
    latestticket,
    ticketStatsChart,
    graphTicketDateFilter,
    load
  } = props;

  const { sharedDataContext, setSharedDataContext } = useContext(
    SharedDataContext
  );

  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  // console.log('defaultLang',defaultLang)
  // console.log('changeLang',changeLang)
  const topNavCustomStyle = {
    navbar: {
      padding: ".8rem 1rem .8rem 5rem",
      borderBottom: kind !== "app" ? "1px solid #e5e5e5" : "0",
      backgroundColor: kind === "dashboard" ? "#fafbfd" : "#fff"
    },
    isPrimary: {
      marginRight: "5rem",
      marginBottom: 0,
      fontSize: "18px"
    },
    selectLang: {
      borderRadius: "34px",
      boxShadow: "0 3px 20px 0 rgba(137, 137, 137, 0.24)",
      border: "solid 1px #e5e5e5"
    },
    control: {
      width: "9rem"
    },
    profile: {
      width: "3.0625rem",
      height: "3.0625rem",
      border: "solid 1px #e3e3e3",
      borderRadius: "50%",
      maxHeight: "3.0625rem",
      margin: "0 .5rem"
    },
    userName: {
      fontSize: "0.875rem",
      color: "#657288",
      margin: "0 .5rem",
      width: "15rem",
      textAlign: "center"
    },
    search: {
      backgroundColor: kind === "dashboard" ? "#fafbfd" : "#fff"
    },
    select: {
      width: "5rem"
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textTransform: "uppercase",
      fontSize: "13px",
      backgroundColor: state.isSelected ? "#ddd" : "#fff",
      color: state.isSelected ? "#000" : "#000"
    })
  };

  const handleCloseNotification = () => {
    setSharedDataContext({
      ...sharedDataContext,
      notification: {
        active: false,
        status: "",
        content: { title: "", msg: "" }
      }
    });
  };

  useEffect(() => {
    // connection forcer du user
    setSharedDataContext({ ...sharedDataContext, userLogged: true });

    return () => {
      handleCloseNotification();
    };
  }, [sharedDataContext.userLogged, sharedDataContext.notification]);

  return (
    <div
      className={`${
        kind === "dashboard" ||
        kind === "settings" ||
        kind === "tickets" ||
        kind === "duplicate" ||
        kind === "webform"
          ? "column dashboard is-four-fifths"
          : ""
      }`}
    >
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        style={topNavCustomStyle.navbar}
      >
        {containerWidth <= 768 && kind !== "onboard" && (
          <img
            src={DrawerIcon}
            className="menu-dash-icon"
            onClick={toggleDrawer("left", true)}
          />
        )}

        {containerWidth <= 768 && (
          <DrawerLayout
            toggleDrawer={toggleDrawer}
            left={state.left}
            t={t}
            containerWidth={containerWidth}
            haveRights={props.haveRights}
          />
        )}

        {kind !== "dashboard" &&
          kind !== "settings" &&
          kind !== "tickets" &&
          kind !== "duplicate" &&
          kind !== "webform" && (
            <div
              className="navbar-brand"
              style={{ display: "flex", alignItems: "center" }}
            >
              <a className="navbar-item" href="/">
                <img alt="logo icon" width="120px;" src={DesktopLogo} />
              </a>
              <p className="left-menu-logo-text"></p>
              <div className="ml-auto">
                {containerWidth <= 768 && (
                  <Select
                    placeholder={t("translations.select")}
                    styles={customStyles}
                    options={options}
                    value={defaultLang}
                    onChange={changeLang}
                    className="App-Select"
                    isSearchable={false}
                    style={topNavCustomStyle.select}
                  />
                )}
              </div>
            </div>
          )}

        {/* Barre du haut */}
        <div id="navbarBasicExample" className="navbar-menu">
          {/* Barre de recherche */}
          {/* { (kind === 'dashboard' || kind === 'settings' || kind === 'tickets' || kind === 'webform')
            && (
              <div className="navbar-start">
                <div className="field navbar-item">
                  <p className="control has-icons-left has-icons-right">
                    <input className="input search-input" type="text" placeholder={t('header.search')} style={topNavCustomStyle.search} />
                    <span className="icon is-small is-left">
                      <img alt="profil icon" src={SearchIcon} />
                    </span>
                  </p>
                </div>
              </div>
            )} */}

          {/* Zone d'affichage du user connecter & select langue */}
          <div className="navbar-end">
            <div className="navbar-item">
              {sharedDataContext.userLogged ? (
                <>
                  {/* refont new design */}
                  {/* <img alt="notif icon" src={NotifIcon} /> */}

                  <span style={topNavCustomStyle.userName}>
                    {sharedDataContext.currentUser.firstname +
                      " " +
                      sharedDataContext.currentUser.lastname}{" "}
                  </span>

                  {/* refont new design */}
                  {/* <img
                    alt="profil icon"
                    src={ProfileIcon}
                    style={topNavCustomStyle.profile}
                  /> */}
                </>
              ) : (
                <>
                  <img
                    alt="profil icon"
                    src={ProfileIcon}
                    style={topNavCustomStyle.profile}
                  />
                </>
              )}
              <Select
                styles={customStyles}
                placeholder={t("translations.select")}
                options={options}
                value={defaultLang}
                onChange={changeLang}
                className="App-Select"
                isSearchable={false}
              />

              <img alt="menu-header" className="menu-header" src={MenuHeader} />
            </div>
          </div>
        </div>
      </nav>

      {(kind === "onboard" ||
        kind === "dashboard" ||
        kind === "tickets" ||
        kind === "duplicate" ||
        kind === "settings" ||
        kind === "webform") &&
        sharedDataContext.notification.active === true && (
          <Notification
            t={t}
            handleCloseNotification={handleCloseNotification}
            statusNotification={sharedDataContext.notification.status}
            contentNotification={sharedDataContext.notification.content}
            i18n={i18n}
          />
        )}

      {props.haveRights ? (
        <>
          {kind === "dashboard" && (
            <AnalyticsManager
              agentFromXP={agentFromXP}
              agentFromPlatform={agentFromPlatform}
              agentCount={agentCount}
              t={t}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              i18n={i18n}
              ticketCompleteDateFilter={ticketCompleteDateFilter}
              ticketPendingDateFilter={ticketPendingDateFilter}
              ticketTotalDateFilter={ticketTotalDateFilter}
              graphTicketDateFilter={graphTicketDateFilter}
              ticketStats={ticketStats}
              latestticket={latestticket}
              ticketStatsChart={ticketStatsChart}
              load={load}
            />
          )}

          {kind === "settings" && (
            <SettingsManager
              t={t}
              kind={kind}
              containerWidth={containerWidth}
              i18n={i18n}
            />
          )}

          {(kind === "tickets") && (
            <TicketsManager
              t={t}
              defaultLang={defaultLang}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              i18n={i18n}
              ticketStats={ticketStats}
            />
          )}
          {(kind === "duplicate") && (
            <TicketsManagerDuplicate
              t={t}
              defaultLang={defaultLang}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              i18n={i18n}
              ticketStats={ticketStats}
            />
          )}

          {kind === "webform" && (
            <WebformManager
              t={t}
              defaultLang={defaultLang}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              i18n={i18n}
            />
          )}
        </>
      ) : (
        <>
          <div
            style={{
              height: "90vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 24,
              textAlign: "center"
            }}
          >
            {" "}
            <div style={{ maxWidth: "36vw" }}>
              <span>{i18next.t("no_right_to_access_head")}</span>
              <p style={{ fontSize: 16 }}>
                {i18next.t("no_right_to_access_desc")}
              </p>
            </div>{" "}
          </div>
        </>
      )}
    </div>
  );
};

Header.propTypes = {
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultLang: PropTypes.shape({}).isRequired,
  i18n: PropTypes.shape({}).isRequired,
  changeLang: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  isLogged: PropTypes.bool.isRequired,
  containerWidth: PropTypes.number.isRequired,
  containerHeight: PropTypes.number
};

export default Header;
