import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "react-select";
import moment from "moment";
import User from "../../../assets/images/tickets/user.svg";
import Envelope from "../../../assets/images/tickets/envelope.svg";
import SearchIcon from "../../../assets/images/profile/search.svg";
import ProfileIcon from "../../../assets/images/profile/user.svg";
import Phone from "../../../assets/images/tickets/phone-call.svg";
import ArrowDown from "../../../assets/images/tickets/full-down-arrow.svg";
import DownloadIcon from "../../../assets/images/tickets/DownloadIcon.svg";
import { SharedDataContext, DataSocketContext } from "../../app/UseContext";
import Footer from "../../layouts/Footer";
/* START $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
import {
  GetAllTicketsHttpService,
  CreateTicketHttpService,
  UpdateTicketHttpService,
  TicketSettingsHttpService
} from "../../../services/HttpService";
import {
  SOCKET,
  SIO_TICKET_SETTINGS,
  SIO_GET_TICKET_DETAILS,
  SIO_AGENT_PLATFORM,
  SIO_TICKET_UPDATE,
  SIO_GET_TICKET_SUBJECT,
  SIO_GET_TICKET_SUBJECT_SETTINGS,
  SIO_GET_TICKET_NATURE_SETTINGS
} from "../../../constants/Constants";
import { CONSTANT } from "../../../constants/browser";
import {Dropdown, Form, ButtonGroup, Spinner, Badge, Button} from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
// import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RightComCDN from "../../../RightComCDN";

const ticketsLog = [
  {
    agent: ProfileIcon,
    activity: "change ticket proprity",
    date: "1 min ago"
  },
  {
    agent: ProfileIcon,
    activity: "Change Ticket Status",
    date: "1 min ago"
  },
  {
    agent: ProfileIcon,
    activity: "Tag Jean-Julian & Venance to ticket",
    date: "1 min ago"
  },
  {
    agent: ProfileIcon,
    activity: "Assign himself to ticket",
    date: "1 min ago"
  },
  {
    agent: ProfileIcon,
    activity: "Create Ticket #34421231",
    date: "1 min ago"
  }
];
// Calculate Time passed from Date of the last update

//Render Agent Name on blue
const updateMessage = message => {
  // console.log("message", message);
  let tab = message.split(" ");
  //console.log("message", tab);
  if (tab[0] === "Assigned") {
    tab[1] = `<span class="agentName">${tab[1]}</span>`;
    tab[2] = `<span class="agentName">${tab[2]}</span>`;
  } else if (tab[1] === "assigné") {
    tab[2] = `<span class="agentName">${tab[2]}</span>`;
    tab[3] = `<span class="agentName">${tab[3]}</span>`;
  }

  return <div dangerouslySetInnerHTML={{ __html: tab.join(" ") }} />;
};

const updateMessage2 = message => {
  let list = message.split("<ul>");
  let text = [];
  // console.log('message',message)
  //console.log('list',list)
  if (message.indexOf("<ul>") === -1 || list.length === 2) {
    return <div dangerouslySetInnerHTML={{ __html: message }} />;
  } else {
    //console.log(list)
    for (let i of list) {
      text.push(<div dangerouslySetInnerHTML={{ __html: i }} />);
    }
    //console.log(text)
    return text.join(" ");
    //let text = <div dangerouslySetInnerHTML={{ __html: message }} />
    //<div dangerouslySetInnerHTML={{ __html: message }} />;
  }
};

function logout(file) {
  //let url = `https://rccdn.sunubank.tg/api/1.0/download?app_name=rightcare&file_id=${file}&as_attachment=0`;
  let url = `https://rccdn.sunubank.tg/api/1.0/download?app_name=rightcare&file_id=${file}&as_attachment=0`;
  return window.open(url);
}

function replaceMessage(str) {
  const regex = /<p>/gi;
  const regex1 = /[</p>]/gi;
  return str.replace(regex, "").replace(regex1, "");
}

class MessageTicket extends Component {
  constructor(props) {
    super(props);

    const { t } = props;

    this.state = {
      natureView: [],
      lang: "",
      displayMessage: true,
      assegneeModalOpen: false,
      initAgents: [],
      storeInitAgents: [],
      multiValue: "High",
      ticketSubjectSetting: {},
      multiValuestat: "Pending",
      userData: {},
      addAgent: [],
      fileTicket: [],
      searchAgent: "",
      priority: [
        {
          value: "High",
          label: t("tickets.details_ticket.priority_select.high"),
          index: "High"
        },
        {
          value: "Medium",
          label: t("tickets.details_ticket.priority_select.medium"),
          index: "Medium"
        },
        {
          value: "Low",
          label: t("tickets.details_ticket.priority_select.low"),
          index: "Low"
        }
      ],
      selectedNature: {},
      prevticketStatus:{},
      status: [
        {
          value: "Pending",
          label: t("tickets.details_ticket.status_select.pending"),
          index: "Pending"
        },
        {
          value: "Resolved",
          label: t("tickets.details_ticket.status_select.resolved"),
          index: "Resolved"
        },
        {
          value: "New",
          label: t("tickets.details_ticket.status_select.new"),
          index: "New"
        }
      ],
      ticket: {},
      ticketStatus: {},
      ticketPriority: {},
      assigned_agent: {},
      ticketId: "",
      nature: "",
      nature_info: {},
      ticketNature: "",
      notifyClient: 0,
      statusModal: false,
      newComment: undefined
    };
  }

  static contextType = SharedDataContext;

  //Close modal every where Click
  componentDidMount() {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    this.setState({
      userData: context.sharedDataContext.currentUser,
      lang: context.sharedDataContext.defaultLang.value
    });
    this.initSocketGetAgentPlatform(
      context.sharedDataContext.currentUser.userid,
      receptor
    );
    this.initSocketTicketSubjectSettings(receptor);
    this.initSocketTicketNature(receptor);
    //console.log('context',context)
    document.addEventListener("mousedown", this.handelClick, false);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handelClick, false);
  }

  initSocketTicketSubjectSettings = receptor => {
    const { socket } = this.props;
    socket.on(SIO_GET_TICKET_SUBJECT_SETTINGS, event => {
      const response = JSON.parse(event);
      this.setState({ ticketSubjectSetting: response.data[0] });
    });

    TicketSettingsHttpService.getTicketSubjectSettings(receptor).then(
      response => {
        if (response.status === 200 || response.status === 202) {
          //  console.log("test success : ", response);
        } else {
          //  console.log("test error : ", response);
        }
      }
    );
  };

  initSocketTicketNature = receptor => {
    const { socket } = this.props;
    socket.on(SIO_GET_TICKET_NATURE_SETTINGS, event => {
      const response = JSON.parse(event);
      this.onSocketGetTicketNature(response);
    });

    TicketSettingsHttpService.getTicketNatureSettings(receptor).then(
      response => {
        // console.log("response http : ", response);
        if (response.status === 200 || response.status === 202) {
          // console.log("test success : ", response);
        } else {
          //  console.log("test error : ", response);
        }
      }
    );
  };

  onSocketGetTicketNature = response => {
    if (response && (response.status === 200 || response.status === 202)) {
      let r = [];
      response.data.map(item => {
        r.push({
          value: item.id,
          label: item.labels[this.state.lang],
          status: item.status
        });
      });
      this.setState({
        natureView: r.filter(item => item.status !== 0)
      });
    }
  };

  handelClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      assegneeModalOpen: false
    });
  };

  reloaded = () => {
    const context = this.context;
    setTimeout(() => {
      context.setSharedDataContext({
        ...context.sharedDataContext,
        status: 0,
        notification: {
          active: false
        }
      });
    }, 2500);
  };

  ticketRedirect = () => {
    const context = this.context;
    let url = window.location.href;
    let tab = url.split("/");
    let alias = tab[tab.length - 1];
    // console.log(alias)
    return alias !== "tickets" || context.sharedDataContext.status === 1
      ? context.setSharedDataContext({
          ...context.sharedDataContext,
          status: 0
        })
      : this.reloaded();
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps === undefined) {
      return false;
    } else {
      const context = this.context;
      const receptor = {
        headers: {
          "Content-Type": "application/json",
          publickey: context.sharedDataContext.currentUser.publickey,
          apisid: context.sharedDataContext.currentUser.apisid,
          sessionid: context.sharedDataContext.currentUser.session_id
        }
      };
      //  console.log("componentWillReceiveProps");
      //  console.log("this.state.ticketId",this.state.ticketId);
      // //this.initSocketgetAllTicket(receptor);
      if (this.state.ticketId === "") {
        this.setState(
          {
            ticketId: nextProps.ticketId
          },
          () => {
            this.initSocketTicketDetails(this.state.ticketId, receptor);
          }
        );
      }
    }
  };

  timeDiffCalc = timeInMinute => {
    let time = typeof timeInMinute === "undefined" ? 0 : timeInMinute;
    //let diffInMilliSeconds = 0;
    let days = 0;
    let hours = 0;
    let minutes = 0;
    /*if (t === 0) {
                    diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
                    // calculate days
                    days = Math.floor(diffInMilliSeconds / 86400);
                    diffInMilliSeconds -= days * 86400;

                    // calculate hours
                    hours = Math.floor(diffInMilliSeconds / 3600) % 24;
                    diffInMilliSeconds -= hours * 3600;

                    // calculate minutes
                    minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                    diffInMilliSeconds -= minutes * 60;
               } else {*/
    const d = time / (60 * 24);
    days = Math.floor(d);
    const h = (d - days) * 24;
    hours = Math.floor(h);
    const m = (h - hours) * 60;
    minutes = Math.floor(m);
    //}

    return this.showDateCalculate(days, hours, minutes);
  };

  showDateCalculate = (days, hours, minutes) => {
    const { lang } = this.state;
    let difference = "";
    if (days > 0) {
      const d =
        lang === "en" && days > 1
          ? "Days"
          : lang === "en" && days === 1
          ? "Day"
          : lang === "fr" && days > 1
          ? "Jours"
          : "Jour";
      difference += `${days} ${d} `;
    }

    if (hours > 0) {
      const h =
        lang === "en" && hours > 1
          ? "Hours"
          : lang === "en" && hours === 1
          ? "Hour"
          : lang === "fr" && hours > 1
          ? "Heures"
          : "Heure";
      difference += `${hours} ${h} `;
    }

    if (days === 0)
      difference +=
        minutes === 0 || minutes === 1
          ? `${minutes} Minute `
          : `${minutes} Minutes `;

    return difference;
  };

  calculateUpdateTime = time => {
    let now = moment.utc();
    let updated = moment.utc(time);
    const days = now.diff(updated, "days");
    const hours = now.subtract(days, "days").diff(updated, "hours");
    const minutes = now.subtract(hours, "hours").diff(updated, "minutes");
    return this.showDateCalculate(days, hours, minutes);
  };
  //Star Get Agent Ticket
  onSocketGetAgentPlatform = response => {
    //console.log("onSocketGetAgentPlatformfff: ", response);
    if (response && (response.status === 200 || response.status === 202)) {
      // console.log("onSocketGetAgentPlatform : ", response.data);
      this.setState({ initAgents: response.data });
      this.setState({ storeInitAgents: response.data });
    }
  };

  initSocketGetAgentPlatform = (userid, receptor) => {
    // console.log("initSocketGetAgentPlatform ** : ");
    const { socket } = this.props;
    socket.on(SIO_AGENT_PLATFORM, event => {
      const response = JSON.parse(event);
      this.onSocketGetAgentPlatform(response);
    });

    CreateTicketHttpService.getAgentPlatFrom(userid, receptor).then(
      response => {
        //  console.log("getAgentPlatFrom : ", response);

        if (response.status === 200 || response.status === 202) {
          // console.log("test success : ", response);
        }
      }
    );
  };

  /* START GET TICKETS SETTINGS $$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
  // onSocketGetTicketDetails = response => {
  //   socket.on(SIO_GET_TICKET_DETAILS, response => {
  //     //console.log("SIO_GET_TICKET8DETAILS : ", response);
  //   });
  // };

  initSocketTicketDetails = (ticketId, receptor) => {
    // console.log("initSocketTicketDetails ");
    const { socket } = this.props;

    socket.on(SIO_GET_TICKET_DETAILS, event => {
      const response = JSON.parse(event);
      this.setState({
        ticket: response.data,
        ticketPriority: {
          value: response.data.priority?.name,
          label: response.data.priority?.name
        },
        selectedNature: {
          value: response.data.nature,
          label: response.data.nature_info?.labels[this.state.lang]
        },
        ticketStatus: {
          value: response.data.status?.name,
          label: response.data.status?.name
        },
        prevticketStatus: {
          value: response.data.status?.name,
          label: response.data.status?.name
        },
        fileTicket: response.data.files || []
      });
    });
    GetAllTicketsHttpService.getTicketDetails(ticketId, receptor).then(
      response => {
        //console.log("getDatasTicketDetails : ", response);

        if (response.status === 200 || response.status === 202) {
          // console.log('test success : ', response);
        }
      }
    );
  };
  // add agent
  handleAddAgent = userId => {
    const { addAgent } = this.state;
    const updatedAgents = this.state.initAgents;
    const isExist = this.state.initAgents.findIndex(
      agent => agent.user_id === userId
    );
    for (let agent of updatedAgents) {
      agent.checked = false;
    }
    if (isExist !== -1) {
      updatedAgents[isExist].checked = true;
      this.setState(
        { addAgent: [], userData: { ...this.state.userData, checked: false } },
        () => {
          const add = this.state.initAgents.find(
            agent => agent.user_id === userId
          );
          this.setState({ addAgent: [add] });
        }
      );

      // addAgent.push(add);
      // this.setState({ addAgent });
    }
    this.setState({ initAgents: updatedAgents });
  };

  //remove agent
  handleRemoveAgent = userId => {
    const updatedAgents = this.state.initAgents;
    const isExist = this.state.initAgents.findIndex(
      agent => agent.user_id === userId
    );

    if (isExist !== -1) {
      updatedAgents[isExist].checked = false;
      const removeAgent = this.state.addAgent.filter(
        agent => agent.user_id !== userId
      );
      this.setState({ addAgent: removeAgent });
    }
    this.setState({ initAgents: updatedAgents });
  };

  // add My self
  handleAddMe = user => {
    const updatedAgents = this.state.initAgents;
    //const { addAgent } = this.state;
    const isExist = this.state.addAgent.findIndex(
      agent => agent.user_id === user.user_id
    );

    if (isExist === -1) {
      const { userData, addAgent } = this.state;
      userData.checked = true;
      for (let agent of updatedAgents) {
        agent.checked = false;
      }
      this.setState({ userData, addAgent: [] }, () => {
        this.setState({ addAgent: [userData] });
      });

      //  addAgent.push(userData);
    }
  };

  //handle remove me
  handleRemoveMe = user => {
    const isExist = this.state.addAgent.findIndex(
      agent => agent.user_id === user.user_id
    );

    if (isExist !== -1) {
      const { userData } = this.state;
      userData.checked = false;
      this.setState({ userData });

      const removeAgent = this.state.addAgent.filter(
        agent => agent.user_id !== user.user_id
      );
      this.setState({ addAgent: removeAgent });
    }
  };

  //search Agent
  handleInputSearchChange = event => {
    const { value } = event.currentTarget;
    this.setState({ searchAgent: value });

    const contentSearch = value;

    if (contentSearch !== "") {
      this.setState(prevState => ({
        ...prevState,
        initAgents: this.state.storeInitAgents.filter(
          option =>
            option.firstname
              .toLowerCase()
              .includes(contentSearch.toLowerCase()) ||
            option.lastname.toLowerCase().includes(contentSearch.toLowerCase())
        )
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        initAgents: this.state.storeInitAgents
      }));
    }
  };

  // change ticket priotity
  handleOnChangePrio = value => {
    this.setState({ ticketPriority: value }, () =>
      this.handleSubmitUpdateTicket(1)
    );
  };
  // change ticket nature
  handleOnChangeNatu = value => {
    this.setState({ ticketNature: value.value }, () =>
      this.handleSubmitUpdateTicket(3)
    );
  };
  // change ticket status
  handleOnChangeStat = value => {
    // console.log("value", value);
    this.setState({statusModal:true})
    this.setState({ ticketStatus: value }, () => {
      //this.handleSubmitUpdateTicket(2)
    });
  };
  handleOnChangeStatWrong = value => {
    // console.log("value", value);
    // this.setState({statusModal:true})
    this.setState({ ticketStatus: value }, () => {
      this.handleSubmitUpdateTicket(2)
    });
  };
  // Update Ticket
  handleSubmitUpdateTicket(state) {
    this.setState({close_loading:true})
    let prio = {};
    const { addAgent, ticketPriority, ticketStatus } = this.state;
    if (state === 0 && addAgent.length !== 0) {
      this.buildDataUpdateTicket(this.state.addAgent[0], state);
      this.initSocketUpdateTicket();
    }
    if (state === 1) {
      if (ticketPriority.value === "High") {
        prio.name = ticketPriority.value;
        prio.label = "Very urgent";
      } else if (ticketPriority.value === "Medium") {
        prio.name = ticketPriority.value;
        prio.label = "Urgent";
      } else {
        prio.name = ticketPriority.value;
        prio.label = "Not urgent";
      }
      this.buildDataUpdateTicket(prio, state);
      this.initSocketUpdateTicket();
    }
    // if (state === 2) {
    //   if (ticketNature.value === "Incident") {
    //     prio.name = ticketNature.value;
    //     prio.label = "Incident";
    //   } else {
    //     prio.name = ticketNature.value;
    //     prio.label = "Request";
    //   }
    //   this.buildDataUpdateTicket(prio, state);
    //   this.initSocketUpdateTicket();
    // }
    if (state === 2) {
      if (ticketStatus.value === "Resolved") {
        prio.name = ticketStatus.value;
        prio.label = "Ticket resolved & close";
        prio.isWrong = this.state.isWrong || undefined
      } else if (ticketStatus.value === "Pending") {
        prio.name = ticketStatus.value;
        prio.label = "On-going ticket";
      } else {
        prio.name = ticketStatus.value;
        prio.label = "Ticket without agent assign";
      }

      this.buildDataUpdateTicket(prio, state);
      this.initSocketUpdateTicket();
    }

    if (state === 3) {
      prio.name = this.state.ticketNature;
      prio.label = "Update nature";
      this.buildDataUpdateTicket(prio, state);
      this.initSocketUpdateTicket();
    }

    if (state === 9) {
      let c = {
        message: this.state.newComment,
        notifyClient: this.state.notifyClient,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        agent:
          this.state.userData.firstname + " " + this.state.userData.lastname
      };
      this.buildDataUpdateTicket(c, state);
      this.initSocketUpdateTicket();

      this.setState({
        notifyClient: 0,
        statusModal:false,
        newComment: undefined
      });
    }

    if (state === 10) {
      prio.name = "wrong";
      prio.label = "Wrong ticket";
      this.buildDataUpdateTicket(prio, state);
      this.initSocketUpdateTicket();
  }
  }
  // build Data Update ticket
  buildDataUpdateTicket = (dataToUpdate, state) => {
    // console.log('dataToUpdate :',dataToUpdate,' ;state :',state)
    const updateTicket = {
      sio_channel: SIO_TICKET_UPDATE,
      lang: this.state.lang,
      updated_by: {
        user_id: this.state.userData.userid,
        firstname: this.state.userData.firstname,
        lastname: this.state.userData.lastname,
        email: this.state.userData.email,
        phone: this.state.userData.phone
      },
      isWrong: this.state.isWrong || undefined,
      type:
        state === 0
          ? "assigned_agent"
          : state === 1
          ? "priority"
          : state === 3
          ? "nature"
          : state === 9
          ? "comments"
          : state === 10
          ? "wrong"
          : "status",
      value: dataToUpdate
    };
    if (localStorage && updateTicket) {
      localStorage.setItem(
        "sv_tmp_update_ticket",
        JSON.stringify(updateTicket)
      );
    }
  };
  //InitSocket Update Ticket

  initSocketUpdateTicket = () => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };
    const { socket } = this.props;
    // console.log("initSocketUpdateTicket : ", context);
    socket.on(SIO_TICKET_UPDATE, event => {
      const response = JSON.parse(event);
      //console.log(response);
      // console.log("initSocketUpdateTicket : ", response);
      this.props.handleMessageTicket(
        "successUpdate",
        response.data,
        response.data.id
      );
      this.initSocketTicketDetails(response.data.id, receptor);
      this.onSocketUpdateTicket(response);
      // console.log(event);
      //this.setState({ newComment: EditorState.createEmpty() });
      //this.setState({ notifyClient: 0 });
    });
    this.handleUpdateTicketSubmit(this.state.ticketId);
  };
  //onsocket Update Ticket
  onSocketUpdateTicket = response => {
    if (response && response.status === 200) {
      //console.log("onSocketUpdateTicket")
      this.setState({
        assegneeModalOpen: false
      });
    }
    if(response.data.isWrong){
      this.props.dispatch({type:"SET", key:"duplicationData", payload:response.data})
    }
  };
  //handleUpdateTicketSubmit
  handleUpdateTicketSubmit = id => {
    const context = this.context;
    const receptor = {
      headers: {
        "Content-Type": "application/json",
        publickey: context.sharedDataContext.currentUser.publickey,
        apisid: context.sharedDataContext.currentUser.apisid,
        sessionid: context.sharedDataContext.currentUser.session_id
      }
    };

    const dataUpdateTicket = JSON.parse(
      localStorage.getItem("sv_tmp_update_ticket")
    );

    UpdateTicketHttpService.updateTicket(id, dataUpdateTicket, receptor)
      .then(response => {
        this.setState({close_loading:false})
        //   console.log("UpdateTicketHttpService : ", response);

        if (response && response.data && response.data.status === 202) {
          // this.setState({ dataInputTicket: [] });
          // this.setState({ objetTicket: "" });
          // this.setState({ messageTicket: EditorState.createEmpty() });

          localStorage.removeItem("sv_tmp_update_ticket");
          if(this.state.newComment?.length){
            this.handleSubmitUpdateTicket(9);
          }
          //this.props.handleCreateTicket()

        } else {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.handleMessageTicket("error", "--", "--");
        }
      })
      .catch(error => {
        this.setState({close_loading:false})
        // console.log("**** print error ****", error);

        // eslint-disable-next-line react/destructuring-assignment
        this.props.handleMessageTicket("error", "--", "--");
      });
  };
  onEditorStateChange = msg => {
    const valueEditor = draftToHtml(
      convertToRaw(msg.getCurrentContent())
    );
    this.setState({ newComment: valueEditor });
  };
  render() {
    const { i18n, t, kind, defaultLang, handleCreateTicket } = this.props;

    const {
      displayMessage,
      status,
      priority,
      // nature,
      assegneeModalOpen,
      ticket,
      ticketPriority,
      // ticketNature,
      ticketStatus,
      addAgent,
      fileTicket,
      lang
    } = this.state;

    // console.log("render ticketId", this.state.ticketId);
    // console.log(" render ticket", ticket);
    // console.log("ticketPriority", ticketPriority);

    const statusView = status.map((elm, i) => ({
      ...elm,
      label: (
        <>
          <button
            className="ticket-select-status"
            style={{
              background: `${
                elm.index === "Pending" || elm.index === "en attente"
                  ? "#FF9B21"
                  : elm.index === "New" || elm.index === "Nouveau"
                  ? "#0089E1"
                  : "#00BD39"
              }`
            }}
          />
          {elm.label}
        </>
      )
    }));

    const priorutyView = priority.map((elm, i) => ({
      ...elm,
      label: (
        <p
          className="ticket-select-priority"
          style={{
            background: `${
              elm.index === "High"
                ? "#eb592321"
                : elm.index === "Low"
                ? "#6572884a"
                : "#ff9b214d"
            }`,
            color: `${
              elm.index === "High"
                ? "#EB5923"
                : elm.index === "Low"
                ? "#657288"
                : "#FF9B21"
            }`
          }}
        >
          {elm.label}
        </p>
      )
    }));

    // const natureView = nature.map((elm, i) => ({
    //   ...elm,
    //   label: (
    //     <p
    //       className="ticket-select-priority"
    //       style={{
    //         background: `${
    //           elm.index === "Incident" ? "#eb592321" : "#ff9b214d"
    //         }`,
    //         color: `${elm.index === "Incident" ? "#EB5923" : "#FF9B21"}`
    //       }}
    //     >
    //       {elm.label}
    //     </p>
    //   )
    // }));

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#ddd" : "#fff",
        color: state.isSelected ? "#000" : "#000",
        cursor: "pointer !important"
      })
    };
    const ticketStatusa = {
      value: ticketStatus.label,
      label:
        ticketStatus.label === "Resolved" || ticketStatus.label === "Résolu"
          ? t("settings.tickets_status_content.resolved")
          : ticketStatus.label === "Pending" ||
            ticketStatus.label === "en attente"
          ? t("settings.tickets_status_content.pending")
          : t("settings.tickets_status_content.new")
    };
    // console.log('ticketStatusa',ticketStatusa)
    const ticketPrioritya = {
      value: ticketPriority.label,
      label:
        ticketPriority.label === "High" || ticketPriority.label === "Haute"
          ? t("settings.tickets_priority_content.hight")
          : ticketPriority.label === "Low" || ticketPriority.label === "Faible"
          ? t("settings.tickets_priority_content.low")
          : t("settings.tickets_priority_content.medium")
    };

    // const ticketNaturea = {
    //   value: ticketNature.label,
    //   label:
    //     ticketNature.label === "Incident" || ticketNature.label === "Incident"
    //       ? t("settings.tickets_nature_content.incident")
    //       : t("settings.tickets_nature_content.request")
    // };

    //GET CUSTOMER INFO
    const customerEmail = ticket.customer_information
      ? ticket.customer_information
          .map((elm, i) => {
            if (elm !== null && i > 1 && elm.type === "email") {
              return elm.value;
            }
          })
          .join(" ")
      : false;

    const customerPhone = ticket.customer_information
      ? ticket.customer_information
          .map((elm, i) => {
            if (elm !== null && i > 1 && elm.name === "Telephone") {
              return elm.value;
            }
          })
          .join(" ")
      : false;

    const customerName = ticket.customer_information
      ? ticket.customer_information
          .map((elm, i) => {
            if (elm !== null && i < 2) {
              return elm.value;
            }
          })
          .join(" ")
      : false;

    const custom_ticket_fields_handler = custom_ticket_fields => {
      let key = "label_" + defaultLang.value;
      if (custom_ticket_fields !== undefined) {
        return custom_ticket_fields.map(elm => (
          <div
            className="display-user-info"
            // data-tooltip={elm["value"]}
            style={{
              display: "inline-block",
              minWidth: "30%",
              marginBottom: 15,
              marginRight: 15,
              cursor: "default",
              padding: 10
            }}
          >
            <div style={{ fontWeight: "bold" }}>{elm[key]}</div>
            <div className="custumers-detail custumers-detail-style">{elm["value"]}</div>
          </div>
        ));
        // return custom_ticket_fields.map(elm => (
        // <div className="ticketAgent-container">
        // {/*<div style={{background:"silver"}}>{JSON.stringify(elm)}</div>*/}
        // {console.log(this.state)}
        // <span className="ticketkeys">{elm[key]}</span>{" "}
        // {elm.type === 'Open Text' &&
        //         <Form.Control value={elm.value} disabled readOnly style={{maxWidth: 118}}/>}
        //         {elm.type === 'Options' && <Select
        //             isDisabled
        //             styles={customStyles}
        //             theme={theme => ({
        //                 ...theme,
        //                 colors: {
        //                     ...theme.colors,
        //                     primary: "#eee",
        //                     primary25: "#eee"
        //                 }
        //             })}
        //             value={{label: elm["value"], value: elm["value"]}}
        //             isSearchable={false}
        //             className="ticket-Select"
        //             placeholder=""
        //         />}
        //         {!elm.type && <div>{elm["value"]}</div>}
        //     </div>
        // ));
      }
    };

    // console.log(ticket);
    const saveComment = () => {
      // console.log(this.state.newComment);
      // console.log(ticket);
      // console.log(moment().format("YYYY-MM-DD HH:mm:ss"));
      // console.log(SharedDataContext);
      this.handleSubmitUpdateTicket(9);
    };
    const makeWrong = () => {
      const that = this
      this.setState({isWrong:true}, function () {
        that.handleOnChangeStatWrong({
          value: "Resolved",
          index: "Resolved"
        })
      })
    };
    const writeNewComment = e => {
      // console.log(e);
      this.setState({
        newComment: e
      });
    };
    const notifyToClient = e => {
      this.setState({
        notifyClient: e
      });
    };
    const toggleNotifyToClient = e => {
      this.setState({
        notifyClient: this.state.notifyClient === 0? 1 : 0
      });
    };
    
    const cancelStatusChange = () => {
      console.log(this.state.prevticketStatus)
      this.setState({
        notifyClient: 0,
        statusModal:false,
        ticketStatus:this.state.prevticketStatus,
        newComment: EditorState.createEmpty() || undefined
      });
    }
    const saveStatusAndComment = () => {
      /*this.setState({ ticketStatus: value }, () => {
        this.handleSubmitUpdateTicket(2);
        if(newComment.length){
          this.handleSubmitUpdateTicket(9);
        }
      });*/
      this.handleSubmitUpdateTicket(2);
      //this.handleSubmitUpdateTicket(9);
      /*if(this.state.newComment?.length){
        this.handleSubmitUpdateTicket(9);
      }*/
    }
    const toggleStatusModal = (e) => {
      this.setState({
        statusModal: e
      });
    };

    const alias = window.location.hostname.split(".")[0];
    const mentionAgents = (this.state.initAgents||[]).map((a)=>{
      return {text:a.firstname + ' ' + a.lastname,
        value: a.firstname + ' ' + a.lastname,
        url: `https://${alias}.admin.sunubank.tg/users/list`
      }
    })
    const suggestions = mentionAgents || []

    const uploadCallback = (file, callback) => {
      console.log(file);
      return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        console.log(reader);
        reader.onloadend = async () => {
          const form_data = new FormData();
          form_data.append("file", file);
          console.log(form_data)
          resolve({ data: {link:"https://example.com", data: form_data} });
        };
        reader.readAsDataURL(file);
      });
    };
    return (
      <>
        <div className="header-indicator">
          <Link to={"/tickets"} onClick={() => this.ticketRedirect()}>
            <h3 className="header-indic-title1">
              {t("tickets.details_ticket.header.ticket_table")}
            </h3>
          </Link>
          {" > "}
          <p className="header-indic-title2">
            {t("tickets.details_ticket.header.tickets_message")}
          </p>
        </div>
        <div className="ticketnalytics-header">
          <h2 className="dashboard-title">
            {t("tickets.details_ticket.ticket_number")} #{ticket.number} &nbsp; &nbsp;
            {ticket.isWrong ?
              <><br/><small><Badge variant={"danger"}>{t("is wrong")}</Badge></small></> : <></>}
            {ticket.parentTicket ?
              <><br/><small><Badge variant={"warning"}>{t("Parent ticket")} #{ticket.parentTicket.number}</Badge></small></> : <></>}
          </h2>
          <button
            className="create_ticketbtn"
            onClick={() => handleCreateTicket()}
          >
            + {t("tickets.ticket_btn_create").toUpperCase()}
          </button>
        </div>
        <div className="columns analytics-columns MessageTicket-conaitner">
          <div className="section1 tecket-detail">
            <div className="profilePicture-contain">
              <img src={ProfileIcon} className="profilepicture" />
            </div>
            <div className="profil-contain">
              <h3 className="profil-name">
                {ticket && ticket.created_by
                  ? ticket.created_by.first_name +
                    " " +
                    ticket.created_by.last_name
                  : false}
              </h3>
              {ticket.created_at && (
                <p className="creationDate">{ticket.created_at} (GMT)</p>
              )}
              <h1 className="ticketTitle">{ticket.subject}</h1>
              <div className="dashet-line" />
              <h3 className="customer-text text-blue">
                {t("tickets.details_ticket.customer_details")}
              </h3>
              <div className="massageticket-input-cont">
                <div className="display-user-info" data-tooltip={customerName}>
                  <img
                    src={User}
                    className="massageticket-log"
                    alt="icon user"
                  />
                  <span className="custumers-detail">
                    {customerName
                      ? customerName.length > 23
                        ? customerName.substring(0, 20) + "..."
                        : customerName
                      : ""}
                  </span>
                </div>
                <div
                  className="display-user-info"
                  data-tooltip={customerEmail ? customerEmail : ""}
                >
                  <img
                    src={Envelope}
                    className="massageticket-log"
                    alt="icon mail"
                  />{" "}
                  <span className="custumers-detail">
                    {" "}
                    {/* {customerEmail===undefined ? '' : customerEmail} */}
                    {customerEmail
                      ? customerEmail.length > 23
                        ? customerEmail.substring(0, 20) + "..."
                        : customerEmail
                      : ""}
                  </span>
                </div>
                <div
                  className="display-user-info"
                  data-tooltip={customerPhone ? customerPhone : ""}
                >
                  <img
                    src={Phone}
                    className="massageticket-log"
                    alt="icon phone"
                  />{" "}
                  <span className="custumers-detail">
                    {customerPhone
                      ? customerPhone.length > 23
                        ? customerPhone.substring(0, 20) + "..."
                        : customerPhone
                      : ""}
                  </span>
                </div>
              </div>
              <div hidden={!ticket.custom_ticket_fields?.length}>
                <div
                  style={{
                    marginTop: "30px",
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{
                      borderTop: "1px dashed #c8d3d6",
                      width: "100%",
                      height: "1px"
                    }}
                  />
                  <div
                    style={{
                      borderTop: "1px dashed #c8d3d6",
                      width: "100%",
                      height: "1px"
                    }}
                  />
                </div>
              </div>

              <div hidden={!ticket.custom_ticket_fields?.length}>
                <h3 className="customer-text text-blue">
                  {t("tickets.details_ticket.custom_fields_details")}
                </h3>
                <div
                  className={"massageticket-input-cont"}
                  style={{ flexWrap: "wrap", justifyContent: "inherit" }}
                >
                  {custom_ticket_fields_handler(ticket.custom_ticket_fields)}
                </div>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    borderTop: "1px dashed #c8d3d6",
                    width: "100%",
                    height: "1px"
                  }}
                />
                <span className="messagedisplay-btn">
                  <img
                    src={ArrowDown}
                    style={{
                      height: "10px",
                      width: "19px",
                      transform: displayMessage ? "scaleY(1)" : "scaleY(-1)"
                    }}
                    onClick={() =>
                      this.setState({ displayMessage: !displayMessage })
                    }
                  />
                </span>
                <div
                  style={{
                    borderTop: "1px dashed #c8d3d6",
                    width: "100%",
                    height: "1px"
                  }}
                />
              </div>
              <div style={{ minHeight: "200px" }}>
                <h3 className="customer-text text-blue">
                  {t("tickets.details_ticket.ticket_description")}
                </h3>
                <div
                  className="massageticket-container"
                  style={{
                    display: displayMessage ? "block" : "none",
                    height: "100%"
                  }}
                >
                  <p>
                    {ticket.message && typeof ticket.message == "string"
                      ? updateMessage2(ticket.message)
                      : ""}
                  </p>
                </div>
                <div className={"commentsList mt-4 mb-4"}>
                  <hr />
                  <h3 className="customer-text text-blue">
                    {t("tickets.details_ticket.comments")}
                  </h3>
                  <div>
                    {!ticket?.comments?.length && (
                      <div>{t("tickets.details_ticket.no_comments")}</div>
                    )}
                    {ticket?.comments?.constructor.name ==="Array" ? ticket?.comments?.map((c, k) => (
                      <div className="media mt-3">
                        <div className="media-body">
                          <h5 className="mt-0" style={{ fontWeight: "bold" }}>
                            {c.agent} ({t("tickets.details_ticket.on_date")}{" "}
                            {c.date})
                          </h5>
                          <div
                            className={"text-justify"}
                            dangerouslySetInnerHTML={{ __html: c.message }}
                          />
                        </div>
                      </div>
                    )) : <div> </div>}
                  </div>
                </div>
                {ticketStatus.value === "Résolue" ||
                ticketStatus.value === "Resolved" ? (
                  <div> </div>
                ) : (
                  <div className={"commentForm"}>
                  <hr />
                    <div className={"d-flex"}>
                    <div className="form-group" style={{flexGrow:1}}>
                        <label htmlFor="exampleFormControlTextarea1">
                          {t("tickets.details_ticket.enter_comment")}
                        </label>
                        {/*<textarea*/}
                        {/*  className="form-control"*/}
                        {/*  id="exampleFormControlTextarea1"*/}
                        {/*  rows="3"*/}
                        {/*  onChange={e => {*/}
                        {/*    this.setState({*/}
                        {/*      newComment: e.target.value*/}
                        {/*    });*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {" "}*/}
                        {/*</textarea>*/}
                        {/*<ReactQuill
                          theme="snow"
                          // value={this.state.newComment}
                          onChange={writeNewComment}
                        />*/}
                        <Editor
                          //onChange={(e) => console.log(e)}
                          newComment={this.state.newComment}
                          onEditorStateChange={this.onEditorStateChange}
                          toolbar={{
                            fontSize: { className: "fontSizetoolbar" },
                            fontFamily: { className: "fontFamilytoolbar" },
                            textAlign: { inDropdown: true },
                            link: { className: "linktoolbar" },
                            emoji: { className: "emojitoolbar" },
                            image: {
                              urlEnabled: true,
                              uploadEnabled: true,
                              alignmentEnabled: false,
                              // uploadCallback: uploadCallback,
                              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf',
                            },
                            remove: { className: "removetoolbar" },
                            blockType: { className: "blockTypetoolbar" },
                            embedded: { className: "embeddedtoolbar" },
                            inline: {
                              strikethrough: { className: "strikethroughtoolbar" },
                              monospace: { className: "monospacetoolbar" }
                            },
                            list: {
                              indent: { className: "indenttoolbar" },
                              outdent: { className: "outdenttoolbar" }
                            }
                          }}
                          mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions:suggestions
                          }}
                          toolbarCustomButtons={[<RightComCDN />]}
                        />
                      </div>
                      {/*<div style={{ marginTop: 30, marginLeft: 15 }}>
                        
                      </div>*/}
                    </div>
                    <div className={'d-inline-block d-sm-flex align-items-center justify-content-between'}>
                      <div>
                        {/*{ticket?.replay_info?.mobile ?*/}
                      <label class="checkbox">
                        <input key={Math.random()} type="checkbox" onClick={toggleNotifyToClient} checked={this.state.notifyClient} />
                         {" "}{t("tickets.details_ticket.notify_client")}
                      </label>
                        {/*:<span> &nbsp;</span>}*/}
                      </div>
                      <div>
                        <button
                          disabled={!this.state.newComment}
                          onClick={saveComment}
                          type="button"
                          style={{ wordBreak: "initial",height:36,width:'auto'}}
                          className="btn btn-primary create_ticketbtn"
                        >
                          {t("tickets.details_ticket.save_comment")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="dashet-line" /> */}
              <h3
                className="customer-text"
                style={{
                  color: "#4C4C4C",
                  marginLeft: "0",
                  marginBottum: "20px"
                }}
              >
                {fileTicket?.length > 0 && fileTicket[0] !== null
                  ? `${fileTicket?.length} ${t(
                      "tickets.details_ticket.attachement"
                    )}`
                  : false}
              </h3>
              <div className="File-Uplaoded-container">
                {fileTicket[0] !== null
                  ? fileTicket.map((file, i) => {
                      return (
                        <p
                          onClick={() => logout(file)}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            flexDirection: "column"
                          }}
                        >
                          <img
                            src={DownloadIcon}
                            alt="dwonload Icon"
                            style={{ maxWidth: "55%", marginRight: "7px" }}
                          />
                          <p>File {i + 1} </p>
                        </p>
                      );
                    })
                  : false}
                {/*{fileTicket.length > 0 && fileTicket[0] !== null ? (*/}
                {/*  <span className=" assign-agent-btn">+</span>*/}
                {/*) : (*/}
                {/*  false*/}
                {/*)}*/}
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="ticketAgent-container">
              <span className="ticketkeys">
                {t("tickets.details_ticket.date")}
              </span>{" "}
              {ticket.created_at && (
                <p className="ticketinfos displayInput">
                  {ticket.created_at} (GMT)
                </p>
              )}
            </div>
            <div className="ticketAgent-container">
              <span className="ticketkeys">
                {t("tickets.details_ticket.created")}
              </span>{" "}
              <p className="ticketinfos displayInput">
                {" "}
                <img
                  src={ProfileIcon}
                  className="profilepicture-Created"
                />{" "}
                {ticket && ticket.created_by
                  ? ticket.created_by.first_name +
                    " " +
                    ticket.created_by.last_name
                  : false}
              </p>
            </div>
            <div className="ticketAgent-container">
              <span className="ticketkeys ">
                {t("tickets.details_ticket.assignee")}
              </span>{" "}
              <p className="ticketinfos display-user-assegne">
                <img src={ProfileIcon} className="profilepicture-assignee" />
                <span className="custumers-detail">
                  {" "}
                  {ticket &&
                  ticket.assigned_agent &&
                  Object.keys(ticket.assigned_agent).length !== 0
                    ? ticket.assigned_agent.firstname +
                      " " +
                      ticket.assigned_agent.lastname
                    : ""}
                </span>
              </p>{" "}
              <div className="assign-agent-Container">
                {ticketStatus.value === "Résolue" ||
                ticketStatus.value === "Resolved" ? (
                  ""
                ) : (
                  <p
                    className="resseign-messageTick"
                    onClick={() =>
                      this.setState({
                        assegneeModalOpen: !this.state.assegneeModalOpen
                      })
                    }
                    ref={node => (this.node = node)}
                  >
                    {t("tickets.details_ticket.reassign")}
                  </p>
                )}
                {/* composant Assign Agent to Ticket: Boutton Reassign  */}
                <div
                  className="assign-text-modal"
                  style={{
                    display: `${assegneeModalOpen ? "flex" : "none"}`,
                    left: "-144px"
                  }}
                  ref={node => (this.node = node)}
                >
                  <h2 className="title assign-modal-title">
                    {t("tickets.create_ticket.assign_agent_ticket")}
                  </h2>
                  <ul className="menu-list menu-list-ticket">
                    {" "}
                    <li className="assign-self">
                      <img src={ProfileIcon} alt="portrait" />
                      <span className="user-name">
                        {t("tickets.create_ticket.assign_ticket_myself")}
                      </span>
                      {this.state.userData.checked ||
                      this.state.userData.checked === true ? (
                        <span
                          className="remove-user"
                          onClick={e =>
                            this.handleRemoveMe(this.state.userData)
                          }
                        >
                          -
                        </span>
                      ) : (
                        <span
                          className="add-user"
                          onClick={e => this.handleAddMe(this.state.userData)}
                        >
                          +
                        </span>
                      )}
                    </li>
                  </ul>
                  <div className="search-box assign-search">
                    <input
                      className="input"
                      type="text"
                      placeholder={t(
                        "tickets.create_ticket.search_agent_input"
                      )}
                      value={this.state.searchAgent}
                      onChange={e => this.handleInputSearchChange(e)}
                      maxLength={128}
                    />
                    <img src={SearchIcon} alt="search" />
                  </div>
                  <section
                    className="modal-card-body"
                    style={{ width: "100%" }}
                  >
                    {/*  Composant list add Agent Create Ticket */}
                    <ul className=" menu-list menu-list-ticket">
                      {this.state.initAgents &&
                        ticket.assigned_agent &&
                        this.state.initAgents.map((item, i) => {
                          if (
                            ticket.assigned_agent.firstname !==
                              item.firstname &&
                            ticket.assigned_agent.lastname !== item.lastname
                          ) {
                            return (
                              <li key={i}>
                                <img src={ProfileIcon} alt="portrait" />
                                <span className="user-name">
                                  {item.firstname} {item.lastname}
                                </span>
                                {item.checked || item.checked === true ? (
                                  <span
                                    className="remove-user"
                                    onClick={e =>
                                      this.handleRemoveAgent(item.user_id)
                                    }
                                  >
                                    -
                                  </span>
                                ) : (
                                  <span
                                    className="add-user"
                                    onClick={e =>
                                      this.handleAddAgent(item.user_id)
                                    }
                                  >
                                    +
                                  </span>
                                )}
                              </li>
                            );
                          }
                        })}
                    </ul>
                    {/*  */}
                  </section>
                  <footer className="assign-modal-footer">
                    <button
                      className="button is-primary"
                      aria-label="close"
                      style={{ width: "100%" }}
                      onClick={() => this.handleSubmitUpdateTicket(0)}
                    >
                      {t("tickets.create_ticket.Assign")}
                    </button>
                  </footer>

                  {/* End Modal add agnet */}
                </div>

                {/* ** ** ** */}
              </div>
            </div>
            <div className="ticketAgent-container ticketprio">
              <span className="ticketkeys">
                {t("tickets.details_ticket.priority")}
              </span>{" "}
              <Select
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#eee",
                    primary25: "#eee"
                  }
                })}
                options={priorutyView}
                onChange={this.handleOnChangePrio}
                value={ticketPrioritya}
                isSearchable={false}
                className="ticket-Select"
                placeholder={t("translations.select")}
                isDisabled={
                  this.state.ticketSubjectSetting.enable_ticket_subject ||
                  ticketStatus.value === "Résolue" ||
                  ticketStatus.value === "Resolved"
                    ? true
                    : false
                }
              />
              {/* <p className="ticketinfos">Adisa Kola</p> */}
            </div>
            {/* /\Start Ticket Nature */}
            <div className="ticketAgent-container ticketprio">
              <span className="ticketkeys">
                {t("tickets.details_ticket.nature")}
              </span>{" "}
              <Select
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#eee",
                    primary25: "#eee"
                  }
                })}
                options={this.state.natureView}
                onChange={this.handleOnChangeNatu}
                value={this.state.selectedNature}
                isSearchable={false}
                className="ticket-Select"
                placeholder={t("translations.select")}
                isDisabled={
                  //false
                  ticketStatus.value === "Résolue" ||
                  ticketStatus.value === "Resolved"
                    ? true
                    : false
                }
              />
              {/* <p className="ticketinfos">Adisa Kola</p> */}
            </div>
            {/* End Ticket Nature */}
            <div className="ticketAgent-container ticketStatus">
              <span className="ticketkeys">
                {t("tickets.details_ticket.status")}
              </span>{" "}
              <Select
                styles={customStyles}
                key={this.state.ticketStatus.value}
                options={statusView}
                onChange={this.handleOnChangeStat}
                value={ticketStatusa}
                isSearchable={false}
                placeholder={t("translations.select")}
                isDisabled={
                  ticketStatus.value === "Résolue" ||
                  ticketStatus.value === "Resolved"
                    ? true
                    : false
                }
              />
            </div>
            {/*{custom_ticket_fields_handler(ticket.custom_ticket_fields)}*/}
            <div
              className="ticketAgent-container"
              style={{ fontSize: "small" }}
            >
              <span>{t("tickets.details_ticket.handling_time")} :</span> &nbsp;
              <span>{this.timeDiffCalc(ticket.handling_time)}</span>
            </div>
            <div className="ticket-log-container">
              <div className="ticket-log-title">
                {t("tickets.details_ticket.ticket_log_activities")}
              </div>
              {ticket && ticket.logs
                ? ticket.logs.map(
                    (ticket, i) => (
                      // i < 5 ? (
                      <div
                        key={i}
                        className={`ticket-log ${
                          i === ticketsLog.length - 1 ? "last" : ""
                        }`}
                      >
                        {/* <img src={} alt="profile picture"/> */}

                        <img src={ProfileIcon} className="profilepicture-log" />

                        <div className="ticket-activities" style={{paddingBottom:5}}>
                          <p
                            className="activity"
                            style={{ fontSize: "12.5px" }}
                          >
                            {ticket.message_en && ticket.message_fr
                              ? lang === "en"
                                ? updateMessage(ticket.message_en)
                                : updateMessage(ticket.message_fr)
                              : updateMessage(ticket.message)}
                          </p>
                          <p className="activity-date ">
                            {this.calculateUpdateTime(ticket.created_at)}
                            <br/>
                            <span className={"badge badge-secondary"} style={{fontSize:10}}>
                              {ticket.trigger?.firstname || ticket.trigger?.first_name || ticket.trigger?.firstName || ""+" "+ticket.trigger?.lastname || ticket.trigger?.last_name|| ticket.trigger?.lastName || ""}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                    // ) : (
                    //   false
                    // )
                  )
                : false}
            </div>
            {ticketStatus.value === "Résolue" ||
            ticketStatus.value === "Resolved" ? (
              false
            ) : (
              <Dropdown as={ButtonGroup} className={'clode_ticketbtn'}>
                <Button
                  disabled={this.state.close_loading}
                className="clode_ticketbtn"
                onClick={() =>
                  this.handleOnChangeStat({
                    value: "Resolved",
                      index: "Resolved",
                      label: t("tickets.details_ticket.status_select.resolved")
                  })
                }
                  variant="warning">{this.state.close_loading ? <Spinner animation={"border"}/> :t("tickets.details_ticket.close_ticket")}</Button>

                <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" className={'clode_ticketbtn_drop'} />

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      this.handleOnChangeStat({
                        value: "Resolved",
                        index: "Resolved",
                        label: t("tickets.details_ticket.status_select.resolved")
                      })
                    }
                    href="#/close">{t("tickets.details_ticket.close_ticket")}</Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => makeWrong()}
                    href={undefined}>{t("tickets.details_ticket.duplicate_and_close_ticket")}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              // <button
              //   className="clode_ticketbtn"
              //   onClick={() =>
              //     this.handleOnChangeStat({
              //       value: "Resolved",
              //       index: "Resolved"
              //     })
              //   }
              // >
              //   {t("tickets.details_ticket.close_ticket")}
              // </button>
            )}
            {ticket.isWrong && <button
              className="clode_ticketbtn"
              onClick={() => this.props.handleUpdateTicket()}>
              {t("edit_ticket")}
            </button>}
          </div>
        </div>
        <div key={ticketStatus} className={this.state.statusModal ? "modal is-active":"modal"}>
          <div class="modal-background"></div>
          <div class="modal-content">
          <header class="modal-card-head bg-white">
            <p class="modal-card-title">{t("tickets.details_ticket.change_status")}</p>
            <button class="delete" aria-label="close" onClick={cancelStatusChange}></button>
          </header>
          <section class="modal-card-body">
            <div>
            <div className="ticketAgent-container ticketStatus">
              <span className="ticketkeys">
                {t("tickets.details_ticket.change_status")}
              </span>{" "}
              <Select
                  styles={customStyles}
                  options={statusView}
                  onChange={this.handleOnChangeStat}
                  defaultValue={this.state.ticketStatus}
                  key={this.state.ticketStatus.value}
                  isSearchable={false}
                  placeholder={t("translations.select")}
              />
            </div>
            </div>
            <div>
            <div style={{paddingBottom:10}}>{t("tickets.details_ticket.add_comment")}</div>
              <Editor
                //onChange={(e) => console.log(e)}
                newComment={this.state.newComment}
                key={this.state.ticketStatus.value}
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  fontSize: { className: "fontSizetoolbar" },
                  fontFamily: { className: "fontFamilytoolbar" },
                  textAlign: { inDropdown: true },
                  link: { className: "linktoolbar" },
                  emoji: { className: "emojitoolbar" },
                  image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: false,
                    // uploadCallback: uploadCallback,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf',
                  },
                  remove: { className: "removetoolbar" },
                  blockType: { className: "blockTypetoolbar" },
                  embedded: { className: "embeddedtoolbar" },
                  inline: {
                    strikethrough: { className: "strikethroughtoolbar" },
                    monospace: { className: "monospacetoolbar" }
                  },
                  list: {
                    indent: { className: "indenttoolbar" },
                    outdent: { className: "outdenttoolbar" }
                  }
                }}
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions:suggestions
                }}
                toolbarCustomButtons={[<RightComCDN />]}
              />
            </div>
            <div className={'d-inline-block d-sm-flex align-items-center justify-content-between'}>
            <div className={'mt-2'}>
              {/*{ticket?.replay_info?.mobile ?*/}
              <label class="checkbox">
                <input
                  key={Math.random()}
                  type="checkbox"
                  onClick={toggleNotifyToClient}
                  checked={this.state.notifyClient}
                />
                  {" "}{t("tickets.details_ticket.notify_client")}
              </label>
              {/*:<span> &nbsp;</span>}*/}
              </div>
              <div>
              
              </div>
            </div>
            <div style={{paddingTop:30,paddingBottom:15}}>
            <button
                onClick={saveStatusAndComment}
                type="button"
                style={{ wordBreak: "initial",height:36,width:'100%'}}
                className="btn-block create_ticketbtn"
              >
                {t("tickets.details_ticket.approve_status")}
              </button>
            </div>
          </section>
          </div>
        </div>
        <Footer t={t} />
      </>
    );
  }
}

MessageTicket.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  handleCreateTicket: PropTypes.func.isRequired
};

const MessageTicketWithSocket = props => (
  <DataSocketContext.Consumer>
    {socket => <MessageTicket {...props} socket={socket} />}
  </DataSocketContext.Consumer>
);

export default connect(null, null)(MessageTicketWithSocket);
